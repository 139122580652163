import { type ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Radio } from '@m/alchemy-ui';
import { type FormikProps, useFormikContext } from 'formik';
import { useDebounce } from 'use-debounce';
import { EVERYONE_GROUP_ID, everyoneGroup, type MixedTypes } from '../../../common/interfaces';
import { SelectorType } from '../../../common/enums';
import { hasModerationStatus, isUser } from '../helpers/access';
import {
  type User,
  type UserGroup,
  type UserGroupWithModerationStatus,
  type UserWithModerationStatus
} from '../../../common/core-api';
import * as Styled from './AccessSelector.styled';
import { AccessListItem, SearchResult } from '.';

interface RadioItem {
  key: string;
  title: string;
}

const ToUserManagement = (msg: ReactNode[]) => (
  <Styled.StyledLink href="/user/admin_view" target="_blank" rel="noreferrer">
    {msg}
  </Styled.StyledLink>
);

const ToUserGroupInfo = (msg: ReactNode[]) => (
  <Styled.StyledLink
    href="https://help.crowdicity.com/en/articles/4530017-working-with-user-groups"
    target="_blank"
    rel="noreferrer"
  >
    {msg}
  </Styled.StyledLink>
);

export const USER_SELECTION = 'users';
export const USER_GROUP_SELECTION = 'user_groups';

export const AccessSelector = ({ type }: { readonly type: SelectorType }) => {
  const intl = useIntl();
  const { values, setFieldValue }: FormikProps<MixedTypes> = useFormikContext();

  const userIdPostfix = 'u';
  const groupIdPostfix = 'g';

  let users: User[] | UserWithModerationStatus[] = [];
  let groups: UserGroup[] | UserGroupWithModerationStatus[] = [];
  if (type === SelectorType.CHALLENGE || type === SelectorType.PHASE) {
    users = values.access.users || [];
    groups = values.access.groups || [];
  }

  if (type === SelectorType.MILESTONE_MODERATE) {
    users = values.moderators?.users || [];
    groups = values.moderators?.groups || [];
  }

  if (type === SelectorType.MILESTONE_VOTE) {
    users = values.voters?.users || [];
    groups = values.voters?.groups || [];
  }

  const items = [...groups, ...users];

  const radioItems: RadioItem[] = [
    {
      key: USER_SELECTION,
      title: intl.formatMessage({
        id: 'users',
        defaultMessage: 'Users'
      })
    },
    {
      key: USER_GROUP_SELECTION,
      title: intl.formatMessage({
        id: 'userGroups',
        defaultMessage: 'User groups'
      })
    }
  ];
  const [selection, setSelection] = useState(radioItems[0].key);
  const [searchString, setSearchString] = useState('');
  const [searchStringToPass] = useDebounce(searchString, 500);

  const getHeader = () => {
    if (type === SelectorType.MILESTONE_MODERATE) {
      return <FormattedMessage id="chooseUsers" defaultMessage="Choose users" />;
    }

    if (type === SelectorType.MILESTONE_VOTE) {
      return <FormattedMessage id="chooseVoters" defaultMessage="Choose voters" />;
    }

    return (
      <FormattedMessage
        id="accessSelectorHeader"
        defaultMessage="Access to this {type}"
        values={{
          type: type.toLowerCase()
        }}
      />
    );
  };

  const handleAddEveryone = () => {
    if (
      !values.access.groups?.some((group: UserGroupWithModerationStatus) => group.usergroup.id === EVERYONE_GROUP_ID)
    ) {
      setFieldValue('access.groups', [...(values.access.groups || []), everyoneGroup]);
    }
  };

  return (
    <Styled.Container>
      <Styled.SelectorContainer>
        <Styled.StyledPanel
          variant="summary"
          header={<Styled.Header>{getHeader()}</Styled.Header>}
          footer={
            <Styled.Footer>
              <Input
                isFullWidth
                placeholder={intl.formatMessage({
                  id: 'accessSelectorSearchPlaceholder',
                  defaultMessage: 'Search...'
                })}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
              <Styled.FooterRadio>
                <Styled.RadioGroupLabel>
                  {intl.formatMessage({
                    id: 'accessSelectorFooter',
                    defaultMessage: 'Include in search:'
                  })}
                </Styled.RadioGroupLabel>
                <Styled.StyledRadioGroup
                  layout="row"
                  selection={selection}
                  onChange={(selection: string) => {
                    setSelection(selection);
                  }}
                >
                  {({ handleChange }: { handleChange: (item: string) => void }) =>
                    radioItems.map((item: RadioItem) => (
                      <Radio
                        key={item.key}
                        label={<Styled.RadioLabel>{item.title}</Styled.RadioLabel>}
                        name="row-group"
                        checked={item.key === selection}
                        onChange={() => handleChange(item.key)}
                      />
                    ))
                  }
                </Styled.StyledRadioGroup>
              </Styled.FooterRadio>
            </Styled.Footer>
          }
        >
          <Styled.AccessList>
            {items &&
              items.map((item) => (
                <AccessListItem
                  key={
                    isUser(item)
                      ? hasModerationStatus(item)
                        ? item.user.id + userIdPostfix
                        : item.id + userIdPostfix
                      : hasModerationStatus(item)
                        ? item.usergroup.id + groupIdPostfix
                        : item.id + groupIdPostfix
                  }
                  item={item}
                  type={type}
                />
              ))}
            {(type === SelectorType.CHALLENGE || type === SelectorType.PHASE) && (!items || items.length === 0) && (
              <Styled.InvisibleChallengeMessage>
                <FormattedMessage
                  id="invisibleChallengeMessage"
                  defaultMessage="This {name} is currently invisible. {click}, or you can add users and groups using the search function below."
                  values={{
                    name: type,
                    click: (
                      <Styled.AddEveryoneButton priority="secondary" onClick={handleAddEveryone}>
                        {intl.formatMessage({
                          id: 'addEveryone',
                          defaultMessage: 'Click here to make it visible to everyone'
                        })}
                      </Styled.AddEveryoneButton>
                    )
                  }}
                />
              </Styled.InvisibleChallengeMessage>
            )}
          </Styled.AccessList>
        </Styled.StyledPanel>
        <SearchResult
          searchString={searchStringToPass}
          setSearchString={setSearchString}
          radioSelection={selection}
          type={type}
        />
      </Styled.SelectorContainer>
      {(type === SelectorType.CHALLENGE || type === SelectorType.PHASE) && (
        <>
          <Styled.EveryoneMessage>
            <FormattedMessage
              id="addEveryoneMessage"
              defaultMessage="By default, new {name}s are only visible to admins, and the 'Everyone' group is not added. To add the 'Everyone' group, search for it above or {click}."
              values={{
                name: type,
                click: (
                  <Styled.AddEveryoneButton priority="secondary" onClick={handleAddEveryone}>
                    {intl.formatMessage({
                      id: 'clickHere',
                      defaultMessage: 'click here'
                    })}
                  </Styled.AddEveryoneButton>
                )
              }}
            />
          </Styled.EveryoneMessage>
          <Styled.HelpText>
            <FormattedMessage
              id="accessSelectorHelpText"
              defaultMessage="You can create user groups in the <a>User Management area</a>. For more information, take a look at our <b>guide to creating a new group</b>."
              values={{
                a: ToUserManagement,
                b: ToUserGroupInfo
              }}
            />
          </Styled.HelpText>
        </>
      )}
    </Styled.Container>
  );
};

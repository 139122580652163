import styled from 'styled-components';
import { Field, ImportantIcon, Input, Popper, PopperBox, units } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { type FormikProps } from 'formik';
import { useFormikContext } from 'formik';
import { type ReactNode } from 'react';
import { Container } from '../../../common/components';
import { SelectorType } from '../../../common/enums';
import { type Milestone, VotingType } from '../../../common/core-api';
import { useFieldError } from '../../../hooks';
import { HelpText, StyledWarningIcon, Title, WarningMessage } from './MilestoneForm.styled';
import { AccessSelector, Scorecard, VotingSelector } from '.';

const VoteTargetLabel = styled.div`
  display: flex;
  align-items: center;
`;

const VoteTargetInfo = styled(ImportantIcon)`
  margin-left: ${units(0.5)};
`;

const VoteTargetInput = styled(Input)`
  width: ${units(10)};
  max-width: ${units(10)};
  input {
    min-width: ${units(8)};
  }
`;

const ScorecardWrapper = styled.div`
  width: 60%;
`;

const MilestoneVoterSelectHelpTextLink = (msg: ReactNode) => (
  <a
    href="https://help.crowdicity.com/en/articles/4530112-creating-a-milestone-challenge"
    target="_blank"
    rel="noreferrer"
  >
    {msg}
  </a>
);

export const MilestoneVoting = () => {
  const intl = useIntl();
  const { values, handleChange, handleBlur }: FormikProps<Milestone> = useFormikContext();

  const [fieldError] = useFieldError();

  const { target, voting, minimumVotes, voters } = values;

  const noVoters = (!voters?.users || voters.users.length === 0) && (!voters?.groups || voters.groups.length === 0);

  return (
    <>
      <Container>
        <Title>
          {intl.formatMessage({
            id: 'votingType',
            defaultMessage: 'Voting type'
          })}
        </Title>
        <VotingSelector type={SelectorType.MILESTONE_VOTE} />
      </Container>
      <Container>
        <Title>
          {intl.formatMessage({
            id: 'usersWhoCanVote',
            defaultMessage: 'Users who can vote'
          })}
        </Title>
        <AccessSelector type={SelectorType.MILESTONE_VOTE} />
        <HelpText>
          <FormattedMessage
            id="milestoneVoterSelectHelpText"
            defaultMessage="Add or edit users who are allowed to vote on this milestone. For more info, please see our guide to <a>working with milestones</a>"
            values={{
              a: MilestoneVoterSelectHelpTextLink
            }}
          />
        </HelpText>
        {noVoters && (
          <WarningMessage>
            <StyledWarningIcon aria-hidden="true" />
            {intl.formatMessage({
              id: 'noVotersWarning',
              defaultMessage:
                'No voters have been configured for this milestone, so ideas will be unable to receive votes.'
            })}
          </WarningMessage>
        )}
      </Container>
      <Container>
        <Field
          isFullWidth
          label={
            <VoteTargetLabel>
              {voting?.type === VotingType.SCORECARD
                ? intl.formatMessage({ id: 'scoreTarget', defaultMessage: 'Average Score Target' })
                : intl.formatMessage({ id: 'voteTarget', defaultMessage: 'Vote Target' })}
              <Popper
                placement="top"
                mode="tooltip"
                overlay={
                  <PopperBox hasArrow colorScheme="alchemyDark">
                    {voting?.type === VotingType.SCORECARD
                      ? intl.formatMessage({
                          id: 'scoreTargetInfo',
                          defaultMessage:
                            'The average score target is the score that an idea must receive to progress to this milestone. The score is calculated as an average of the scorecard criteria ratings submitted by voters.'
                        })
                      : intl.formatMessage({
                          id: 'voteTargetInfo',
                          defaultMessage:
                            'The vote target is the number of votes that an idea must get to reach this milestone. One vote is counted as 1 point. So if you would like an idea to achieve 50 votes enter 50 in the "Milestone Target" field'
                        })}
                  </PopperBox>
                }
                trigger={
                  <VoteTargetInfo
                    aria-label={intl.formatMessage({ id: 'voteTargetInfo', defaultMessage: 'Vote info' })}
                  />
                }
              />
            </VoteTargetLabel>
          }
          required
          status={fieldError('target')}
          input={
            <VoteTargetInput
              maxLength={4}
              name="target"
              value={target || undefined}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          helpText={
            <HelpText>
              {voting?.type === VotingType.SCORECARD
                ? intl.formatMessage({
                    id: 'scoreTargetHelpText',
                    defaultMessage: "Enter the average that's needed for the post to progress"
                  })
                : intl.formatMessage({
                    id: 'voteTargetHelpText',
                    defaultMessage: 'Enter the number of votes you require an idea to achieve'
                  })}
            </HelpText>
          }
        />
      </Container>
      {voting?.type === VotingType.SCORECARD && (
        <>
          <Container>
            <Field
              isFullWidth
              label={intl.formatMessage({ id: 'mininumVotes', defaultMessage: 'Minimum number of ratings required' })}
              required
              status={fieldError('minimumVotes')}
              input={
                <VoteTargetInput
                  name="minimumVotes"
                  maxLength={4}
                  value={minimumVotes || undefined}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
            />
          </Container>
          <Container>
            <ScorecardWrapper>
              <Scorecard type={SelectorType.MILESTONE_VOTE} />
            </ScorecardWrapper>
          </Container>
        </>
      )}
    </>
  );
};

import { gql } from '@apollo/client';

export const SAVE_SOCIAL_MEDIA = gql`
  mutation saveSocialMedia($input: [SocialLinkInput!]!) {
    saveSocialMedia(input: $input) {
      success
      message
    }
  }
`;

import type React from 'react';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { ArrowDownIcon, ArrowUpIcon } from '@m/alchemy-ui';
import { UserContext } from '../../../context';
import { useRoutePath } from '../../../hooks';
import { type ProfileTab } from '../Profile';
import * as Styled from './Menu.styled';

interface TabItem {
  label: React.ReactNode | string;
  url: string;
  isPublic?: boolean;
}

interface DropdownProps {
  readonly title: string;
  readonly list: TabItem[];
  readonly selectedTab: string;
  readonly selected: (tab: string) => void;
}

const Dropdown = ({ title, list, selectedTab, selected }: DropdownProps) => {
  const isSelected = list?.some(({ url }) => selectedTab.includes(url));
  const [isListOpen, setIsListOpen] = useState(isSelected);
  const toggleList = () => {
    setIsListOpen((prevState) => !prevState);
  };

  if (list.length === 0) {
    return null;
  }

  return (
    <Styled.Wrapper isSelected={isSelected}>
      <Styled.ToggleButton data-testid="dropdown" onClick={toggleList} isSelected={isSelected}>
        <div>{title}</div>
        {isListOpen ? <ArrowUpIcon aria-hidden="true" /> : <ArrowDownIcon aria-hidden="true" />}
      </Styled.ToggleButton>
      {isListOpen &&
        list.map((tab) => (
          <Styled.Tab
            isSubtab
            role="tab"
            onClick={() => selected(tab.url)}
            key={tab.url}
            isActive={selectedTab.includes(tab.url)}
          >
            {tab.label}
          </Styled.Tab>
        ))}
    </Styled.Wrapper>
  );
};

export const Menu = ({
  ownerTabs,
  userTabs,
  setSelectedTab,
  userId,
  selectedTab
}: {
  readonly ownerTabs: ProfileTab[];
  readonly userTabs: ProfileTab[];
  readonly setSelectedTab: (arg0: string) => void;
  readonly selectedTab: string;
  readonly userId: string;
}) => {
  const intl = useIntl();
  const currentUser = useContext(UserContext);
  const isProfileOwner = currentUser.id === userId;
  const [addRoutePath] = useRoutePath();
  const selectTabHandler = (tab: string) => {
    const url = isProfileOwner ? tab : `${tab}/${userId}`;
    addRoutePath(url);
    setSelectedTab(url);
  };

  return (
    <div role="tablist">
      {isProfileOwner ? (
        <>
          {ownerTabs.map((tab) => (
            <Styled.Tab
              role="tab"
              onClick={() => selectTabHandler(tab.url)}
              key={tab.url}
              isActive={tab.url === selectedTab}
            >
              {tab.label}
            </Styled.Tab>
          ))}
          <Dropdown
            selectedTab={selectedTab}
            list={userTabs}
            title={intl.formatMessage({ id: 'more', defaultMessage: 'More' })}
            selected={selectTabHandler}
          />
        </>
      ) : (
        userTabs
          .filter(({ isPublic }) => isPublic)
          .map((tab) => (
            <Styled.Tab
              role="tab"
              onClick={() => selectTabHandler(tab.url)}
              key={tab.url}
              isActive={selectedTab.includes(tab.url)}
            >
              {tab.label}
            </Styled.Tab>
          ))
      )}
    </div>
  );
};

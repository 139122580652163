import Highcharts from 'highcharts/highstock';
import { type IntlShape } from 'react-intl';

// Do not show all menu items
const getMenuItems = () => Highcharts?.defaultOptions?.exporting?.buttons?.contextButton?.menuItems?.slice(1, 10);

export const downloadIcon = () => {
  const path = [
    'M7.661,12.853l0,0a.5.5,0,0,0,.166.11.5.5,0,0,0,.542-.109l0,0,3.459-3.483a.5.5,0,0,0-.707-.707L8.5,11.307V.5a.5.5,0,0,0-1,0V11.277L4.874,8.652a.5.5,0,0,0-.707.707Z',
    'M16,12v-.5a.5.5,0,0,0-1,0v1h0V14.04a.955.955,0,0,1-.946.96H1.943A.952.952,0,0,1,1,14.044V12H1v-.5a.5.5,0,0,0-1,0v1H0V14.04A1.952,1.952,0,0,0,1.943,16H14.058A1.952,1.952,0,0,0,16,14.04V12Z'
  ];
  return path;
};

interface chartPoint extends Highcharts.Point {
  endDate: string;
  name: string;
  startDate: string;
  ideas: number;
}

interface chartTooltip extends Highcharts.TooltipFormatterContextObject {
  point: chartPoint;
}

export const ideaStatsChartOptions = (intl: IntlShape) => ({
  credits: {
    enabled: false
  },
  navigator: {
    enabled: false
  },
  chart: {
    renderTo: 'container',
    type: 'area',
    width: 850
  },
  legend: {
    enabled: false
  },
  rangeSelector: {
    selected: 6,
    buttons: [
      {
        type: 'day',
        count: 1,
        text: intl.formatMessage({ id: '1d', defaultMessage: '1d' })
      },
      {
        type: 'month',
        count: 1,
        text: intl.formatMessage({ id: '1m', defaultMessage: '1m' })
      },
      {
        type: 'month',
        count: 3,
        text: intl.formatMessage({ id: '3m', defaultMessage: '3m' })
      },
      {
        type: 'month',
        count: 6,
        text: intl.formatMessage({ id: '6m', defaultMessage: '6m' })
      },
      {
        type: 'ytd',
        text: intl.formatMessage({ id: 'YTD', defaultMessage: 'YTD' })
      },
      {
        type: 'year',
        count: 1,
        text: intl.formatMessage({ id: '1y', defaultMessage: '1y' })
      },
      {
        type: 'all',
        text: intl.formatMessage({ id: 'all', defaultMessage: 'All' })
      }
    ],
    inputEnabled: false
  },
  exporting: {
    buttons: {
      contextButton: {
        theme: {
          states: {
            hover: {
              fill: '#ffffff'
            },
            select: {
              fill: '#ffffff',
              stroke: '#ffffff'
            }
          }
        },
        symbol: 'download',
        symbolStrokeWidth: 0.8,
        y: 6.5,
        menuItems: getMenuItems()
      }
    }
  },
  xAxis: {
    minRange: 1000 * 60 * 60 * 24
  },
  yAxis: {
    min: 0,
    minRange: 0.1,
    allowDecimals: false
  },
  chartOptions: {
    scrollbar: {
      enabled: false
    }
  },
  plotOptions: {
    area: {
      fillColor: '#7cb5ec',
      threshold: null
    }
  }
});

export const userLoginsStatsChartOptions = (intl: IntlShape) => ({
  credits: {
    enabled: false
  },
  navigator: {
    enabled: false
  },
  chart: {
    renderTo: 'container',
    type: 'area',
    width: 850
  },
  accessibility: {
    description: intl.formatMessage({ id: 'userLoginAccessibility', defaultMessage: 'User logins chart' })
  },
  legend: {
    enabled: false
  },
  title: {
    text: ''
  },
  rangeSelector: {
    selected: 6,
    buttons: [
      {
        type: 'day',
        count: 1,
        text: intl.formatMessage({ id: '1d', defaultMessage: '1d' })
      },
      {
        type: 'month',
        count: 1,
        text: intl.formatMessage({ id: '1m', defaultMessage: '1m' })
      },
      {
        type: 'month',
        count: 3,
        text: intl.formatMessage({ id: '3m', defaultMessage: '3m' })
      },
      {
        type: 'month',
        count: 6,
        text: intl.formatMessage({ id: '6m', defaultMessage: '6m' })
      },
      {
        type: 'ytd',
        text: intl.formatMessage({ id: 'YTD', defaultMessage: 'YTD' })
      },
      {
        type: 'year',
        count: 1,
        text: intl.formatMessage({ id: '1y', defaultMessage: '1y' })
      },
      {
        type: 'all',
        text: intl.formatMessage({ id: 'all', defaultMessage: 'All' })
      }
    ],
    inputEnabled: false,
    allButtonsEnabled: true
  },
  xAxis: {
    type: 'datetime'
  },
  yAxis: {
    min: 0,
    minRange: 0.1,
    title: {
      text: ''
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        theme: {
          states: {
            hover: {
              fill: '#ffffff'
            },
            select: {
              fill: '#ffffff',
              stroke: '#ffffff'
            }
          }
        },
        symbol: 'download',
        symbolStrokeWidth: 0.8,
        y: 6.5,
        menuItems: getMenuItems()
      }
    }
  },
  chartOptions: {
    scrollbar: {
      enabled: false
    }
  },
  plotOptions: {
    area: {
      fillColor: '#7cb5ec',
      threshold: null
    }
  }
});

export const votesAndCommentsChartOptions = () => ({
  chart: {
    type: 'column'
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  yAxis: {
    min: 0,
    minRange: 0.1,
    allowDecimals: false,
    title: {
      enabled: true,
      text: ''
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
        color: 'gray'
      }
    }
  },
  legend: {
    align: 'center',
    x: 0,
    verticalAlign: 'bottom',
    y: 0,
    floating: true,
    backgroundColor: '#ffffff',
    borderColor: '#ccc',
    borderWidth: 1,
    shadow: false
  },
  tooltip: {
    formatter(this: Highcharts.TooltipFormatterContextObject): string {
      return (
        '<b>' + (this.x as unknown as string) + '</b><br/>' + this.series.name + ': ' + (this.y as unknown as string)
      );
    }
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
        color: '#ffffff',
        style: {
          textShadow: false
        }
      }
    }
  },
  exporting: {
    filename: 'totalvotesandcomments',
    buttons: {
      contextButton: {
        theme: {
          states: {
            hover: {
              fill: '#ffffff'
            },
            select: {
              fill: '#ffffff',
              stroke: '#ffffff'
            }
          }
        },
        symbol: 'download',
        symbolStrokeWidth: 0.8,
        y: 6.5,
        menuItems: getMenuItems()
      }
    }
  }
});

export const ideasByPhaseChartOptions = (_intl: IntlShape) => ({
  credits: {
    enabled: false
  },
  chart: {
    type: 'funnel',
    marginRight: 100,
    shadow: false
  },
  title: {
    text: '', // title removed
    x: -50
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        format: '{point.name} ({point.ideas})',
        softConnector: true,
        connectorWidth: 0,
        textShadow: false,
        style: {
          color: '#ffffff',
          textOutline: '0px 0px contrast',
          fontSize: '13px',
          fontWeight: 'bold'
        }
      },
      neckHeight: '25%'
    }
  },
  legend: {
    enabled: false
  },
  exporting: {
    buttons: {
      contextButton: {
        theme: {
          states: {
            hover: {
              fill: '#ffffff'
            },
            select: {
              fill: '#ffffff',
              stroke: '#ffffff'
            }
          }
        },
        symbol: 'download',
        symbolStrokeWidth: 0.8,
        y: 6.5,
        menuItems: getMenuItems()
      }
    }
  },
  tooltip: {
    formatter(this: chartTooltip) {
      let { name, endDate, ideas, startDate } = this.point;
      if (name === '') {
        name = 'Unnamed phase';
      }

      let endDateString;
      let d = new Date(endDate);
      if (this.point.endDate === '0101-01-01T00:00:00.000Z') {
        endDateString = '';
      } else {
        const timeStr = `${d.toDateString()} ${d.getHours()}:${d.getMinutes()}`;

        endDateString = `<br /> End Date: ${timeStr}`;
      }

      d = new Date(startDate);
      const startTimeStr = `${d.toDateString()} ${d.getHours()}:${d.getMinutes()}`;

      return `Phase name: ${name}
             <br />Ideas in Phase: ${ideas}
             <br />Start Date: ${startTimeStr}
             ${endDateString}`;
    }
  }
});

export const ideasByMilestoneChartOptions = (intl: IntlShape) => ({
  credits: {
    enabled: false
  },
  chart: {
    type: 'column',
    width: '900'
  },
  legend: {
    enabled: false
  },
  title: {
    text: '',
    x: -50
  },
  lang: {
    noData: intl.formatMessage({
      id: 'thisChallengeHasNoMilestones',
      defaultMessage: 'This challenge has no milestones'
    })
  },
  yAxis: {
    min: 0,
    minRange: 0.1,
    allowDecimals: false,
    title: {
      text: intl.formatMessage({
        id: 'numberOfIdeasAtThisMilestone',
        defaultMessage: 'Number of ideas at this milestone'
      })
    }
  },
  tooltip: {
    formatter(this: Highcharts.TooltipFormatterContextObject) {
      const { x, y } = this;
      return `<b>${x}</b><br/>${intl.formatMessage({ id: 'numIdeas', defaultMessage: 'No. ideas:' })} ${y}`;
    },
    shared: true,
    useHTML: true
  },
  exporting: {
    buttons: {
      contextButton: {
        theme: {
          states: {
            hover: {
              fill: '#ffffff'
            },
            select: {
              fill: '#ffffff',
              stroke: '#ffffff'
            }
          }
        },
        symbol: 'download',
        symbolStrokeWidth: 0.8,
        y: 6.5,
        menuItems: getMenuItems()
      }
    }
  }
});

export const ideasByThemeChartOptions = (intl: IntlShape) => ({
  accessibility: {
    description: intl.formatMessage({ id: 'ideasByThemeChart', defaultMessage: 'Ideas by Theme chart' })
  },
  chart: {
    renderTo: 'container',
    type: 'column',
    width: 850
  },
  credits: {
    enabled: false
  },
  exporting: {
    buttons: {
      contextButton: {
        theme: {
          states: {
            hover: {
              fill: '#ffffff'
            },
            select: {
              fill: '#ffffff',
              stroke: '#ffffff'
            }
          }
        },
        symbol: 'download',
        symbolStrokeWidth: 0.8,
        y: 6.5,
        menuItems: getMenuItems()
      }
    }
  },
  navigator: {
    enabled: false
  },
  lang: {
    noData: intl.formatMessage({ id: 'thisChallengeHasNoThemes', defaultMessage: 'This challenge has no themes' })
  },
  legend: {
    enabled: false
  },
  rangeSelector: {
    enabled: false
  },
  title: {
    text: intl.formatMessage({ id: 'ideasByTheme', defaultMessage: 'Ideas by Theme' })
  },
  xAxis: [
    {
      title: {
        text: intl.formatMessage({ id: 'theme', defaultMessage: 'Theme' })
      }
    },
    {
      labels: { enabled: false },
      linkedTo: 0,
      visible: false
    }
  ],
  yAxis: {
    title: {
      enabled: true,
      text: intl.formatMessage({ id: 'numberOfIdeas', defaultMessage: 'Number of ideas' })
    },
    minRange: 0.1,
    min: 0,
    allowDecimals: false
  },
  tooltip: {
    shared: false,
    formatter(this: Highcharts.TooltipFormatterContextObject): string {
      return `<b>${this.x}</b><br/>${this.series.name}: ${this.y}<br/>`;
    }
  },
  plotOptions: {
    area: {
      fillColor: '#7cb5ec',
      threshold: null
    },
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
        color: '#ffffff',
        style: {
          textShadow: false
        }
      }
    }
  },
  series: [
    {
      name: intl.formatMessage({ id: 'ideas', defaultMessage: 'Ideas' })
    }
  ],
  chartOptions: {
    scrollbar: {
      enabled: false
    }
  }
});

export const ideasByTagChartOptions = (intl: IntlShape) => ({
  accessibility: {
    description: intl.formatMessage({ id: 'ideasByTagChart', defaultMessage: 'Ideas by Tag chart' })
  },
  credits: {
    enabled: false
  },
  chart: {
    type: 'column',
    width: 850
  },
  legend: {
    enabled: false
  },
  title: {
    text: intl.formatMessage({
      id: 'ideasByTag',
      defaultMessage: 'Ideas by Tag'
    })
  },
  lang: {
    noData: intl.formatMessage({
      id: 'thisChallengeHasNoTags',
      defaultMessage: 'This challenge has no tags'
    })
  },
  xAxis: [
    {
      title: {
        text: intl.formatMessage({ id: 'tag', defaultMessage: 'Tag' })
      }
    },
    {
      linkedTo: 0,
      labels: {
        enabled: false
      },
      visible: false
    }
  ],
  yAxis: {
    min: 0,
    minRange: 0.1,
    allowDecimals: false,
    title: {
      text: intl.formatMessage({
        id: 'numberOfIdeasWithThisTag',
        defaultMessage: 'Number of ideas with this tag'
      })
    }
  },
  series: [
    {
      name: intl.formatMessage({ id: 'ideas', defaultMessage: 'Ideas' })
    }
  ],
  tooltip: {
    formatter(this: Highcharts.TooltipFormatterContextObject) {
      const { x, y } = this;
      return `<b>${x}</b><br/>${intl.formatMessage({ id: 'numIdeas', defaultMessage: 'No. ideas:' })} ${y}`;
    },
    shared: true,
    useHTML: true
  },
  plotOptions: {
    area: {
      fillColor: '#7cb5ec',
      threshold: null
    },
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
        color: '#ffffff',
        style: {
          textShadow: false
        }
      }
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        theme: {
          states: {
            hover: {
              fill: '#ffffff'
            },
            select: {
              fill: '#ffffff',
              stroke: '#ffffff'
            }
          }
        },
        symbol: 'download',
        symbolStrokeWidth: 0.8,
        y: 6.5,
        menuItems: getMenuItems()
      }
    }
  }
});

export const ideasByStatusChartOptions = (intl: IntlShape) => ({
  accessibility: {
    description: intl.formatMessage({ id: 'ideasByStatusChart', defaultMessage: 'Ideas by Status chart' })
  },
  chart: {
    renderTo: 'container',
    type: 'column',
    width: 850
  },
  credits: {
    enabled: false
  },
  title: {
    text: intl.formatMessage({ id: 'ideasByStatus', defaultMessage: 'Ideas by Status' })
  },
  lang: {
    noData: intl.formatMessage({
      id: 'thisChallengeHasNoStatusLabels',
      defaultMessage: 'This challenge has no status labels'
    })
  },
  legend: {
    enabled: false
  },
  xAxis: [
    {
      title: {
        text: intl.formatMessage({ id: 'statusLabel', defaultMessage: 'Status Label' })
      }
    },
    {
      labels: { enabled: false },
      linkedTo: 0,
      visible: false
    }
  ],
  yAxis: {
    title: {
      enabled: true,
      text: intl.formatMessage({ id: 'ideasByStatus', defaultMessage: 'Ideas by Status' })
    },
    minRange: 0.1,
    min: 0,
    allowDecimals: false
  },
  tooltip: {
    shared: false,
    formatter(this: Highcharts.TooltipFormatterContextObject): string {
      return `<b>${this.x}</b><br/>${intl.formatMessage({ id: 'numberOfIdeas', defaultMessage: 'Number of Ideas' })}: ${
        this.y
      }<br/>`;
    }
  },
  plotOptions: {
    series: {
      cursor: 'pointer'
    },
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
        color: '#ffffff',
        style: {
          textShadow: false
        }
      }
    }
  },
  series: [
    {
      name: intl.formatMessage({ id: 'ideas', defaultMessage: 'Ideas' })
    }
  ],
  exporting: {
    buttons: {
      contextButton: {
        theme: {
          states: {
            hover: {
              fill: '#ffffff'
            },
            select: {
              fill: '#ffffff',
              stroke: '#ffffff'
            }
          }
        },
        symbol: 'download',
        symbolStrokeWidth: 0.8,
        y: 6.5,
        menuItems: getMenuItems()
      }
    }
  }
});

import { CancelXIcon, Field, Popper, PopperBox } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik, type FormikHelpers } from 'formik';
import { DeleteButton } from '../People.styled';
import { PEOPLE_SEARCH_CHARACTER_MIN } from '../../shared-constants';
import * as Styled from './SearchFilters.styled';

export const FilterWithInput = ({
  id,
  name,
  selectedFilters,
  handleAddFilter,
  handleRemoveFilter
}: {
  readonly id: string;
  readonly name: string;
  readonly selectedFilters?: string[];
  readonly handleAddFilter: ({
    id,
    option,
    resetForm,
    isTextInput
  }: {
    id: string;
    option: string;
    resetForm?: () => void;
    isTextInput: boolean;
  }) => void;
  readonly handleRemoveFilter: ({ id, option }: { id: string; option: string }) => void;
}) => {
  const intl = useIntl();
  const selectedFiltersCount = selectedFilters?.length;

  const handleInputSubmit = (
    { text }: { text: string },
    formik: FormikHelpers<{
      text: string;
    }>
  ) => {
    const { resetForm, setFieldError } = formik;
    const trimmedText = text.trim();
    const filterAlreadyExist = selectedFilters?.find((filter) => filter.toLowerCase() === trimmedText.toLowerCase());
    if (trimmedText.length > 0 && trimmedText.length < PEOPLE_SEARCH_CHARACTER_MIN) {
      setFieldError(
        'text',
        intl.formatMessage({
          id: 'enterAtLeastTwoChars',
          defaultMessage: 'Please enter at least two characters'
        })
      );

      return false;
    }

    if (filterAlreadyExist) {
      setFieldError(
        'text',
        intl.formatMessage(
          {
            id: 'filterAlreadyApplied',
            defaultMessage: 'Filter {input} is already applied'
          },
          { input: trimmedText }
        )
      );

      return false;
    }

    handleAddFilter({ id, option: text, resetForm, isTextInput: true });
    return true;
  };

  return (
    <Popper
      trigger={
        <Styled.FilterButton priority="secondary">
          <Styled.FilterName>{name}</Styled.FilterName>
          {selectedFiltersCount ? (
            <Styled.FiltersCount>{selectedFiltersCount}</Styled.FiltersCount>
          ) : (
            <Styled.FiltersCount>{intl.formatMessage({ id: 'all', defaultMessage: 'All' })}</Styled.FiltersCount>
          )}
          <Styled.ArrowDown aria-hidden="true" />
        </Styled.FilterButton>
      }
      placement="bottom-start"
      zIndex={990} // should be below nav bar's z-index which is 998
      shouldCloseOnOutsideInteraction={false}
      overlay={({ closeAndReturnFocus }) => (
        <PopperBox hasArrow maxWidth={250}>
          <Styled.FiltersContainer>
            {selectedFilters?.map((option: string) => (
              <Styled.AddedFilter key={option} data-testid="AddedFilter">
                {option}
                <DeleteButton
                  aria-label={intl.formatMessage(
                    {
                      id: 'deleteFilter',
                      defaultMessage: 'Delete filter {filterName}'
                    },
                    { filterName: option }
                  )}
                  onClick={() => handleRemoveFilter({ id, option })}
                >
                  <CancelXIcon aria-hidden="true" />
                </DeleteButton>
              </Styled.AddedFilter>
            ))}
          </Styled.FiltersContainer>
          <Formik
            initialValues={{
              text: ''
            }}
            onSubmit={(text, formik) => {
              const validForm = handleInputSubmit(text, formik);
              validForm && closeAndReturnFocus();
            }}
          >
            {({ values, handleChange, errors }) => (
              <Form>
                <Field
                  input={
                    <Styled.StyledInput
                      name="text"
                      placeholder={intl.formatMessage({
                        id: 'keywordToFilter',
                        defaultMessage: 'Enter keyword to filter'
                      })}
                      value={values.text}
                      onChange={handleChange}
                    />
                  }
                  status={errors.text ? { level: 'error', message: errors.text } : undefined}
                />
                <Styled.FilterInputSubmitButton
                  priority="primary"
                  size="small"
                  type="submit"
                  aria-label={intl.formatMessage({
                    id: 'applyFilter',
                    defaultMessage: 'Apply filter'
                  })}
                >
                  <FormattedMessage id="apply" defaultMessage="Apply" />
                </Styled.FilterInputSubmitButton>
              </Form>
            )}
          </Formik>
        </PopperBox>
      )}
    />
  );
};

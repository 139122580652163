import { useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { Breakpoints } from '../common/enums';

export const useBreakpoint = (minWidth: number) => {
  const [hasMinWidth, setHasMinWidth] = useState(() => window.innerWidth >= minWidth);
  useEffect(() => {
    const handleResize = debounce(100, () => setHasMinWidth(window.innerWidth >= minWidth));
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel();
    };
  }, [minWidth, setHasMinWidth]);
  return hasMinWidth;
};

export const useIsMobile = () => !useBreakpoint(Breakpoints.TABLET_MIN);

export const useIsNarrowMobile = () => !useBreakpoint(Breakpoints.WIDE_MOBILE_MIN);

export const useIsTablet = () => {
  const isTablet = useBreakpoint(Breakpoints.TABLET_MIN);
  const isDesktop = useBreakpoint(Breakpoints.DESKTOP_MIN);

  return isTablet && !isDesktop;
};

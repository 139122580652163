import type react from 'react';

export const getFocusableElements = (
  ref: react.RefObject<HTMLDivElement | null>
): NodeListOf<HTMLElement> | undefined =>
  ref.current?.querySelectorAll('a, input, textarea, button:enabled, select, [tabindex]');

export const getActiveElement = (ref: react.RefObject<HTMLDivElement | null>): number => {
  const items = getFocusableElements(ref);

  return items && document.activeElement ? Array.from(items).indexOf(document.activeElement as HTMLElement) : -1;
};

export interface RuntimeUser {
  id: string;
  displayName: string;
  email: string;
  avatar: string;
  permissions: {
    isModerator: boolean;
    isApprovalModerator: boolean;
    isChallengeCreator: boolean;
  };
  canInvite: boolean;
  actionKey: string;
  language: string;
}

export const defaultRuntimeUser = {
  id: '0', // guest user's id is set to '0' in the main app
  displayName: '',
  email: '',
  avatar: '',
  permissions: {
    isModerator: false,
    isApprovalModerator: false,
    isChallengeCreator: false
  },
  canInvite: false,
  actionKey: '',
  language: 'en_GB'
};

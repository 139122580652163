import { Button, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const MoreButton = styled(Button)`
  display: block;
  margin: ${units(2)} 0;
`;

export const UserListContainer = styled.ul`
  margin-top: ${units(1.5)};
`;

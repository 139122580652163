import { type ReactNode, useEffect, useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { shouldPolyfill } from '@formatjs/intl-pluralrules/should-polyfill';
import { setDefaultOptions } from 'date-fns';
import { locales } from '../hooks/date-format';
import defaultMessages from '../messages/default-messages.json';
import { decodeHtml } from '../helpers';

export type LocaleMessages = typeof defaultMessages;
export type SupportedLocales =
  | 'da-DK'
  | 'de-CH'
  | 'de-DE'
  | 'el-GR'
  | 'en-GB'
  | 'en-US'
  | 'es-ES'
  | 'fi-FI'
  | 'fr-CA'
  | 'fr-FR'
  | 'hu-HU'
  | 'it-IT'
  | 'ja-JP'
  | 'ka-GE'
  | 'ko-KR'
  | 'ms-MY'
  | 'nl-NL'
  | 'pl-PL'
  | 'pt-PT'
  | 'sk-SK'
  | 'tr-TR'
  | 'zh-CN';

export const IntlProvider = ({
  children,
  locale
}: {
  readonly children: ReactNode;
  readonly locale: SupportedLocales;
}) => {
  const [pluralDataLoaded, setPluralDataLoaded] = useState(() => !shouldPolyfill());
  const [messages, setMessages] = useState(defaultMessages);

  useEffect(() => {
    if (!pluralDataLoaded) {
      (async () => {
        try {
          await import(
            /* webpackChunkName: "canononicalnames-pluralrules-polyfill" */
            '../common/polyfills/i18n-polyfills'
          );
          await import(
            /* webpackChunkName: "pluralrules-locale-[request]" */
            /* webpackInclude: /(da|de|el|en|es|fi|fr|hu|it|ja|ka|ko|ms|nl|pl|pt|sk|tr|zh)\.js$/ */
            `@formatjs/intl-pluralrules/locale-data/${locale.split('-')[0]}`
          );
          setPluralDataLoaded(true);
        } catch (e) {
          console.warn(e);
        }
      })();
    }

    // set locale for date-fns lib
    setDefaultOptions({ locale: locales[locale || 'en-GB'] });

    if (locale === 'en-GB') {
      return;
    }

    (async () => {
      try {
        const importedMessages: LocaleMessages = await import(
          /* webpackChunkName: "messages-[request]" */
          /* webpackInclude: /(da-DK|de-CH|de-DE|el-GR|es-ES|fi-FI|fr-CA|fr-FR|hu-HU|it-IT|ja-JP|ka-GE|ko-KR|ms-MY|nl-NL|pl-PL|pt-PT|sk-SK|tr-TR|zh-CN)\.json/ */
          `../messages/${locale}.json`
        );

        const decodedMessages: { [key: string]: string } = {};
        for (const [key, value] of Object.entries(importedMessages)) {
          decodedMessages[key] = decodeHtml(value);
        }

        return setMessages(decodedMessages as LocaleMessages);
      } catch (e) {
        console.warn(e);
      }
    })();
  }, [locale, pluralDataLoaded]);

  if (!messages || !pluralDataLoaded) {
    return null;
  }

  return (
    <ReactIntlProvider messages={messages} locale={locale} defaultLocale="en">
      {children}
    </ReactIntlProvider>
  );
};

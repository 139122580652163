import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { CommentBubbleIcon, CustomerIdeaSignalOutlineIcon, ThumbsUpIcon } from '@m/alchemy-ui';
import { UserContext } from '../../../context';
import { type User } from '../../../common/core-api';
import { type CheckFollowingQuery, GET_FOLLOWING } from '../../../graphql/queries';
import { type FollowMutation, type UnfollowMutation, USER_FOLLOW, USER_UNFOLLOW } from '../../../graphql/mutations';
import { useFile } from '../../../hooks';
import { getPopupData } from '../../../../src/helpers';
import * as Styled from './Header.styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderStat = ({ value, label }: { value: any; label: any }, key: number) => (
  <Styled.StatItem key={key}>
    <Styled.StatValue>{value}</Styled.StatValue>
    <Styled.StatLabel>{label}</Styled.StatLabel>
  </Styled.StatItem>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderActivity = ({ icon, value }: { icon: any; value: any }, key: number) => (
  <Styled.ActivityItem key={key}>
    {icon}
    <Styled.ActivityLabel>{value}</Styled.ActivityLabel>
  </Styled.ActivityItem>
);

export const Header = ({
  user,
  showUserScores = false,
  onEditPopupLoaded,
  refetchUsers
}: {
  readonly user: User;
  readonly showUserScores: boolean | undefined;
  readonly onEditPopupLoaded: (result: Document) => void;
  readonly refetchUsers: () => void;
}) => {
  const currentUser = useContext(UserContext);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isEditPopupLoading, setIsEditPopupLoading] = useState(false);
  const [getFile] = useFile();
  const isProfileOwner = currentUser.id === user.id;
  const { data } = useQuery<CheckFollowingQuery>(GET_FOLLOWING, {
    variables: {
      userId: currentUser.id,
      targetUser: user.id
    },
    skip: isProfileOwner
  });

  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  useEffect(() => {
    const getAuthUrl = async () => {
      const url = user?.profileImageFile && (await getFile({ file: user.profileImageFile, width: 100, height: 100 }));
      setProfilePictureUrl(url || '');
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.profileImageFile]);

  useEffect(() => {
    if (data?.checkFollowing) {
      setIsFollowing(data?.checkFollowing?.success);
    }
  }, [data]);

  const loadPopupData = async () => {
    setIsEditPopupLoading(true);

    const result = (await getPopupData(
      'user/onboard?showClose=false&bodyClass=-full-height&edit=true&autoHeight=true'
    )) as Document;

    setIsEditPopupLoading(false);
    onEditPopupLoaded(result);
  };

  const [followUser, { loading: userFollowLoading }] = useMutation<FollowMutation>(USER_FOLLOW);
  const [unfollowUser, { loading: userUnfollowLoading }] = useMutation<UnfollowMutation>(USER_UNFOLLOW);
  const joinedDate = format(new Date(user.createdAt), 'MMMM d, yyyy');

  const toggleFollow = async () => {
    const params = {
      variables: {
        targetUser: user.id
      }
    };

    await (isFollowing ? unfollowUser(params) : followUser(params));
    setIsFollowing(!isFollowing);
    refetchUsers();
  };

  const activities = [
    {
      icon: <CustomerIdeaSignalOutlineIcon aria-hidden="true" />,
      value: user.ideasCount
    },
    {
      icon: <ThumbsUpIcon aria-hidden="true" />,
      value: user.votesCount
    },
    {
      icon: <CommentBubbleIcon aria-hidden="true" />,
      value: user.commentsCount
    }
  ];
  const rankingStats = [
    {
      value: user.ranking?.position || <FormattedMessage id="na" defaultMessage="N/A" />,
      label: <FormattedMessage id="ranking" defaultMessage="Ranking" />
    },
    {
      value: user.ranking?.score || <FormattedMessage id="na" defaultMessage="N/A" />,
      label: <FormattedMessage id="pointsVoting" defaultMessage="Points" />
    }
  ];
  const stats = [
    {
      value: user.followersCount,
      label: <FormattedMessage id="followers" defaultMessage="Followers" />
    },
    {
      value: user.followingCount,
      label: <FormattedMessage id="following" defaultMessage="Following" />
    }
  ];

  return (
    <Styled.Wrapper>
      <Styled.ProfileImageHolder>
        {profilePictureUrl ? (
          <Styled.ProfileImage data-testid="UserProfileImage" src={profilePictureUrl} aria-hidden="true" />
        ) : (
          <Styled.NoProfileImage aria-hidden="true" />
        )}
      </Styled.ProfileImageHolder>
      <Styled.Name>{user.displayName}</Styled.Name>
      <Styled.Location>{user.town ? `${user.town}, ${user.country}` : user.country}</Styled.Location>
      <Styled.Date>
        <FormattedMessage id="joinedOnDate" defaultMessage="Joined on {date}" values={{ date: joinedDate }} />
      </Styled.Date>
      <Styled.ButtonGroup>
        {isProfileOwner ? (
          <Styled.EditBtn onClick={loadPopupData} isLoading={isEditPopupLoading} priority="secondary" size="extraSmall">
            <FormattedMessage id="edit" defaultMessage="Edit" />
          </Styled.EditBtn>
        ) : (
          <Styled.FollowButton
            priority="secondary"
            size="extraSmall"
            onClick={toggleFollow}
            isLoading={userFollowLoading || userUnfollowLoading}
          >
            {isFollowing ? (
              <FormattedMessage id="unfollow" defaultMessage="Unfollow" />
            ) : (
              <FormattedMessage id="follow" defaultMessage="Follow" />
            )}
          </Styled.FollowButton>
        )}
      </Styled.ButtonGroup>
      <Styled.Stats>
        {showUserScores && rankingStats.map(renderStat)}
        {stats.map(renderStat)}
      </Styled.Stats>
      <Styled.Activity>{activities.map(renderActivity)}</Styled.Activity>
    </Styled.Wrapper>
  );
};

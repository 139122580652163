import { FormattedMessage } from 'react-intl';
import { color, ConfirmIcon, ErrorIcon, units } from '@m/alchemy-ui';
import styled from 'styled-components';

const Container = styled.div<{ type: string }>`
  display: flex;
  align-items: center;
  font-size: ${units(1.5)};
  margin-top: ${units(0.5)};
  color: ${({ type }) =>
    type === 'error'
      ? color('contrast', { palette: 'interactiveDestructive' })
      : color('base', { palette: 'interactiveAction' })};
`;

const IconContainer = styled.span`
  display: flex;
  margin-right: ${units(0.5)};
`;

const fieldIcons = {
  error: ErrorIcon,
  success: ConfirmIcon
};

export const FieldStatusMessage = ({
  message,
  type = 'success'
}: {
  readonly message: {
    id: string;
    defaultMessage: string;
    values?: never;
  };
  readonly type: string;
}): JSX.Element => {
  // @ts-expect-error
  const Icon: JSX.Element = fieldIcons[type] || ConfirmIcon;

  return (
    <Container type={type}>
      <IconContainer>
        {/* @ts-expect-error */}
        <Icon />
      </IconContainer>
      <FormattedMessage {...message} />
    </Container>
  );
};

import produce from 'immer';
import { type WritableDraft } from 'immer/dist/types/types-external';
import set from 'lodash.set';
import { defaultChallenge, type IChallenge } from '../../../common/interfaces';
import { decodeHtml } from '../../../helpers';
import { type ScorecardsAndLabels } from '../../../common/core-api';

export enum ChallengeActionTypes {
  SET_CHALLENGE = 'SET_CHALLENGE',
  UPDATE = 'UPDATE'
}

export type ChallengeActionType =
  | { type: ChallengeActionTypes.SET_CHALLENGE; value: IChallenge }
  | { type: ChallengeActionTypes.UPDATE; name: string; value: unknown };

const decodeHtmlScorecards = (scorecards: WritableDraft<ScorecardsAndLabels> | null | undefined) => {
  scorecards?.cards?.forEach((card) => {
    card.name = decodeHtml(card.name || undefined);
    card.description = decodeHtml(card.description || undefined);
  });

  scorecards?.labels?.forEach((label) => {
    label.name = decodeHtml(label.name || undefined);
  });
};

export const challengeReducer = produce((draft: IChallenge, action: ChallengeActionType) => {
  switch (action.type) {
    case ChallengeActionTypes.SET_CHALLENGE: {
      /*
        These fields have HTML entities encoded in API.
        Decoding the HTML here prevents untouched fields being double encoding
        eg. <b> -> &amp;lt;b&amp;gt; -> &amp;amp;lt;b&amp;amp;gt;
      */
      return produce(action.value, (draft) => {
        draft.title = decodeHtml(draft.title);
        draft.subtitle = decodeHtml(draft.subtitle || undefined);

        if (draft.headings) {
          draft.headings.ideaDescriptionHeading = decodeHtml(draft.headings.ideaDescriptionHeading);
          draft.headings.ideaDescriptionInfoHeading = decodeHtml(draft.headings.ideaDescriptionInfoHeading);
          draft.headings.ideaFilesHeading = decodeHtml(draft.headings.ideaFilesHeading);
          draft.headings.ideaTitleHeading = decodeHtml(draft.headings.ideaTitleHeading);
          draft.headings.ideaTitleInfoHeading = decodeHtml(draft.headings.ideaTitleInfoHeading);
          draft.headings.themeSelectHeading = decodeHtml(draft.headings.themeSelectHeading);
        }

        draft.voting && decodeHtmlScorecards(draft.voting.scorecards);

        draft.themes?.forEach((theme) => {
          theme.name = decodeHtml(theme.name);
          theme.description = decodeHtml(theme.description);
        });

        draft.categories?.forEach((category) => {
          category.name = decodeHtml(category.name);
        });

        draft.phases?.forEach((phase) => {
          phase.name = decodeHtml(phase.name || undefined);
          phase.questions?.forEach((question) => {
            question.name = decodeHtml(question.name || undefined);
            question.description = decodeHtml(question.description || undefined);
          });

          draft.voting && decodeHtmlScorecards(draft.voting.scorecards);
        });

        draft.milestone?.milestones?.forEach((milestone) => {
          milestone.name = decodeHtml(milestone.name || undefined);
          milestone.label = decodeHtml(milestone.label || undefined);
          milestone.questions?.forEach((question) => {
            question.name = decodeHtml(question.name || undefined);
            question.description = decodeHtml(question.description || undefined);
          });

          draft.voting && decodeHtmlScorecards(draft.voting.scorecards);
        });

        draft.questions?.forEach((question) => {
          question.name = decodeHtml(question.name || undefined);
          question.description = decodeHtml(question.description || undefined);
          question.options?.sort((a, b) => Number(a.id) - Number(b.id));
        });

        draft.loading = false;
      });
    }

    case ChallengeActionTypes.UPDATE:
      return set(draft, action.name, action.value);

    default:
      return draft;
  }
}, defaultChallenge);

import axios from 'axios';
import { useContext } from 'react';
import { CommunityContext, RuntimeContext } from '../context';

interface IApiV2UploadFile {
  file: File;
  updateProgress: (percent: number) => void;
}

export const useApiV2 = () => {
  const config = useContext(RuntimeContext);
  const community = useContext(CommunityContext);

  const upload = async ({ file, updateProgress }: IApiV2UploadFile) => {
    const data = new FormData();
    data.append('file', file);
    data.append(
      'data',
      JSON.stringify({
        use_filename: true,
        hubbubId: community.id
      })
    );
    data.append('pkey', config.pkey);
    data.append('hubbubUrl', community.url);

    return axios({
      url: '/proxy/file',
      method: 'POST',
      data,
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        updateProgress(percent);
      }
    });
  };

  return [upload];
};

import { gql } from '@apollo/client';

export const GET_GLOBAL_SCORE_RESETS = gql`
  query globalScoreResets {
    globalScoreResets {
      __typename
      id
      date
      type
      excludeBadges
    }
  }
`;

export const GET_USER_SCORE_RESETS = gql`
  query userScoreResets($userId: String!) {
    userScoreResets(userId: $userId) {
      __typename
      id
      date
      type
      excludeBadges
    }
  }
`;

import { units } from '@m/alchemy-ui';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ inline?: boolean }>`
  display: flex;
  ${(props) =>
    props.inline
      ? css`
          flex-direction: row;
          align-items: center;
        `
      : css`
          flex-direction: column;
          align-items: flex-start;
          button {
            margin: ${units(1)} 0 0 0;
          }
        `}
`;

export const StyledInput = styled.div<{ applyMaxWidth?: boolean }>`
  flex-grow: 1;
  width: 100%;
  & > div {
    width: 100%;
  }
  input,
  textarea {
    display: block;
    width: 100%;
  }
  button {
    max-width: 100%;
  }
  ${(props) =>
    props.applyMaxWidth &&
    css`
      max-width: 70%;
    `}
`;

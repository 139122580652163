import { alchemyBrand, alchemyPanel, BREAKPOINTS, theme, type Theme, typography } from '@m/alchemy-ui';
import { type CommunityDesign } from '../../common/interfaces';
import { getCrowdicityTheme, getCrowidicityBlackTheme } from './crowdicity-theme';

export const getCommunityTheme = ({ communityColors }: { communityColors?: CommunityDesign }): Theme => {
  // Return default theme early if community colours not yet loaded or not set
  if (!communityColors || !communityColors.button) {
    return theme;
  }

  const crowdicityTheme = getCrowdicityTheme(communityColors);
  const crowidicityBlackTheme = getCrowidicityBlackTheme(communityColors);

  const newTheme: Theme = {
    name: 'alchemy',
    breakpoints: BREAKPOINTS,
    defaults: {
      colorScheme: 'alchemyLight',
      palette: 'structural',
      accentPalette: 'interactiveConstructive'
    },
    typography,
    colorSchemes: {
      alchemyLight: crowdicityTheme,
      alchemyDark: crowidicityBlackTheme,
      alchemyPanel,
      alchemyBrand
    },
    features: theme.features
  };

  return newTheme;
};

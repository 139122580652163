import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@m/alchemy-ui';
import { ScoreResetView } from '../enums/ScoreResetView';
import {
  type GlobalScoreResetsQuery,
  type UserScoreResetsQuery
} from '../../../graphql/queries/__generated__/score-resets.generated';
import { ScoreResetType } from '../../../common/core-api';
import { ScoreResetListHeader } from './ScoreResetListHeader';

export const ScoreResetList = ({
  list,
  view,
  undoLoading,
  handleUndoClick
}: {
  readonly list: GlobalScoreResetsQuery['globalScoreResets'] | UserScoreResetsQuery['userScoreResets'];
  readonly view: ScoreResetView;
  readonly undoLoading: boolean;
  readonly handleUndoClick: (eventId: string) => void;
}) => {
  const intl = useIntl();
  const fullLegend = intl.formatMessage({
    id: 'fullLegend',
    defaultMessage: '(full)'
  });
  const excludeBadgesLegend = intl.formatMessage({
    id: 'excludeBadgesLegend',
    defaultMessage: '(badge score retention)'
  });
  const resetAppliesToAllUsers = intl.formatMessage({
    id: 'resetAppliesToAllUsers',
    defaultMessage: 'This reset applies to all users'
  });
  const andCanBeUndoneFromScoresManager = intl.formatMessage({
    id: 'andCanBeUndoneFromScoresManager',
    defaultMessage: ', and can only be undone from the Scores manager'
  });

  return (
    <div data-testid="score-reset-list">
      <ScoreResetListHeader view={view} resetCount={list?.length ?? 0} />
      {list &&
        list.map(({ date, excludeBadges, type, id }, index) => {
          const formattedDate = format(new Date(date), 'MMM d, yyy HH:mm');
          const resetType = excludeBadges ? excludeBadgesLegend : fullLegend;

          const firstRow = index === 0;
          const displayAppliesToAllUsers = view === ScoreResetView.USER && type === ScoreResetType.GLOBAL;
          const displayCanBeUndoneFromScoreManager = displayAppliesToAllUsers && firstRow;
          const displayUndo =
            firstRow &&
            (view === ScoreResetView.GLOBAL || (view === ScoreResetView.USER && type === ScoreResetType.USER));
          const displayDash = displayUndo || displayAppliesToAllUsers;

          return (
            <div data-testid={`score-reset-list-item-${id}`} key={id}>
              {formattedDate} {resetType}
              {displayDash && ' - '}
              {displayUndo && (
                <Button
                  isLoading={undoLoading}
                  size="extraSmall"
                  priority="secondary"
                  onClick={() => handleUndoClick(id)}
                  data-testid="score-reset-undo-button"
                >
                  <FormattedMessage id="undo" defaultMessage="Undo" />
                </Button>
              )}
              {displayAppliesToAllUsers && resetAppliesToAllUsers}
              {displayCanBeUndoneFromScoreManager && andCanBeUndoneFromScoresManager}
            </div>
          );
        })}
    </div>
  );
};

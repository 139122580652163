import type React from 'react';
import { forwardRef } from 'react';
import { TimelineItemType } from '../../../common/enums';
import { type ActivityTimelineItemProps } from '../../../common/interfaces';
import * as Styled from './ActivityTimelineItem.styled';

export const ActivityTimelineItem = forwardRef((props: ActivityTimelineItemProps, ref: React.Ref<HTMLLIElement>) => {
  const { children, icon, itemIconWrapperPalette, customIndicator, itemType, ...otherProps } = props;

  return (
    <Styled.ActivityTimelineItem ref={ref} data-testid="ActivityTimelineItem" itemType={itemType} {...otherProps}>
      {customIndicator ? (
        <Styled.ActivityTimelineItemIndicator>
          {customIndicator.indicator}
          {itemType === TimelineItemType.MILESTONE && (
            <Styled.ActivityTimelineItemIndicatorName>{customIndicator.name}</Styled.ActivityTimelineItemIndicatorName>
          )}
        </Styled.ActivityTimelineItemIndicator>
      ) : (
        <Styled.ActivityTimelineItemIndicator shouldUseDefaultStyles>
          <Styled.ActivityTimelineItemIconWrapper>
            {/* {icon || <FlagIcon size={16} aria-hidden="true" />} */}
          </Styled.ActivityTimelineItemIconWrapper>
        </Styled.ActivityTimelineItemIndicator>
      )}
      {!itemType && <Styled.ActivityTimelineItemCard>{children}</Styled.ActivityTimelineItemCard>}
      {itemType === TimelineItemType.PHASE_BLOCK && (
        <Styled.ActivityTimelineItemCard>{children}</Styled.ActivityTimelineItemCard>
      )}
    </Styled.ActivityTimelineItem>
  );
});

ActivityTimelineItem.displayName = 'ActivityTimelineItem';

ActivityTimelineItem.defaultProps = {
  itemIconWrapperPalette: 'structural'
};

import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, ErrorMessage, LoadingIndicator, Select } from '@m/alchemy-ui';
import { useQuery } from '@apollo/client';
import { useUserFields } from '../../hooks';
import {
  GET_COUNTRIES,
  GET_VOTES_AND_COMMENTS,
  type GetCountriesQuery,
  type GetVotesAndCommentsQuery
} from '../../graphql/queries';
import { CommunityContext } from '../../context';
import { ChallengePublicationStatus } from '../../common/core-api';
import { CustomFilters } from './CustomFilters';
import { Grid, GridCol, GridHeader, GridRow, Pagination } from './Analytics.styled';
import * as Styled from './components/SearchFilters.styled';
import { type ColumnSortType, type UserFieldType } from './types';

import { CHALLENGES_DEFAULT_OFFSET, CHALLENGES_DEFAULT_SORT, CHALLENGES_PER_PAGE } from './constants';

import { ChallengeTable } from './components';
import { AlchemySortDirectionType, CoreApiSortDirectionType } from './enums';

export const VotesAndCommentsStats = () => {
  const intl = useIntl();
  const community = useContext(CommunityContext);
  const defaultUserFieldOptions: UserFieldType = {};
  const [userFieldOptions, setUserFieldOptions] = useState(defaultUserFieldOptions);
  const { data: communityUserFields } = useUserFields();

  const [countries, setCountries] = useState<string[]>([]);

  const [publicationStatus, setPublicationStatus] = useState<ChallengePublicationStatus>(
    ChallengePublicationStatus.PUBLISHED
  );

  const [offset, setOffset] = useState<number>(CHALLENGES_DEFAULT_OFFSET);

  const [order, setOrder] = useState<ColumnSortType>(CHALLENGES_DEFAULT_SORT);

  const { data: countriesList } = useQuery<GetCountriesQuery>(GET_COUNTRIES, {
    variables: { id: community.id },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (countriesList?.community) {
      countriesList.community && setCountries(countriesList.community.countries as string[]);
    }
  }, [countriesList]);

  const {
    loading: loadingVotesAndComments,
    data: votesAndCommentsData,
    refetch: refetchVotesAndComments
  } = useQuery<GetVotesAndCommentsQuery>(GET_VOTES_AND_COMMENTS, {
    variables: {
      publicationStatus,
      limit: CHALLENGES_PER_PAGE,
      offset,
      order
    },
    fetchPolicy: 'cache-first'
  });

  const sortChallenges = (currentColumn: string) => {
    const { column, direction } = order;

    setOrder({
      column: currentColumn,
      direction:
        currentColumn === column && direction === CoreApiSortDirectionType.ASC
          ? CoreApiSortDirectionType.DESC
          : CoreApiSortDirectionType.ASC
    });
  };

  const getColumnSortDirection = (currentColumn: string) => {
    const { column, direction } = order;

    return column === currentColumn
      ? direction === CoreApiSortDirectionType.DESC
        ? AlchemySortDirectionType.DESC
        : AlchemySortDirectionType.ASC
      : 'none';
  };

  return (
    <Grid key="votesAndComments">
      <CustomFilters
        userFieldOptions={userFieldOptions}
        communityUserFields={communityUserFields}
        countries={countries}
        userFieldUpdate={setUserFieldOptions}
      />
      <GridHeader key="publishedState">
        {intl.formatMessage({ id: 'publishedState', defaultMessage: 'Published state' })}
      </GridHeader>
      <GridRow key="publicationStatus">
        <Select
          defaultValue={publicationStatus}
          options={[
            {
              label: intl.formatMessage({ id: 'published', defaultMessage: 'Published' }),
              value: ChallengePublicationStatus.PUBLISHED
            },
            {
              label: intl.formatMessage({ id: 'archived', defaultMessage: 'Archived' }),
              value: ChallengePublicationStatus.ARCHIVED
            },
            {
              label: intl.formatMessage({ id: 'unpublished', defaultMessage: 'Unpublished' }),
              value: ChallengePublicationStatus.UNPUBLISHED
            },
            {
              label: intl.formatMessage({ id: 'all', defaultMessage: 'All' }),
              value: ChallengePublicationStatus.ALL
            }
          ]}
          onChange={(e) => {
            setPublicationStatus(e.value as ChallengePublicationStatus);
            setOffset(CHALLENGES_DEFAULT_OFFSET);
          }}
        />
      </GridRow>
      <GridRow key="updateButton">
        <Button
          priority="secondary"
          onClick={() => {
            setOffset(CHALLENGES_DEFAULT_OFFSET);
            refetchVotesAndComments?.({
              publicationStatus,
              order,
              offset: CHALLENGES_DEFAULT_OFFSET,
              filters: { userFields: JSON.stringify(userFieldOptions) }
            });
          }}
        >
          {intl.formatMessage({ id: 'update', defaultMessage: 'Update' })}
        </Button>
      </GridRow>
      <GridRow key="table" justify="center">
        {loadingVotesAndComments && !votesAndCommentsData ? (
          <Styled.LoadingContainer>
            <LoadingIndicator inline />
          </Styled.LoadingContainer>
        ) : votesAndCommentsData?.votesAndCommentsCount && votesAndCommentsData.votesAndCommentsCount.count > 0 ? (
          <>
            <GridCol>
              <ChallengeTable
                getColumnSortDirection={getColumnSortDirection}
                data={votesAndCommentsData?.votesAndCommentsCount.rows}
                intl={intl}
                sortChallenges={sortChallenges}
              />
            </GridCol>
            <GridCol>
              <Pagination
                numPages={Math.ceil(votesAndCommentsData.votesAndCommentsCount.count / CHALLENGES_PER_PAGE)}
                activeIndex={Math.ceil(offset / CHALLENGES_PER_PAGE)}
                onPageClick={(page) => {
                  const pageIndex = page.index as number;

                  setOffset(pageIndex * CHALLENGES_PER_PAGE);
                }}
              />
            </GridCol>
          </>
        ) : (
          <ErrorMessage
            message={intl.formatMessage({ id: 'noChallengesWereFound', defaultMessage: 'No challenges were found' })}
            details={intl.formatMessage({
              id: 'pleaseAdjustYourSearch',
              defaultMessage: 'Please adjust your search parameters and try again.'
            })}
          />
        )}
      </GridRow>
    </Grid>
  );
};

import { CancelXIcon, EditIcon, IconButton, TeamIcon, units } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { type Theme } from '../../../common/core-api';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${units(1)};
`;
const StyledButtonContainer = styled.span`
  margin-left: auto;
`;
const StyledIconButton = styled(IconButton)`
  margin-left: ${units(1)};
`;
const ThemeName = styled.div`
  max-width: 70%;
  word-break: break-all;
`;

export const ThemesEditorListItem = ({
  theme,
  onEdit,
  onPublishToggle
}: {
  readonly theme: Theme;
  readonly onEdit: (theme: Theme) => void;
  readonly onPublishToggle: (id: Theme) => void;
}) => {
  const intl = useIntl();
  return (
    <StyledWrapper>
      <ThemeName>{theme.name}</ThemeName>
      <StyledButtonContainer>
        <StyledIconButton
          icon={<EditIcon aria-label={intl.formatMessage({ id: 'edit', defaultMessage: 'Edit' })} />}
          tooltipContents={intl.formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
          onClick={() => onEdit(theme)}
        />
        {theme.archived ? (
          <StyledIconButton
            icon={<TeamIcon aria-label={intl.formatMessage({ id: 'publish', defaultMessage: 'Publish' })} />}
            tooltipContents={intl.formatMessage({ id: 'publish', defaultMessage: 'Publish' })}
            onClick={() => onPublishToggle(theme)}
          />
        ) : (
          <StyledIconButton
            icon={<CancelXIcon aria-label={intl.formatMessage({ id: 'unpublish', defaultMessage: 'Unpublish' })} />}
            tooltipContents={intl.formatMessage({ id: 'unpublish', defaultMessage: 'Unpublish' })}
            onClick={() => onPublishToggle(theme)}
          />
        )}
      </StyledButtonContainer>
    </StyledWrapper>
  );
};

import { createContext, type FunctionComponent, type ReactNode, useCallback, useMemo, useState } from 'react';
import { Dialog } from '@m/alchemy-ui';
import * as Styled from './Dialog.styled';

const DialogContext = createContext({
  closeDialog: () => {},
  isDialogLoading: false,
  isDialogOpen: false,
  openDialog: () => {},
  setData: (_v: string) => {},
  setDialogProps: (_props: DefaultDialogProps) => {},
  setFooter: (_v: ReactNode) => {},
  setHasStickyFooter: (_v: boolean) => {},
  setHeader: (_v: ReactNode) => {},
  setIsDialogLoading: (_v: boolean) => {}
});

export interface DialogContextProps {
  closeDialog: () => void;
  isDialogLoading: boolean;
  isDialogOpen: boolean;
  openDialog: () => void;
  setData: (v: string) => void;
  setDialogProps: (props: DefaultDialogProps) => void;
  setFooter: (v: ReactNode) => void;
  setHasStickyFooter: (v: boolean) => void;
  setHeader: (v: ReactNode) => void;
  setIsDialogLoading: (v: boolean) => void;
}

export interface DialogProviderProps {
  readonly children: ReactNode;
}

export interface DefaultDialogProps {
  size?: 'extraLarge' | 'fullpage' | 'large' | 'medium' | 'small' | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const DialogProvider: FunctionComponent<DialogProviderProps> = ({ children }) => {
  const [data, setData] = useState<string>('');
  const [dialogProps, setDialogProps] = useState<DefaultDialogProps>({});
  const [header, setHeader] = useState<ReactNode>(null);
  const [footer, setFooter] = useState<ReactNode>(null);
  const [hasStickyFooter, setHasStickyFooter] = useState<boolean>(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const openDialog = useCallback(() => setIsDialogOpen(true), [setIsDialogOpen]);

  const closeDialog = useCallback(() => {
    setFooter(null);
    setHeader(null);
    setHasStickyFooter(false);
    setIsDialogOpen(false);
    setTimeout(() => {
      setData('');
      setDialogProps({});
    }, 100);
  }, []);

  const dialog = useMemo(
    () => ({
      closeDialog,
      isDialogLoading,
      isDialogOpen,
      openDialog,
      setData,
      setFooter,
      setHasStickyFooter,
      setHeader,
      setIsDialogLoading,
      setDialogProps
    }),
    [
      closeDialog,
      isDialogLoading,
      isDialogOpen,
      openDialog,
      setData,
      setFooter,
      setHasStickyFooter,
      setHeader,
      setIsDialogLoading,
      setDialogProps
    ]
  );

  const noPadding = dialogProps.padding === 0;

  return (
    <DialogContext.Provider value={dialog}>
      {children}
      <Styled.Dialog
        isOpen={isDialogOpen}
        isCentered
        onClose={closeDialog}
        hasCloseIcon
        noPadding={noPadding}
        {...dialogProps}
      >
        {isDialogLoading ? (
          <Styled.LoadingIndicator />
        ) : (
          <>
            {header && <Dialog.Header>{header}</Dialog.Header>}
            <Styled.DialogBody noPadding={noPadding}>
              <Styled.CorrectedStyles hasStickyFooter={hasStickyFooter}>
                {/* eslint-disable-next-line react/no-danger */}
                <div id="dialogContent" dangerouslySetInnerHTML={{ __html: data }} />
              </Styled.CorrectedStyles>
            </Styled.DialogBody>
            {footer && <Dialog.Footer>{footer}</Dialog.Footer>}
          </>
        )}
      </Styled.Dialog>
    </DialogContext.Provider>
  );
};

const DialogConsumer = DialogContext.Consumer;

export { DialogContext, DialogConsumer, DialogProvider };

export interface NotificationSettingsValues {
  action: string;
  useraccess_receiveinstancebulkemails: boolean | null | undefined;
  user_emailforpostcomment: boolean | null | undefined;
  user_emailforpostvote: boolean | null | undefined;
  user_emailforpostfollow: boolean | null | undefined;
  user_emailforuserfollow: boolean | null | undefined;
  user_emailforcommentreply: boolean | null | undefined;
  user_emailforcommentvote: boolean | null | undefined;
  user_slackforpostcomment: boolean | null | undefined;
  user_slackforpostvote: boolean | null | undefined;
  user_slackforpostfollow: boolean | null | undefined;
  user_teamsforpostcomment: boolean | null | undefined;
  user_teamsforpostvote: boolean | null | undefined;
  user_teamsforpostfollow: boolean | null | undefined;
}

export const defaultNotificationSettingsValues = {
  action: 'saveEmailSettings',
  useraccess_receiveinstancebulkemails: false,
  user_emailforpostcomment: false,
  user_emailforpostvote: false,
  user_emailforpostfollow: false,
  user_emailforuserfollow: false,
  user_emailforcommentreply: false,
  user_emailforcommentvote: false,
  user_slackforpostcomment: false,
  user_slackforpostvote: false,
  user_slackforpostfollow: false,
  user_teamsforpostcomment: false,
  user_teamsforpostvote: false,
  user_teamsforpostfollow: false
};

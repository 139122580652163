export const DiscussTemplateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="180" height="90" viewBox="0 0 180 90">
    <defs>
      <mask id="a" x="-50.503" y="-43.003" width="301.505" height="149.505" maskUnits="userSpaceOnUse">
        <rect width="180" height="90" fill="#fff" />
      </mask>
    </defs>
    <rect width="180" height="90" fill="#e2f7f4" />
    <g mask="url(#a)">
      <path
        d="M98.881,14.043a16,16,0,0,1-5.632,21.666l-.292.172-119.5,68.5a16,16,0,0,1-21.838-5.924,16,16,0,0,1,5.632-21.666l.292-.172,119.5-68.5A16,16,0,0,1,98.881,14.043Zm150-49a16,16,0,0,1-5.632,21.666l-.292.172-119.5,68.5a16,16,0,0,1-16.206-27.59l.292-.172,119.5-68.5A16,16,0,0,1,248.881-34.957Z"
        fill="#00ba9e"
        fillOpacity="0.07"
      />
    </g>
    <path
      d="M43.515,41.785H64.777c-.028.505-.046,1.01-.046,1.517a1.516,1.516,0,0,0,1.515,1.517H92.008a1.516,1.516,0,0,0,1.437-2L87.223,24.138A4.549,4.549,0,0,0,84.431,16H69.106a18.142,18.142,0,0,0-15.131,8.105l-.663,1h-9.8A1.516,1.516,0,0,0,42,26.618V40.269a1.516,1.516,0,0,0,1.515,1.516Zm24.294,0a24.2,24.2,0,0,1,3.2-10.618h4.333A4.549,4.549,0,0,0,79.624,25.1h4.725l5.556,16.684Zm-11.312-16a15.119,15.119,0,0,1,12.609-6.754H84.431a1.517,1.517,0,0,1,0,3.033H67.762V25.1h7.577a1.517,1.517,0,0,1,0,3.033H69.277a1.517,1.517,0,0,0-1.261.676,8.675,8.675,0,0,1-7.235,3.876H58.669v3.033h2.112a11.7,11.7,0,0,0,5.448-1.345,27.3,27.3,0,0,0-1.116,4.379H55.639V27.077Z"
      fill="#00ba9e"
    />
    <path
      d="M85.831,67.745l14.8-20.714a1.588,1.588,0,0,0,.215-1.43l-8.05-24.15a25.514,25.514,0,0,1,30.181,34.224,4.78,4.78,0,0,0-4.517,4.774v2.52a25.512,25.512,0,0,1-32.625,4.776Z"
      fill="#71baff"
      fillRule="evenodd"
    />
    <path
      d="M128.377,46.336a4.523,4.523,0,0,0-1.519.262c0-.087,0-.175,0-.262a27.3,27.3,0,0,0-35.9-25.909A1.519,1.519,0,0,0,90,22.346L97.9,46.083,83.376,66.439a1.518,1.518,0,0,0,.351,2.115,27.073,27.073,0,0,0,15.858,5.084,27.349,27.349,0,0,0,6.061-.678L105.662,90h18.145l.024-7.961.995-.664a18.167,18.167,0,0,0,8.1-15.144V50.886A4.552,4.552,0,0,0,128.377,46.336Zm-41.628,20.6,14.069-19.714a1.514,1.514,0,0,0,.2-1.361L93.368,22.871a24.28,24.28,0,0,1,28.7,32.573,4.548,4.548,0,0,0-4.295,4.544v2.4a24.242,24.242,0,0,1-31.021,4.545Zm43.144-.7a15.143,15.143,0,0,1-6.749,12.62l-1.287.859h-13.18V72.082a27.093,27.093,0,0,0,5.848-2.9,11.743,11.743,0,0,0-1.3,5.373v2.114h3.031V74.558a8.688,8.688,0,0,1,3.873-7.242,1.515,1.515,0,0,0,.674-1.262V59.987a1.516,1.516,0,1,1,3.031,0v7.584h3.031V61.5h0V50.886a1.516,1.516,0,1,1,3.031,0Z"
      fill="#51abff"
    />
    <path
      d="M65.273,90l2.236-4.865L79.441,73.194a1.519,1.519,0,0,0,.444-1.073v-7.4L90.21,50.251a1.516,1.516,0,0,0-1.233-2.4H63.215A1.516,1.516,0,0,0,61.7,49.369a27.249,27.249,0,0,0,.979,7.229l-2.871,2.155-.756-5.292a4.761,4.761,0,0,0-9.475.674V76.186l-7.3,10.222a1.519,1.519,0,0,0-.282.881v2.727ZM86.032,50.886,73.678,68.2a24.371,24.371,0,0,1-8.9-17.312ZM54.339,52.4a1.74,1.74,0,0,1,1.713,1.487l1.117,7.828a1.517,1.517,0,0,0,.591,1,13.721,13.721,0,0,1,5.455,10.921h3.031a16.783,16.783,0,0,0-5.152-12.059l2.611-1.959a27.392,27.392,0,0,0,9.414,11.968,1.514,1.514,0,0,0,2.115-.352l1.62-2.27v2.527L65.947,82.41,52.608,75.734v-21.6A1.733,1.733,0,0,1,54.339,52.4Z"
      fill="#404fc7"
    />
    <path d="M86.032,50.886,73.678,68.2a24.371,24.371,0,0,1-8.9-17.312Z" fill="#fff" fillRule="evenodd" />
  </svg>
);

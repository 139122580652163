import { useIntl } from 'react-intl';

const ToggleShowAllLess = (props: {
  readonly isShowAll: boolean;
  readonly isDisabled: boolean;
  readonly isLoading: boolean;
  readonly toggle: () => void;
}) => {
  const { isShowAll, isDisabled, isLoading, toggle } = props;
  const intl = useIntl();

  const showAllIntl = intl.formatMessage({
    id: 'moderatorAction.showAll',
    defaultMessage: 'Show All'
  });

  const showLessIntl = intl.formatMessage({
    id: 'moderatorAction.showLess',
    defaultMessage: 'Show Less'
  });

  return (
    <button
      disabled={isDisabled || isLoading}
      type="button"
      className="b-single-idea__follow mdl-button"
      onClick={() => toggle()}
    >
      {isShowAll ? `${showAllIntl}` : showLessIntl}
    </button>
  );
};

export default ToggleShowAllLess;

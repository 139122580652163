import { DEFAULT_DATE } from '../../features/challenge-management/helpers/phase';
import { type Phase } from '../core-api';
import { defaultChallengeVisibility } from './ChallengeVisibility';
import { defaultVoting } from './Voting';

const nowStartDate = new Date(Date.now() + 3_600_000).toISOString();

export const defaultPhase: Phase = {
  id: Date.now().toString(),
  access: {
    users: [],
    groups: [],
    options: defaultChallengeVisibility
  },
  description: '',
  startDate: nowStartDate,
  endDate: DEFAULT_DATE,
  name: '',
  questions: [],
  voting: defaultVoting
};

import { hasValue } from '@m/magic-typescript';
import { ChallengeManagementTabTypes } from '../../common/enums';

export interface Error {
  [key: string]: Error;
}

// Define order: [ChallengeManagement Tab]: [field1, field2, field3, ...]
const fieldsByTab = {
  [ChallengeManagementTabTypes.DETAILS]: ['title', 'subtitle', 'config'],
  [ChallengeManagementTabTypes.TYPE]: ['milestone', 'phases'],
  [ChallengeManagementTabTypes.SETTINGS]: ['access', 'headings', 'voting', 'questions'],
  global: ['template']
};

export const getFirstError = (errors: Error) => {
  const orderedErrors = errorFieldNames(errors)
    .flatMap((error) => {
      const [field] = error.split('.');
      return Object.entries(fieldsByTab)
        .map((group, tabIndex) => {
          const [tab, fields] = group;
          const fieldIndex = fields.indexOf(field.split('[')[0]);
          const [, itemIndex] = error.match(/\[([0-9]+)\]/) || [];
          // itemTabIndex is as deeply nested as this goes
          // this is the field in Customise Form tab in the Phase modal in the Challenge Type tab
          // fieldsByTab may need children if this becomes more than a single case
          const itemTabIndex = error.includes('phases') && error.includes('questions') ? 3 : 0;
          return fieldIndex > -1
            ? {
                tabIndex, // index of ChallengeManagement tabs (for ordering)
                tab, // ChallengeManagement tab name (for navigation)
                fieldIndex, // index of field (for ordering)
                field: fields[fieldIndex], // name of field
                name: error, // full path of field eg. phases[2].questions[1].name
                itemIndex: Number(itemIndex || 0), // index of phase / milestone (for navigation)
                itemTabIndex // index of tab within phase form (for navigation)
              }
            : undefined;
        })
        .filter(hasValue);
    })
    .sort((a, b) => a.tabIndex - b.tabIndex || a.itemIndex - b.itemIndex || a.fieldIndex - b.fieldIndex);
  return orderedErrors[0] || null;
};

export const focusField = (name: string) => {
  // Remove milestone & phase path from name as they have their own forms where phase[1].name / milestone.milestones[2].name is referred to as 'name'
  const cleanedName = name.replace(/phases\[[0-9]+\]\./, '');
  const field = document.getElementsByName(cleanedName);
  // timeout to allow for popper transition
  return setTimeout(() => field.length > 0 && field[0].focus(), 200);
};

/*
  Get the field name from the errors shape
  eg. { name: { message: '' }, questions: { 2: { name: { message: '' } } } }
  returns: [ 'name', 'questions[2].name' ]
*/
const errorFieldNames = (errors: Error) => {
  const getChild = (path = '', error: Error): string[] =>
    Object.entries(error).flatMap(([key, value]) => {
      // No children
      if (typeof value === 'string') {
        return path;
      }

      // Key is digit then set path to array
      if (!/\D/g.test(key)) {
        return getChild(`${path}[${key}]`, value);
      }

      const currentPath = `${path}.${key}`;
      if (!Object.prototype.hasOwnProperty.call(value, 'message')) {
        return getChild(currentPath, value);
      }

      return currentPath;
    });

  return Object.entries(errors).flatMap(([key, value]) => getChild(key, value));
};

import { ChallengeSelectionType } from '../features/analytics/enums';
import { type CoauthorIdeasQuery, type FilteredUsersQuery } from '../graphql/queries';
import { type Community } from '../common/core-api';
import { EXPORT_TO_CSV_ENDPOINT } from '../features/analytics/constants';

export const getExportIdeasUrl = (
  {
    challenge,
    status,
    customFiltersUserIds,
    coauthorIdeaIds
  }: {
    challenge: string;
    status?: string;
    customFiltersUserIds?: FilteredUsersQuery;
    coauthorIdeaIds?: CoauthorIdeasQuery;
  },
  community: Community
): URL => {
  const challengeId =
    challenge === ChallengeSelectionType.PUBLISHED || challenge === ChallengeSelectionType.ALL ? '0' : challenge;

  const baseUrl = `https://${community.url}`;
  const url = new URL(EXPORT_TO_CSV_ENDPOINT + challengeId, baseUrl);

  if (status) {
    url.searchParams.append('ideaStatus', status);
  }

  url.searchParams.append('includeUnpublished', challenge === ChallengeSelectionType.PUBLISHED ? '0' : '1');
  url.searchParams.append('includeComments', '0');

  if (customFiltersUserIds && customFiltersUserIds.filteredUsers.length > 0) {
    url.searchParams.append('customFiltersUserIds', customFiltersUserIds.filteredUsers.join(','));
  }

  if (coauthorIdeaIds && coauthorIdeaIds.coauthorIdeas.length > 0) {
    url.searchParams.append('ideaIds', coauthorIdeaIds.coauthorIdeas.join(','));
  }

  return url;
};

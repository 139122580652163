import { atMinWidth, borderRadius, color, MultilineEllipsis, snap, typeStyle, units } from '@m/alchemy-ui';
import styled from 'styled-components';
import { Breakpoints } from '../../../common/enums';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: top;
  & > div:last-of-type {
    width: 100%;
  }
  &:not(:last-child) {
    margin-bottom: ${units(1.5)};
  }

  ${atMinWidth(
    Breakpoints.WIDE_MOBILE_MIN,
    `
    flex-direction: row;
    `
  )};
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const IdeaImageWrapper = styled.div`
  height: ${snap(200)};
  width: 100%;
  margin-bottom: ${units(1)};

  ${atMinWidth(
    Breakpoints.WIDE_MOBILE_MIN,
    `
    height: ${snap(120)};
    width: ${snap(120)};
    margin-right: ${units(2)};
    `
  )};
`;

export const DefaultImageContainer = styled.div`
  border-radius: ${borderRadius('small')};
  margin-right: ${units(2)};
  svg {
    width: ${snap(120)};
    height: ${snap(120)};
  }
`;

export const IdeaTitle = styled.a`
  font: ${typeStyle('bodyM')};
`;

export const IdeaTitleGreyout = styled.a`
  font: ${typeStyle('bodyM')};
  color: grey;
  pointer-events: none;
`;

export const IdeaDescription = styled(MultilineEllipsis)`
  margin-top: ${units(1)};
  line-height: 1.3;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color('contrast', { palette: 'illustrativeBrand' })};
  border-radius: 50%;
  width: ${units(3)};
  height: ${units(3)};
  margin-right: ${units(0.5)};
  svg {
    color: ${color('base', { palette: 'illustrativeBrand' })};
    width: ${units(1.5)};
    height: ${units(1.5)};
  }
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ByAuthor = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-left: ${units(0.5)};
  }
`;

import { Button, color, typeStyle, units, UserIcon } from '@m/alchemy-ui';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${units(2.5)};
`;

export const Name = styled.h3`
  ${typeStyle('subHeaderM')};
  margin: ${units(0.5)} 0;
  text-align: center;
`;

export const Location = styled.p`
  ${typeStyle('captionM')};
`;

export const Date = styled.p`
  ${typeStyle('captionM')};
  margin-bottom: ${units(1)};
`;

export const EditBtn = styled(Button)`
  ${typeStyle('captionM')};
  border-color: ${color('base', { palette: 'interactiveConstructive' })};
`;

export const FollowButton = styled(Button)`
  border-color: ${color('base', { palette: 'interactiveConstructive' })};
`;

export const Stats = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  max-width: 100%;
  padding: ${units(2)} 0 ${units(1)};
`;

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${units(1)};
`;

export const StatValue = styled.div`
  ${typeStyle('subHeaderM')};
  text-align: center;
`;

export const StatLabel = styled.div`
  ${typeStyle('captionS')};
  text-align: center;
`;

export const Activity = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  max-width: 100%;
`;

export const ActivityItem = styled.div`
  align-items: center;
  color: ${color('base')};
  display: flex;
  justify-content: center;
  padding: ${units(1)} ${units(1.5)};
  position: relative;

  &:nth-child(2) {
    border-left: 1px solid ${color('line')};
    border-right: 1px solid ${color('line')};
  }
`;

export const ActivityLabel = styled.div`
  ${typeStyle('subHeaderM')};
  color: ${color('typeBody')};
  padding-left: ${units(1)};
`;

export const ProfileImageHolder = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  height: ${units(8)};
  margin-bottom: ${units(1)};
  margin: 0 auto;
  width: ${units(8)};
`;

export const NoProfileImage = styled(UserIcon)`
  height: ${units(8)};
  width: ${units(8)};
`;

export const ButtonGroup = styled.div`
  display: flex;
`;

import styled from 'styled-components';
import {
  LoadingIndicator as AlchemyLoadingIndicator,
  Button,
  color,
  snap,
  typeStyle,
  units,
  UserGradientIcon
} from '@m/alchemy-ui';

export const Results = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${snap(300)};
  width: 100%;

  @media (min-width: 500px) {
    padding: ${units(3)};
  }
`;

export const LoadingIndicator = styled(AlchemyLoadingIndicator)`
  height: ${snap(300)};
  position: relative;
`;

export const Ranking = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid ${color('hint')};
  display: flex;
  padding: ${units(2)} 0;
  width: 100%;

  @media (min-width: 700px) {
    align-items: center;
  }
`;

export const Count = styled.div`
  ${typeStyle('subHeaderM')};
  align-items: center;
  display: flex;
  height: ${snap(50)};
  justify-content: center;
  margin-right: ${units(1)};
  text-align: center;
  width: ${snap(50)};

  @media (min-width: 500px) {
    margin-right: ${units(2.5)};
  }
`;

export const UserImage = styled.img`
  border-radius: 50%;
  cursor: pointer;
  height: ${snap(50)};
  margin-bottom: ${units(1)};
  margin-right: ${units(2.5)};
  min-width: ${snap(50)};
  width: ${snap(50)};
`;

export const DefaultUserImage = styled(UserGradientIcon)`
  color: ${color('line')};
  cursor: pointer;
  height: ${snap(50)};
  margin-right: ${units(2.5)};
  min-width: ${snap(50)};
  width: ${snap(50)};
`;

export const FlexGroup = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 699px) {
    & > * {
      &:not(:last-child) {
        margin-bottom: ${units(1)};
      }
    }
  }

  @media (min-width: 700px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const UserInfo = styled.div`
  ${typeStyle('captionM')};
  color: ${color('typeSubHeader')};

  @media (min-width: 700px) {
    margin-right: ${units(3)};
    width: 50%;
  }
`;

export const Score = styled.span`
  margin-right: ${units(0.5)};
`;

export const UserName = styled.a`
  ${typeStyle('headerS')};
  color: ${color('typeLink')};
  display: block;
`;

export const JoinDate = styled.span`
  margin-left: ${units(0.5)};
`;

export const FollowButton = styled(Button)`
  border: 1px solid ${color('highlight', { palette: 'interactiveConstructive' })};
  overflow-wrap: break-word;
  white-space: normal;

  @media (min-width: 700px) {
    margin-left: auto;
    max-width: 20%;
  }
`;

export const NoRankingsMesssage = styled.div`
  margin-top: ${units(2)};
  padding: ${units(1)} ${units(1.5)};
  border-radius: 8px;
  background-color: ${color('hint', { palette: 'interactiveConstructive' })};
  border: 1px solid ${color('highlight', { palette: 'interactiveConstructive' })};
`;

import { type ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { units } from '@m/alchemy-ui';
import { type Challenge, type Idea, type IdeaUser, type Maybe, NotificationType } from '../../../common/core-api';
import { useFile } from '../../../hooks';
import { removeTags } from '../../../helpers';
import { ANONYMOUS_USER_ID } from '../../shared-constants';
import { AnonymousIcon } from '../NotificationStream.styled';
import * as Styled from './Header.styled';
import { ChallengeLink, IdeaLink, UserLink } from '.';

const getHeaderAvatar = (url: string, isAuthorAnonymous: boolean) => {
  if (url) {
    return <Styled.ProfileImage data-testid="UserProfileImage" src={url} />;
  }

  if (isAuthorAnonymous) {
    return <AnonymousIcon aria-hidden="true" />;
  }

  return <Styled.NoProfileImage aria-hidden="true" />;
};

const getProfileImage = (author: Maybe<IdeaUser> | undefined) =>
  author && 'profileImageFile' in author && author.profileImageFile;

export const IdeaCardHeader = ({
  type,
  notificationCount,
  challenge,
  isUserNotification,
  author,
  idea,
  sameChallenge,
  isValid
}: {
  readonly type: NotificationType;
  readonly notificationCount: number;
  readonly challenge?: Maybe<Challenge> | null;
  readonly isUserNotification: boolean;
  readonly author?: Maybe<IdeaUser>;
  readonly idea?: Maybe<Idea>;
  readonly sameChallenge: boolean;
  readonly isValid?: boolean;
}) => {
  const intl = useIntl();

  const [getFile] = useFile();
  const file = getProfileImage(author);
  const challengeTitle = removeTags(challenge?.title || '');
  const isAuthorAnonymous = author?.id === ANONYMOUS_USER_ID;
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    const getAuthUrl = async () => {
      const url = file && (await getFile({ file, width: parseInt(units(3), 2), height: parseInt(units(3), 2) }));
      setAvatarUrl(url || '');
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const target =
    type === NotificationType.IDEA_PHASE_REACHED
      ? intl.formatMessage({ id: 'phase', defaultMessage: 'phase' })
      : intl.formatMessage({ id: 'milestone', defaultMessage: 'Milestone' }).toLowerCase();

  const challengeLink = (msg: ReactNode, id: string | undefined) => (isValid ? ChallengeLink(msg, id) : msg);

  return (
    <>
      {!isUserNotification && (
        <Styled.AlignItemsWrap>
          {type === NotificationType.IDEA_NEW && challenge && (
            <FormattedMessage
              id="challengeReceivedIdeas"
              defaultMessage="The challenge <a>{challengeTitle}</a> {itemCount, plural, one {received a new idea} other {has new ideas}}"
              values={{
                challengeTitle,
                a: (msg) => challengeLink(msg, challenge?.id),
                itemCount: notificationCount
              }}
            />
          )}
          {type === NotificationType.IDEA_VOTE && challenge && (
            <FormattedMessage
              id="anIdeasHasNewVotes"
              defaultMessage="{itemCount, plural, one {An idea} other {Ideas}} from the challenge <a>{challengeTitle}</a> received votes"
              values={{
                challengeTitle,
                a: (msg) => challengeLink(msg, challenge?.id),
                itemCount: notificationCount
              }}
            />
          )}
          {(type === NotificationType.IDEA_PHASE_REACHED || type === NotificationType.IDEA_MILESTONE_REACHED) &&
            notificationCount === 1 && (
              <FormattedMessage
                id="anIdeaReached"
                defaultMessage="The idea <a>{ideaTitle}</a> from the challenge <b>{challengeTitle}</b> reached the next {target}"
                values={{
                  challengeTitle,
                  ideaTitle: removeTags(idea?.title || ''),
                  target,
                  a: (msg) => (isValid ? IdeaLink(msg, idea?.id) : msg),
                  b: (msg) => challengeLink(msg, challenge?.id)
                }}
              />
            )}
          {(type === NotificationType.IDEA_PHASE_REACHED || type === NotificationType.IDEA_MILESTONE_REACHED) &&
            notificationCount > 1 && (
              <FormattedMessage
                id="ideasReached"
                defaultMessage="Ideas from the challenge <b>{challengeTitle}</b> reached the next {target}"
                values={{
                  challengeTitle,
                  target,
                  b: (msg) => challengeLink(msg, challenge?.id)
                }}
              />
            )}
          {type === NotificationType.BLOG_POST_NEW && (
            <FormattedMessage
              id="blogHasNewPosts"
              defaultMessage="Check out {itemCount, plural, one {this new blog post} other {these new blog posts}}"
              values={{
                itemCount: notificationCount
              }}
            />
          )}
        </Styled.AlignItemsWrap>
      )}

      {isUserNotification && (
        <Styled.AlignItemsTop>
          {getHeaderAvatar(avatarUrl, isAuthorAnonymous)}
          <Styled.AlignItemsWrap>
            {type === NotificationType.IDEA_NEW && (
              <>
                {isAuthorAnonymous ? (
                  <FormattedMessage
                    id="anonymousContributedToChallenge"
                    defaultMessage="Anonymous contributed {itemCount, plural, one {an idea} other {ideas}} to the {challengeCount, plural, one {challenge <challengeLink>{challengeTitle}</challengeLink>} other {challenges}}"
                    values={{
                      challengeTitle,
                      challengeLink: (msg) => challengeLink(msg, challenge?.id),
                      itemCount: notificationCount,
                      challengeCount: notificationCount === 1 ? 1 : sameChallenge ? 1 : 2
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="userContributedToChallenge"
                    defaultMessage="<userLink>{userName}</userLink> contributed {itemCount, plural, one {an idea} other {ideas}} to the {challengeCount, plural, one {challenge <challengeLink>{challengeTitle}</challengeLink>} other {challenges}}"
                    values={{
                      challengeTitle,
                      userLink: (msg) =>
                        UserLink(msg, author?.id, intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' })),
                      challengeLink: (msg) => challengeLink(msg, challenge?.id),
                      itemCount: notificationCount,
                      userName: author?.displayName,
                      challengeCount: notificationCount === 1 ? 1 : sameChallenge ? 1 : 2
                    }}
                  />
                )}
              </>
            )}
            {(type === NotificationType.IDEA_PHASE_REACHED || type === NotificationType.IDEA_MILESTONE_REACHED) && (
              <FormattedMessage
                id="userIdeaReached"
                defaultMessage="<a>{authorName}</a> {itemCount, plural, one {idea} other {ideas}} from the challenge <b>{challengeTitle}</b> reached the next {target}"
                values={{
                  challengeTitle,
                  authorName: author?.displayName,
                  target,
                  a: (msg) =>
                    UserLink(msg, author?.id, intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' })),
                  b: (msg) => challengeLink(msg, challenge?.id),
                  itemCount: notificationCount
                }}
              />
            )}
            {type === NotificationType.BLOG_POST_NEW && (
              <FormattedMessage
                id="userBlogHasNewPosts"
                defaultMessage="<userLink>{userName}</userLink> contributed to the blog"
                values={{
                  userLink: (msg) =>
                    UserLink(msg, author?.id, intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' })),
                  userName: author?.displayName
                }}
              />
            )}
            {type === NotificationType.IDEA_TAG && (
              <>
                {isAuthorAnonymous ? (
                  <FormattedMessage id="anonymousTaggedOnIdea" defaultMessage="Anonymous has tagged you on an idea" />
                ) : (
                  <FormattedMessage
                    id="taggedOnIdea"
                    defaultMessage="<userLink>{userName}</userLink> has tagged you on an idea"
                    values={{
                      userLink: (msg) =>
                        UserLink(msg, author?.id, intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' })),
                      userName: author?.displayName
                    }}
                  />
                )}
              </>
            )}
          </Styled.AlignItemsWrap>
        </Styled.AlignItemsTop>
      )}
    </>
  );
};

import { gql } from '@apollo/client';

export const GET_MODERATOR_ACTIONS = gql`
  query getModeratorActionLogs($ideaId: Int!, $language: String, $offset: Int, $limit: Int) {
    moderatorActionLogs(ideaId: $ideaId, language: $language, offset: $offset, limit: $limit) {
      __typename
      total
      offset
      limit
      items {
        id
        createdAt
        user {
          id
          name
          url
        }
        actionId
        payload {
          ... on ModeratorActionSimplePayload {
            id
            name
            url
          }
          ... on ModeratorActionIdeaMovePayload {
            from {
              id
              name
              url
            }
            to {
              id
              name
              url
            }
          }
          ... on ModeratorActionIdeaStatusPayload {
            added {
              id
              name
            }
            removed {
              id
              name
            }
          }
        }
      }
    }
  }
`;

import { FormattedMessage } from 'react-intl';
import { getValidationMessages } from '../../helpers/resetPasswordValidation';
import { usePasswordComplexity } from '../../../../context/use-password-complexity';
import { PasswordHintsList, PasswordHintsListItem } from './PasswordReset.styled';

export const PasswordResetHint = () => {
  const complexityConfig = usePasswordComplexity();
  const messages = getValidationMessages(complexityConfig);
  const minLengthError = complexityConfig.passwordComplexityEnabled
    ? messages.passwordConfigurableMinLengthError
    : messages.passwordMinLengthError;
  const charactersComplexityError = complexityConfig.passwordComplexityEnabled
    ? messages.passwordConfigurableCharactersComplexityError
    : messages.passwordCharactersComplexityError;

  return (
    <PasswordHintsList>
      <PasswordHintsListItem>
        <FormattedMessage {...minLengthError} />
      </PasswordHintsListItem>
      <PasswordHintsListItem>
        <FormattedMessage {...messages.passwordMaxLengthError} />
      </PasswordHintsListItem>
      <PasswordHintsListItem>
        <FormattedMessage {...charactersComplexityError} />
      </PasswordHintsListItem>
      <PasswordHintsListItem>
        <FormattedMessage {...messages.passwordComplexityError} />
      </PasswordHintsListItem>
    </PasswordHintsList>
  );
};

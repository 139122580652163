import { FieldStatus } from '@m/alchemy-ui';
import React, { type ChangeEvent, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { type MixedTypes } from '../../../common/interfaces';
import { CommunityContext } from '../../../context';
import { getValueOrPath, handleTranslationChange } from '../helpers/translation';
import { DEFAULT_LANGUAGE } from '../../shared-constants';
import * as Styled from './TranslatableInput.styled';
import { LanguageSelector } from '.';

export interface TranslatableInputProps {
  readonly children: JSX.Element;
  readonly type: string;
  readonly field: string;
  readonly id?: string;
  readonly translateId: string;
}

export const TranslatableInput = ({ children, type, field, translateId, id }: TranslatableInputProps) => {
  const community = useContext(CommunityContext);
  const { values, setFieldValue } = useFormikContext<MixedTypes & { language: string }>();
  const { language: allLanguage } = values;

  const [language, setLanguage] = useState<string>(allLanguage || DEFAULT_LANGUAGE);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  /*
    fieldPath looks like '--.value' when there is an existing value to be updated for the field
    ex) 'questions[1].options[1].translations[0].value'
    If there is no exisiting value (new translation is being added),
    fieldPath looks like '--.trnaslations'
    ex) 'questions[1].options[1].translations'
  */
  const [fieldPath, setFieldPath] = useState<string>('');

  const isDefaultLanguage = language === DEFAULT_LANGUAGE;
  const isET = children.type.displayName === 'EditableText';

  useEffect(() => {
    const path = getValueOrPath(values, { language, field, type, translateId }, true);
    setFieldPath(path);
  }, [field, language, translateId, type, values]);

  useEffect(() => {
    setLanguage(allLanguage);
  }, [allLanguage]);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (!isDefaultLanguage && !translateId) {
      setShowMessage(true);
    }

    handleTranslationChange({
      language,
      field,
      type,
      translateId,
      value,
      fieldPath,
      communityId: community.id,
      setFieldValue,
      values
    });
  };

  const translatedInputProps = (child: JSX.Element) => {
    const value = getValueOrPath(values, { language, field, type, translateId });

    if (isET) {
      return {
        id,
        inputProps: {
          name: `${child.props.inputProps?.name || id}[${language}]`,
          onChange: handleChange
        },
        value
      };
    }

    return {
      id,
      name: `${child.props.name || id}[${language}]`,
      value,
      onChange: handleChange
    };
  };

  // Clone the element, don't change anything but provide ID which may have been given from Field cmp to preserve label targets
  const defaultLanguageInput = React.cloneElement(children, { id });

  if (!community.options?.translationLanguages || community.options?.translationLanguages.length <= 1) {
    return <Styled.StyledInput>{defaultLanguageInput}</Styled.StyledInput>;
  }

  return (
    <Styled.Wrapper inline>
      <Styled.StyledInput applyMaxWidth={isET}>
        {isDefaultLanguage
          ? defaultLanguageInput
          : React.Children.map(children, (child) => React.cloneElement(child, translatedInputProps(child)))}
        {showMessage && (
          <FieldStatus
            level="error"
            message={<FormattedMessage id="InsertInEnglish" defaultMessage="Insert field in English first" />}
          />
        )}
      </Styled.StyledInput>
      <LanguageSelector value={language} onLanguageChange={(lang: string) => setLanguage(lang)} />
    </Styled.Wrapper>
  );
};

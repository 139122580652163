import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FacebookLogo, GoogleLogo, InstagramLogo, LinkedInLogo, TwitterLogo } from '@m/alchemy-ui';
import { type SocialLinkType } from '../../../common/core-api';
import * as Styled from './SocialMedia.styled';

const SocialMediaIconLink = ({ type, url }: Omit<SocialLinkType, 'id'>) => {
  const icons = {
    Facebook: <FacebookLogo aria-hidden="true" />,
    Google: <GoogleLogo aria-hidden="true" />,
    Instagram: <InstagramLogo aria-hidden="true" />,
    LinkedIn: <LinkedInLogo aria-hidden="true" />,
    X: <TwitterLogo aria-hidden="true" />
  } as { [key: string]: React.ReactNode };
  const intl = useIntl();

  return (
    <Styled.IconLink
      data-testid="icon"
      href={url}
      target="_blank"
      aria-label={intl.formatMessage(
        {
          id: 'socialMediaIconLink',
          description: 'Social media icon link',
          defaultMessage: "Link to users' {social} page"
        },
        { social: type }
      )}
    >
      {icons[type] || null}
    </Styled.IconLink>
  );
};

export const SocialMedia = ({ socialLinks = null }: { readonly socialLinks: SocialLinkType[] | null | undefined }) => {
  if (!socialLinks || socialLinks.length === 0) {
    return null;
  }

  return (
    <Styled.Holder>
      <Styled.Title>
        <FormattedMessage id="socialMedia" defaultMessage="Social media" />
      </Styled.Title>
      <Styled.IconsHolder data-testid="icons-wrapper">
        {socialLinks?.map((item) => <SocialMediaIconLink key={item.id} type={item.type} url={item.url} />)}
      </Styled.IconsHolder>
    </Styled.Holder>
  );
};

import { useState } from 'react';
import { Button, Checkbox, color, DeleteIcon, Field, Input, snap, units, useBeautifulList } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { type FormikProps, useFormikContext } from 'formik';
import { type DropResult } from 'react-beautiful-dnd';
import { Body, DeleteIconButton, type Item, ReorderableList, SectionTitle } from '../../../common/components';
import { type IChallenge } from '../../../common/interfaces';
import { type GlobalCategoryWithAssignedField } from '../../../common/core-api';

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 ${units(2)};
  & > div {
    width: ${snap(500)};
    max-width: 80%;
    margin-right: ${units(1)};
  }
`;

const CategoryLisitItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${units(1)};
  border-bottom: 1px solid ${color('line')};
  div {
    margin: 0 auto;
  }
  & > * {
    &:first-child {
      width: 66.66%;
      margin-left: 0;
    }
    &:last-child {
      margin-left: auto;
    }
  }
`;

export const Categories = () => {
  const { values, handleChange, setFieldValue }: FormikProps<IChallenge> = useFormikContext();
  const categories = values.categories || [];

  const intl = useIntl();

  const [newCategory, setNewCategory] = useState('');

  const handleDelete = (index: number) =>
    setFieldValue(
      'categories',
      categories.filter((_, CategoryIndex) => CategoryIndex !== index)
    );

  const handleAdd = () => {
    setNewCategory('');
    setFieldValue('categories', [
      ...categories,
      {
        // Temporary ID to be replaced on save
        id: Date.now().toString(),
        order: categories.length > 0 ? categories[categories.length - 1].order + 1 : 0,
        name: newCategory,
        assigned: true
      }
    ]);
  };

  const getItems = () =>
    categories.map((category, index) => ({
      id: category.id,
      content: (
        <CategoryLisitItem key={category.id}>
          <Field
            isFullWidth
            input={
              <Input
                placeholder={intl.formatMessage({ id: 'categoryName', defaultMessage: 'Category name' })}
                name={`categories[${index}].name`}
                value={category.name}
                onChange={handleChange}
              />
            }
          />
          <Field
            input={
              <Checkbox
                label={intl.formatMessage({ id: 'assign', defaultMessage: 'Assign' })}
                name={`categories[${index}].assigned`}
                checked={category.assigned}
                onChange={handleChange}
              />
            }
          />
          <DeleteIconButton
            icon={<DeleteIcon aria-label={intl.formatMessage({ id: 'deleteIcon', defaultMessage: 'Delete icon' })} />}
            tooltipContents={intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
            onClick={() => handleDelete(index)}
          />
        </CategoryLisitItem>
      )
    })) || [];

  const reorder = (items: Item[], result: DropResult) => {
    const startIndex = result.source.index;
    const endIndex = result.destination?.index || 0;

    const orderedList = [...items];
    const [removed] = orderedList.splice(startIndex, 1);
    orderedList.splice(endIndex, 0, removed);

    const orderedCategories = [...categories];
    const [removedCategory] = orderedCategories.splice(startIndex, 1);
    orderedCategories.splice(endIndex, 0, removedCategory);
    setFieldValue(
      'categories',
      orderedCategories.map((category: GlobalCategoryWithAssignedField, index: number) => ({
        ...category,
        order: index
      }))
    );

    return orderedList;
  };

  const { onDragEnd } = useBeautifulList({ defaultCollection: getItems(), reorder });

  return (
    <>
      <SectionTitle>
        <FormattedMessage id="Categories" defaultMessage="Categories" />
      </SectionTitle>
      <Body>
        <FormattedMessage
          id="categoriesHelp"
          defaultMessage="Categories are a way of grouping and filtering challenges. To learn more about adding categories, please see our help guide."
        />
      </Body>

      <FlexRow>
        <Field
          isFullWidth
          input={
            <Input
              placeholder={intl.formatMessage({ id: 'newCategoryName', defaultMessage: 'New category name' })}
              name="newCategoryName"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
          }
        />
        <Button priority="secondary" size="small" onClick={handleAdd}>
          <FormattedMessage id="addCategory" defaultMessage="Add category" />
        </Button>
      </FlexRow>

      <ReorderableList items={getItems()} onDragEnd={onDragEnd} />
    </>
  );
};

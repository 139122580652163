import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { type ScoreHistoryRow } from '../interface/ScoreHistoryRow';
import { MemoizedScoreHistoryListElement } from './ScoreHistoryListElement';

const StoryHistoryListContainer = styled.div`
  margin-top: 20px;
  height: 250px;
  overflow-y: auto;
`;

const StyleTableHeader = styled.th`
  text-align: left;
  border-bottom: 2px solid #eaeaea;

  &:nth-of-type(1) {
    width: 195px;
  }

  &:nth-of-type(2) {
    width: 160px;
  }

  &:nth-of-type(3) {
    width: 110px;
  }

  &:nth-of-type(4) {
    width: 30px;
  }

  &:nth-of-type(5) {
    width: 155px;
  }
`;

export const ScoreHistoryList = ({
  scoreHistoryData,
  handleScoreTotalUpdate
}: {
  readonly scoreHistoryData: ScoreHistoryRow[];
  readonly handleScoreTotalUpdate: (row: ScoreHistoryRow) => void;
}) => {
  const intl = useIntl();

  const date = intl.formatMessage({ id: 'date', defaultMessage: 'Date' });
  const event = intl.formatMessage({ id: 'event', defaultMessage: 'Event' });
  const scoreAwarded = intl.formatMessage({ id: 'scoreAwarded', defaultMessage: 'Score awarded' });
  const link = intl.formatMessage({ id: 'link', defaultMessage: 'Link' });

  return (
    <StoryHistoryListContainer>
      <table className="mdl-table">
        <tbody>
          <tr>
            <StyleTableHeader>{date}</StyleTableHeader>
            <StyleTableHeader>{event}</StyleTableHeader>
            <StyleTableHeader>{scoreAwarded}</StyleTableHeader>
            <StyleTableHeader />
            <StyleTableHeader>{link}</StyleTableHeader>
          </tr>
          {scoreHistoryData?.map((row) => (
            <MemoizedScoreHistoryListElement
              key={`userscore_row_${row.id}`}
              row={row}
              handleScoreTotalUpdate={handleScoreTotalUpdate}
            />
          ))}
        </tbody>
      </table>
    </StoryHistoryListContainer>
  );
};

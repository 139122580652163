import { Field as AlchemyField, DisplayBox, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const Section = styled.section`
  margin: ${units(2)} 0;
`;

export const Field = styled(AlchemyField)`
  margin-bottom: ${units(1)};
`;

export const ApiBox = styled(DisplayBox)`
  margin: ${units(2)} 0;
  padding: ${units(3)} ${units(2)};
`;

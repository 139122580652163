import styled, { css } from 'styled-components';
import { Pagination as AlchemyPagination, borderRadius, Button, color, snap, typeStyle, units } from '@m/alchemy-ui';

const statColumnWidth = '17%';

export const Wrapper = styled.div`
  ${typeStyle('bodyS')};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PaginationContainer = styled.div`
  align-items: center;
  align-self: center;
  border-radius: ${borderRadius('large')};
  border: 1px solid ${color('lineContrast')};
  display: flex;
  margin: ${units(1)} 0;
  padding: 0 ${units(0.5)};
`;

export const Pagination = styled(AlchemyPagination)`
  @media (max-width: 400px) {
    li {
      min-width: ${units(4)};
      width: ${units(4)};
    }
  }
`;

export const PaginationButton = styled(Button)`
  padding: 6px 8px;
`;

export const UserCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const InitialFilter = styled.ul`
  background-color: ${color('canvas')};
  border-radius: ${borderRadius('large')};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: ${units(2)} 0;
  button {
    padding: ${units(0.5)} ${units(1)};
    margin: 3px 4px;
    border-radius: ${borderRadius('large')};
    ${typeStyle('bodyS')};
  }
`;

export const NoUsersMessage = styled.div`
  background-color: ${color('hint', { palette: 'interactiveConstructive' })};
  border-radius: 8px;
  border: 1px solid ${color('highlight', { palette: 'interactiveConstructive' })};
  height: ${units(5)};
  margin-left: ${units(2)};
  margin-top: ${units(2)};
  padding: ${units(1)} ${units(1.5)};
  width: 100%;
`;

export const TotalStat = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

export const FiltersDisplay = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: ${units(2)};
  width: 100%;

  @media (min-width: 700px) {
    align-items: flex-start;
    min-width: ${statColumnWidth};
    padding-top: ${units(2.5)};
    width: ${statColumnWidth};
  }
`;

export const Initial = styled.div`
  ${typeStyle('displayM')};
  font-weight: 400;
  margin: ${units(1.5)} 0;
`;

export const Bold = styled.p`
  ${typeStyle('bodyM')};
  margin-right: ${units(0.5)};
`;

export const Result = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 700px) {
    flex-direction: row;
  }
`;

export const SelectedFilter = styled.div<{ autoWidth?: boolean }>`
  background-color: ${color('fill')};
  border-radius: ${borderRadius('extraLarge')};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${units(1)};
  padding: ${units(0.5)} ${units(1.5)};
  word-break: break-all;

  ${({ autoWidth }) =>
    autoWidth &&
    css`
      width: fit-content;
    `}
`;

export const LoadingContainer = styled.div`
  height: ${snap(200)};
  padding-top: ${snap(50)};
  text-align: center;
  width: 100%;
`;

export const DeleteButton = styled(Button)`
  background: none;
  margin-left: ${units(1)};
  margin: 0;
  min-height: auto;
  padding: 0;
  :hover,
  :focus {
    background-color: transparent !important;
  }
  svg {
    color: ${color('base')};
    min-width: ${units(1.5)};
    width: ${units(1.5)};
  }
`;

export const Range = styled.div`
  margin-top: ${units(1)};
  text-align: center;
`;

import { createContext, type FunctionComponent, useMemo } from 'react';
import { type ApolloError, useQuery } from '@apollo/client';
import { type CommunityQuery, GET_COMMUNITY } from '../graphql/queries';
import { type Community } from '../common/core-api';

export interface ICommunity extends Community {
  error?: ApolloError;
  loading: boolean;
}

const defaultCommunity: ICommunity = {
  apiEnabled: false,
  id: '0',
  name: '',
  url: '',
  logoId: '0',
  hideUnModerated: 0,
  colors: {
    link: '',
    button: ''
  },
  defaultAccess: {
    users: [],
    groups: []
  },
  loading: true
};

const CommunityContext = createContext(defaultCommunity);

interface CommunityProviderProps {
  readonly id: string;
  children: React.ReactNode;
}

const CommunityProvider: FunctionComponent<CommunityProviderProps> = ({ id, children }) => {
  // TODO: Handle error
  const { loading, data, error } = useQuery<CommunityQuery>(GET_COMMUNITY, {
    variables: { id }
  });

  const community = useMemo(() => {
    const communityObj: ICommunity = {
      ...defaultCommunity,
      ...data?.community,
      error,
      loading
    };
    return communityObj;
  }, [data, error, loading]);
  return <CommunityContext.Provider value={community}>{children}</CommunityContext.Provider>;
};

const CommunityConsumer = CommunityContext.Consumer;

export { CommunityContext, CommunityConsumer, CommunityProvider };

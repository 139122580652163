import { type ChallengeVisibility, IdeaSubmissionsType } from '../core-api';

export const defaultChallengeVisibility: ChallengeVisibility = {
  comments: {
    enabled: true,
    attachmentsEnabled: true,
    anonymousEnabled: false
  },
  ideaSubmissionEnabled: true,
  ideasVisible: true,
  ideaSubmissionsType: IdeaSubmissionsType.STANDARD,
  showClosedEnabled: false,
  voteOnCommentsEnabled: true,
  voteOnIdeasEnabled: true,
  privateSubmissionEnabled: false,
  ideaEditLock: {
    enabled: false,
    graceMinutes: 120
  },
  phaseAutoAdvancement: true,
  phaseResetComments: false,
  phaseResetVotes: false,
  shareFacebookEnabled: false,
  shareTwitterEnabled: false,
  shareLinkedInEnabled: false,
  copyUrlEnabled: false
};

import {
  alchemyDark,
  alchemyLight,
  brandPrimary,
  brandSecondary,
  type ColorScheme,
  deepBlue800,
  purple500,
  steelGrey100,
  steelGrey1000,
  steelGrey200,
  steelGrey50,
  steelGrey500,
  steelGreyA400,
  white
} from '@m/alchemy-ui';
import { darken, lighten, transparentize } from 'polished';
import { type CommunityDesign } from '../../common/interfaces';
import { getContrastColors } from './color-helpers';

export const getCrowdicityTheme = (communityColors: Partial<CommunityDesign>): ColorScheme => {
  /*
    from Design management | from db `hubbub_colours` column
    Banner | "banner":"01702E"
    Background | "bg":"D0CEF0"
    Button | "button":"012169"
    Link | "link":"9F2A9E"
    Design elements | "voteBar":"E31837"
  */

  const { bg, button, link, voteBar } = communityColors;

  const colors = {
    button: `#${button}`,
    link: `#${link}`,
    buttonHint: transparentize(0.8, `#${button}`),
    buttonHighlight: transparentize(0.6, `#${button}`),
    designElement: `#${voteBar}`,
    background: `#${bg}`
  };

  const { element, container, contrast } = getContrastColors(colors.designElement);

  return {
    ...alchemyLight,
    structural: {
      base: steelGreyA400,
      inverse: white,
      contrast: steelGrey1000,
      highlight: steelGrey100,
      hint: steelGrey50,
      inputBorder: '#8e95a4',

      // Brand colors
      brandPrimary: colors.designElement,
      brandSecondary,

      // Text colors
      typeLink: colors.link,
      typeHeader: '#1b2437',
      typeSubHeader: steelGrey500,
      typeBody: '#1b2437',
      typeMeta: steelGrey500,

      // UI
      line: lighten(0.05, steelGrey200),
      lineContrast: steelGrey200,
      fill: steelGrey100,
      canvas: steelGrey50,
      container: white
    },
    interactiveConstructive: {
      contrast: deepBlue800,
      base: colors.button,
      highlight: colors.buttonHighlight,
      hint: colors.buttonHint
    },
    illustrativeBrand: {
      a11yBase: darken(0.02, purple500),
      base: container || brandPrimary,
      contrast: element || white,
      highlight: lighten(0.25, purple500),
      hint: lighten(0.34, purple500),
      typeLink: contrast
    }
  };
};

export const getCrowidicityBlackTheme = (communityColors: Partial<CommunityDesign>): ColorScheme => {
  const { button, link } = communityColors;

  const colors = {
    button: `#${button}`,
    link: `#${link}`,
    buttonHint: transparentize(0.8, `#${button}`),
    buttonHighlight: transparentize(0.6, `#${button}`)
  };

  return {
    ...alchemyDark,
    interactiveConstructive: {
      contrast: deepBlue800,
      base: colors.button,
      highlight: colors.buttonHighlight,
      hint: colors.buttonHint
    }
  };
};

import { MultilineEllipsis, snap, typeStyle, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: ${units(2)};
  }
`;

export const ChallengeImageWrapper = styled.div`
  height: ${snap(200)};
  width: 100%;
  margin-top: ${units(1.5)};
`;

export const ChallengeHeader = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ChallengeTitle = styled.a`
  font: ${typeStyle('headerM')};
  font-weight: 400;
`;

export const ChallengeTitleGreyout = styled.a`
  font: ${typeStyle('headerM')};
  font-weight: 400;
  color: grey;
  pointer-events: none;
`;

export const ChallengeDescription = styled(MultilineEllipsis)`
  margin-top: ${units(1)};
  line-height: 1.3;
`;

import { useContext } from 'react';
import { differenceInMinutes } from 'date-fns';
import { LoadingIndicator } from '@m/alchemy-ui';
import { UserContext } from '../../../../context';
import { usePasswordComplexity } from '../../../../context/use-password-complexity';
import { PasswordResetDelay } from './PasswordResetDelay';
import { PasswordResetForm } from './PasswordResetForm';

export interface PasswordResetState {
  resetAt: string | null;
  blocked: boolean;
}

export const PasswordReset = ({
  passwordResetState,
  updateHandler
}: {
  readonly passwordResetState: PasswordResetState;
  readonly updateHandler: (value: PasswordResetState) => void;
}) => {
  const user = useContext(UserContext);
  const { passwordComplexityEnabled, passwordComplexityLifetimeMinutesMin } = usePasswordComplexity();

  const resetAt =
    typeof user.passwordChangedAt === 'string' && !passwordResetState.resetAt
      ? user.passwordChangedAt
      : passwordResetState.resetAt;

  const shouldBeBlocked = (): boolean => {
    if (!passwordComplexityEnabled || !resetAt) {
      return false;
    }

    const minutesSinceLastChange = differenceInMinutes(new Date(), new Date(resetAt));

    return minutesSinceLastChange < passwordComplexityLifetimeMinutesMin;
  };

  if (user.loading) {
    return <LoadingIndicator />;
  }

  if (resetAt && shouldBeBlocked()) {
    return (
      <PasswordResetDelay
        afterDelayHandler={() => updateHandler({ blocked: false, resetAt })}
        resetAt={resetAt}
        timezone={user.timezone}
      />
    );
  }

  return (
    <PasswordResetForm updateHandler={() => updateHandler({ blocked: true, resetAt: new Date().toISOString() })} />
  );
};

import { gql } from '@apollo/client';

export const GET_TEMPLATES = gql`
  query templates {
    templates {
      global {
        id
        name
        description
        template
        createdAt
        updatedAt
      }
      community {
        id
        name
        description
        template
        communityId
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

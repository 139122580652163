import styled from 'styled-components';
import {
  ArrowDownIcon,
  borderRadius,
  boxShadow,
  Button,
  Checkbox,
  color,
  Field,
  GridCol,
  Input,
  PopperBox,
  SearchField,
  snap,
  typeStyle,
  units
} from '@m/alchemy-ui';

export const Form = styled.form`
  background-color: ${color('canvas')};
  padding: ${units(2)} 0;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Column = styled(GridCol)`
  display: flex;
`;

export const StyledField = styled(Field)`
  align-self: center;
  align-items: center;
  label {
    margin: 0 ${units(1)} 0 0 !important;
    color: inherit;
  }
  input {
    background: ${color('container')};
  }
`;

export const AdvancedFilters = styled.div<{ displayAdvancedFilters: boolean }>`
  display: ${({ displayAdvancedFilters }) => (displayAdvancedFilters ? 'flex' : 'none')};
  align-items: center;
  align-self: center;
  margin-top: ${units(-1)};
  flex-wrap: wrap;
  justify-content: center;
`;

export const FilterButton = styled(Button)`
  ${typeStyle('bodyS')};
  color: inherit;
  font-weight: 400;
  padding: ${units(0.5)} ${units(1.5)};
  background-color: ${color('container')};
  border-color: ${color('typeMeta')};
  border-radius: ${borderRadius('extraLarge')};
  box-shadow: ${boxShadow(2)};
  margin-top: ${units(0.5)};
  margin-bottom: ${units(0.5)};
  margin-right: ${units(1)};
  :hover,
  :focus {
    background-color: ${color('container')} !important;
    border-color: ${color('typeMeta')} !important;
  }
`;

export const ArrowDown = styled(ArrowDownIcon)`
  margin-left: ${units(1)};
  width: ${units(2.5)};
  height: ${units(2.5)};
  color: ${color('base')};
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: ${units(1)};
`;

export const AddedFilter = styled.div`
  display: flex;
  align-items: center;
  background-color: ${color('fill')};
  border-radius: ${borderRadius('extraLarge')};
  padding: ${units(0.5)} ${units(1.5)};
  width: fit-content;
  margin-bottom: ${units(0.5)};
  margin-right: ${units(0.5)};
`;

export const FilterInputSubmitButton = styled(Button)`
  margin-top: ${units(1)};
`;

export const SearchSubmitButton = styled(Button)`
  position: relative;
  width: fit-content;
  margin-left: ${units(2)};
`;

export const FiltersCount = styled.span`
  color: ${color('typeLink', { palette: 'structural' })};
  margin-left: ${units(1)};
`;

export const LinkButton = styled(Button)`
  ${typeStyle('bodyS')};
  background: none;
  border-radius: ${borderRadius('large')};
  width: fit-content;
  text-decoration: underline;
  margin: ${units(0)} ${units(1)};
  padding: ${units(0)};
  :hover,
  :focus {
    background-color: transparent !important;
  }
`;

export const StyledSearchField = styled(SearchField)`
  margin-bottom: ${units(1.5)};
  input {
    width: auto !important;
    min-height: auto;
    height: ${units(3)};
  }
`;

export const StyledPopperBox = styled(PopperBox)`
  margin: -10px;
  max-height: ${snap(500)};
  overflow: auto;
  background: ${color('container')};
`;

// adding outline styling for checkbox and input for Alchemy PopperBox,
// focus always automatically moves into the box,
// but indicator appearance is not consistent
export const StyledCheckbox = styled(Checkbox)`
  :focus-within {
    outline: 1px auto ${color('base', { palette: 'interactiveConstructive' })};
  }
`;

export const StyledInput = styled(Input)`
  :focus-within {
    outline: 1px auto ${color('base', { palette: 'interactiveConstructive' })};
  }
`;

export const DeleteButton = styled(Button)`
  margin: 0;
  padding: 0;
  background: none;
  margin-left: ${units(1)};
  min-height: auto;
  :hover,
  :focus {
    background-color: transparent !important;
  }
  svg {
    color: ${color('base')};
    min-width: ${units(1.5)};
    width: ${units(1.5)};
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  text-align: center;
  height: ${snap(200)};
  padding-top: ${snap(50)};
`;

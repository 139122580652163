import { color, units } from '@m/alchemy-ui';
import styled, { css } from 'styled-components';

interface TabProps {
  isActive: boolean;
  isSubtab?: boolean;
}

interface DropdownProps {
  isSelected: boolean;
}

interface WrapperProps {
  isSelected?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${color('line')};
    `};
`;

export const Tab = styled.button<TabProps>`
  background: none;
  border: none;
  justify-content: flex-start;
  padding: ${units(1.25)} ${units(3)};
  position: relative;
  text-align: left;
  width: 100%;

  ::before {
    background-color: ${color('base', { palette: 'interactiveConstructive' })};
    border-radius: ${units(0.5)};
    content: '';
    display: none;
    height: 85%;
    left: ${units(0.5)};
    min-height: ${units(4.5)};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 500;
      background-color: ${color('line')};

      ::before {
        display: block;
      }
    `};

  ${({ isSubtab }) =>
    isSubtab &&
    css`
      padding-left: ${units(4.5)};
    `};
`;

export const ToggleButton = styled.button<DropdownProps>`
  background: none;
  border: none;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: ${units(1.25)} ${units(3)};
  text-align: left;
  width: 100%;

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 500;
    `};
`;

import { createContext, type Dispatch, type ReactNode, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useImmerReducer } from 'use-immer';
import { defaultChallenge } from '../../../common/interfaces';
import { type ChallengeQuery, GET_CHALLENGE } from '../../../graphql/queries';
import { type ChallengeActionType, ChallengeActionTypes, challengeReducer } from '../reducers/challenge';

const ChallengeContext = createContext(defaultChallenge);
const ChallengeDispatchContext = createContext<Dispatch<ChallengeActionType> | undefined>(undefined);

const ChallengeProvider = ({ children, id }: { readonly children: ReactNode; readonly id?: string }) => {
  const isNew = id === defaultChallenge.id;
  const [challenge, dispatch] = useImmerReducer(challengeReducer, defaultChallenge);

  const { data, loading } = useQuery<ChallengeQuery>(GET_CHALLENGE, {
    variables: { id },
    skip: isNew
  });

  useEffect(() => {
    if ((loading === false && data) || isNew) {
      dispatch({
        type: ChallengeActionTypes.SET_CHALLENGE,
        value: isNew
          ? { ...defaultChallenge, isNew, loading: false }
          : { ...(data?.challenge || defaultChallenge), isNew, loading: false }
      });
    }
  }, [isNew, dispatch, loading, data]);

  return (
    <ChallengeContext.Provider value={challenge}>
      <ChallengeDispatchContext.Provider value={dispatch}>{children}</ChallengeDispatchContext.Provider>
    </ChallengeContext.Provider>
  );
};

const useChallenge = () => useContext(ChallengeContext);

const useChallengeDispatch = () => {
  const context = useContext(ChallengeDispatchContext);

  if (context === undefined) {
    throw new Error('useChallengeDispatch requires ChallengeProvider');
  }

  return context;
};

export { ChallengeProvider, useChallengeDispatch, useChallenge };

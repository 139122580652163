import { format, formatDistanceToNow } from 'date-fns';
import styled from 'styled-components';
import { color, typeStyle, units } from '@m/alchemy-ui';
import { Tooltip } from './Tooltip';

const RelativeTime = styled.time`
  font: ${typeStyle('captionM')};
  color: ${color('typeHeader')};
  padding-right: ${units(1)};
`;

export const RelativeDateWithTooltip = ({ time }: { readonly time: string }) => {
  const tipDate = format(new Date(time), 'h:mm a - d MMM yyyy');
  return (
    <Tooltip content={tipDate}>
      <RelativeTime>{formatDistanceToNow(new Date(time), { addSuffix: true })}</RelativeTime>
    </Tooltip>
  );
};

import { Alert, Button, CancelXIcon, Tabs, TeamIcon, units, useBeautifulList } from '@m/alchemy-ui';
import { type DropResult } from 'react-beautiful-dnd';
import { type MouseEvent, type ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { useFormikContext } from 'formik';
import { Container, ReorderableList } from '../../../common/components';
import { defaultTheme, type IChallenge } from '../../../common/interfaces';
import { ChallengeArchived } from '../../../common/enums';
import { type Theme } from '../../../common/core-api';
import { ThemeForm } from './ThemeForm';
import { ThemesEditorListItem } from './ThemesEditorListItem';

interface Item {
  id: string;
  content: ReactNode;
}

const ThemeContainer = styled.section`
  margin-bottom: ${units(2)};
`;

const ThemeEdit = ({
  theme,
  isNew,
  isOpen,
  onClose
}: {
  readonly theme: Theme;
  readonly isNew: boolean;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}) => {
  if (theme === defaultTheme) {
    return null;
  }

  return <ThemeForm isNew={isNew} isOpen={isOpen} theme={theme} onClose={onClose} />;
};

export const ThemesEditor = () => {
  const intl = useIntl();
  const { values: challenge, setFieldValue } = useFormikContext<IChallenge>();
  const [activeTab, setActiveTab] = useState('PUBLISHED');
  const [editor, setEditor] = useState({ open: false, new: false });
  const [activeTheme, setActiveTheme] = useImmer(defaultTheme);
  const [newThemeName, setNewThemeName] = useState('');

  const handlePublishToggle = (theme: Theme) => {
    const themeIndex = challenge.themes.findIndex((existingTheme) => existingTheme.id === theme.id);
    setFieldValue(`themes[${themeIndex}]`, {
      ...theme,
      archived: theme.archived ? ChallengeArchived.PUBLISHED : ChallengeArchived.UNPUBLISHED
    });
  };

  const handleAddNewTheme = () => {
    const active = activeTab === 'PUBLISHED' ? ChallengeArchived.PUBLISHED : ChallengeArchived.UNPUBLISHED;

    setActiveTheme(() => ({
      ...defaultTheme,
      id: Date.now().toString(), // Temporary id until theme is saved
      name: newThemeName,
      archived: active
    }));
    setEditor({ open: true, new: true });
  };

  const handleEditClick = (theme: Theme) => {
    setActiveTheme(() => theme);
    setEditor({ open: true, new: false });
  };

  const handleCloseForm = () => {
    setActiveTheme(() => defaultTheme);
    setNewThemeName('');
    setEditor({ ...editor, open: false });
  };

  const tabData = [
    {
      label: intl.formatMessage({
        id: 'published',
        defaultMessage: 'Published'
      }),
      value: 'PUBLISHED'
    },
    {
      label: intl.formatMessage({
        id: 'unpublished',
        defaultMessage: 'Unpublished'
      }),
      value: 'UNPUBLISHED'
    }
  ];

  const getItems = () => {
    const active = activeTab === 'PUBLISHED' ? ChallengeArchived.PUBLISHED : ChallengeArchived.UNPUBLISHED;
    const themesForTab = challenge.themes.filter((theme) => theme?.archived === active);
    return themesForTab.map((theme) => ({
      id: theme.id,
      content: (
        <ThemesEditorListItem
          theme={theme}
          onEdit={() => handleEditClick(theme)}
          onPublishToggle={() => handlePublishToggle(theme)}
        />
      )
    }));
  };

  const reorder = (items: Item[], result: DropResult) => {
    const startIndex = result.source.index;
    const endIndex = result.destination?.index || 0;

    const orderedList = [...items];
    const [removed] = orderedList.splice(startIndex, 1);
    orderedList.splice(endIndex, 0, removed);

    const publishedThemes = challenge.themes.filter((theme) => !theme.archived);
    const unpublishedThemes = challenge.themes.filter((theme) => theme.archived);

    const orderedThemes = activeTab === 'PUBLISHED' ? publishedThemes : unpublishedThemes;
    const restOfThemes = activeTab === 'PUBLISHED' ? unpublishedThemes : publishedThemes;
    const [removedTheme] = orderedThemes.splice(startIndex, 1);
    orderedThemes.splice(endIndex, 0, removedTheme);
    setFieldValue(
      'themes',
      [...orderedThemes, ...restOfThemes].map((theme: Theme, index: number) => ({
        ...theme,
        order: index
      }))
    );

    return orderedList;
  };

  const { onDragEnd } = useBeautifulList({ defaultCollection: getItems(), reorder });

  return (
    <>
      <ThemeContainer>
        <Tabs
          tabs={tabData}
          onTabClick={(event: MouseEvent<HTMLElement>, { value }: { value: string }) => setActiveTab(value)}
          activeTabValue={activeTab}
          layout="left"
        />
        {getItems().length > 0 ? (
          <ReorderableList items={getItems()} onDragEnd={onDragEnd} />
        ) : (
          <Container>
            {activeTab === 'PUBLISHED' ? (
              <Alert
                level="informative"
                value={intl.formatMessage({
                  id: 'noPublishedThemes',
                  defaultMessage: 'There are no published themes'
                })}
                icon={<TeamIcon aria-hidden="true" />}
              />
            ) : (
              <Alert
                level="informative"
                value={intl.formatMessage({
                  id: 'noUnpublishedThemes',
                  defaultMessage: 'There are no unpublished themes'
                })}
                icon={<CancelXIcon aria-hidden="true" />}
              />
            )}
          </Container>
        )}
        <ThemeEdit isNew={editor.new} isOpen={editor.open} theme={activeTheme} onClose={handleCloseForm} />
      </ThemeContainer>
      <Button priority="secondary" size="small" onClick={handleAddNewTheme}>
        <FormattedMessage id="addTheme" defaultMessage="Add theme" />
      </Button>
    </>
  );
};

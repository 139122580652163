import { createContext, type ReactNode, useMemo } from 'react';
import { type ApolloError, useQuery } from '@apollo/client';
import { GET_ME, type MeQuery } from '../graphql/queries';
import { type User } from '../common/core-api';

export interface AlchemyUser extends User {
  error?: ApolloError;
  loading?: boolean;
  refetch?: () => void;
}

const defaultUser: AlchemyUser = {
  id: '0',
  displayName: 'Guest',
  timezone: 'UTC',
  screenName: 'Guest',
  createdAt: '',
  country: '',
  email: '',
  firstName: '',
  lastName: '',
  profileImageId: '',
  error: undefined,
  loading: true
};

const UserContext = createContext(defaultUser);

const UserProvider = ({ children }: { readonly children: ReactNode }) => {
  // TODO: Handle error
  const { loading, data, error, refetch } = useQuery<MeQuery>(GET_ME, { errorPolicy: 'all' });

  const user = useMemo<AlchemyUser>(() => {
    const userObj = {
      ...defaultUser,
      ...data?.me,
      error,
      loading,
      refetch
    };
    return userObj;
  }, [data, error, loading, refetch]);
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

const UserConsumer = UserContext.Consumer;

export { UserContext, UserConsumer, UserProvider };

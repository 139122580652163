import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Tabs, units } from '@m/alchemy-ui';
import { type UserScoreProps } from '../../features';
import { ScoreReset } from '../score-reset/ScoreReset';
import { ScoreResetView } from '../score-reset/enums/ScoreResetView';
import { ScoreHistory } from './components/ScoreHistory';
import { UserScoreProvider } from './context/UserScoreContext';

export enum UserScoreTabTypes {
  HISTORY = 'HISTORY',
  RESET = 'RESET'
}

const UserScoreTabs = styled(Tabs)`
  margin-bottom: ${units(2.5)};
  margin-top: ${units(1)};
`;

export const UserScore = (props: UserScoreProps) => {
  const tabData = [
    {
      label: <FormattedMessage id="scoreHistory" defaultMessage="Score history" />,
      value: UserScoreTabTypes.HISTORY
    },
    {
      label: <FormattedMessage id="resetScore" defaultMessage="Reset Score" />,
      value: UserScoreTabTypes.RESET
    }
  ];

  const [activeTab, setActiveTab] = useState<string>(UserScoreTabTypes.HISTORY);

  return (
    <UserScoreProvider>
      <div data-testid="user-score-container">
        <UserScoreTabs
          tabs={tabData}
          defaultTabValue={UserScoreTabTypes.HISTORY}
          layout="distributed"
          activeTabValue={activeTab}
          onTabClick={(_, { value }: { value: string }) => {
            setActiveTab(value);
          }}
        />
        {activeTab === UserScoreTabTypes.HISTORY && <ScoreHistory {...props} />}
        {activeTab === UserScoreTabTypes.RESET && (
          <ScoreReset {...props} scoreResetUserId={props.userScoreId} scoreResetView={ScoreResetView.USER} />
        )}
      </div>
    </UserScoreProvider>
  );
};

import styled from 'styled-components';

const Wrapper = styled.div`
  svg {
    .cls-1 {
      fill: #fff;
    }
    .cls-2 {
      fill: #f5f6f7;
    }
    .cls-3 {
      mask: url(#mask);
    }
    .cls-4 {
      fill: #ebedf0;
      fill-rule: evenodd;
    }
    .cls-5 {
      mask: url(#mask-2-2);
    }
    .cls-6 {
      fill: #778fae;
    }
  }
`;

export const DefaultTemplateIcon = () => (
  <Wrapper>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="90"
      viewBox="0 0 180 90"
    >
      <defs>
        <mask id="mask" x="0" y="-0.12" width="180" height="90.12" maskUnits="userSpaceOnUse">
          <g id="mask-2">
            <rect id="path-1" className="cls-1" y="-0.12" width="180" height="90" />
          </g>
        </mask>
        <mask id="mask-2-2" x="0" y="-0.12" width="180" height="90" maskUnits="userSpaceOnUse">
          <g id="mask-2-3" data-name="mask-2">
            <rect id="path-1-2" data-name="path-1" className="cls-1" y="-0.12" width="180" height="90" />
          </g>
        </mask>
      </defs>
      <g id="Page-1">
        <g id="Group-3-Copy-8">
          <g id="Rectangle-Copy-41">
            <rect id="path-1-4" data-name="path-1" className="cls-2" width="180" height="90" />
          </g>
          <g className="cls-3">
            <path
              id="Rectangle"
              className="cls-4"
              d="M98.25,0a18.11,18.11,0,0,1,4.12,33.11L3.83,90H0V50.38L84.25,1.74A18.3,18.3,0,0,1,88.35,0Z"
            />
          </g>
          <g className="cls-5">
            <path
              id="Rectangle-Copy-4"
              className="cls-4"
              d="M180,29.81,91.92,80.66A18.11,18.11,0,1,1,73.81,49.29L159.17,0H180Z"
            />
          </g>
          <g className="cls-5">
            <g id="Group">
              <circle id="Oval" className="cls-1" cx="90" cy="40.63" r="28.3" />
              <g id="iconCrowdicity_48" data-name="iconCrowdicity 48">
                <path
                  id="Shape"
                  className="cls-6"
                  d="M90,42.33a3,3,0,1,0,3,3h0a2.93,2.93,0,0,0-3-3ZM99.78,44a2.15,2.15,0,1,0,2.15,2.21h0A2.16,2.16,0,0,0,99.78,44ZM80.27,44a2.15,2.15,0,1,0,2.16,2.21h0A2.24,2.24,0,0,0,80.27,44ZM90,40.63a4.67,4.67,0,1,1-4.64,4.7h0A4.61,4.61,0,0,1,90,40.63Zm6.19,4.19a3.81,3.81,0,0,1,4.93-2.26,3.77,3.77,0,0,1,2.27,4.92,3.47,3.47,0,0,1-1.7,2,6.32,6.32,0,0,1,1.58.79.85.85,0,0,1-1,1.36,4.23,4.23,0,0,0-5.89.91,1.9,1.9,0,0,0-.29.45.79.79,0,0,1-.23,1l-.11.06a.82.82,0,0,1-1.08-.23,6,6,0,0,0-8.5-.9,7,7,0,0,0-.91.9.87.87,0,0,1-1.19.11.86.86,0,0,1-.23-1c-.11-.17-.17-.29-.28-.46a4.25,4.25,0,0,0-5.9-1,.88.88,0,0,1-1.19-.17.94.94,0,0,1,.29-1.08,5.44,5.44,0,0,1,1.58-.79,3.82,3.82,0,1,1,5.22-1.41,4.25,4.25,0,0,1-1.42,1.41,5.73,5.73,0,0,1,3,2.27,7.7,7.7,0,0,1,9.75,0,5.73,5.73,0,0,1,3-2.27,3.89,3.89,0,0,1-1.7-4.64Z"
                />
                <path
                  id="Path"
                  className="cls-6"
                  d="M99.66,41.2a.75.75,0,0,1-.39-.12l-3.8-2.15H93.71a.85.85,0,1,1,0-1.69h2a.79.79,0,0,1,.4.11l2.72,1.53v-.8a.87.87,0,0,1,.85-.84h2.27v-5.1h-2a.85.85,0,0,1,0-1.7h2.83a.87.87,0,0,1,.85.85v6.79a.86.86,0,0,1-.85.85h-2.27v1.42A.87.87,0,0,1,99.66,41.2Z"
                />
                <path
                  id="Path-2"
                  data-name="Path"
                  className="cls-6"
                  d="M80.39,41.2a.88.88,0,0,1-.86-.85V38.93H77.27a.86.86,0,0,1-.85-.85V31.29a.87.87,0,0,1,.85-.85H80.1a.85.85,0,0,1,0,1.7h-2v5.1h2.27a.87.87,0,0,1,.85.84v.8L84,37.35a1,1,0,0,1,.39-.11h2a.85.85,0,1,1,0,1.69H84.58l-3.8,2.15A.75.75,0,0,1,80.39,41.2Z"
                />
                <path
                  id="Shape-2"
                  data-name="Shape"
                  className="cls-6"
                  d="M90,38.37a1.23,1.23,0,0,1-.62-.23l-2-2H82.94a.86.86,0,0,1-.85-.85h0V27.9a.86.86,0,0,1,.85-.85H97.11a.86.86,0,0,1,.85.85v7.35a.86.86,0,0,1-.85.85H92.63l-2,2A.88.88,0,0,1,90,38.37Zm-6.23-4h4a1.22,1.22,0,0,1,.62.22L90,36.27l1.65-1.64a.85.85,0,0,1,.62-.22h4V28.75H83.79Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </Wrapper>
);

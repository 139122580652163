import { type MakeGenerics, useMatch, useNavigate } from 'react-location';
import { useEffect } from 'react';
import { off, on } from 'delegated-events';
import { usePageMetadata } from '../hooks';
import { evalScripts } from '../helpers';
import { location } from './Application';

export type LocationGenerics = MakeGenerics<{
  LoaderData: {
    pageContent: HTMLElement;
  };
}>;

export const StaticPage = () => {
  const {
    data: { pageContent }
  } = useMatch<LocationGenerics>();
  const navigate = useNavigate();
  const { setTitle } = usePageMetadata();

  const { pathname } = location.current;

  const ajaxRouterUrl = window.AJAX_ROUTER_URL;
  const isChallengePage = pathname?.includes('/category/') || false;
  const isHomePage = pathname === '/';
  /*
  We are doing this here because we use ajax-router(Crowdicity.jRouter) in the main app.
  React router doesn't recognize that page is changed when it is changed with the ajax-router.
  We save the ajax-router's last visited page url as a window variable
  to compare with the react router's location
  */
  if ((isChallengePage || isHomePage) && ajaxRouterUrl && ajaxRouterUrl !== pathname) {
    window.AJAX_ROUTER_URL = undefined;
    window.location.reload();
  }

  useEffect(() => {
    const handleClick: Parameters<typeof on>['2'] = (event) => {
      const link = event.currentTarget as HTMLLinkElement;
      const { href } = link;
      if (new URL(document.baseURI).origin === new URL(href, document.baseURI).origin) {
        event.preventDefault();
        navigate({ to: href.replace(link.baseURI, '/') });
      }
    };

    const nonDynamicLinks = '#application-page-body-content a:not([href="#"],[onclick])';
    on('click', nonDynamicLinks, handleClick);

    return () => {
      off('click', nonDynamicLinks, handleClick);
    };
  }, [navigate]);

  useEffect(() => {
    if (!pageContent) {
      return;
    }

    setTitle(pageContent?.querySelector<HTMLDivElement>('#main')?.dataset?.pageTitle || '');
  }, [pageContent, setTitle]);

  useEffect(() => {
    if (!pageContent) {
      return;
    }

    const scripts = pageContent
      ? pageContent.querySelectorAll<HTMLScriptElement>('script')
      : document.body.querySelectorAll<HTMLScriptElement>('#main script');

    // clear out existing ones before evaling new ones
    const bodyScripts = document.body.querySelectorAll<HTMLScriptElement>('script[data-dynamic]');
    bodyScripts.forEach((scriptTag) => scriptTag.remove());

    evalScripts(scripts);

    window.scrollTo({
      top: 0
    });
  }, [pageContent, navigate]);

  const pageHtml = pageContent?.querySelector('#main')?.innerHTML || '';
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: pageHtml }} />;
};

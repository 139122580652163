import { type MouseEvent } from 'react';

declare global {
  interface Window {
    popUp: (path: string, options: { popUpTitle: string; height: number }) => void;
  }
}

interface ModalProps {
  url?: string;
  height?: number;
  title?: string;
}

export const useMainModal = () => {
  const open = (e: MouseEvent<HTMLAnchorElement>, { height, url, title: popUpTitle }: ModalProps) => {
    e.preventDefault();
    const {
      currentTarget: { href, title }
    } = e;
    // eslint-disable-next-line no-restricted-globals
    parent.popUp(url || href, { popUpTitle: popUpTitle || title, height: height || 390 });
    return false;
  };

  return [open] as const;
};

import React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Popper, PopperBox } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { type ScoreHistoryRow } from '../interface/ScoreHistoryRow';
import { UPDATE_USER_SCORE } from '../../../graphql/mutations/score-reset';
import { type UserScorelogUpdateMutation } from '../../../graphql/mutations/__generated__/score-reset.generated';

const StyledRow = styled.tr`
  border-bottom: 1px solid #eaeaea;

  &.score-removed {
    color: #bbb;

    a {
      color: #bbb;
    }
  }

  .userscore-cell__action {
    display: none;
    padding-left: 5px;
  }

  &:hover {
    .userscore-cell__action {
      display: block;
    }
  }
`;

const StyledActionSpan = styled.span`
  display: inlne;
  cursor: pointer;

  &.black {
    color: #1b2437;
  }

  &.red {
    color: red;
  }
`;

const StyledCell = styled.td`
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 4px;
  padding-top: 4px;

  &:nth-of-type(3) {
    text-align: center;
  }
`;

export const ScoreHistoryListElement = ({
  row,
  handleScoreTotalUpdate
}: {
  readonly row: ScoreHistoryRow;
  readonly handleScoreTotalUpdate: (row: ScoreHistoryRow) => void;
}) => {
  const intl = useIntl();
  const hoverRemove = intl.formatMessage({ id: 'hoverRemoveScore', defaultMessage: 'Remove this score' });
  const hoverRestore = intl.formatMessage({ id: 'hoverRestoreScore', defaultMessage: 'Restore this score' });
  const [updateScore] = useMutation<UserScorelogUpdateMutation>(UPDATE_USER_SCORE);

  const { removed, score, name, id, date, url, urlTitle } = row;

  const handleRemoveScore = () => {
    updateScore({ variables: { scorelogId: id, isRemoved: true } });
    handleScoreTotalUpdate(row);
  };

  const handleRestoreScore = () => {
    updateScore({ variables: { scorelogId: id, isRemoved: false } });
    handleScoreTotalUpdate(row);
  };

  const fullDate = new Date(date);
  const formattedDate = format(fullDate, 'dd MMM, yyyy');
  const formattedTime = format(fullDate, 'HH:mm:ss');

  return (
    <StyledRow className={removed ? 'score-removed' : ''} id={`userscore_row_${id}`}>
      <StyledCell>
        <strong>{formattedDate}</strong> {formattedTime}
      </StyledCell>
      <StyledCell>{name}</StyledCell>
      <StyledCell>{score}</StyledCell>
      <StyledCell>
        <Popper
          placement="left"
          mode="tooltip"
          delay={100}
          zIndex={1052}
          overlay={
            <PopperBox hasArrow colorScheme="alchemyDark">
              {removed ? hoverRestore : hoverRemove}
            </PopperBox>
          }
          trigger={
            <div className="userscore-cell__action">
              {removed ? (
                <StyledActionSpan className="mdl-icon -s black" onClick={handleRestoreScore}>
                  <svg>
                    <use href="#confirm" />
                  </svg>
                </StyledActionSpan>
              ) : (
                <StyledActionSpan className="mdl-icon -s red" onClick={handleRemoveScore}>
                  <svg>
                    <use href="#cancel" />
                  </svg>
                </StyledActionSpan>
              )}
            </div>
          }
        />
      </StyledCell>
      <StyledCell>
        <a href={url} target="_blank">
          {urlTitle}
        </a>
      </StyledCell>
    </StyledRow>
  );
};

export const MemoizedScoreHistoryListElement = React.memo(
  ScoreHistoryListElement,
  (prevProps, nextProps) => JSON.stringify(prevProps?.row) === JSON.stringify(nextProps?.row)
);

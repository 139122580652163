import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { Field, LoadingIndicator, type Option, ZeroStateAddIcon } from '@m/alchemy-ui';
import { groupNotifications } from '@m/notification-grouper';
import { NotificationCategory, type NotificationEvent } from '../../common/core-api';
import {
  GET_NOTIFICATIONS,
  LOAD_MORE_NOTIFICATIONS,
  type MoreNotificationsQuery,
  type NotificationsQuery
} from '../../graphql/queries';
import * as FocusHelper from '../../helpers/focus';
import * as Styled from './NotificationStream.styled';
import { NotificationCard } from './components';

export const NotificationStream = () => {
  const [categories, setCategories] = useState<NotificationCategory[]>([]);
  const { loading, data } = useQuery<NotificationsQuery>(GET_NOTIFICATIONS, {
    variables: {
      categories
    }
  });
  const intl = useIntl();
  const [skip, setSkip] = useState<boolean>(true);
  const {
    loading: loadMoreLoading,
    data: moreData,
    refetch: getMoreNotifications
  } = useQuery<MoreNotificationsQuery>(LOAD_MORE_NOTIFICATIONS, { skip });
  const [notifications, setNotifications] = useState<NotificationEvent[][]>([]);
  const [showLoadmore, setShowLoadmore] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data) {
      const groupedNotifications =
        data.notifications &&
        data.notifications.length > 0 &&
        (groupNotifications(data.notifications) as NotificationEvent[][]);

      if (groupedNotifications) {
        setNotifications(groupedNotifications);
        setShowLoadmore(true);
      } else {
        setNotifications([]);
        setShowLoadmore(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (moreData) {
      const groupedNotifications =
        moreData.notifications &&
        moreData.notifications.length > 0 &&
        (groupNotifications(moreData.notifications) as NotificationEvent[][]);

      if (groupedNotifications) {
        setNotifications((existingNotifications) => [...existingNotifications, ...groupedNotifications]);
      } else {
        setShowLoadmore(false);
      }
    }
  }, [moreData]);

  useEffect(() => {
    const items = FocusHelper.getFocusableElements(ref);
    items && items[currentIndex]?.focus();
  }, [currentIndex, notifications]);

  const updateCurrentIndex = () => {
    const currentIndex = FocusHelper.getActiveElement(ref);

    setCurrentIndex(currentIndex);
  };

  const loadMoreClicked = async () => {
    const fromDate = notifications.flat().sort((a, b) => Date.parse(a.created) - Date.parse(b.created))[0].created;

    if (fromDate) {
      const params = {
        from: fromDate,
        categories
      };

      updateCurrentIndex();
      setSkip(false);
      await getMoreNotifications(params);
    } else {
      setShowLoadmore(false);
    }
  };

  const addCategoryHandler = (option: Option | Option[]) => {
    let newCategories: NotificationCategory[] = [];
    if (Array.isArray(option)) {
      newCategories = categories.concat(option.map((o) => o.value as NotificationCategory));
    } else {
      newCategories = newCategories.concat(categories);
      newCategories.push(option.value as NotificationCategory);
    }

    setCategories(newCategories);
  };

  const removeCategoryHandler = (option: Option) => {
    setCategories(categories.filter((c) => c !== option.value));
  };

  const categoryOptions: Option[] = [
    {
      label: intl.formatMessage({
        id: 'notificationCategoryNewChallengesAndIdeas',
        defaultMessage: 'New Challenges and Ideas'
      }),
      value: NotificationCategory.NEW_CHALLENGES_AND_IDEAS
    },
    {
      label: intl.formatMessage({ id: 'notificationCategoryIdeaActivity', defaultMessage: 'Idea Activity' }),
      value: NotificationCategory.IDEA_ACTIVITY
    },
    {
      label: intl.formatMessage({ id: 'notificationCategoryCommentActivity', defaultMessage: 'Comment Activity' }),
      value: NotificationCategory.COMMENT_ACTIVITY
    },
    {
      label: intl.formatMessage({ id: 'notificationCategoryUserActivity', defaultMessage: 'Followed User Activity' }),
      value: NotificationCategory.FOLLOWED_USER_ACTIVITY
    },
    {
      label: intl.formatMessage({ id: 'notificationCategoryBlogActivity', defaultMessage: 'Blog Activity' }),
      value: NotificationCategory.BLOG_ACTIVITY
    }
  ];

  const chosenOptions = categories.map(
    (category) => categoryOptions.find((option) => option.value === category) as Option
  );

  if (loading) {
    return (
      <Styled.NotificationStreamWrapper>
        <Styled.Title>
          <FormattedMessage id="ideasstream" defaultMessage="Ideas Stream" />
        </Styled.Title>
        <Styled.CardsContainer>
          {categories.length > 0 && (
            <Field
              label={intl.formatMessage({ id: 'filterNotifications', defaultMessage: 'Filter notifications' })}
              input={
                <Styled.CustomMultiSelect
                  defaultValues={chosenOptions}
                  options={categoryOptions}
                  onAdd={addCategoryHandler}
                  onRemove={removeCategoryHandler}
                />
              }
            />
          )}
          <Styled.LoadingWrapper>
            <LoadingIndicator />
          </Styled.LoadingWrapper>
        </Styled.CardsContainer>
      </Styled.NotificationStreamWrapper>
    );
  }

  return (
    <Styled.NotificationStreamWrapper>
      <Styled.Title>
        <FormattedMessage id="ideasstream" defaultMessage="Ideas Stream" />
      </Styled.Title>
      <Styled.CardsContainer ref={ref}>
        <Field
          label={intl.formatMessage({ id: 'filterNotifications', defaultMessage: 'Filter notifications' })}
          input={
            <Styled.CustomMultiSelect
              defaultValues={chosenOptions}
              options={categoryOptions}
              onAdd={addCategoryHandler}
              onRemove={removeCategoryHandler}
            />
          }
        />
        {notifications &&
          // eslint-disable-next-line react/no-array-index-key
          notifications.map((group, index) => group && <NotificationCard key={String(index)} notifications={group} />)}
        {showLoadmore && (
          <Styled.ButtonContainer>
            <Styled.LoadMoreButton
              priority="secondary"
              size="small"
              onClick={loadMoreClicked}
              isLoading={loadMoreLoading}
            >
              <FormattedMessage id="loadmore" defaultMessage="Load more" />
            </Styled.LoadMoreButton>
          </Styled.ButtonContainer>
        )}
        {!showLoadmore && notifications.length > 0 && (
          <Styled.NoNotificationMessage>
            <FormattedMessage id="noMoreNotifications" defaultMessage="No notifications found." />
          </Styled.NoNotificationMessage>
        )}
      </Styled.CardsContainer>
      {(!notifications || notifications.length === 0) && (
        <Styled.ZeroState>
          <ZeroStateAddIcon size={60} aria-hidden="true" />
          <FormattedMessage id="noNotifications" defaultMessage="You have no notifications." />
        </Styled.ZeroState>
      )}
    </Styled.NotificationStreamWrapper>
  );
};

import styled from 'styled-components';
import { useContext, useRef, useState } from 'react';
import { borderRadius, Button, color, snap, Tabs, units } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { Form, Formik, type FormikErrors, useFormikContext } from 'formik';
import { PhaseFormTabTypes, PhasesEditType } from '../../../common/enums';
import { ButtonRow } from '../../../common/components';
import { type IChallenge } from '../../../common/interfaces';
import {
  DEFAULT_DATE,
  getNewPhase,
  hasPhaseEnded,
  hasPhaseStarted,
  updatePhasesStartAndEndDate
} from '../helpers/phase';
import { CommunityContext } from '../../../context';
import { funnelValidationSchema } from '../validation-schema';
import { useBeforeUnload } from '../../../hooks';
import { handleBeforeunload } from '../helpers/beforeUnload';
import { useChallenge, useChallengeDispatch } from '../context/Challenge';
import { type Phase } from '../../../common/core-api';
import { type Error, focusField, getFirstError } from '../field-map';
import { ChallengeActionTypes } from '../reducers/challenge';
import { DEFAULT_LANGUAGE } from '../../shared-constants';
import { PhaseAccess, PhaseDetails, PhaseQuestions, PhaseVoting } from '.';

const LabelWrapper = styled.div`
  width: ${snap(110)};
`;

const EndedPhaseMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${color('gradient', { palette: 'expressiveWarning' })};
  border-radius: ${borderRadius('large')};
  background-color: ${color('hint', { palette: 'expressiveWarning' })};
  padding: 0 ${units(2)};
`;

// This is for the questions drag and drop in Customise Form
// without this, question row goes out from the modal when it is dragged
const StyledForm = styled(Form)`
  [class^='ReorderableListstyled__DragListItem'] {
    top: auto !important;
    left: auto !important;
  }
`;

export const PhaseForm = ({
  currentPhase,
  previousPhase,
  afterPhase,
  setPopperOpen,
  phaseIndex
}: {
  readonly currentPhase?: Phase;
  readonly previousPhase?: Phase;
  readonly afterPhase?: Phase;
  readonly setPopperOpen: (value: boolean) => void;
  readonly phaseIndex?: number;
}) => {
  const { values: challenge, setFieldValue, status: challengeStatus } = useFormikContext<IChallenge>();
  const intl = useIntl();
  const community = useContext(CommunityContext);
  const { focusError } = useChallenge();
  const dispatch = useChallengeDispatch();

  const errorStatus = (challengeStatus?.phases && challengeStatus.phases[phaseIndex || 0]) || null;

  let phase: Phase;
  let isNewPhase: boolean = false;
  let isFirstPhase: boolean = false;
  let isLastPhase: boolean = false;

  if (currentPhase) {
    const phaseCount = Number(challenge.phases?.length);
    phase = currentPhase;
    if (
      phaseCount === 0 ||
      (phaseCount > 0 && challenge.phases?.findIndex((phase) => phase.startDate === currentPhase.startDate) === 0)
    ) {
      isFirstPhase = true;
    }

    if (currentPhase.endDate === DEFAULT_DATE) {
      isLastPhase = true;
    }
  } else {
    phase = getNewPhase(previousPhase, afterPhase, community.defaultAccess);
    isNewPhase = true;
    if (previousPhase && !afterPhase) {
      isLastPhase = true;
    }
  }

  const phasedEnded = phase.endDate !== DEFAULT_DATE && hasPhaseEnded(phase.endDate as string);

  const phaseDataChanged = useRef(false);

  useBeforeUnload((event: BeforeUnloadEvent) =>
    handleBeforeunload(
      event,
      intl.formatMessage({
        id: 'changesNotSaved',
        defaultMessage: 'Changes you made may not be saved.'
      }),
      phaseDataChanged.current
    )
  );

  const tabData = [
    {
      label: <LabelWrapper>{intl.formatMessage({ id: 'details', defaultMessage: 'Details' })}</LabelWrapper>,
      value: PhaseFormTabTypes.DETAILS
    },
    {
      label: <LabelWrapper>{intl.formatMessage({ id: 'access', defaultMessage: 'Access' })}</LabelWrapper>,
      value: PhaseFormTabTypes.ACCESS
    },
    {
      label: <LabelWrapper>{intl.formatMessage({ id: 'voting', defaultMessage: 'Voting' })}</LabelWrapper>,
      value: PhaseFormTabTypes.VOTING
    },
    {
      label: (
        <LabelWrapper>{intl.formatMessage({ id: 'phaseQuestions', defaultMessage: 'Customise Form' })}</LabelWrapper>
      ),
      value: PhaseFormTabTypes.QUESTIONS
    }
  ];

  const [activeTab, setActiveTab] = useState(
    focusError && errorStatus ? tabData[focusError.itemTabIndex].value : PhaseFormTabTypes.DETAILS
  );

  const handlePhaseFormSave = (value: Phase) => {
    const { phases } = challenge;

    let newPhasesValue: Phase[] = [];
    let indexOfPhase: number = Number(phases?.length);

    if (!phases || phases.length === 0) {
      newPhasesValue = [value];
    } else if (phases.length > 0 && isNewPhase) {
      // add a new phase to the phases array with correct order
      if (!isLastPhase) {
        const beforePhaseIndex = phases.findIndex((phase) => phase.endDate === value.startDate);
        indexOfPhase = beforePhaseIndex + 1;
      }

      phases.forEach((phase, i) => {
        if (i === indexOfPhase) {
          newPhasesValue.push(value);
        }

        newPhasesValue.push(phase);

        if (isLastPhase && i === phases.length - 1) {
          newPhasesValue.push(value);
        }
      });

      // reorganize start and end date of phases
      newPhasesValue = updatePhasesStartAndEndDate(newPhasesValue, indexOfPhase, PhasesEditType.ADD);
    } else {
      // update the edited phase
      newPhasesValue = phases.map((phase, i) => {
        if (i === 0 && isFirstPhase) {
          indexOfPhase = 0;
          return value;
        }

        if (phase.startDate === value.startDate) {
          indexOfPhase = i;
          return value;
        }

        return phase;
      });

      // reorganize start and end date of phases
      newPhasesValue = updatePhasesStartAndEndDate(newPhasesValue, indexOfPhase, PhasesEditType.EDIT);
    }

    setFieldValue('phases', newPhasesValue);
    setPopperOpen(false);
  };

  const startDateChangeDisabled =
    isNewPhase || (!isNewPhase && isFirstPhase && hasPhaseStarted(phase.startDate as string));

  const handleConfirmClick = (isValid: boolean, errors?: FormikErrors<Phase>) => {
    if (!isValid && errors) {
      const focusError = getFirstError(errors as Error);
      if (focusError) {
        dispatch({ type: ChallengeActionTypes.UPDATE, name: 'focusError', value: focusError });
        setActiveTab(tabData[focusError.fieldIndex].value);
        focusField(focusError.name);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        language: DEFAULT_LANGUAGE,
        ...phase
      }}
      validationSchema={funnelValidationSchema}
      initialStatus={errorStatus}
      onSubmit={(value: Phase) => handlePhaseFormSave(value)}
    >
      {({ dirty, isValid, errors }) => {
        phaseDataChanged.current = dirty;

        return (
          <StyledForm>
            <Tabs
              tabs={tabData}
              layout="left"
              onTabClick={(e, tab) => {
                const index = tabData.findIndex((data) => data.value === tab.value);
                setActiveTab(tabData[index].value);
              }}
              activeTabValue={activeTab}
            />
            {activeTab === PhaseFormTabTypes.DETAILS && (
              <PhaseDetails
                isNewPhase={isNewPhase}
                isFirstPhase={isFirstPhase}
                isLastPhase={isLastPhase}
                startDateChangeDisabled={startDateChangeDisabled}
              />
            )}
            {activeTab === PhaseFormTabTypes.ACCESS && <PhaseAccess />}
            {activeTab === PhaseFormTabTypes.VOTING && <PhaseVoting />}
            {activeTab === PhaseFormTabTypes.QUESTIONS && <PhaseQuestions />}
            <ButtonRow>
              {phasedEnded && (
                <EndedPhaseMessage>
                  {intl.formatMessage({
                    id: 'endedPhaseMessage',
                    defaultMessage: 'This phase has ended and cannot be modified'
                  })}
                </EndedPhaseMessage>
              )}
              <Button priority="tertiary" onClick={() => setPopperOpen(false)}>
                {intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
              </Button>
              {!phasedEnded && (
                <Button
                  priority="secondary"
                  type="submit"
                  disabled={phasedEnded}
                  key="submit"
                  onClick={() => {
                    handleConfirmClick(isValid, errors);
                  }}
                >
                  {intl.formatMessage({
                    id: 'confirm',
                    defaultMessage: 'Confirm'
                  })}
                </Button>
              )}
            </ButtonRow>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

import styled, { css } from 'styled-components';
import { Button, ExpandableContent, Field, LoadingIndicator, snap, typeStyle, units } from '@m/alchemy-ui';

export const FlexRow = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  div {
    width: 100%;
  }
  ${({ visible }) =>
    visible
      ? ''
      : css`
          display: none;
        `}
`;

export const StyledField = styled(Field)`
  margin-bottom: ${units(3)};
`;

export const StyledLabel = styled.div`
  &:before {
    content: '— ';
  }
  &:after {
    content: ' —';
  }
`;

export const StyledButton = styled(Button)`
  margin: ${units(2)} 0;
`;

export const StyledExpandableContent = styled(ExpandableContent)`
  & > button {
    display: flex;
    align-items: center;
    color: inherit;
    text-align: left;
    ${typeStyle('bodyM')}
  }
  & > div {
    padding-left: 0;
  }
`;

export const StyledLoadingIndicator = styled(LoadingIndicator)`
  height: ${snap(30)};
  position: relative;
`;

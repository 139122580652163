import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { type GraphQLError } from 'graphql';
import { Button, LoadingIndicator, snap, Toast, typeStyle, units } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik, type FormikErrors } from 'formik';
import { hasValue } from '@m/magic-typescript';
import omitDeep from 'omit-deep-lodash';
import omit from 'lodash.omit';
import produce from 'immer';
import styled from 'styled-components';
import { CommunityContext, UserContext } from '../../context';
import { ButtonRow, ButtonRowLeftGroup } from '../../common/components';
import { UPDATE_CHALLENGE, type UpdateMutation } from '../../graphql/mutations';
import { ChallengeManagementTabTypes, ModeratorType, ToastLevel, type ToastMessageType } from '../../common/enums';
import { defaultChallenge, type IChallenge } from '../../common/interfaces';
import { useBeforeUnload, useUrlParams } from '../../hooks';
import { type Challenge, type UsersAndGroups } from '../../common/core-api';
import { getToastMessages } from '../../helpers';
import { DEFAULT_LANGUAGE } from '../shared-constants';
import { Create } from './Create';
import { Details } from './Details';
import { Type } from './Type';
import { Settings } from './Settings';
import { useChallenge, useChallengeDispatch } from './context/Challenge';
import { ChallengeManagementTabs, TemplateOptions } from './components';
import { challengeManagementValidationSchema, mapSeverErrorToClient } from './validation-schema';
import { handleBeforeunload } from './helpers/beforeUnload';
import { ChallengeActionTypes } from './reducers/challenge';
import { type Error, focusField, getFirstError } from './field-map';

const ChallengeTitle = styled.div`
  ${typeStyle('headerS')};
  margin-bottom: ${units(3)};
`;

const ToastWrapper = styled.div`
  width: 100%;
`;

const ToastBox = styled.div`
  width: ${snap(210)};
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

const BackToChallengeListWrapper = styled.div`
  margin: 0 0 ${units(3)} calc(${units(2)} * -1);
`;

export const ChallengeManagement = () => {
  const intl = useIntl();
  const user = useContext(UserContext);
  const community = useContext(CommunityContext);
  const challenge = useChallenge();
  const dispatch = useChallengeDispatch();
  const challengeIsNew = challenge.isNew;
  const challengeIsDefault = challenge.id === defaultChallenge.id;
  const challengeLoading = challenge.loading;

  const [messages, setMessages] = useState<ToastMessageType[]>();
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const portalTargetRef = useRef(null);
  const [insertUrlParam] = useUrlParams();

  const tabData = [
    {
      label: <FormattedMessage id="selectTemplate" defaultMessage="Select Template" />,
      value: ChallengeManagementTabTypes.CREATE
    },
    {
      label: <FormattedMessage id="challengeDetails" defaultMessage="Challenge Details" />,
      value: ChallengeManagementTabTypes.DETAILS
    },
    {
      label: <FormattedMessage id="challengeType" defaultMessage="Challenge Type" />,
      value: ChallengeManagementTabTypes.TYPE
    },
    {
      label: <FormattedMessage id="settings" defaultMessage="Settings" />,
      value: ChallengeManagementTabTypes.SETTINGS
    }
  ].filter(hasValue);

  const tabNavigationReducer = (state: string, action: { type: string; value?: string }) => {
    let tab;
    switch (action.type) {
      case 'BACK':
        tab = tabData[tabData.findIndex((tab) => tab.value === state) - 1].value;
        break;
      case 'FORWARD':
        tab = tabData[tabData.findIndex((tab) => tab.value === state) + 1].value;
        break;
      case 'BY_NAME':
        tab = action.value || tabData[0].value;
        break;
      default:
        tab = state;
    }

    insertUrlParam('tab', tab.toLowerCase());
    return tab;
  };

  const [activeTab, dispatchTabChange] = useReducer(tabNavigationReducer, ChallengeManagementTabTypes.DETAILS);

  const dataChanged = useRef(false);

  useBeforeUnload((event: BeforeUnloadEvent) =>
    handleBeforeunload(
      event,
      intl.formatMessage({
        id: 'changesNotSaved',
        defaultMessage: 'Changes you made may not be saved.'
      }),
      dataChanged.current && activeTab !== ChallengeManagementTabTypes.CREATE
    )
  );

  useEffect(() => {
    if (challengeIsNew && challengeIsDefault && !challengeLoading) {
      dispatchTabChange({ type: 'BY_NAME', value: ChallengeManagementTabTypes.CREATE });
    }

    if (!challengeIsNew && !challengeLoading) {
      const urlParams = new URLSearchParams(window.location.search);

      const tab =
        (urlParams.has('tab') && tabData.find((tab) => tab.value.toLowerCase() === urlParams.get('tab'))?.value) ||
        ChallengeManagementTabTypes.DETAILS;

      dispatchTabChange({
        type: 'BY_NAME',
        value: tab
      });
    }
  });

  const [updateChallenge, { loading: updateLoading }] = useMutation<UpdateMutation>(UPDATE_CHALLENGE);

  if (user.loading || community.loading || challenge.loading) {
    return <LoadingIndicator inline />;
  }

  const getVoters = (id: string | null, voters: UsersAndGroups) => {
    const users = voters?.users?.map((user) => ({
      milestoneId: id,
      voterId: user.id,
      type: ModeratorType.USER
    }));
    const groups = voters?.groups?.map((group) => ({
      milestoneId: id,
      voterId: group.id,
      type: ModeratorType.USERGROUP
    }));

    return {
      users,
      groups
    };
  };

  const getModerators = (id: string | null, moderators: UsersAndGroups) => {
    const users = moderators?.users?.map((user) => ({
      milestoneId: id,
      moderatorId: user.id,
      type: ModeratorType.USER
    }));
    const groups = moderators?.groups?.map((group) => ({
      milestoneId: id,
      moderatorId: group.id,
      type: ModeratorType.USERGROUP
    }));

    return {
      users,
      groups
    };
  };

  const handleSave = async (values: IChallenge, { setStatus }: { setStatus: (values: unknown) => void }) => {
    setStatus({ saving: true });
    dispatch({ type: ChallengeActionTypes.UPDATE, name: 'focusError', value: {} });

    const input = produce(challengeManagementValidationSchema.cast(values), (draft) => {
      if (draft.access) {
        draft.access.users = (draft.access.users || []).map(({ user, canModerate }) => ({
          typeId: user.id,
          canModerate
        }));
        draft.access.groups = (draft.access.groups || []).map(({ usergroup, canModerate }) => ({
          typeId: usergroup.id,
          canModerate
        }));
      }

      if (draft.themes) {
        // casting entity type to input type
        // @ts-expect-error
        draft.themes = draft.themes.map((theme) => omit(theme, 'communityId', 'createdAt'));
      }

      if (draft.phases) {
        // casting entity type to input type
        // @ts-expect-error
        draft.phases = draft.phases.map((phase) => ({
          ...phase,
          access: {
            options: phase.access?.options,
            users: (phase.access?.users || []).map(({ user, canModerate }) => ({ typeId: user.id, canModerate })),
            groups: (phase.access?.groups || []).map(({ usergroup, canModerate }) => ({
              typeId: usergroup.id,
              canModerate
            }))
          }
        }));
      }

      if (draft.milestone?.milestones) {
        draft.daysLimit = draft.milestone?.daysLimit;
        delete draft.milestone.daysLimit;
        delete draft.milestone.timeLimitEnabled;
        // casting entity type to input type
        // @ts-expect-error
        draft.milestone.milestones = draft.milestone.milestones.map((milestone) => ({
          ...omit(milestone, 'condition', 'access'),
          allowComments: milestone.access?.allowComments,
          isPrivateSubmission: milestone.access?.privateSubmissionEnabled,
          voters: getVoters(milestone.id, milestone.voters),
          moderators: getModerators(milestone.id, milestone.moderators)
        }));
      }

      if (draft.template) {
        // casting entity type to input type
        // @ts-expect-error
        draft.template.userId = user.id;
      }
    });

    const saveResult = await updateChallenge({
      variables: {
        // omitDeep takes an object and recursively removes properties
        input: omitDeep(
          input,
          'inCreation', // Used to check if the question has been created to disable/enable description type change
          '__typename', // Used by Apollo Client, hated by Apollo Server
          'ideas', // Not needed for saving challenge
          'ideasHaveVotes', // Not needed for saving challenge
          'communityId', // Not needed for saving challenge
          'loading', // Used locally
          'language', // Local state for current selected language on TranslatableInputs
          'isNew', // Local state for challenge
          'errors', // Local state for api errors
          'focusError'
        )
      }
    }).catch((error) => {
      setMessages(
        getToastMessages(ToastLevel.ERROR, intl.formatMessage({ id: 'saveFail', defaultMessage: 'Save failed' }))
      );

      const gqlErrors: GraphQLError[] | undefined = error?.networkError
        ? error?.networkError?.result?.errors
        : error?.graphQLErrors?.[0]?.errors;

      const clientErrors = mapSeverErrorToClient(gqlErrors);

      setStatus({ ...clientErrors, saving: false });
      const focusError = getFirstError(clientErrors as unknown as Error);
      if (focusError) {
        dispatchTabChange({ type: 'BY_NAME', value: focusError.tab });
        dispatch({ type: ChallengeActionTypes.UPDATE, name: 'focusError', value: focusError });
        focusField(focusError.name);
      }
    });

    if (saveResult && saveResult?.data?.updateChallenge) {
      setMessages(
        getToastMessages(
          ToastLevel.CONFIRM,
          intl.formatMessage({ id: 'saveSuccess', defaultMessage: 'Save successful' })
        )
      );
      setStatus({ saving: false });
      dispatch({
        type: ChallengeActionTypes.SET_CHALLENGE,
        value: { ...saveResult?.data.updateChallenge, isNew: false, loading: false }
      });
      return true;
    }

    return false;
  };

  const handleContinueClick = (errors: string[], setTouched: (fields: Record<string, boolean>) => void) => {
    if (errors.length === 0) {
      return dispatchTabChange({ type: 'FORWARD' });
    }

    return setTouched(errors.reduce((object, key) => ({ ...object, [key]: true }), {}));
  };

  const renderBackButton = () => {
    const renderBtnProps = () =>
      activeTab === ChallengeManagementTabTypes.CREATE
        ? { href: '/category/admin_view' }
        : { onClick: () => dispatchTabChange({ type: 'BACK' }) };

    return (
      <Button priority="tertiary" {...renderBtnProps()}>
        <FormattedMessage id="back" defaultMessage="Back" />
      </Button>
    );
  };

  const renderCancelButton = (isBack?: boolean) => (
    <Button priority="tertiary" href="/category/admin_view">
      {isBack ? (
        <FormattedMessage id="backToChallengeList" defaultMessage="Back to Challenge List" />
      ) : (
        <FormattedMessage id="cancel" defaultMessage="Cancel" />
      )}
    </Button>
  );

  const handleSaveClick = (isValid: boolean, errors?: FormikErrors<Challenge>) => {
    if (!isValid && errors) {
      const focusError = getFirstError(errors as Error);
      if (focusError) {
        dispatchTabChange({ type: 'BY_NAME', value: focusError.tab });
        dispatch({ type: ChallengeActionTypes.UPDATE, name: 'focusError', value: focusError });
        focusField(focusError.name);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        language: DEFAULT_LANGUAGE,
        // enableReinitialize must be enabled to support challenge creation but challenge obj is modified by translations
        // Both together cause constant reiniting of form values when translations are changed
        ...omit(challenge, 'focusError')
      }}
      enableReinitialize
      validationSchema={challengeManagementValidationSchema}
      onSubmit={(values, formik) => {
        formik.setFieldValue('isNew', false);
        handleSave(values, { setStatus: formik.setStatus });
      }}
    >
      {({ values, isValid, validateForm, setTouched, dirty, setStatus, errors }) => {
        dataChanged.current = dirty;

        return (
          <Form>
            {!values.isNew && <BackToChallengeListWrapper>{renderCancelButton(true)}</BackToChallengeListWrapper>}
            <ChallengeTitle>{challenge.title}</ChallengeTitle>
            <ChallengeManagementTabs
              onTabClick={(event: string, { value }: { value: string }) =>
                dispatchTabChange({ type: 'BY_NAME', value })
              }
              tabs={tabData.filter((tab) => (tab.value === ChallengeManagementTabTypes.CREATE ? values.isNew : true))}
              activeTabValue={activeTab}
              interactive={!values.isNew}
            />
            {activeTab === ChallengeManagementTabTypes.CREATE && (
              <Create dispatchTabChange={dispatchTabChange} userId={user.id} />
            )}
            {activeTab === ChallengeManagementTabTypes.DETAILS && <Details />}
            {activeTab === ChallengeManagementTabTypes.TYPE && <Type />}
            {activeTab === ChallengeManagementTabTypes.SETTINGS && <Settings />}
            <ToastWrapper>
              <ToastBox ref={portalTargetRef}>
                {/* @ts-expect-error */}
                <Toast messages={messages} portalTarget={portalTargetRef.current} />
              </ToastBox>
            </ToastWrapper>
            <ButtonRow>
              {activeTab !== ChallengeManagementTabTypes.CREATE &&
                (!challenge.isNew || activeTab === ChallengeManagementTabTypes.SETTINGS) && (
                  <ButtonRowLeftGroup>
                    <Button priority="secondary" onClick={() => setTemplateDialogOpen(true)}>
                      <FormattedMessage id="saveAsTemplate" defaultMessage="Save as a template" />
                    </Button>
                  </ButtonRowLeftGroup>
                )}

              {values.isNew ? renderBackButton() : renderCancelButton()}

              {!values.isNew || activeTab === ChallengeManagementTabTypes.SETTINGS ? (
                <Button
                  priority="primary"
                  type="submit"
                  key="submit"
                  isLoading={updateLoading}
                  onClick={() => handleSaveClick(isValid, errors)}
                >
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
              ) : (
                activeTab !== ChallengeManagementTabTypes.CREATE && (
                  <Button
                    priority="primary"
                    onClick={async () => handleContinueClick(Object.keys(await validateForm()), setTouched)}
                  >
                    <FormattedMessage id="continue" defaultMessage="Continue" />
                  </Button>
                )
              )}
            </ButtonRow>
            <TemplateOptions
              isOpen={templateDialogOpen}
              isLoading={updateLoading}
              handleClose={() => setTemplateDialogOpen(false)}
              handleSave={() => handleSave(values, { setStatus })}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

import { type IntlShape } from 'react-intl';
import { Button } from '@m/alchemy-ui';

export const IdeasExportButton = ({ exportUrl, intl }: { readonly exportUrl: URL; readonly intl: IntlShape }) => {
  const exportIdeas = (): void => {
    window.location.assign(exportUrl);
  };

  return (
    <Button priority="secondary" onClick={exportIdeas}>
      {intl.formatMessage({ id: 'exportData', defaultMessage: 'Export Data' })}
    </Button>
  );
};

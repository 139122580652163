import type React from 'react';
import { ExclamationTriangleIcon, Popper, PopperBox } from '@m/alchemy-ui';
import * as Styled from './FunnelEditor.styled';

export const PhaseInfo = ({ icon, message }: { readonly icon: React.ReactNode; readonly message: string }) => (
  <Styled.PhaseInfo>
    <Styled.PhaseInfoIconWrapper>{icon}</Styled.PhaseInfoIconWrapper>
    <div>{message}</div>
  </Styled.PhaseInfo>
);

export const PhaseInfoWithWarning = ({
  label,
  message,
  leftMargin
}: {
  readonly label: string;
  readonly message: string;
  readonly leftMargin: boolean;
}) => (
  <Styled.PhaseInfo>
    <Styled.WarningLabel leftMargin={leftMargin}>{label}</Styled.WarningLabel>
    <Popper
      placement="top"
      mode="tooltip"
      overlay={
        <PopperBox hasArrow colorScheme="alchemyDark">
          {message}
        </PopperBox>
      }
      trigger={
        <Styled.WarningIconWrapper>
          <ExclamationTriangleIcon aria-hidden="true" />
        </Styled.WarningIconWrapper>
      }
    />
  </Styled.PhaseInfo>
);

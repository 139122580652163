import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { Button } from '@m/alchemy-ui';
import { FormattedMessage } from 'react-intl';
import { CommunityContext } from '../../../context';
import { type MixedTypes } from '../../../common/interfaces';
import { DEFAULT_LANGUAGE } from '../../shared-constants';
import * as Styled from './LanguageSelector.styled';

export const LanguageSelector = (props: {
  readonly onLanguageChange: (lang: string) => void;
  readonly value: string;
}) => {
  const { onLanguageChange, value } = props;
  const { setFieldValue } = useFormikContext<MixedTypes & { language: string }>();
  const community = useContext(CommunityContext);

  const handleClick = ({
    language,
    setIsOpen,
    all = false
  }: {
    language: string;
    setIsOpen: (open: boolean) => void;
    all?: boolean;
  }) => {
    if (all) {
      setFieldValue('language', language);
    } else {
      onLanguageChange(language);
    }

    return setIsOpen(false);
  };

  let languages = community.options?.translationLanguages || [];

  if (!languages.includes(DEFAULT_LANGUAGE)) {
    languages = [DEFAULT_LANGUAGE, ...languages];
  }

  return (
    <Styled.LanguageSelector
      data-testid="LanguageSelector"
      size="extraSmall"
      popperProps={{
        shouldMatchWidth: true,
        overlay: ({ setIsOpen }) => (
          <Styled.LanguageSelectorMenu>
            {languages.map((languageISO) => (
              <Styled.LanguageSelectorMenuItem key={languageISO}>
                <Button
                  priority="secondary"
                  size="extraSmall"
                  onClick={() => handleClick({ language: languageISO, setIsOpen })}
                >
                  {languageISO}
                </Button>
                <Styled.AllButton
                  size="extraSmall"
                  onClick={() => handleClick({ language: languageISO, setIsOpen, all: true })}
                >
                  <FormattedMessage id="all" defaultMessage="All" />
                </Styled.AllButton>
              </Styled.LanguageSelectorMenuItem>
            ))}
          </Styled.LanguageSelectorMenu>
        )
      }}
    >
      {value}
    </Styled.LanguageSelector>
  );
};

import { typeStyle, type TypeStyleConfig, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const Title = styled.h2`
  ${typeStyle('headerS', { marginStrategy: 'collapse' })};
  margin-bottom: ${units(1)} !important;
`;

export const SectionTitle = styled(Title)`
  margin-top: ${units(4)};
`;

export const SubTitle = styled.h3<Partial<TypeStyleConfig>>`
  ${(props) => typeStyle('bodyM', { marginStrategy: props.marginStrategy || 'collapse' })};
`;

export const Body = styled.p<Partial<TypeStyleConfig>>`
  ${(props) => typeStyle('bodyS', { marginStrategy: props.marginStrategy || 'collapse' })};
`;

import { gql } from '@apollo/client';

export const GET_ME = gql`
  query me {
    me {
      id
      displayName
      timezone
      screenName
      createdAt
      country
      firstName
      lastName
      profileImageId
      emailforpostcomment
      emailforpostvote
      emailforpostfollow
      emailforuserfollow
      emailforcommentreply
      emailforcommentvote
      passwordChangedAt
      hasPassword
      regCode
      notificationSettings {
        teamsforpostcomment
        teamsforpostvote
        teamsforpostfollow
        slackforpostcomment
        slackforpostvote
        slackforpostfollow
      }
      profileImageFile {
        id
        name
        url
        hash
        extension
      }
      userAccess {
        communityId
        instanceJoined
        id
        userId
        userType
        language
        receiveEmails
        stealthMode
      }
      subscribedIdeaIds
      subscribedChallengeIds
      accounts {
        id
        provider
        userName
        url
      }
      socialLinks {
        id
        url
        type
      }
      useroids {
        id
        provider
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: String!) {
    user(id: $id) {
      id
      displayName
      timezone
      screenName
      createdAt
      commentsCount
      firstName
      lastName
      votesCount
      bio
      country
      town
      badges {
        id
        name
        dateAwarded
        description
        auto
        imageUrl
        imageFile {
          id
          name
          url
          hash
          extension
          userId
        }
        score
        count
        totalScore
      }
      ranking {
        score
        position
        communityId
        id
        userId
      }
      profileImageFile {
        id
        name
        url
        hash
        extension
        userId
      }
      userFields {
        id
        name
        value
        hidden
      }
      followers {
        id
        displayName
        timezone
        screenName
        createdAt
        commentsCount
        firstName
        lastName
        profileImageId
        votesCount
        profileImageFile {
          id
          name
          url
          hash
          extension
          userId
        }
      }
      following {
        id
        displayName
        timezone
        screenName
        createdAt
        commentsCount
        firstName
        lastName
        profileImageId
        votesCount
        profileImageFile {
          id
          name
          url
          hash
          extension
          userId
        }
      }
      followersCount
      followingCount
      commentsCount
      votesCount
      ideasCount
      ideas {
        id
        challengeId
        communityId
        userId
      }
      socialLinks {
        id
        url
        type
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers($offset: Int, $limit: Int, $filters: UserSearchFilters!) {
    users(offset: $offset, limit: $limit, filters: $filters) {
      userCount
      userBatch {
        id
        displayName
        timezone
        screenName
        createdAt
        firstName
        lastName
        country
        town
        hasPassword
        profileImageFile {
          id
          name
          url
          hash
          extension
          userId
        }
      }
    }
  }
`;

import CountUp from 'react-countup';
import * as Styled from './Score.styled';

export const Score = ({
  title,
  value,
  type
}: {
  readonly title: string;
  readonly value: number;
  readonly type: number;
}) => (
  <Styled.Score>
    <div className="title">{title}</div>
    <div className="value" data-testid={`score-${type}`} data-value={value}>
      <CountUp end={value} duration={1} />
    </div>
  </Styled.Score>
);

import { gql } from '@apollo/client';

export const USER_UNFOLLOW = gql`
  mutation unfollow($targetUser: String!) {
    unfollowUser(targetUser: $targetUser) {
      success
      message
    }
  }
`;

export const USER_FOLLOW = gql`
  mutation follow($targetUser: String!) {
    followUser(targetUser: $targetUser) {
      success
      message
    }
  }
`;

import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BellIcon, Dialog, RocketIcon } from '@m/alchemy-ui';
import { type Challenge, type Idea, type IdeaUser, type Maybe, NotificationType } from '../../../common/core-api';
import { CheckOutIcon, FunnelChallengeIcon } from '../../../common/icons';
import { type CommentNotificationTypes, NotificationCardTypes } from '../../../common/enums';
import { removeTags } from '../../../helpers';
import { RelativeDateWithTooltip } from '../../../common/components';
import * as Styled from './Header.styled';
import { ChallengeLink, CommentCardHeader, IdeaCardHeader } from '.';

export interface DialogData {
  open: boolean;
  headerContent: string;
  bodyContent: string;
}

export const Header = ({
  type,
  notificationCount,
  challenge,
  idea,
  author,
  commentCardType,
  time,
  isUserNotification,
  isModeratorNotification,
  sameChallenge,
  cardType,
  isValid
}: {
  readonly type: NotificationType;
  readonly notificationCount: number;
  readonly challenge?: Maybe<Challenge>;
  readonly idea?: Maybe<Idea>;
  readonly author?: Maybe<IdeaUser>;
  readonly commentCardType?: CommentNotificationTypes;
  readonly time?: string;
  readonly isUserNotification: boolean;
  readonly isModeratorNotification: boolean;
  readonly sameChallenge: boolean;
  readonly cardType?: NotificationCardTypes;
  readonly isValid?: boolean;
}) => {
  const intl = useIntl();

  const [dialogData, setDialogData] = useState<DialogData>();
  const open = (open: boolean, headerContent: string, bodyContent: string) => {
    setDialogData({
      open,
      headerContent,
      bodyContent
    });
  };

  const close = () => {
    setDialogData({
      open: false,
      headerContent: '',
      bodyContent: ''
    });
  };

  const modalAction = () => {
    close();
  };

  const getIcon = () => {
    switch (type) {
      case NotificationType.CHALLENGE_CHECK_OUT:
        return <CheckOutIcon />;
      case NotificationType.CHALLENGE_NEW:
        return <RocketIcon aria-hidden="true" />;
      case NotificationType.CHALLENGE_ENTERED_PHASE:
        return <FunnelChallengeIcon />;
      case NotificationType.IDEA_NEW:
        return <BellIcon aria-hidden="true" />;
      default:
        return null;
    }
  };

  const action = [
    {
      id: type,
      label: intl.formatMessage({ id: 'dismiss', defaultMessage: 'Dismiss' }),
      onClick: () => {
        open(
          true,
          intl.formatMessage({ id: 'dismiss', defaultMessage: 'Dismiss' }),
          intl.formatMessage({
            id: 'dismissNotificationBody',
            defaultMessage: 'Do you really want to dismiss this notification?'
          })
        );
      }
    },
    {
      id: type,
      label: intl.formatMessage({ id: 'hideTypeNotification', defaultMessage: 'Hide this type of notification' }),
      onClick: () => {
        open(
          true,
          intl.formatMessage({ id: 'hideNotification', defaultMessage: 'Hide Notification' }),
          intl.formatMessage({
            id: 'hideThisTypeNotification',
            defaultMessage: 'Do you really want to hide this type of notification?'
          })
        );
      }
    }
  ];

  const isHeaderFilledIn = cardType === NotificationCardTypes.CHALLENGE || isModeratorNotification;

  return (
    <div>
      <Styled.HeaderContainer addBackgroundColor={isHeaderFilledIn}>
        {((cardType === NotificationCardTypes.IDEA && !isModeratorNotification) ||
          cardType === NotificationCardTypes.BLOG_POST) && (
          <Styled.HeaderBody>
            <IdeaCardHeader
              isUserNotification={isUserNotification}
              type={type}
              challenge={challenge}
              notificationCount={notificationCount}
              author={author}
              idea={idea}
              sameChallenge={sameChallenge}
              isValid={isValid}
            />
            {notificationCount === 1 && time && <RelativeDateWithTooltip time={time} />}
          </Styled.HeaderBody>
        )}

        {isHeaderFilledIn && (
          <Styled.HeaderBodyWithIcon>
            <Styled.IconContainer>{getIcon()}</Styled.IconContainer>
            {type === NotificationType.CHALLENGE_CHECK_OUT && (
              <FormattedMessage id="checkoutChallenge" defaultMessage="Check out this challenge" />
            )}
            {type === NotificationType.CHALLENGE_NEW && (
              <FormattedMessage id="newChallenge" defaultMessage="New Challenge" />
            )}
            {type === NotificationType.CHALLENGE_ENTERED_PHASE && (
              <FormattedMessage id="challengeEnteredPhase" defaultMessage="Challenge entered new phase" />
            )}
            {type === NotificationType.IDEA_NEW && (
              <FormattedMessage id="moderatorActionRequired" defaultMessage="Moderator action required" />
            )}
          </Styled.HeaderBodyWithIcon>
        )}

        {cardType && [NotificationCardTypes.COMMENT, NotificationCardTypes.BLOG_COMMENT].includes(cardType) && (
          <Styled.HeaderBody>
            <CommentCardHeader
              notificationCount={notificationCount}
              author={author}
              challenge={challenge}
              commentCardType={commentCardType}
              idea={idea}
              isValid={isValid}
            />
          </Styled.HeaderBody>
        )}
        <Styled.MoreButton actions={action} hasPadding={!isHeaderFilledIn} />
      </Styled.HeaderContainer>
      {cardType === NotificationCardTypes.IDEA && isModeratorNotification && (
        <Styled.SubHeader>
          <Styled.HeaderBody>
            <Styled.AlignItemsWrap>
              <FormattedMessage
                id="ideasRequireModeration"
                defaultMessage="The challenge <a>{challengeTitle}</a> {itemCount, plural, one {received a new idea that requires} other {has new ideas that require}} moderator action"
                values={{
                  challengeTitle: removeTags(challenge?.title || ''),
                  a: (msg) => (isValid ? ChallengeLink(msg, challenge?.id) : msg),
                  itemCount: notificationCount
                }}
              />
            </Styled.AlignItemsWrap>
            <Styled.AlignItems>
              {notificationCount === 1 && time && <RelativeDateWithTooltip time={time} />}
            </Styled.AlignItems>
          </Styled.HeaderBody>
        </Styled.SubHeader>
      )}
      <Dialog
        isOpen={dialogData?.open}
        onClose={close}
        headerContent={dialogData?.headerContent}
        bodyContent={dialogData?.bodyContent}
        footerButtons={[
          {
            priority: 'tertiary',
            label: intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' }),
            palette: 'interactiveNavigational',
            onClick: close
          },
          {
            label: intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' }),
            palette: 'interactiveDestructive',
            onClick: modalAction
          }
        ]}
      />
    </div>
  );
};

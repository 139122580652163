import { gql } from '@apollo/client';

export const notificationFragment = gql`
  fragment NotificationWithData on NotificationEvent {
    id
    type
    subject
    body
    created
    icon
    actions
    blogPostId
    phaseId
    challengeId
    commentId
    ideaId
    userId
    reasonType
    reasonId
    isValid
    idea {
      id
      title
      text
      stats {
        comments
        followers
        views
        votes
      }
      files {
        images {
          id
          hash
          extension
        }
      }
      user {
        ... on User {
          id
          displayName
          userAccess {
            id
            userType
          }
        }
        ... on AnonymousUser {
          id
          displayName
        }
      }
    }
    blogPost {
      id
      title
      text
      stats {
        comments
        followers
        views
      }
      files {
        images {
          id
          hash
          extension
        }
      }
    }
    challenge {
      id
      title
      description
      stats {
        comments
        ideas
        subscribers
        votes
      }
      files {
        list {
          id
          hash
          extension
        }
      }
    }
    user {
      ... on User {
        id
        displayName
        profileImageFile {
          hash
          extension
          id
          name
          userId
        }
        userAccess {
          communityId
          id
          userId
          userType
          instanceJoined
        }
      }
      ... on AnonymousUser {
        id
        profileImageId
        screenName
        displayName
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query notifications($categories: [NotificationCategory!]) {
    notifications(categories: $categories) {
      ...NotificationWithData
    }
  }
  ${notificationFragment}
`;

export const LOAD_MORE_NOTIFICATIONS = gql`
  query moreNotifications($from: String, $categories: [NotificationCategory!]) {
    notifications(from: $from, categories: $categories) {
      ...NotificationWithData
    }
  }
  ${notificationFragment}
`;

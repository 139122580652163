import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { type DraggableProvided, type DraggableStateSnapshot } from 'react-beautiful-dnd';

export const usePortalledDraggable = () => {
  const self = useRef<{ elt?: HTMLDivElement }>({}).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return (render: (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => JSX.Element) =>
    (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
      const element = render(provided, snapshot);
      // TODO: need to refine the type
      // @ts-expect-error
      if (provided.draggableProps.style?.position === 'fixed' && self.elt) {
        return createPortal(element, self.elt);
      }

      return element;
    };
};

import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  color,
  DeleteIcon,
  DocumentIcon,
  ExclamationTriangleIcon,
  FileTypeExcelIcon,
  FileTypePDFIcon,
  FileTypePowerPointIcon,
  FileTypeVideoIcon,
  FileTypeWordIcon,
  FileTypeZipIcon,
  ProgressBar,
  snap,
  units
} from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { FileUploadType } from '../enums';
import { type IChallenge } from '../interfaces';
import { useApiV2, useFile } from '../../hooks';
import { type File as FileType } from '../core-api';
import { UserContext } from '../../context';
import { DeleteIconButton } from '.';

interface ImagePreviewProps {
  url: string;
}
const FileList = styled.div`
  padding: ${units(1)};
  border-bottom: 1px solid ${color('line')};
`;
const List = styled.div`
  display: flex;
  align-items: center;
`;
const ListWithProgressBar = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const ListImage = styled.div<ImagePreviewProps>`
  min-width: ${snap(20)};
  width: ${snap(20)};
  height: ${snap(20)};
  margin-right: ${units(1)};
  ${({ url }) => css`
    background: url(${url}) no-repeat center;
    background-size: cover;
  `}
`;
const FileName = styled.div<{ failed?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  max-width: calc(100% - (${units(2)} * 2));
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ failed }) =>
    failed &&
    css`
      color: ${color('base', { palette: 'interactiveDestructive' })};
    `}
`;
const StyledWarningIcon = styled(ExclamationTriangleIcon)`
  margin-right: ${units(1)};
`;

const IconWrapper = styled.div`
  svg {
    min-width: ${snap(20)};
    width: ${snap(20)};
    height: ${snap(20)};
    margin-right: ${units(1)};
  }
`;

interface IconProps {
  readonly ext: string;
  readonly url: string;
}

const Icon = ({ ext, url }: IconProps) => {
  const [, UploadExtensions] = useFile();
  let resultIcon;
  if (['doc', 'docx'].includes(ext)) {
    resultIcon = <FileTypeWordIcon aria-hidden="true" />;
  }

  if (['zip', 'rar'].includes(ext)) {
    resultIcon = <FileTypeZipIcon aria-hidden="true" />;
  }

  if (ext === 'pdf') {
    resultIcon = <FileTypePDFIcon aria-hidden="true" />;
  }

  if (['ppt', 'pptx'].includes(ext)) {
    resultIcon = <FileTypePowerPointIcon aria-hidden="true" />;
  }

  if (['xls', 'xlsx', 'csv'].includes(ext)) {
    resultIcon = <FileTypeExcelIcon aria-hidden="true" />;
  }

  if (UploadExtensions.video.includes(ext)) {
    resultIcon = <FileTypeVideoIcon aria-hidden="true" />;
  }

  if (['txt', 'odt', 'rtf'].includes(ext)) {
    resultIcon = <DocumentIcon aria-hidden="true" />;
  }

  if (['jpg', 'png', 'gif', 'jpeg'].includes(ext)) {
    resultIcon = <ListImage url={url} />;
  }

  return <IconWrapper>{resultIcon}</IconWrapper>;
};

interface FileListProps {
  readonly file: File | FileType;
  readonly type: FileUploadType;
  readonly files: (File | FileType)[];
  readonly setFiles: (files: (File | FileType)[]) => void;
}

export const FileListItem = ({ file, type, files, setFiles }: FileListProps) => {
  const { values: challenge, setFieldValue } = useFormikContext<IChallenge>();
  const intl = useIntl();
  const user = useContext(UserContext);

  const [getFile] = useFile();
  const [uploadFile] = useApiV2();

  const [percent, setPercent] = useState(0);
  const [uploadedFile, setUploadedFile] = useState<FileType>();
  const [uploadFailedFile, setUploadFailedFile] = useState<File>();

  const isFileType = (file: File | FileType): file is FileType => (file as FileType).id !== undefined;

  useEffect(() => {
    if (!isFileType(file)) {
      uploadFile({ file, updateProgress: setPercent })
        .then((res) => {
          if (res.data.response && !res.data.response.success) {
            setUploadFailedFile(file);
            return;
          }

          if (uploadFailedFile) {
            setUploadFailedFile(undefined);
          }

          const { id, name, storage, hash, ext } = res.data;
          const newFile = {
            id: String(id),
            name,
            url: storage.url,
            hash,
            extension: ext,
            userId: user.id
          };
          setPercent(100);
          setUploadedFile(newFile);

          if (type === FileUploadType.CHALLENGE_ATTACHMENT) {
            const existingAttachments = challenge.files?.attachments || [];
            setFieldValue('files', { ...challenge.files, attachments: [...existingAttachments, newFile] });
          }
        })
        .catch(() => {
          setUploadFailedFile(file);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const previewFile = isFileType(file) ? file : uploadedFile;

  const [iconUrl, setIconUrl] = useState('');
  useEffect(() => {
    const getAuthUrl = async () => {
      const url = previewFile?.url && (await getFile({ file: previewFile, width: 24, height: 24 }));
      setIconUrl(url || '');
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewFile]);

  const handleFileDeleteClick = () => {
    const newFilesValue = files.filter((f) => f.name !== file.name);
    setFiles(newFilesValue);

    if (type === FileUploadType.CHALLENGE_ATTACHMENT) {
      const filteredAttachments = (challenge.files?.attachments || []).filter(
        (f) => isFileType(file) && f.id !== file.id
      );

      setFieldValue('files', { ...challenge.files, attachments: filteredAttachments });
    }
  };

  return (
    <FileList>
      {(isFileType(file) || uploadedFile) && (
        <List>
          <Icon ext={previewFile?.extension || ''} url={iconUrl} />
          <FileName>{uploadedFile ? uploadedFile.name : file.name}</FileName>
          <DeleteIconButton
            icon={<DeleteIcon aria-label={intl.formatMessage({ id: 'deleteIcon', defaultMessage: 'Delete icon' })} />}
            tooltipContents={intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
            onClick={handleFileDeleteClick}
          />
        </List>
      )}
      {!isFileType(file) && !uploadedFile && (
        <ListWithProgressBar>
          <FileName failed={Boolean(uploadFailedFile)}>
            {uploadFailedFile && <StyledWarningIcon aria-hidden="true" />}
            {file.name}
          </FileName>
          {!uploadFailedFile && <ProgressBar value={percent / 100} />}
        </ListWithProgressBar>
      )}
    </FileList>
  );
};

export const CheckOutIcon = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="currentColor">
      <g id="icon_CheckOut" transform="translate(-0.000000, -0.000000)">
        <g id="megaphone" transform="translate(0.000000, 0.000000)">
          <path
            d="M6.53635064,4.4261431 L0.666041724,4.4261431 C0.297106847,4.4261431 0,4.72349515 0,5.09273452 L0,10.8633249 C0,11.2325643 0.297106847,11.5299164 0.666041724,11.5299164 L2.77190894,11.5299164 C2.64784234,12.3304796 2.77843876,13.1506484 3.15716837,13.8858596 C3.63058038,14.8105918 4.44027816,15.4967888 5.42954601,15.8137465 C5.82460017,15.9411831 6.22618415,16 6.62123831,16 C8.2177795,16 9.70657865,15.0099157 10.277938,13.4480005 L15.0283826,15.8856338 C15.6291261,16.1666479 16.0143856,15.627493 15.998061,15.2909297 L15.998061,0.661862143 C16.0307101,0.364510088 15.6487156,-0.197517972 15.0283826,0.0704256384 L6.53635064,4.4261431 Z M1.01119982,5.50582607 L6.0177227,5.50582607 L6.0177227,10.549155 L1.01119982,10.549155 L1.01119982,5.50582607 Z M9.41842931,13.0445026 C8.98746113,14.3842207 7.39414064,15.5028501 5.89655373,15.0410625 C3.52897103,14.3110077 3.65557376,12.1213529 3.79596491,11.533184 L6.53308573,11.533184 C6.53308573,11.533184 7.49486692,12.0369568 9.41842931,13.0445026 Z M15,14.7575497 L7.04831628,10.5690626 L7.04831628,5.38859837 L15,1.24778337 L15,14.7575497 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

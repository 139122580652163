export const PollTemplateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="180" height="90" viewBox="0 0 180 90">
    <defs>
      <mask id="a" x="-54.503" y="-51.003" width="301.505" height="149.505" maskUnits="userSpaceOnUse">
        <rect width="180" height="90" fill="#fff" />
      </mask>
      <mask id="b" x="0" y="0" width="180" height="101" maskUnits="userSpaceOnUse">
        <rect width="180" height="90" fill="#fff" />
      </mask>
      <mask id="c" x="0" y="0" width="180" height="90" maskUnits="userSpaceOnUse">
        <rect width="180" height="90" fill="#fff" />
      </mask>
    </defs>
    <rect width="180" height="90" fill="#e7f4ff" />
    <g mask="url(#a)">
      <path
        d="M94.881,6.043a16,16,0,0,1-5.632,21.666l-.292.172-119.5,68.5a16,16,0,0,1-21.838-5.924,16,16,0,0,1,5.632-21.666l.292-.172L73.043.119A16,16,0,0,1,94.881,6.043Zm150-49a16,16,0,0,1-5.632,21.666l-.292.172-119.5,68.5a16,16,0,0,1-16.206-27.59l.292-.172,119.5-68.5A16,16,0,0,1,244.881-42.957Z"
        fill="#51abff"
        fillOpacity="0.07"
      />
    </g>
    <g mask="url(#b)">
      <path
        d="M106.447,49.3a5.746,5.746,0,0,1,5.543,4.232,5.752,5.752,0,0,1,6.958,2.25,5.747,5.747,0,0,1,8.563,5.007V91.426a1.911,1.911,0,0,1-.556,1.359l-3.274,3.255V101h-3.83V95.255a1.914,1.914,0,0,1,.555-1.359l3.275-3.256V60.787a1.915,1.915,0,1,0-3.83,0v13.4h-3.83V58.872a1.915,1.915,0,0,0-3.83,0V74.191h-3.829V55.043a1.915,1.915,0,1,0-3.83,0V74.191H100.7V58.872a1.915,1.915,0,1,0-3.83,0V79.936H93.043V72.684a3.6,3.6,0,0,0-1.914,3.422V84.9l9.019,9.019a1.913,1.913,0,0,1,.555,1.34V101h-3.83V96.04l-9.019-9.019a1.912,1.912,0,0,1-.555-1.34V76.106a7.448,7.448,0,0,1,5.744-7.465V58.872A5.749,5.749,0,0,1,100.9,53.53,5.746,5.746,0,0,1,106.447,49.3Z"
        fill="#00ba9e"
      />
    </g>
    <g mask="url(#c)">
      <rect
        x="50.915"
        y="61.745"
        width="28.723"
        height="8.617"
        fill="#404fc7"
        opacity="0.3"
        style={{ isolation: 'isolate' }}
      />
    </g>
    <g mask="url(#c)">
      <rect
        x="50.915"
        y="73.883"
        width="28.723"
        height="16"
        fill="#404fc7"
        opacity="0.3"
        style={{ isolation: 'isolate' }}
      />
    </g>
    <g mask="url(#b)">
      <path
        d="M70.064,11a5.746,5.746,0,0,1,5.543,4.232,5.749,5.749,0,0,1,7.861,5.342v13.6a7.448,7.448,0,0,1,5.744,7.465v9.575a1.911,1.911,0,0,1-.556,1.359L82.233,59a1.914,1.914,0,0,1,1.235,1.79v11.49a1.914,1.914,0,0,1-1.915,1.914V101h-3.83V74.191H54.744V101h-3.83V74.191A1.914,1.914,0,0,1,49,72.277V60.787A1.914,1.914,0,0,1,50.235,59l-.68-.68A1.917,1.917,0,0,1,49,56.957V22.489a5.747,5.747,0,0,1,8.563-5.007,5.752,5.752,0,0,1,6.958-2.25A5.746,5.746,0,0,1,70.064,11Zm9.574,51.7H52.83v7.66H79.638ZM71.979,16.745a1.915,1.915,0,0,0-3.83,0V39.723h-3.83V20.574a1.915,1.915,0,0,0-3.83,0V39.723H56.66V22.489a1.915,1.915,0,0,0-3.83,0V56.172l2.7,2.7H76.956l8.427-8.444v-8.79a3.6,3.6,0,0,0-1.914-3.422v7.252h-3.83V20.574a1.915,1.915,0,0,0-3.829,0V39.723h-3.83Z"
        fill="#404fc7"
      />
    </g>
    <path
      d="M126.3,14.094h-24a9,9,0,0,0-9,9V36.055a9,9,0,0,0,9,9h.039L115,45h0l11,8V45.094h.3a9,9,0,0,0,9-9v-13A9,9,0,0,0,126.3,14.094Z"
      fill="#7339c1"
      fillRule="evenodd"
    />
    <path d="M103.5,23a1.5,1.5,0,0,0-.144,2.993L103.5,26h23a1.5,1.5,0,0,0,.144-2.993L126.5,23Z" fill="#fff" />
    <path d="M103.5,29a1.5,1.5,0,0,0-.144,2.993L103.5,32h17a1.5,1.5,0,0,0,.144-2.993L120.5,29Z" fill="#fff" />
    <path d="M103.5,35a1.5,1.5,0,0,0-.144,2.993L103.5,38h23a1.5,1.5,0,0,0,.144-2.993L126.5,35Z" fill="#fff" />
  </svg>
);

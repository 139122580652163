import styled from 'styled-components';
import { Button, color, typeStyle, units } from '@m/alchemy-ui';

export const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${units(1.5)};
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  color: ${color('base')};
  font: ${typeStyle('captionM')};
`;

export const Stat = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${units(3)};
  svg {
    margin-right: ${units(1)};
  }
`;

export const SubscribeButton = styled(Button)`
  border-color: ${color('base', { palette: 'interactiveConstructive' })};
  margin: ${units(0.5)} 0 ${units(0.5)} auto;
`;

import { hasValue } from '@m/magic-typescript';
import { type MixedTypes, type Translation } from '../../../common/interfaces';

export const getValueOrPath = (
  values: MixedTypes,
  {
    language,
    field,
    type: translationType,
    translateId
  }: { language: string; field: string; type: string; translateId: string },
  returnPath: boolean = false
) => {
  // type can be: Challenge, Milestone, Phase, ChallengeQuestion, ChallengeQuestionOption, Scorecard, ScorecardLabel
  const type = translationType.toLowerCase();

  let fieldValue;
  let fieldPath: string = '';

  if (type === 'challengequestion') {
    fieldValue = values.questions
      ?.find(({ id }, index) => {
        if (id === translateId) {
          fieldPath = `questions[${index}]`;
          return true;
        }

        return false;
      })
      ?.translations?.find(({ iso, field: tField }, index) => {
        if (iso === language && tField === field) {
          fieldPath += `.translations[${index}]`;
          return true;
        }

        return false;
      })?.value;
  }

  if (type === 'challengequestionoption') {
    values.questions?.forEach(({ options }, index) => {
      options
        ?.find(({ id }, optionIndex) => {
          if (id === translateId) {
            fieldPath = `questions[${index}].options[${optionIndex}]`;
            return true;
          }

          return false;
        })
        ?.translations?.find(({ iso, field: tField, value }, translationIndex) => {
          if (iso === language && tField === field) {
            fieldPath += `.translations[${translationIndex}]`;
            fieldValue = value;
            return true;
          }

          return false;
        });
    });
  }

  if (type === 'scorecard') {
    fieldValue = values.voting.scorecards?.cards
      ?.find(({ id }, index) => {
        if (id === translateId) {
          fieldPath = `voting.scorecards.cards[${index}]`;
          return true;
        }

        return false;
      })
      ?.translations?.find(({ iso, field: tField }, index) => {
        if (iso === language && tField === field) {
          fieldPath += `.translations[${index}]`;
          return true;
        }

        return false;
      })?.value;
  }

  if (type === 'scorecardlabel') {
    fieldValue = values.voting.scorecards?.labels
      ?.find(({ id }, index) => {
        if (id === translateId) {
          fieldPath = `voting.scorecards.labels[${index}]`;
          return true;
        }

        return false;
      })
      ?.translations?.find(({ iso }, index) => {
        if (iso === language) {
          fieldPath += `.translations[${index}]`;
          return true;
        }

        return false;
      })?.value;
  }

  if (!fieldValue) {
    fieldValue = values.translations?.find(({ iso, field: tField, objectId }, index) => {
      if (iso === language && tField === field && objectId === translateId) {
        fieldPath = `translations[${index}]`;
        return true;
      }

      return false;
    })?.value;
  }

  if (returnPath) {
    if (fieldPath === '') {
      return 'translations';
    }

    return fieldPath.includes('translations') ? fieldPath + '.value' : fieldPath + '.translations';
  }

  return fieldValue || '';
};

export const getExistingTranslations = (values: MixedTypes, fieldPath: string) => {
  if (!fieldPath) {
    return null;
  }

  // for example, fieldPath can be 'voting.scorecards.cards[2].translations'
  // we need to retrieve the existing translations array from the values as:
  // values['voting']['scorecards']['cards'][2]['translations']
  const pathSplited = fieldPath.split(/\.|\[|\]/g).filter(hasValue);

  if (pathSplited[pathSplited.length - 1] !== 'translations') {
    return null;
  }

  let translations = values;
  pathSplited.forEach((path) => {
    // @ts-expect-error
    translations = Number(path) >= 0 ? translations[Number(path)] : translations[path];
  });

  return translations as unknown as Translation[];
};

export const handleTranslationChange = ({
  language,
  field,
  type,
  translateId,
  value,
  fieldPath,
  communityId,
  setFieldValue,
  values
}: {
  language: string;
  field: string;
  type: string;
  translateId: string;
  value: string;
  communityId: string;
  fieldPath: string;
  setFieldValue: (path: string, value: unknown) => void;
  values: MixedTypes;
}) => {
  if (!fieldPath.includes('value')) {
    // when new translation is added
    const newTranslation = {
      id: '',
      communityId,
      iso: language,
      value,
      objectId: translateId,
      objectType: type,
      field
    };

    const exisitingTranslations = getExistingTranslations(values, fieldPath);
    const newValue = exisitingTranslations ? [...exisitingTranslations, newTranslation] : [newTranslation];
    return setFieldValue(fieldPath, newValue);
  }

  if (!value) {
    // when translation is removed
    const path = fieldPath.split('translations')[0] + 'translations';
    // ex) fieldPath:'voting.scorecards.cards[2].translations[1].value' => path:'voting.scorecards.cards[2].translations'
    const exisitingTranslations = getExistingTranslations(values, path);
    const newValue = exisitingTranslations?.filter(
      ({ iso, objectId, objectType, field: tField }) =>
        !(iso === language && objectType === type && objectId === translateId && tField === field)
    );
    return newValue && setFieldValue(path, newValue);
  }

  // update the existing translation value
  return setFieldValue(fieldPath, value);
};

import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { format } from 'date-fns';
import { useMutation } from '@apollo/client';
import { UserContext } from '../../../../context';
import { type FollowMutation, type UnfollowMutation, USER_FOLLOW, USER_UNFOLLOW } from '../../../../graphql/mutations';
import { type User } from '../../../../common/core-api';
import { useFile } from '../../../../hooks';
import * as Styled from './UserItem.styled';

interface UserItemProps {
  readonly user: User & { isFollowing?: boolean };
  readonly onProfilePopupLoaded: (id: string) => void;
  readonly type: 'followersTab' | 'followingTab';
  readonly toggleFollowState: (id: string, type: string) => void;
  readonly refetchUsers: () => void;
  readonly isProfileOwner?: boolean;
  readonly updateIndex: () => void;
}

const UserItem = ({
  user,
  onProfilePopupLoaded,
  type,
  toggleFollowState,
  refetchUsers,
  isProfileOwner,
  updateIndex
}: UserItemProps) => {
  const intl = useIntl();
  const [getFile] = useFile();
  const currentUser = useContext(UserContext);

  const [followUser, { loading: userFollowLoading }] = useMutation<FollowMutation>(USER_FOLLOW);
  const [unfollowUser, { loading: userUnfollowLoading }] = useMutation<UnfollowMutation>(USER_UNFOLLOW);
  const url = user.profileImageFile && getFile({ file: user.profileImageFile, width: 100, height: 100 });
  const joinedDate = format(new Date(user.createdAt), 'PPP');

  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  useEffect(() => {
    const getAuthUrl = async () => {
      const url = user.profileImageFile && (await getFile({ file: user.profileImageFile, width: 100, height: 100 }));
      setProfilePictureUrl(url || '');
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.profileImageFile]);

  const toggleFollow = async (id: string) => {
    updateIndex();

    const params = {
      variables: {
        byUser: currentUser.id,
        targetUser: id
      }
    };

    await (user.isFollowing ? unfollowUser(params) : followUser(params));

    refetchUsers();

    toggleFollowState(id, type);
  };

  return (
    <Styled.User>
      {url ? (
        <Styled.UserImage
          data-testid="UserProfileImage"
          src={profilePictureUrl}
          onClick={() => onProfilePopupLoaded(user.id)}
        />
      ) : (
        <Styled.NoUserImage
          aria-label={intl.formatMessage({ id: 'noUserImage', defaultMessage: 'User without image' })}
          onClick={() => onProfilePopupLoaded(user.id)}
        />
      )}
      <Styled.UserDescr>
        <Styled.UserInfo>
          <Styled.UserName onClick={() => onProfilePopupLoaded(user.id)}>{user.displayName}</Styled.UserName>
          <Styled.Date>
            <FormattedMessage id="sinceDate" defaultMessage="User since {date}" values={{ date: joinedDate }} />
          </Styled.Date>
        </Styled.UserInfo>
        {isProfileOwner ? (
          <Styled.FollowBtn
            onClick={() => toggleFollow(user.id)}
            isLoading={userFollowLoading || userUnfollowLoading}
            priority="secondary"
            size="medium"
          >
            {user.isFollowing ? (
              <FormattedMessage id="unfollow" defaultMessage="Unfollow" />
            ) : (
              <FormattedMessage id="follow" defaultMessage="Follow" />
            )}
          </Styled.FollowBtn>
        ) : (
          ''
        )}
      </Styled.UserDescr>
    </Styled.User>
  );
};

export default UserItem;

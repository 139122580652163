import { useEffect, useState } from 'react';
import { ChangeUserIcon, color, units, UserGradientIcon } from '@m/alchemy-ui';
import styled, { css } from 'styled-components';
import { type FormikProps, useFormikContext } from 'formik';
import { type MixedTypes } from '../../../common/interfaces';
import { SelectorType } from '../../../common/enums';
import { isUser } from '../helpers/access';
import { useFile } from '../../../hooks';
import { type AccessOption, type User, type UserGroup, type UsersAndGroups } from '../../../common/core-api';

const SearchItemContianer = styled.div`
  border-bottom: 1px solid ${color('fill')};
  padding: ${units(0.6)};
  display: flex;
  align-items: center;
`;
const ImageContainer = styled.div`
  color: ${color('fill')};
  margin-right: ${units(1)};
  display: flex;
  align-items: center;
  min-width: ${units(3)};
  svg {
    width: ${units(3)};
    height: ${units(3)};
  }
`;
const SearchListName = styled.div<{ selected: boolean }>`
  ${(props) =>
    props.selected &&
    css`
      color: ${color('lineContrast')};
    `}
`;
const UserProfileImage = styled.img`
  width: ${units(3)};
  height: ${units(3)};
  border-radius: 50%;
`;

export const SearchListItem = ({
  item,
  setSearchString,
  setSearchResults,
  type
}: {
  readonly item: User | UserGroup;
  readonly setSearchString: (string: string) => void;
  readonly setSearchResults: (data: never[]) => void;
  readonly type: SelectorType;
}) => {
  const [getFile] = useFile();
  const { values, setFieldValue }: FormikProps<MixedTypes> = useFormikContext();
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  useEffect(() => {
    const getAuthUrl = async () => {
      const file = isUser(item) && item.profileImageFile;
      let url = '';

      if (file) {
        url = await getFile({ file, width: 22, height: 22 });
      }

      setProfilePictureUrl(url || '');
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  let access: AccessOption;
  let moderators: UsersAndGroups | null | undefined;
  if (type === SelectorType.CHALLENGE) {
    access = values.access;
  }

  if (type === SelectorType.PHASE) {
    access = values.access;
  }

  if (type === SelectorType.MILESTONE_MODERATE) {
    moderators = values.moderators;
  }

  if (type === SelectorType.MILESTONE_VOTE) {
    moderators = values.voters;
  }

  const getUserProfileImage = () => {
    if (isUser(item) && item.profileImageFile) {
      return <UserProfileImage src={profilePictureUrl} />;
    }

    return <UserGradientIcon aria-hidden="true" />;
  };

  const selected = (item: User | UserGroup) => {
    let ids;
    if (access) {
      ids = isUser(item)
        ? access?.users?.map((user) => user.user.id)
        : access?.groups?.map((group) => group.usergroup.id);
    }

    if (moderators) {
      ids = isUser(item) ? moderators?.users?.map((user) => user.id) : moderators?.groups?.map((group) => group.id);
    }

    if (!ids || ids.length === 0) {
      return false;
    }

    return ids.includes(item.id);
  };

  const handleListClick = () => {
    if (!selected(item)) {
      let newValue;
      let newAccessValue;
      if (access) {
        newValue = isUser(item)
          ? [...(access?.users || []), { user: item, canModerate: false }]
          : [...(access?.groups || []), { usergroup: item, canModerate: false }];
        newAccessValue = isUser(item) ? { ...access, users: newValue } : { ...access, groups: newValue };
      }

      if (moderators) {
        newValue = isUser(item) ? [...(moderators?.users || []), item] : [...(moderators?.groups || []), item];
        newAccessValue = isUser(item) ? { ...moderators, users: newValue } : { ...moderators, groups: newValue };
      }

      if (type === SelectorType.CHALLENGE || type === SelectorType.PHASE) {
        setFieldValue('access', newAccessValue);
      }

      if (type === SelectorType.MILESTONE_MODERATE) {
        setFieldValue('moderators', newAccessValue);
      }

      if (type === SelectorType.MILESTONE_VOTE) {
        setFieldValue('voters', newAccessValue);
      }
    }

    setSearchString('');
    setSearchResults([]);
  };

  return (
    <SearchItemContianer onClick={handleListClick}>
      <ImageContainer>{isUser(item) ? getUserProfileImage() : <ChangeUserIcon aria-hidden="true" />}</ImageContainer>
      <SearchListName selected={selected(item)}>{isUser(item) ? item.displayName : item.name}</SearchListName>
    </SearchItemContianer>
  );
};

import React, { useContext, useState } from 'react';
import { Input } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { type FormikProps } from 'formik';
import omit from 'lodash.omit';
import { type Filters } from '../People';
import { type UserField } from '../../../common/core-api';
import { UserContext } from '../../../context';
import { PEOPLE_SEARCH_CHARACTER_MIN } from '../../shared-constants';
import * as Styled from './SearchFilters.styled';
import { FilterWithCheckbox, FilterWithInput } from '.';

export const SearchFilters = React.forwardRef(function SearchFilters(
  {
    formik,
    userFieldsList,
    allCountries,
    handleRemoveUserFieldFilter,
    handleRemoveCountryFilter,
    resetAll
  }: {
    readonly formik: FormikProps<Filters>;
    readonly userFieldsList?: UserField[];
    readonly allCountries: string[];
    readonly handleRemoveUserFieldFilter: ({ id, option }: { id: string; option: string }) => void;
    readonly handleRemoveCountryFilter: ({ option }: { option: string }) => void;
    readonly resetAll: () => void;
  },
  ref: React.Ref<HTMLInputElement>
) {
  const intl = useIntl();
  const loggedInUser = useContext(UserContext);

  const { values, handleChange, handleSubmit, errors, setFieldValue } = formik;
  const { searchText, userFields, countries } = values;
  const [displayAdvancedFilters, setDisplayAdvancedFilters] = useState<boolean>(false);
  const [clearSearchFilters, setClearSearchFilters] = useState<boolean>(false);

  const handleAddUserFieldFilter = ({
    id,
    option,
    resetForm,
    isTextInput
  }: {
    id: string;
    option: string;
    resetForm?: () => void;
    isTextInput?: boolean;
  }) => {
    const value = option.trim();
    if (!isTextInput || value.length >= PEOPLE_SEARCH_CHARACTER_MIN) {
      setFieldValue(`userFields[${id}]`, [...(userFields[id] || []), value]);
      setFieldValue(`userFieldType[${id}]`, userFieldsList?.find((f) => f.id === id)?.type || '');
      resetForm && resetForm();
    }
  };

  const handleSelectUserFieldFilterAll = (id: string) => {
    setFieldValue('userFields', omit(userFields, id));
    const { userFieldType } = formik.values;
    setFieldValue('userFieldType', omit(userFieldType, id));
  };

  return (
    <Styled.StyledForm onSubmit={handleSubmit}>
      <Styled.StyledField
        label={intl.formatMessage({ id: 'searchForAUser', defaultMessage: 'Search for a user' })}
        input={<Input isFullWidth value={searchText} name="searchText" onChange={handleChange} ref={ref} />}
        status={errors.searchText ? { level: 'error', message: errors.searchText } : undefined}
        isFullWidth
      />

      <Styled.ButtonsContainer>
        {loggedInUser && Number(loggedInUser.id) > 0 && (
          <Styled.OptionToggleButton
            priority="tertiary"
            onClick={() => {
              setDisplayAdvancedFilters(!displayAdvancedFilters);
            }}
            aria-label={
              displayAdvancedFilters
                ? intl.formatMessage({
                    id: 'closeAdvancedFilters',
                    defaultMessage: 'Close advanced filters'
                  })
                : intl.formatMessage({
                    id: 'openAdvancedFilters',
                    defaultMessage: 'Open advanced filters'
                  })
            }
          >
            <>
              <FormattedMessage id="advancedOptions" defaultMessage="Advanced Options" />
              {displayAdvancedFilters ? <Styled.ArrowUp aria-hidden="true" /> : <Styled.ArrowDown aria-hidden="true" />}
            </>
          </Styled.OptionToggleButton>
        )}
        {!displayAdvancedFilters && (
          <Styled.SearchSubmitButton
            floatRight
            type="submit"
            aria-label={intl.formatMessage({
              id: 'submitSearchFilters',
              defaultMessage: 'Submit search filters'
            })}
          >
            <FormattedMessage id="search" defaultMessage="Search" />
          </Styled.SearchSubmitButton>
        )}
      </Styled.ButtonsContainer>

      <Styled.AdvancedFilters data-testid="advancedFilters" displayAdvancedFilters={displayAdvancedFilters}>
        {userFieldsList?.map(({ id, name, type, options }) => {
          const selectedFilters = userFields[id];

          if (type === 'option') {
            const optionsFiltered = options?.filter((option) => Boolean(option));
            if (!optionsFiltered || optionsFiltered.length === 0) {
              return null;
            }

            return (
              <FilterWithCheckbox
                key={id}
                id={id}
                name={name}
                options={optionsFiltered}
                selectedFilters={selectedFilters}
                handleAddFilter={handleAddUserFieldFilter}
                handleRemoveFilter={handleRemoveUserFieldFilter}
                handleSelectAll={handleSelectUserFieldFilterAll}
                clearSearch={clearSearchFilters}
                handleClearSearch={setClearSearchFilters}
              />
            );
          }

          return (
            <FilterWithInput
              key={id}
              id={id}
              name={name}
              selectedFilters={selectedFilters}
              handleAddFilter={handleAddUserFieldFilter}
              handleRemoveFilter={handleRemoveUserFieldFilter}
            />
          );
        })}

        <FilterWithCheckbox
          id="Countries"
          name={intl.formatMessage({
            id: 'Countries',
            defaultMessage: 'Countries'
          })}
          selectedFilters={countries}
          handleAddFilter={({ option }: { option: string }) => {
            const newVal = countries.includes(option) ? countries : [...countries, option];
            setFieldValue('countries', newVal);
          }}
          handleRemoveFilter={handleRemoveCountryFilter}
          handleSelectAll={() => {
            setFieldValue('countries', []);
          }}
          options={allCountries.filter((country) => Boolean(country))}
          clearSearch={clearSearchFilters}
          handleClearSearch={setClearSearchFilters}
        />

        {displayAdvancedFilters && (
          <Styled.AdvancedOptionsButtons>
            <Styled.LinkButton
              priority="tertiary"
              onClick={() => {
                resetAll();
                setDisplayAdvancedFilters(false);
                setClearSearchFilters(true);
              }}
              aria-label={intl.formatMessage({
                id: 'resetAllFilters',
                defaultMessage: 'Reset all filters'
              })}
            >
              <FormattedMessage id="resetAll" defaultMessage="Reset All" />
            </Styled.LinkButton>
            <Styled.SearchSubmitButton
              type="submit"
              aria-label={intl.formatMessage({
                id: 'submitSearchFilters',
                defaultMessage: 'Submit search filters'
              })}
            >
              <FormattedMessage id="search" defaultMessage="Search" />
            </Styled.SearchSubmitButton>
          </Styled.AdvancedOptionsButtons>
        )}
      </Styled.AdvancedFilters>
    </Styled.StyledForm>
  );
});

import { type FormikErrors, type FormikTouched, getIn, useFormikContext } from 'formik';
import { type MessageDescriptor, useIntl } from 'react-intl';
import { type IChallenge } from '../common/interfaces';
import { messages } from '../features/challenge-management/validation-schema';
import { type Milestone, type Phase } from '../common/core-api';

export enum FIELD_STATUS_LEVEL {
  ERROR = 'error',
  WARNING = 'warning'
}

export const useFieldError = () => {
  const { errors, status, touched } = useFormikContext();
  const intl = useIntl();

  const getErrorStatus = (
    path = '',
    providedErrorsMeta?: {
      errors: FormikErrors<IChallenge | Milestone | Phase>;
      touched: FormikTouched<IChallenge | Milestone | Phase>;
      status?: FormikErrors<IChallenge | Milestone | Phase>;
    },
    level = FIELD_STATUS_LEVEL.ERROR
  ) => {
    // client errors from formik context
    const touch = getIn(providedErrorsMeta?.touched, path) || getIn(touched, path);
    const error = getIn(providedErrorsMeta?.errors, path) || getIn(errors, path);
    // api errors set in formik.status
    const apiError = getIn(providedErrorsMeta?.status, path) || getIn(status, path);

    // do not return an error message when a field has nested validation errors, it will be handled in nested elements
    if (Array.isArray(error) && touch) {
      return undefined;
    }

    // id of 'error' used as fallback just in case `error` value does not have a properly constructed i18n message which can crash the app
    let message = {};
    if (error && touch) {
      message = { id: 'error', ...error };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return { message: intl.formatMessage(message, error.values), level };
    }

    if (apiError) {
      message = { id: 'error', ...messages[apiError.message] };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return { message: intl.formatMessage(message, apiError.values), level };
    }

    return undefined;
  };

  return [getErrorStatus] as const;
};

export const useFormikError = () => {
  const intl = useIntl();
  const getErrorStatus = (
    path = '',
    status: {
      errors: FormikErrors<unknown>;
      touched: FormikErrors<unknown>;
    },
    level = FIELD_STATUS_LEVEL.ERROR
  ) => {
    const touch = getIn(status?.touched, path);
    const error = getIn(status?.errors, path);

    // Custom message for custom validation
    if (error?.id && error?.defaultMessage) {
      return { message: intl.formatMessage(error as MessageDescriptor), level };
    }

    if (error && touch) {
      const message = { id: error, ...error };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return { message: intl.formatMessage(message, error.values), level };
    }

    return undefined;
  };

  return [getErrorStatus] as const;
};

import { useState } from 'react';
import styled from 'styled-components';
import { color, MenuButton, Popper, PopperBox, snap, units } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { MilestoneType } from '../../../common/core-api';
import { MilestoneForm } from '.';

const StyledButton = styled(MenuButton)`
  padding-top: ${units(0.7)};
  padding-bottom: ${units(0.7)};
  min-height: 0;
  max-height: ${units(4)};
  background-color: ${color('container')};
  border: 2px solid ${color('fill')};
`;

const MilestoneFormPopper = styled(PopperBox)`
  width: ${snap(1000)};
`;

const AddButtonLabel = styled.div`
  &:before {
    content: '— ';
  }
  &:after {
    content: ' —';
  }
`;

export const MilestoneAddButton = ({
  message,
  newMilestoneIndex
}: {
  readonly message: string;
  readonly newMilestoneIndex: number;
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [milestoneType, setMilestoneType] = useState<MilestoneType>(MilestoneType.APPROVAL);

  const handleMilestoneClick = (value = false) => {
    setIsOpen(value);
    const popupsArray = document.querySelectorAll('.milestonePopper');
    const body = document.querySelector('#fullContainer') as HTMLElement;
    if (body && value) {
      /**
       * 1000px max default height of popup
       */
      body.style.paddingBottom = '1000px';
    } else if (body && !value && popupsArray.length < 2) {
      body.style.paddingBottom = '0';
    }
  };

  const addOptions = [
    {
      label: (
        <AddButtonLabel>
          {intl.formatMessage({ id: 'addMilestoneType', defaultMessage: 'Add Milestone Type' })}
        </AddButtonLabel>
      ),
      actions: [
        {
          label: intl.formatMessage({ id: 'voteTarget', defaultMessage: 'Vote Target' }),
          onClick: () => {
            setMilestoneType(MilestoneType.VOTE);
            handleMilestoneClick(true);
          }
        },
        {
          label: intl.formatMessage({ id: 'approval', defaultMessage: 'Approval' }),
          onClick: () => {
            setMilestoneType(MilestoneType.APPROVAL);
            handleMilestoneClick(true);
          }
        },
        {
          label: intl.formatMessage({ id: 'refine', defaultMessage: 'Refine' }),
          onClick: () => {
            setMilestoneType(MilestoneType.REFINE);
            handleMilestoneClick(true);
          }
        }
      ]
    }
  ];
  return (
    <Popper
      placement="bottom"
      shouldCloseOnOutsideInteraction={false}
      isOpen={isOpen}
      trigger={
        <StyledButton priority="tertiary" actions={addOptions}>
          {message}
        </StyledButton>
      }
      overlay={
        <div className="milestonePopper">
          <MilestoneFormPopper hasArrow maxWidth={snap(1000)}>
            <MilestoneForm
              setPopperOpen={handleMilestoneClick}
              milestoneType={milestoneType}
              newMilestoneIndex={newMilestoneIndex}
            />
          </MilestoneFormPopper>
        </div>
      }
    />
  );
};

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GlobalStyles, ThemeProvider } from '@m/alchemy-ui';
import fetch from 'cross-fetch';
import { enableES5 } from 'immer';
import { ErrorBoundary } from 'react-error-boundary';
import {
  CommunityProvider,
  DialogProvider,
  IntlProvider,
  PageMetadataProvider,
  RuntimeProvider,
  UserProvider
} from '../context';
import { getCommunityTheme } from './theme';
import { type AppProps, defaultRuntimeUser } from './interfaces';
import { ErrorFallback } from './components';
import { CrowdicityGlobalStyle } from './CrowdicityGlobalStyle';

// Enable ES5 for immer / IE support
enableES5();

const createApolloClient = ({
  api,
  communityId,
  pkey,
  sessionId,
  sessionTimeout
}: Pick<AppProps, 'api' | 'pkey' | 'sessionId' | 'sessionTimeout'> & { communityId: AppProps['community']['id'] }) => {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      'x-crowdicity-pkey': pkey,
      'x-crowdicity-community-id': communityId,
      'x-crowdicity-session-id': sessionId,
      'x-crowdicity-session-timeout': sessionTimeout
    }
  }));
  const GRAPHQL_URI = '/graphql';
  return new ApolloClient({
    uri: GRAPHQL_URI,
    cache: new InMemoryCache({
      typePolicies: {
        User: {
          fields: {
            socialLinks: {
              merge: false
            }
          }
        }
      }
    }),
    link: authLink.concat(
      createHttpLink({
        fetch,
        uri: api
      })
    )
  });
};

export const Wrapper = (props: AppProps) => {
  const api = props.api || 'http://localhost:3000/graphql';
  const apiV2Url = String(props.apiV2Url);
  const communityId = String(props.community.id) || '0';
  const sessionId = String(props.sessionId) || '0';
  const sessionTimeout = String(props.sessionTimeout) || '0';
  const cloudinaryUrl = String(props.cloudinaryUrl);
  const mirp = {
    enabled: Boolean(props.mirp.enabled),
    url: String(props.mirp.url),
    region: String(props.mirp.region)
  };
  const locale = props.locale || 'en-GB';
  const user = props?.user || defaultRuntimeUser;
  const pkey = String(props.pkey);
  const idp = {
    region_idp_url: props?.idp?.region_idp_url || '',
    sp: {
      salesforce: {
        entityid: props?.idp?.sp?.salesforce?.entityid || ''
      }
    }
  };

  const handleError = (error: Error) => {
    // Do something with the error
    // E.g. log to an error logging client here
    console.log(error);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      <RuntimeProvider
        value={{
          ...props,
          api,
          apiV2Url,
          cloudinaryUrl,
          mirp: { ...mirp },
          pkey,
          sessionId,
          sessionTimeout,
          user,
          idp
        }}
      >
        <ApolloProvider
          client={createApolloClient({
            api,
            communityId,
            pkey,
            sessionId,
            sessionTimeout
          })}
        >
          <UserProvider>
            <PageMetadataProvider>
              <CommunityProvider id={communityId}>
                <ThemeProvider
                  theme={getCommunityTheme({
                    communityColors: props.community.colors
                  })}
                >
                  <IntlProvider locale={locale}>
                    <DialogProvider>
                      <GlobalStyles globalCss={CrowdicityGlobalStyle} />
                      {props.children}
                    </DialogProvider>
                  </IntlProvider>
                </ThemeProvider>
              </CommunityProvider>
            </PageMetadataProvider>
          </UserProvider>
        </ApolloProvider>
      </RuntimeProvider>
    </ErrorBoundary>
  );
};

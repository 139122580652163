import styled from 'styled-components';
import { atMinWidth, borderRadius, boxShadow, Button, color, Dialog, DialogBody, snap, units } from '@m/alchemy-ui';
import { Breakpoints } from '../../common/enums';

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${atMinWidth(
    Breakpoints.TABLET_MIN,
    `
    flex-direction: row;
    `
  )};
`;

export const ProfileSidebar = styled.div`
  background: ${color('canvas')};
  border-radius: ${borderRadius('extraExtraLarge')};
  box-shadow: ${boxShadow('moduleChrome')};
  height: 0%; // fixes flexbox infinite height bug
  margin-bottom: ${units(3)};
  width: 100%;

  ${atMinWidth(
    Breakpoints.TABLET_MIN,
    `
    min-width: ${snap(250)};
    max-width: 35%;
    `
  )};
`;

export const BottomSection = styled.div`
  background-color: ${color('container')};
  border-radius: 0 0 ${borderRadius('extraExtraLarge')} ${borderRadius('extraExtraLarge')};
  padding: ${units(1)};
  display: flex;
  flex-direction: column;
`;

export const TabContentWrapper = styled.div`
  width: 100%;

  ${atMinWidth(
    Breakpoints.TABLET_MIN,
    `
    max-width: 65%;
    `
  )};
`;

export const ProfileDialog = styled(Dialog)`
  .mdl-form__button-row {
    background: rgba(255, 255, 255, 1);
  }
`;

export const ProfileDialogBody = styled(DialogBody)`
  overflow-x: hidden;
  margin: 0;

  .mdl-grid__col {
    margin: 0;
  }
`;

export const UserInfo = styled.div`
  padding: ${units(1)};
`;

export const UserInfoButton = styled(Button)`
  align-self: center;
  padding: ${units(0.5)} ${units(2)};
`;

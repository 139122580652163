import React, { useContext, useEffect, useState } from 'react';
import { UserGradientIcon } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { type User } from '../../../common/core-api';
import { useFile, useMainModal } from '../../../hooks';
import { type FollowMutation, type UnfollowMutation, USER_FOLLOW, USER_UNFOLLOW } from '../../../graphql/mutations';
import { type CheckFollowingQuery, GET_FOLLOWING } from '../../../graphql/queries';
import { UserContext } from '../../../context';
import { type ToastMessageType } from '../../../common/enums';
import { getConfirmToastMessage, getErrorToastMessage } from '../../../helpers';
import * as Styled from './Usercard.styled';

export const Usercard = React.forwardRef(function Usercard(
  { user }: { readonly user: User },
  ref: React.Ref<HTMLAnchorElement>
) {
  const intl = useIntl();
  const [getFile] = useFile();
  const [openModal] = useMainModal();

  const [followUser, { loading: userFollowLoading }] = useMutation<FollowMutation>(USER_FOLLOW);
  const [unfollowUser, { loading: userUnfollowLoading }] = useMutation<UnfollowMutation>(USER_UNFOLLOW);
  const [isFollowing, setIsFollowing] = useState(false);
  const [messages, setMessages] = useState<ToastMessageType[]>();
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  useEffect(() => {
    const getAuthUrl = async () => {
      const url = user?.profileImageFile && (await getFile({ file: user.profileImageFile, width: 100, height: 100 }));
      setProfilePictureUrl(url || '');
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.profileImageFile]);

  const { id, town, country, displayName } = user;

  const currentUser = useContext(UserContext);
  const isSelf = currentUser.id === id;

  const { data } = useQuery<CheckFollowingQuery>(GET_FOLLOWING, {
    variables: {
      userId: currentUser.id,
      targetUser: id
    },
    skip: isSelf
  });

  useEffect(() => {
    if (data?.checkFollowing) {
      setIsFollowing(data?.checkFollowing?.success);
    }
  }, [data]);

  const toggleFollow = async () => {
    const params = {
      variables: {
        targetUser: id
      }
    };

    if (isFollowing) {
      const result = await unfollowUser(params);
      if (result.data?.unfollowUser.success) {
        setMessages(
          getConfirmToastMessage(intl.formatMessage({ id: 'userUnfollowed', defaultMessage: 'User unfollowed' }))
        );
        setIsFollowing(!isFollowing);
      } else {
        setMessages(
          getErrorToastMessage(intl.formatMessage({ id: 'userUnfollowFailed', defaultMessage: 'User unfollow failed' }))
        );
      }
    } else {
      const result = await followUser(params);
      if (result.data?.followUser.success) {
        setMessages(
          getConfirmToastMessage(intl.formatMessage({ id: 'userFollowed', defaultMessage: 'User followed' }))
        );
        setIsFollowing(!isFollowing);
      } else {
        setMessages(
          getErrorToastMessage(intl.formatMessage({ id: 'userFollowFailed', defaultMessage: 'User follow failed' }))
        );
      }
    }
  };

  return (
    <Styled.UserCard data-testid="UserCard">
      {profilePictureUrl ? (
        <Styled.ProfileImage data-testid="UserProfileImage" src={profilePictureUrl} aria-hidden="true" />
      ) : (
        <UserGradientIcon aria-hidden="true" />
      )}
      <Styled.UserInfo>
        <div>
          <Styled.UserName
            ref={ref}
            href={`/user/activity/${id}`}
            onClick={(e) =>
              openModal(e, {
                title: intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' }),
                url: `/user/view/${id}`
              })
            }
          >
            {displayName}
          </Styled.UserName>
          <Styled.UserTown>{(town ? town + ', ' : '') + (country ? country : '')}</Styled.UserTown>
        </div>
        {!isSelf && (
          <Styled.FollowButton
            priority="secondary"
            size="small"
            onClick={toggleFollow}
            isLoading={userFollowLoading || userUnfollowLoading}
          >
            {isFollowing ? (
              <FormattedMessage id="unfollow" defaultMessage="Unfollow" />
            ) : (
              <FormattedMessage id="follow" defaultMessage="Follow" />
            )}
          </Styled.FollowButton>
        )}
      </Styled.UserInfo>
      <Styled.Toast messages={messages} />
    </Styled.UserCard>
  );
});

import styled, { css } from 'styled-components';
import { Alert, atMinWidth, borderRadius, color, typeStyle, units } from '@m/alchemy-ui';
import { Breakpoints } from '../common/enums';

export const OutletWrapper = styled.main<{ isSticky?: boolean }>`
  background: ${color('container')};

  /*56px = the height of the header */
  ${(props) =>
    props.isSticky
      ? css`
          margin: 56px auto 0;
        `
      : css`
          margin: auto auto 0;
        `}
  padding: ${units(1.5)};
  max-width: 1024px;

  ${atMinWidth(
    Breakpoints.WIDE_MOBILE_MIN,
    `
      padding: 22px;
    `
  )};

  ${atMinWidth(
    Breakpoints.DESKTOP_MIN,
    `
      body.mdl-wrapper__body.moderator & {
        margin: 56px auto 0;
      }
    `
  )};

  ${atMinWidth(
    Breakpoints.MONITOR_MIN,
    `
      margin-top: 52px;

      body.mdl-wrapper__body.moderator & {
        /* Height of the navbar */
        margin: 52px auto 0;
      }
    `
  )};

  /* Styles for idea details page, need to be added here
  to support both reflow feature flag on and off */
  .ideaDetails {
    padding: 0;
  }
  .mdl-moderator-tools-container {
    margin-bottom: ${units(2)};
  }

  /* Styles for blog page, need to be added here
  to support both reflow feature flag on and off */
  .mdl-blog {
    &__header {
      padding-left: 0;
      padding-right: 0;
    }
    &__sidebar {
      width: 100% !important;
      margin-left: 0 !important;

      ${atMinWidth(
        Breakpoints.TABLET_MIN,
        `
          width: 25% !important;
        `
      )};
    }
    &__content {
      padding: 0 !important;
    }
    &__content__box__title-box {
      margin-bottom: ${units(1.5)};
    }
  }
`;

export const PostPageWrapper = styled.div`
  margin: ${units(4)} auto;

  ${atMinWidth(
    Breakpoints.TABLET_MIN,
    `
      margin: ${units(3)} auto;
    `
  )};
`;

export const PageTitle = styled.h1`
  ${typeStyle('headerXL')}
  text-align: center;
  padding: ${units(3)};
`;

export const BannerWrapper = styled.div<{ bannerHeight?: number | string }>`
  position: relative;
  overflow: hidden;
  margin-bottom: ${units(2)};
  img {
    border-radius: ${borderRadius('large')};
  }
`;

export const BannerTextWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  color: white;
  padding: ${units(0.5)} ${units(1)};
  ${atMinWidth(Breakpoints.TABLET_MIN, `padding: ${units(3)} ${units(4)}`)}
  & p:first-child {
    ${typeStyle('headerM')}
    ${atMinWidth(Breakpoints.MONITOR_MIN, typeStyle('headerXL'))}
    font-weight: bold;
  }
`;

export const AnnouncementWrapper = styled.div`
  padding-bottom: ${units(1)};
  & a {
    padding-left: ${units(1)};
  }
`;

export const WarningAlert = styled(Alert)`
  div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-weight: 400;
    justify-content: start;
    row-gap: ${units(2)};

    a {
      text-decoration: underline;
    }
  }

  margin: ${units(2)} 0;
`;

import { NotificationReasonType, NotificationType } from '../../../common/core-api';
import { CommentNotificationTypes } from '../../../common/enums';

export const getCommentCardType = ({
  isSingle,
  notificationReason,
  sameChallenge,
  sameIdea,
  sameUser,
  notificationType
}: {
  isSingle: boolean;
  notificationReason: NotificationReasonType;
  sameChallenge: boolean;
  sameIdea: boolean;
  sameUser: boolean;
  notificationType: NotificationType;
}) => {
  const blogNotification =
    notificationReason === NotificationReasonType.FOLLOWED_BLOG ||
    notificationReason === NotificationReasonType.FOLLOWED_BLOG_POST;

  if (isSingle && notificationReason === NotificationReasonType.FOLLOWED_IDEA) {
    if (notificationType === NotificationType.BLOG_POST_COMMENT) {
      return CommentNotificationTypes.REASON_BLOG_SINGLE;
    }

    return CommentNotificationTypes.REASON_IDEA_SINGLE;
  }

  if (isSingle && blogNotification) {
    return CommentNotificationTypes.REASON_BLOG_SINGLE;
  }

  if (notificationReason === NotificationReasonType.FOLLOWED_IDEA && sameIdea) {
    if (notificationType === NotificationType.BLOG_POST_COMMENT) {
      if (sameUser) {
        return CommentNotificationTypes.REASON_BLOG_SAME_USER;
      }

      return CommentNotificationTypes.REASON_BLOG_DIFFERENT_USER;
    }

    return CommentNotificationTypes.REASON_IDEA_SAME_IDEA;
  }

  if (notificationReason === NotificationReasonType.FOLLOWED_IDEA && sameUser) {
    return CommentNotificationTypes.REASON_IDEA_SAME_USER;
  }

  if (blogNotification && sameUser) {
    return CommentNotificationTypes.REASON_BLOG_SAME_USER;
  }

  if (isSingle && notificationReason === NotificationReasonType.FOLLOWED_USER) {
    if (notificationType === NotificationType.BLOG_POST_COMMENT) {
      return CommentNotificationTypes.REASON_BLOG_SINGLE;
    }

    return CommentNotificationTypes.REASON_USER_SINGLE;
  }

  if (notificationReason === NotificationReasonType.FOLLOWED_USER && sameChallenge) {
    if (notificationType === NotificationType.BLOG_POST_COMMENT) {
      return CommentNotificationTypes.REASON_BLOG_SAME_USER;
    }

    if (sameUser) {
      return CommentNotificationTypes.REASON_USER_SAME_USER;
    }

    return CommentNotificationTypes.REASON_USER_SAME_CHALLENGE;
  }

  if (notificationReason === NotificationReasonType.FOLLOWED_USER && !sameChallenge) {
    return CommentNotificationTypes.REASON_USER_DIFFERENT_CHALLENGES;
  }

  if (isSingle && notificationReason === NotificationReasonType.FOLLOWED_CHALLENGE) {
    return CommentNotificationTypes.REASON_CHALLENGE_SINGLE;
  }

  if (notificationReason === NotificationReasonType.FOLLOWED_CHALLENGE && sameChallenge) {
    return CommentNotificationTypes.REASON_CHALLENGE_SAME_CHALLENGE;
  }

  if (notificationReason === NotificationReasonType.USER_TAGGED_IN_COMMENT) {
    return CommentNotificationTypes.REASON_TAG;
  }

  return undefined;
};

enum ModeratorAction {
  IDEA_MOVED = 1,
  STATUS_LABEL_CHANGED = 2,
  MODERATION_STATUS_CHANGED = 3,
  MILESTONE_REACHED_VOTE = 4,
  MILESTONE_REACHED_REFINEMENT = 5,
  MILESTONE_REACHED_APPROVAL = 6,
  MILESTONE_COMPLETED_ALL = 7,
  MILESTONE_SENT_BACK_TO_PREVIOUS = 8,
  PHASE_REVERTED_TO_PREVIOUS = 9,
  PHASE_REVERTED_TO_CURRENT_FROM_NEXT = 10,
  PHASE_ADVANCED_TO_CURRENT_FROM_PREVIOUS = 11,
  PHASE_ADVANCED_TO_NEXT = 12,
  MILESTONE_IDEA_REJECTED = 13
}

export default ModeratorAction;

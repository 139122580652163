import { useContext, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, ErrorMessage, LoadingIndicator, Select } from '@m/alchemy-ui';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useIntl } from 'react-intl';
import Exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import {
  type ChallengesQuery,
  type ChallengeWithFiltersQuery,
  type CoauthorIdeasQuery,
  type FilteredUsersQuery,
  GET_CHALLENGE_WITH_FILTERS,
  GET_CHALLENGES,
  GET_COAUTHOR_IDEAS,
  GET_COUNTRIES,
  GET_IDEAS_AND_COUNT,
  GET_USERFIELDS_FILTERED_USERIDS,
  type GetCountriesQuery,
  type GetIdeasAndCountQuery
} from '../../graphql/queries';
import { useUserFields } from '../../hooks';
import { CommunityContext } from '../../context';
import { ChallengeType } from '../../common/enums';
import { type IdeasAndCount } from '../../common/core-api';
import { Grid, GridCol, GridHeader, GridRow, Pagination } from './Analytics.styled';
import * as Styled from './components/SearchFilters.styled';
import { downloadIcon, ideasByMilestoneChartOptions } from './HighchartsData';
import { CustomFilters } from './CustomFilters';
import { type UserFieldType } from './types';
import { IdeasTable } from './components';
import { EXPORT_TO_CSV_ENDPOINT, IDEAS_PER_PAGE } from './constants';
import { ChallengeSelectionType } from './enums';

Exporting(Highcharts);
NoDataToDisplay(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.download = downloadIcon;

export const IdeasByMilestoneStats = () => {
  const intl = useIntl();
  const community = useContext(CommunityContext);
  const { data: communityUserFields } = useUserFields();
  const [userFieldOptions, setUserFieldOptions] = useState<UserFieldType>({});
  const [userFields, setUserFields] = useState<string>('{}');
  const [challenge, setChallenge] = useState<string>(ChallengeSelectionType.SELECT);
  const [milestone, setMilestone] = useState<number>();
  const [sort, setSort] = useState<string[][]>([['createdAt', 'DESC']]);
  const [offset, setOffset] = useState<number>(0);
  const [chartData, setChartData] = useState<ChallengeWithFiltersQuery>();
  const [ideasAndCount, setIdeasAndCount] = useState<IdeasAndCount | undefined>();

  const { data: countriesList } = useQuery<GetCountriesQuery>(GET_COUNTRIES, {
    variables: { id: community.id },
    fetchPolicy: 'cache-first'
  });

  useQuery<GetIdeasAndCountQuery>(GET_IDEAS_AND_COUNT, {
    variables: {
      challengeSelection: challenge,
      filters: { userFields },
      milestoneSelection: milestone,
      order: sort,
      offset: offset * 10
    },
    skip: challenge === ChallengeSelectionType.SELECT || milestone === undefined,
    onCompleted: (response) => {
      setIdeasAndCount(response?.ideasAndCount as IdeasAndCount);
    }
  });

  const { data: customFiltersUserIds } = useQuery<FilteredUsersQuery>(GET_USERFIELDS_FILTERED_USERIDS, {
    variables: { filters: { userFields } }
  });

  const { data: coauthorIdeaIds } = useQuery<CoauthorIdeasQuery>(GET_COAUTHOR_IDEAS, {
    variables: { customFiltersUserIds: customFiltersUserIds?.filteredUsers, filters: { userFields } }
  });

  const { loading: loadingChart } = useQuery<ChallengeWithFiltersQuery>(GET_CHALLENGE_WITH_FILTERS, {
    variables: {
      id: challenge,
      filters: { userFields }
    },
    onCompleted: (response) => {
      setChartData(response);
    }
  });

  // Used for getting the challenges dropdown
  const { loading: loadingMilestoneChallenges, data: milestoneChallengeList } = useQuery<ChallengesQuery>(
    GET_CHALLENGES,
    {
      variables: { order: ['title'], types: [ChallengeType.MILESTONE] },
      fetchPolicy: 'cache-first'
    }
  );

  const baseChartOptions = useMemo(() => ideasByMilestoneChartOptions(intl), [intl]);

  const ideasSort = (column: string) => {
    const [sortColumn, sortDirection] = sort[0];
    const newOrder = [[column, column === sortColumn && sortDirection === 'ASC' ? 'DESC' : 'ASC']];
    setSort(newOrder);
  };

  const getColumnSortDirection = (column: string) => {
    const [sortColumn, sortDirection] = sort[0];
    return sortColumn === column ? (sortDirection === 'DESC' ? 'descending' : 'ascending') : 'none';
  };

  const highchartsOptions = useMemo(() => {
    const milestoneArray = chartData?.challenge?.milestone?.milestones || [];
    const rejectedIdeaIds = chartData?.challenge?.milestone?.milestoneRejectedIdeaIds || [];

    let rejectedIdeasCount = 0;
    milestoneArray.forEach((milestone) => {
      rejectedIdeasCount += milestone?.ideas?.filter((idea) => rejectedIdeaIds.includes(idea.id))?.length || 0;
    });

    const ideaList = milestoneArray?.map((milestone) => ({
      y: milestone?.ideas?.filter((idea) => !rejectedIdeaIds.includes(idea.id)).length || 0
    }));

    const nameList = milestoneArray ? [...milestoneArray.map((milestone) => milestone.name), 'Rejected'] : [];
    const idList = milestoneArray ? [...milestoneArray.map((milestone) => milestone.id), '0'] : [];

    ideaList?.push({
      y: rejectedIdeasCount,
      // Highcharts doesn't have this typed
      // @ts-expect-error
      color: 'red'
    });

    return {
      ...baseChartOptions,

      series: [
        {
          name: intl.formatMessage({ id: 'ideas', defaultMessage: 'Ideas' }),
          data: ideaList
        }
      ],
      xAxis: [
        {
          title: {
            text: intl.formatMessage({ id: 'milestone', defaultMessage: 'Milestone' })
          },
          categories: nameList
        },
        {
          linkedTo: 0,
          categories: idList,
          labels: {
            enabled: false
          },
          visible: false
        }
      ],
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click(this, eventObj: Highcharts.PointClickEventObject) {
                const milestoneIds = eventObj.point.series.chart.xAxis[1].categories;
                const currentMilestoneIndex = eventObj.point.x;
                const currentMilestoneId = Number(milestoneIds[currentMilestoneIndex]);

                setOffset(0);
                setMilestone(currentMilestoneId);
              }
            }
          }
        }
      }
    };
  }, [intl, setMilestone, chartData, baseChartOptions, setOffset]);

  const exportData = () => {
    const exportUrl = new URL(EXPORT_TO_CSV_ENDPOINT + (challenge || '0').toString(), `https://${community.url}`);
    exportUrl.searchParams.append('includeUnpublished', '0');
    exportUrl.searchParams.append('includeComments', '0');
    milestone && exportUrl.searchParams.append('forMilestone', milestone.toString());
    milestone === 0 && exportUrl.searchParams.append('additionalFilters[rejected]', '1');

    if (Object.keys(userFieldOptions).length > 0) {
      exportUrl.searchParams.append('customFiltersUserIds', (customFiltersUserIds?.filteredUsers || []).toString());
      exportUrl.searchParams.append('ideaIds', (coauthorIdeaIds?.coauthorIdeas || []).toString());
    }

    window.location = exportUrl as unknown as Location;
  };

  if ((loadingMilestoneChallenges && !milestoneChallengeList) || (loadingChart && !chartData)) {
    return (
      <Styled.LoadingContainer>
        <LoadingIndicator inline />
      </Styled.LoadingContainer>
    );
  }

  return (
    <Grid key="ideasByMilestoneStats">
      <CustomFilters
        userFieldOptions={userFieldOptions}
        communityUserFields={communityUserFields}
        countries={countriesList?.community?.countries || []}
        userFieldUpdate={setUserFieldOptions}
      />
      <GridHeader key="challengeSelectionHeader">
        {intl.formatMessage({ id: 'show', defaultMessage: 'Show' })}
      </GridHeader>
      <GridRow key="challengeSelection">
        <Select
          defaultValue={challenge}
          options={[
            {
              label: intl.formatMessage({ id: 'selectAChallenge', defaultMessage: 'Select a challenge' }),
              value: ChallengeSelectionType.SELECT
            },
            ...((milestoneChallengeList?.challenges || [])?.map((c) => ({
              label: c.title,
              value: c.id
            })) || {})
          ]}
          onChange={(challengeSelection) => {
            setMilestone(undefined);
            setChallenge(challengeSelection.value);
            setUserFields(JSON.stringify(userFieldOptions));
            setIdeasAndCount(undefined);
          }}
        />
      </GridRow>
      <GridRow key="updateButton">
        <Button
          priority="secondary"
          onClick={() => {
            setMilestone(undefined);
            setUserFields(JSON.stringify(userFieldOptions));
            setIdeasAndCount(undefined);
          }}
          disabled={challenge === ChallengeSelectionType.SELECT}
        >
          {intl.formatMessage({ id: 'update', defaultMessage: 'Update' })}
        </Button>
      </GridRow>
      <GridRow key="chart">
        {challenge !== ChallengeSelectionType.SELECT && chartData && (
          <HighchartsReact highcharts={Highcharts} options={highchartsOptions} />
        )}
      </GridRow>
      {challenge !== ChallengeSelectionType.SELECT && ideasAndCount && ideasAndCount.count > 0 ? (
        <>
          <GridRow key="table" justify="center">
            <GridCol>
              <IdeasTable
                getColumnSortDirection={getColumnSortDirection}
                ideasAndCount={ideasAndCount || { count: 0, rows: [] }}
                intl={intl}
                sortIdeas={ideasSort}
              />
            </GridCol>
            <GridCol>
              <Pagination
                numPages={ideasAndCount?.count && Math.ceil(ideasAndCount.count / IDEAS_PER_PAGE)}
                activeIndex={offset}
                onPageClick={(page) => {
                  setOffset(page.index as number);
                }}
              />
            </GridCol>
          </GridRow>
          <GridRow key="exportData" justify="start">
            <GridCol>
              <Button priority="secondary" onClick={exportData}>
                {intl.formatMessage({ id: 'exportData', defaultMessage: 'Export Data' })}
              </Button>
            </GridCol>
          </GridRow>
        </>
      ) : (
        chartData?.challenge &&
        ideasAndCount?.count === 0 && (
          <ErrorMessage
            message={intl.formatMessage({ id: 'noIdeasWereFound', defaultMessage: 'No ideas were found' })}
            details={intl.formatMessage({
              id: 'pleaseAdjustYourSearch',
              defaultMessage: 'Please adjust your search parameters and try again.'
            })}
          />
        )
      )}
    </Grid>
  );
};

import { NotificationReasonType, NotificationType } from '../../../common/core-api';
import { NotificationCardTypes } from '../../../common/enums';

export const getCardType = (type: NotificationType, reason: NotificationReasonType) => {
  switch (type) {
    case NotificationType.CHALLENGE_CHECK_OUT:
    case NotificationType.CHALLENGE_ENTERED_PHASE:
    case NotificationType.CHALLENGE_NEW:
      return NotificationCardTypes.CHALLENGE;
    case NotificationType.IDEA_COMMENT:
    case NotificationType.COMMENT_NEW:
    case NotificationType.COMMENT_REPLY:
      return NotificationCardTypes.COMMENT;
    case NotificationType.IDEA_CHECK_OUT:
    case NotificationType.IDEA_FOLLOW:
    case NotificationType.IDEA_NEW:
    case NotificationType.IDEA_VOTE:
    case NotificationType.IDEA_PHASE_REACHED:
    case NotificationType.IDEA_MILESTONE_REACHED:
      return NotificationCardTypes.IDEA;
    case NotificationType.IDEA_TAG:
      return reason === NotificationReasonType.USER_TAGGED_IN_IDEA
        ? NotificationCardTypes.IDEA
        : NotificationCardTypes.COMMENT;
    case NotificationType.BLOG_POST_COMMENT:
      return NotificationCardTypes.BLOG_COMMENT;
    case NotificationType.BLOG_POST_NEW:
      return NotificationCardTypes.BLOG_POST;
    default:
      return undefined;
  }
};

import styled from 'styled-components';
import { borderRadius, color, Dialog, DialogBody, DialogHeader, LoadingIndicator, snap } from '@m/alchemy-ui';
import { FormattedMessage } from 'react-intl';

const DialogContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  > * {
    padding-bottom: 0;
  }
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  height: ${snap(100)};
  background: ${color('container')};
  border-radius: ${borderRadius('extraLarge')};
`;

export const ProfilePreview = ({
  isDialogOpen,
  closeDialog,
  isDialogLoading,
  dialogData
}: {
  readonly isDialogOpen: boolean;
  readonly closeDialog: () => void;
  readonly isDialogLoading: boolean;
  readonly dialogData: string;
}) => (
  <Dialog isCentered hasCloseIcon isOpen={isDialogOpen} onClose={closeDialog} size="large">
    {isDialogLoading ? (
      <StyledLoadingIndicator />
    ) : (
      <DialogContent>
        <DialogHeader>
          <FormattedMessage id="profile" defaultMessage="Profile" />
        </DialogHeader>
        <DialogBody dangerouslySetInnerHTML={{ __html: dialogData }} />
      </DialogContent>
    )}
  </Dialog>
);

import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { type Challenge, type Maybe } from '../../../common/core-api';
import { useFile } from '../../../hooks';
import { removeTags } from '../../../helpers';
import { NotificationCardTypes } from '../../../common/enums';
import { RelativeDateWithTooltip, ResponsiveImage, Tooltip } from '../../../common/components';
import { NOTIF_STREAM_DEFAULT_IMAGE_HEIGHT } from '../../shared-constants';
import * as Styled from './ChallengeContent.styled';
import { Footer } from '.';

export const ChallengeContent = ({
  challenge,
  time,
  isValid
}: {
  readonly challenge?: Maybe<Challenge>;
  readonly time?: string;
  readonly isValid: boolean;
}) => {
  const [getFile] = useFile();
  const intl = useIntl();

  const { id, files, title, description } = challenge || {};
  const challengeImage = files?.list;

  const [challengeImageUrl, setChallengeImageUrl] = useState('');
  const [width, setWidth] = useState(0);
  useEffect(() => {
    const getAuthUrl = async () => {
      let url = '';

      if (challengeImage) {
        url = await getFile({ file: challengeImage, width, height: NOTIF_STREAM_DEFAULT_IMAGE_HEIGHT });
      }

      setChallengeImageUrl(url || '');
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengeImage, width]);

  const imageResizeCallback = (width: number) => {
    if (!challengeImage) {
      return '';
    }

    setWidth(width);
    return challengeImageUrl;
  };

  if (!challenge) {
    return null;
  }

  const message = intl.formatMessage({
    id: 'youNoLongerHaveAccessToThisItem',
    defaultMessage: 'You no longer have access to this item'
  });

  const clearTitle = removeTags(title || '');

  return (
    <Styled.ContentContainer>
      <Styled.ChallengeHeader>
        {isValid ? (
          <Styled.ChallengeTitle href={`/category/${id}`} target="_blank" rel="noreferrer">
            {clearTitle}
          </Styled.ChallengeTitle>
        ) : (
          <Tooltip content={message}>
            <Styled.ChallengeTitleGreyout target="_blank" rel="noreferrer">
              {clearTitle}
            </Styled.ChallengeTitleGreyout>
          </Tooltip>
        )}
        {time && <RelativeDateWithTooltip time={time} />}
      </Styled.ChallengeHeader>
      <Styled.ChallengeDescription lines={3} maxWidth="100%">
        {removeTags(description || '')}
      </Styled.ChallengeDescription>
      {challengeImage && (
        <Styled.ChallengeImageWrapper>
          <ResponsiveImage callback={imageResizeCallback} altText={title || ''} />
        </Styled.ChallengeImageWrapper>
      )}
      {isValid && <Footer challenge={challenge} type={NotificationCardTypes.CHALLENGE} />}
    </Styled.ContentContainer>
  );
};

// This not recommended because of security reasons, but it's a quick fix for the issue.
/* eslint-disable react/iframe-missing-sandbox */
import { type ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { LoadingIndicator } from '@m/alchemy-ui';
import { useHasChanged, useInterval } from '../../../hooks';
import * as Styled from './TabContent.styled';

const DEFAULT_LOADING_HEIGHT = 710;
const HEIGHT_CHECKER_INTERVAL = 150;
const RENDER_COMPONENT_PATHS = ['user/settings', 'user/ideastream'];

export const TabContent = ({ url, component = null }: { readonly url: string; readonly component?: ReactNode }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [iframeHeight, setIframeHeight] = useState<string>('100%');
  const newUrl = useHasChanged(url);

  useEffect(() => {
    if (newUrl) {
      setIsLoading(true);
      setIframeHeight(`${DEFAULT_LOADING_HEIGHT}px`);
    }
  }, [newUrl]);

  const handleSetScrollHeight = () => {
    if (isLoading || !iframeRef?.current) {
      return;
    }

    const iframeBody = iframeRef.current?.contentWindow?.document?.body;
    const popUpHeight = (iframeBody?.querySelector('#GB_popup') && 800) || 0;
    const currentIframe = iframeBody?.scrollHeight || 0;
    const height = Math.max(popUpHeight, currentIframe, DEFAULT_LOADING_HEIGHT);
    const iframeHeight = `${height}px`;

    setIframeHeight(iframeHeight);
    iframeRef.current.height = iframeHeight;
  };

  const handleOnLoad = () => {
    setIsLoading(false);
    handleSetScrollHeight();
  };

  useInterval(handleSetScrollHeight, HEIGHT_CHECKER_INTERVAL);

  const urlParams = new URLSearchParams(document.location.search.slice(1));
  // Only render the <Component /> if path is in RENDER_COMPONENT_PATHS or ns querystring flag is present
  // Allows <Settings /> to launch before <NotificationStream />
  if (RENDER_COMPONENT_PATHS.includes(url.toLowerCase()) && component) {
    return <>{component}</>;
  }

  const contentUrl = `${url}?isProfileUi=true${urlParams.get('goTab') ? `&goTab=${urlParams.get('goTab')}` : ''}`;

  return (
    <Styled.Wrapper height={iframeHeight}>
      <iframe
        title="Profile Tab"
        src={contentUrl}
        data-testid="iframe"
        onLoad={handleOnLoad}
        ref={iframeRef}
        sandbox="allow-scripts allow-same-origin allow-top-navigation"
      />
      {isLoading && (
        <Styled.LoadingCover>
          <LoadingIndicator />
        </Styled.LoadingCover>
      )}
    </Styled.Wrapper>
  );
};

import {
  DeleteIcon,
  Dialog,
  FacebookLogo,
  GoogleLogo,
  LinkedInLogo,
  LoadingIndicator,
  MedalliaLogo,
  Toast,
  TwitterLogo
} from '@m/alchemy-ui';
import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { Container, DeleteIconButton, SectionTitle, SubTitle, Title } from '../../../../common/components';
import { UserContext } from '../../../../context';
import { AccountProviderType } from '../../../../common/core-api';
import { DELETE_LINKED_ACCOUNT } from '../../../../graphql/queries';
import { type ToastMessageType } from '../../../../common/enums';
import { getConfirmToastMessage, getErrorToastMessage } from '../../../../helpers';
import * as Styled from './Accounts.styled';

const Provider = ({ provider }: { readonly provider: AccountProviderType }) => {
  const social = {
    [AccountProviderType.FACEBOOK]: {
      icon: <FacebookLogo aria-hidden="true" />,
      name: 'Facebook'
    },
    [AccountProviderType.TWITTER]: {
      icon: <TwitterLogo aria-hidden="true" />,
      name: 'Twitter'
    },
    [AccountProviderType.LINKEDIN]: {
      icon: <LinkedInLogo aria-hidden="true" />,
      name: 'LinkedIn'
    },
    [AccountProviderType.GOOGLE]: {
      icon: <GoogleLogo aria-hidden="true" />,
      name: 'Google'
    },
    [AccountProviderType.GOOGLEP]: {
      icon: <GoogleLogo aria-hidden="true" />,
      name: 'Google+'
    },
    [AccountProviderType.SAML2]: {
      icon: null,
      name: 'SAML2'
    },
    [AccountProviderType.YAMMER]: {
      icon: null,
      name: 'Yammer'
    },
    [AccountProviderType.AZURE]: {
      icon: null,
      name: 'Azure'
    },
    [AccountProviderType.MEC]: {
      icon: <MedalliaLogo aria-hidden="true" />,
      name: 'MEC'
    },
    [AccountProviderType.SSOPROXY]: {
      icon: null,
      name: 'SSOPROXY'
    },
    [AccountProviderType.SLACK]: {
      icon: null,
      name: 'Slack'
    },
    [AccountProviderType.TEAMS]: {
      icon: null,
      name: 'Microsoft Teams'
    }
  };
  return (
    <>
      {social[provider].icon || null}
      <Styled.ProviderName>{social[provider].name || provider.toLowerCase()}</Styled.ProviderName>
    </>
  );
};

export const Accounts = () => {
  const intl = useIntl();
  const currentUser = useContext(UserContext);
  const [deleteAccount] = useMutation(DELETE_LINKED_ACCOUNT);
  const [deleteConfirmId, setDeleteConfirmId] = useState<string | null>(null);
  const [messages, setMessages] = useState<ToastMessageType[]>([]);

  const handleDelete = async (id: string) => {
    setDeleteConfirmId(null);
    try {
      const saveResult = await deleteAccount({
        variables: { id },
        update(cache) {
          cache.evict({ id: `UserAccount:${id}` });
        }
      });

      if (saveResult.data?.deleteUserAccount.success) {
        setMessages(
          getConfirmToastMessage(intl.formatMessage({ id: 'deleteSuccess', defaultMessage: 'Delete successful' }))
        );
      }
    } catch (e) {
      setMessages(getErrorToastMessage(intl.formatMessage({ id: 'deleteFail', defaultMessage: 'Delete failed' })));
    }
  };

  if (currentUser.loading) {
    return <LoadingIndicator />;
  }

  return (
    <Container>
      <Title>
        <FormattedMessage id="linkedAccounts" defaultMessage="Linked accounts" />
      </Title>
      {currentUser.accounts?.length === 0 && (
        <p>
          <FormattedMessage
            id="noLinkedAccounts"
            defaultMessage="You have no accounts linked to your Medallia Ideas account."
          />
        </p>
      )}

      {(currentUser.accounts || []).length > 0 &&
        currentUser.accounts?.map((account) => (
          <Styled.AccountRow key={account.id}>
            <Provider provider={account.provider} />
            {account.url ? (
              <a href={account.url} target="_blank" rel="noopener noreferrer">
                {account.userName}
              </a>
            ) : (
              account.userName
            )}
            <DeleteIconButton
              icon={
                <DeleteIcon
                  size={16}
                  aria-label={intl.formatMessage({ id: 'deleteIcon', defaultMessage: 'Delete icon' })}
                />
              }
              tooltipContents={intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
              onClick={() => setDeleteConfirmId(account.id)}
            />
          </Styled.AccountRow>
        ))}
      <SectionTitle>
        <FormattedMessage id="linkNewAccount" defaultMessage="Link a new account" />
      </SectionTitle>
      <SubTitle>
        <FormattedMessage id="socialNetworks" defaultMessage="Social Networks" />
      </SubTitle>
      <Styled.AccountIconButtonWrapper>
        <Styled.AccountButton href="/user/settings?provider=twitter&linkAccount=1">
          <TwitterLogo aria-label="Twitter" />
        </Styled.AccountButton>
        <Styled.AccountButton href="/user/settings?provider=facebook&linkAccount=1">
          <FacebookLogo aria-label="Facebook" />
        </Styled.AccountButton>
        <Styled.AccountButton href="/user/settings?provider=google&linkAccount=1">
          <GoogleLogo aria-label="Google" />
        </Styled.AccountButton>
        <Styled.AccountButton href="/user/settings?provider=linkedin&linkAccount=1">
          <LinkedInLogo aria-label="LinkedIn" />
        </Styled.AccountButton>
      </Styled.AccountIconButtonWrapper>
      <Toast messages={messages} />
      <Dialog
        isOpen={deleteConfirmId !== null}
        onClose={() => setDeleteConfirmId(null)}
        headerContent={intl.formatMessage({
          id: 'deleteUserAccountConfirmTitle',
          defaultMessage: 'Delete linked account'
        })}
        bodyContent={intl.formatMessage({
          id: 'deleteUserAccountConfirmBody',
          defaultMessage: 'Are you sure you want to remove this identity?'
        })}
        footerButtons={[
          {
            label: intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' }),
            onClick: () => setDeleteConfirmId(null),
            priority: 'tertiary'
          },
          {
            label: intl.formatMessage({ id: 'confirm', defaultMessage: 'Confirm' }),
            onClick: () => {
              deleteConfirmId && handleDelete(deleteConfirmId);
            },
            destructive: true
          }
        ]}
        isConstrainedToPortalRoot
      />
    </Container>
  );
};

import { type ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type BlogPost, type Challenge, type Idea, type IdeaUser, type Maybe } from '../../../common/core-api';
import { useFile, useMainModal } from '../../../hooks';
import { CommentNotificationTypes } from '../../../common/enums';
import { removeTags } from '../../../helpers';
import { ANONYMOUS_USER_ID } from '../../shared-constants';
import { AnonymousIcon, AnonymousName } from '../NotificationStream.styled';
import * as Styled from './Header.styled';
import { BlogPostLink, ChallengeLink, IdeaLink } from '.';

export const CommentCardHeader = ({
  notificationCount,
  commentCardType,
  challenge,
  idea,
  blogPost,
  author,
  isValid
}: {
  readonly notificationCount: number;
  readonly commentCardType?: CommentNotificationTypes;
  readonly challenge?: Maybe<Challenge> | null;
  readonly idea?: Maybe<Idea>;
  readonly blogPost?: Maybe<BlogPost>;
  readonly author?: Maybe<IdeaUser>;
  readonly isValid?: boolean;
}) => {
  const intl = useIntl();
  const [openModal] = useMainModal();

  const [getFile] = useFile();

  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  useEffect(() => {
    const getAuthUrl = async () => {
      const url =
        author &&
        'profileImageFile' in author &&
        author.profileImageFile &&
        (await getFile({ file: author.profileImageFile, width: 30, height: 30 }));
      setProfilePictureUrl(url || '');
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [author]);

  const isAuthorAnonymous = author?.id === ANONYMOUS_USER_ID;
  const challengeTitle = removeTags(challenge?.title || '');
  const challengeLink = (msg: ReactNode, id: string | undefined) => (isValid ? ChallengeLink(msg, id) : msg);

  return (
    <>
      {commentCardType &&
        [
          CommentNotificationTypes.REASON_BLOG_SINGLE,
          CommentNotificationTypes.REASON_IDEA_SINGLE,
          CommentNotificationTypes.REASON_IDEA_SAME_USER,
          CommentNotificationTypes.REASON_USER_SINGLE,
          CommentNotificationTypes.REASON_USER_SAME_USER,
          CommentNotificationTypes.REASON_USER_DIFFERENT_CHALLENGES,
          CommentNotificationTypes.REASON_BLOG_SAME_USER,
          CommentNotificationTypes.REASON_TAG
        ].includes(commentCardType) && (
          <Styled.AlignItemsTop>
            {profilePictureUrl && !isAuthorAnonymous && (
              <Styled.ProfileImage data-testid="UserProfileImage" src={profilePictureUrl} />
            )}
            {isAuthorAnonymous && <AnonymousIcon aria-hidden="true" />}
            <Styled.AlignItemsWrap>
              {isAuthorAnonymous ? (
                <AnonymousName>
                  <FormattedMessage id="anonymous" defaultMessage="Anonymous" />
                </AnonymousName>
              ) : (
                <Styled.LinkedName
                  href={`/user/activity/${author?.id}`}
                  onClick={(e) =>
                    openModal(e, {
                      title: intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' }),
                      url: `/user/view/${author?.id}`
                    })
                  }
                >
                  {author?.displayName}
                </Styled.LinkedName>
              )}
              {commentCardType === CommentNotificationTypes.REASON_IDEA_SINGLE && (
                <FormattedMessage
                  id="hasCommentedOnAnIdea"
                  defaultMessage="has commented on an idea from the challenge <a>{challengeTitle}</a>"
                  values={{
                    challengeTitle,
                    a: (msg) => challengeLink(msg, challenge?.id)
                  }}
                />
              )}
              {(commentCardType === CommentNotificationTypes.REASON_BLOG_SINGLE ||
                commentCardType === CommentNotificationTypes.REASON_BLOG_SAME_USER) && (
                <FormattedMessage
                  id="commentOnBlogPost"
                  defaultMessage="has commented on the blog {itemCount, plural, one {post} other {posts}}"
                  values={{
                    itemCount: notificationCount
                  }}
                />
              )}
              {commentCardType === CommentNotificationTypes.REASON_IDEA_SAME_USER && (
                <FormattedMessage id="hasCommentedOnIdeas" defaultMessage="has commented on these ideas" />
              )}
              {commentCardType === CommentNotificationTypes.REASON_USER_DIFFERENT_CHALLENGES && (
                <FormattedMessage
                  id="hasCommentedOnChallenges"
                  defaultMessage="has commented on challenges"
                  values={{
                    itemCount: notificationCount
                  }}
                />
              )}
              {(commentCardType === CommentNotificationTypes.REASON_USER_SINGLE ||
                commentCardType === CommentNotificationTypes.REASON_USER_SAME_USER) && (
                <FormattedMessage id="hasCommentedOnAChallenge" defaultMessage="has commented on a challenge" />
              )}
              {commentCardType === CommentNotificationTypes.REASON_TAG && (
                <FormattedMessage id="taggedOnComment" defaultMessage="has tagged you on a comment" />
              )}
            </Styled.AlignItemsWrap>
          </Styled.AlignItemsTop>
        )}
      <Styled.AlignItemsWrap>
        {commentCardType === CommentNotificationTypes.REASON_IDEA_SAME_IDEA && (
          <FormattedMessage
            id="ideaHasComments"
            defaultMessage="The idea <a>{ideaTitle}</a> from the challenge <b>{challengeTitle}</b> has new comments"
            values={{
              ideaTitle: removeTags(idea?.title || ''),
              challengeTitle,
              a: (msg) => (isValid ? IdeaLink(msg, idea?.id) : msg),
              b: (msg) => challengeLink(msg, challenge?.id)
            }}
          />
        )}
        {(commentCardType === CommentNotificationTypes.REASON_USER_SAME_CHALLENGE ||
          commentCardType === CommentNotificationTypes.REASON_CHALLENGE_SINGLE ||
          commentCardType === CommentNotificationTypes.REASON_CHALLENGE_SAME_CHALLENGE) && (
          <FormattedMessage
            id="challengeHasComments"
            defaultMessage="The challenge <a>{challengeTitle}</a> has {itemCount, plural, one {a new comment} other {new comments}}"
            values={{
              challengeTitle,
              a: (msg) => challengeLink(msg, challenge?.id),
              itemCount: notificationCount
            }}
          />
        )}
        {commentCardType === CommentNotificationTypes.REASON_BLOG_DIFFERENT_USER && (
          <FormattedMessage
            id="blogPostHasComments"
            defaultMessage="The blog post <a>{blogTitle}</a> has new comments"
            values={{
              blogTitle: blogPost?.title,
              a: (msg) => BlogPostLink(msg, blogPost?.id)
            }}
          />
        )}
      </Styled.AlignItemsWrap>
    </>
  );
};

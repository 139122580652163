import { borderRadius, Button, color, snap, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const AccountIconButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    margin-bottom: ${units(2)};
  }
`;

export const AccountButton = styled(Button)`
  display: inline-flex;
  background: ${color('hint')};
  width: ${snap(120)};
  height: ${snap(60)};
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius('large')};
  &:hover,
  &:focus {
    &:not([disabled]) {
      background: ${color('highlight')};
    }
  }
  svg {
    width: ${snap(40)};
    height: ${snap(30)};
  }
`;

export const AccountRow = styled.div`
  display: flex;
  align-items: center;
  & > *:not(a) {
    margin-left: ${units(1)};
  }
`;

export const ProviderName = styled.span`
  display: inline-block;
  &:after {
    content: '—';
    display: inline-block;
    margin: 0 ${units(1)};
  }
`;

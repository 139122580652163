import {
  Dialog as AlchemyDialog,
  DialogBody as AlchemyDialogBody,
  LoadingIndicator as AlchemyLoadingIndicator,
  borderRadius,
  color,
  units
} from '@m/alchemy-ui';
import styled from 'styled-components';

export interface CorrectedStylesProps {
  hasStickyFooter?: boolean;
}

export interface PaddingStylesProps {
  noPadding?: boolean;
}

export const CorrectedStyles = styled.div<CorrectedStylesProps>`
  ${({ hasStickyFooter }) =>
    hasStickyFooter
      ? ``
      : `
      overflow-x: hidden;
      height: 100%;
      width: 100%;
    `}

  /* Account for login dialog */
  .mdl-container.-narrow .l-grid__container,
  .mdl-grid.-x2,
  .mdl-login {
    padding: 0;
    margin: 0;
  }
`;

export const LoadingIndicator = styled(AlchemyLoadingIndicator)`
  background: ${color('container')};
  border-radius: ${borderRadius('extraLarge')};
`;

export const DialogBody = styled(AlchemyDialogBody)<PaddingStylesProps>`
  padding-bottom: 0;

  ${({ noPadding }) =>
    noPadding &&
    `
      margin: 0;
      background-color: ${color('container')};
    `}

  /* Account for post idea form */
  .mdl-idea-post__button-row {
    padding-bottom: 0;
    min-height: ${units(6.5)} !important;
    margin-bottom: 12px;
  }
  @media (min-width: 700px) {
    .mdl-idea-post__content {
      height: 85vh;
    }
    .mdl-idea-post__button-row {
      margin-bottom: 0;
    }
  }
`;

export const Dialog = styled(AlchemyDialog)<PaddingStylesProps>`
  z-index: 1048;
  ${({ noPadding }) =>
    noPadding &&
    `
  & > * {
    padding: 0;
  }
  header {
    margin: 0;
    padding: ${units(1.5)} ${units(3)};
  }
`}
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  height: ${({ height }: { height: string }) => height};
  position: relative;
  width: 100%;

  iframe {
    height: ${({ height }: { height: string }) => height};
    overflow-y: hidden !important;
    width: 100%;
  }
`;

export const LoadingCover = styled.div`
  background: #fff;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
`;

export enum NotificationType {
  BLOG_POST_COMMENT = 'BLOG_POST_COMMENT',
  BLOG_POST_NEW = 'BLOG_POST_NEW',
  CHALLENGE_CHECK_OUT = 'CHALLENGE_CHECK_OUT',
  CHALLENGE_ENTERED_PHASE = 'CHALLENGE_ENTERED_PHASE',
  CHALLENGE_NEW = 'CHALLENGE_NEW',
  COMMENT_NEW = 'COMMENT_NEW',
  COMMENT_REPLY = 'COMMENT_REPLY',
  IDEA_CHECK_OUT = 'IDEA_CHECK_OUT',
  IDEA_COMMENT = 'IDEA_COMMENT',
  IDEA_FOLLOW = 'IDEA_FOLLOW',
  IDEA_NEW = 'IDEA_NEW',
  IDEA_MILESTONE_REACHED = 'IDEA_MILESTONE_REACHED',
  IDEA_PHASE_REACHED = 'IDEA_PHASE_REACHED',
  IDEA_VOTE = 'IDEA_VOTE',
  IDEA_TAG = 'IDEA_TAG'
}

export enum NotificationActions {
  REPLY = 'REPLY',
  VIEW = 'VIEW'
}

export enum NotificationReasonType {
  CHALLENGE_MODERATOR = 'CHALLENGE_MODERATOR',
  COMMUNITY = 'COMMUNITY',
  FOLLOWED_BLOG = 'FOLLOWED_BLOG',
  FOLLOWED_BLOG_POST = 'FOLLOWED_BLOG_POST',
  FOLLOWED_CHALLENGE = 'FOLLOWED_CHALLENGE',
  FOLLOWED_IDEA = 'FOLLOWED_IDEA',
  FOLLOWED_USER = 'FOLLOWED_USER',
  USER_TAGGED_IN_COMMENT = 'USER_TAGGED_IN_COMMENT',
  USER_TAGGED_IN_IDEA = 'USER_TAGGED_IN_IDEA',
  USER_WITH_CHALLENGE_ACCESS = 'USER_WITH_CHALLENGE_ACCESS'
}

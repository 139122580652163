import styled from 'styled-components';

export const Score = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #65788b;
  height: 90px;

  .title {
    font:
      400 18px / 1.3 'Nunito',
      -apple-system,
      BlinkMacSystemFont,
      helvetica,
      arial,
      sans-serif;
    text-transform: none !important;
  }

  .value {
    margin-top: auto;
    align-self: flex-end;
    font:
      400 28px 'Nunito',
      -apple-system,
      BlinkMacSystemFont,
      helvetica,
      arial,
      sans-serif !important;
  }
`;

import { gql } from '@apollo/client';

export const GET_USER_FIELDS = gql`
  query userFields {
    userFields {
      id
      name
      type
      options
    }
  }
`;

export const GET_USERFIELDS_FILTERED_USERIDS = gql`
  query filteredUsers($filters: UserSearchFilters!) {
    filteredUsers(filters: $filters)
  }
`;

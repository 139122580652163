import { useState } from 'react';
import { AddIconOutlined, color, Field, type FieldProps, typeStyle, units } from '@m/alchemy-ui';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const CollapsedFieldContainer = styled.div`
  margin: ${units(2)} 0;
`;

const Optional = styled.span`
  color: ${color('typeBody')};
  font-weight: normal;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background: 0;
  padding: 0;
  color: ${color('typeLink')};
  cursor: pointer;
  ${typeStyle('bodyS')};
  & > * {
    margin-right: ${units(0.5)};
  }
`;

export const CollapsedField = ({
  trigger,
  optional = true,
  initialExpanded = false,
  input,
  ...rest
}: FieldProps & {
  readonly input: JSX.Element;
  readonly trigger: string;
  readonly optional?: boolean;
  readonly initialExpanded?: boolean;
}) => {
  const hasValue =
    input.props.value?.length > 0 ||
    input.props.defaultValue?.length > 0 ||
    (input.props.children &&
      (input.props.children.props?.value?.length > 0 || input.props.children.props?.defaultValue?.length > 0));
  const [collapsed, setCollapsed] = useState(!initialExpanded && !hasValue);

  return (
    <CollapsedFieldContainer>
      {collapsed && (
        <StyledButton type="button" onClick={() => setCollapsed(false)}>
          <AddIconOutlined aria-hidden="true" />
          <span>{trigger}</span>
          {optional && (
            <Optional className="optional">
              <FormattedMessage id="optionalParenthetical" defaultMessage="(optional)" />
            </Optional>
          )}
        </StyledButton>
      )}
      {!collapsed && <Field input={input} {...rest} />}
    </CollapsedFieldContainer>
  );
};

import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button, Checkbox, Toast, units } from '@m/alchemy-ui';
import { useMutation } from '@apollo/client';
import { Body, Container, Title } from '../../../../common/components';
import { UserContext } from '../../../../context';
import { type ToastMessageType } from '../../../../common/enums';
import { getConfirmToastMessage, getErrorToastMessage } from '../../../../helpers';
import { type StealthMutation, UPDATE_STEALTH_MODE } from '../../../../graphql/mutations';

const SaveButton = styled(Button)`
  float: right;
  margin-top: ${units(4)};
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: ${units(3)};
`;

export const Visibility = () => {
  const intl = useIntl();
  const user = useContext(UserContext);

  const [isStealthOn, setIsStealthOn] = useState<boolean>();
  const [messages, setMessages] = useState<ToastMessageType[]>();

  const [updateStealthMode, { loading: stealthUpdateLoading }] = useMutation<StealthMutation>(UPDATE_STEALTH_MODE);

  useEffect(() => {
    if (user.userAccess?.stealthMode) {
      setIsStealthOn(user.userAccess.stealthMode);
    }
  }, [user]);

  const handleSave = async () => {
    const result = await updateStealthMode({
      variables: {
        enabled: isStealthOn
      }
    });

    if (result.data?.updateStealth.success) {
      setMessages(getConfirmToastMessage(intl.formatMessage({ id: 'saveSuccess', defaultMessage: 'Save successful' })));
    } else {
      setMessages(getErrorToastMessage(intl.formatMessage({ id: 'saveFail', defaultMessage: 'Save failed' })));
    }
  };

  return (
    <Container>
      <Title>
        <FormattedMessage id="visibility" defaultMessage="Visibility" />
      </Title>
      <Body>
        <FormattedMessage
          id="stealthHelpText"
          defaultMessage="As an admin, you may prefer to be invisible on leaderboards. Uncheck the box below to remove yourself from leaderboards and keep your points at zero. You can re-enable this at any time, and your points will be returned to you."
        />
      </Body>
      <StyledCheckbox
        label={<FormattedMessage id="stealthOn" defaultMessage="Receive points and appear in leaderboards" />}
        checked={!isStealthOn}
        onChange={() => setIsStealthOn(!isStealthOn)}
      />
      <SaveButton type="button" priority="primary" isLoading={stealthUpdateLoading} onClick={handleSave}>
        <FormattedMessage id="save" defaultMessage="Save" />
      </SaveButton>
      <Toast messages={messages} />
    </Container>
  );
};

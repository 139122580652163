import { type ReactNode, useState } from 'react';
import { units } from '@m/alchemy-ui';
import styled from 'styled-components';

interface TooltipProps {
  readonly content: string;
  readonly children?: ReactNode;
  readonly delay?: number;
}

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const TooltipTip = styled.div`
  position: absolute;
  border-radius: ${units(1)};
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 16px;
  color: #fff;
  background: #292f3b;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  top: -36px;

  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: -6px;
    top: 100%;
    border-top-color: black;
  }
`;

export const Tooltip = (props: TooltipProps) => {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 100);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <TooltipWrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
      {/* Wrapping */}
      {props.children}
      {active && (
        <TooltipTip>
          {/* Content */}
          {props.content}
        </TooltipTip>
      )}
    </TooltipWrapper>
  );
};

import { type ReactNode, useState } from 'react';
import { Checkbox, Dialog, typeStyle, units, WarningIcon } from '@m/alchemy-ui';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { WarningType } from '../../../common/enums';

interface WarningProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
  readonly warningType: WarningType | undefined;
}

const StyledDiv = styled.div`
  ${typeStyle('bodyS')};
  display: flex;
  align-items: center;
  & > * {
    margin-right: ${units(1)};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin: ${units(1.5)} 0 ${units(1.5)} ${units(0.5)};
`;

const BoldText = styled.span`
  ${typeStyle('actionM')};
`;

const WarningBody = ({ message }: { readonly message: string }) => (
  <StyledDiv>
    <WarningIcon palette="interactiveDestructive" aria-hidden="true" />
    {message}
  </StyledDiv>
);

const Bold = (msg: ReactNode[]) => <BoldText>{msg}</BoldText>;

export const Warning = ({ isOpen, onClose, onConfirm, warningType }: WarningProps) => {
  const intl = useIntl();

  const [checkbox1, setCheckbox1] = useState<boolean>(false);
  const [checkbox2, setCheckbox2] = useState<boolean>(false);

  let headerMessage;
  let bodyMessage;
  switch (warningType) {
    case WarningType.FUNNEL_TO_STANDARD:
      headerMessage = intl.formatMessage({
        id: 'funnelToStandardWarningHeader',
        defaultMessage: 'Switch to standard challenge?'
      });
      bodyMessage = (
        <WarningBody
          message={intl.formatMessage({
            id: 'funnelToStandardWarningBody',
            defaultMessage: 'All phases will be removed.'
          })}
        />
      );
      break;
    case WarningType.FUNNEL_TO_MILESTONE:
      headerMessage = intl.formatMessage({
        id: 'funnelToMilestoneWarningHeader',
        defaultMessage: 'Switch to milestone challenge?'
      });
      bodyMessage = (
        <WarningBody
          message={intl.formatMessage({
            id: 'funnelToMilestoneWarningBody',
            defaultMessage: 'All phases will be removed.'
          })}
        />
      );
      break;
    case WarningType.MILESTONE_TO_STANDARD:
      headerMessage = intl.formatMessage({
        id: 'milestoneToStandardWarningHeader',
        defaultMessage: 'Switch to standard challenge?'
      });
      bodyMessage = (
        <WarningBody
          message={intl.formatMessage({
            id: 'milestoneToStandardWarningBody',
            defaultMessage: 'All milestones will be removed.'
          })}
        />
      );
      break;
    case WarningType.MILESTONE_TO_FUNNEL:
      headerMessage = intl.formatMessage({
        id: 'milestoneTofunnelWarningHeader',
        defaultMessage: 'Switch to funnel challenge?'
      });
      bodyMessage = (
        <WarningBody
          message={intl.formatMessage({
            id: 'milestoneTofunnelWarningBody',
            defaultMessage: 'All milestones will be removed.'
          })}
        />
      );
      break;
    case WarningType.PHASE_DELETE:
      headerMessage = intl.formatMessage({ id: 'deletePhaseConfirmHeader', defaultMessage: 'Delete this phase?' });
      bodyMessage = intl.formatMessage({
        id: 'deletePhaseConfirmBody',
        defaultMessage:
          'Subsequent phases will be brought forward, and one may become active immediately if any fall within the current date'
      });
      break;
    case WarningType.MILESTONE_DELETE:
      headerMessage = intl.formatMessage({
        id: 'deleteMilestoneConfirmHeader',
        defaultMessage: 'Delete this milestone?'
      });
      bodyMessage = (
        <>
          <FormattedMessage
            id="deleteMilestoneConfirmBody1"
            defaultMessage="By deleting the milestone you acknowledge you are going to <b>permanently</b> lose the following data:"
            values={{
              b: Bold
            }}
          />
          <StyledCheckbox
            label={intl.formatMessage({
              id: 'deleteMilestoneCheckbox1',
              defaultMessage: 'Answers to any additional questions associated with the milestone'
            })}
            checked={checkbox1}
            onChange={() => setCheckbox1(!checkbox1)}
          />
          <StyledCheckbox
            label={intl.formatMessage({
              id: 'deleteMilestoneCheckbox2',
              defaultMessage: 'Votes associated with the milestone'
            })}
            checked={checkbox2}
            onChange={() => setCheckbox2(!checkbox2)}
          />
          <FormattedMessage
            id="deleteMilestoneConfirmBody2"
            defaultMessage="Please tick the boxes if you want to continue"
          />
        </>
      );

      break;
    default:
      headerMessage = intl.formatMessage({
        id: 'defaultWarningHeader',
        defaultMessage: 'Are you sure?'
      });
      bodyMessage = '';
      break;
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      headerContent={headerMessage}
      bodyContent={bodyMessage}
      footerButtons={[
        {
          priority: 'tertiary',
          label: intl.formatMessage({
            id: 'cancel',
            defaultMessage: 'Cancel'
          }),
          palette: 'interactiveNavigational',
          onClick: onClose
        },
        {
          label: intl.formatMessage({
            id: 'confirm',
            defaultMessage: 'Confirm'
          }),
          onClick: onConfirm,
          disabled: warningType === WarningType.MILESTONE_DELETE ? !checkbox1 || !checkbox2 : false
        }
      ]}
    />
  );
};

import { FormattedMessage } from 'react-intl';
import { addMinutes, differenceInMilliseconds } from 'date-fns';
import { useEffect } from 'react';
import { format } from 'date-fns-tz';
import { usePasswordComplexity } from '../../../../context/use-password-complexity';
import { Body, Container, Title } from '../../../../common/components';

export const PasswordResetDelay = ({
  afterDelayHandler,
  resetAt,
  timezone
}: {
  readonly afterDelayHandler: () => void;
  readonly resetAt: string;
  readonly timezone: string;
}) => {
  const { passwordComplexityLifetimeMinutesMin } = usePasswordComplexity();

  const deadline = addMinutes(new Date(resetAt), passwordComplexityLifetimeMinutesMin);
  useEffect(() => {
    const timer = setTimeout(afterDelayHandler, differenceInMilliseconds(deadline, new Date()));

    return () => clearTimeout(timer);
  }, [afterDelayHandler, deadline]);

  return (
    <Container>
      <Title>
        <FormattedMessage id="passwordReset" defaultMessage="Password reset" />
      </Title>
      <Body marginStrategy="bottom">
        <FormattedMessage id="passwordResetOn" defaultMessage="You can change your password again at:" />
        {` ${format(deadline, 'h:mm a - d MMM yyyy', { timeZone: timezone })}`}
      </Body>
    </Container>
  );
};

import groupBy from 'lodash.groupby';
import orderBy from 'lodash.orderby';

import { NotificationReasonType, NotificationType } from './common/constants';
import { type INotification } from './types/INotification';

export class NotificationHelper {
  private notifications: Array<INotification>;

  constructor(notifications: Array<INotification>) {
    this.notifications = notifications;
  }

  private isNewIdeaInChallenge = (group: Array<INotification>) =>
    group.some(
      (notifiction: INotification) =>
        notifiction.type === NotificationType.IDEA_NEW &&
        notifiction.reasonType === NotificationReasonType.FOLLOWED_CHALLENGE
    );

  public groupByReasonAndType = (): Array<Array<INotification>> => {
    // group notification by reason type
    const groupedByReasonType = Object.values(
      groupBy(this.notifications, (notif) =>
        notif.type === NotificationType.CHALLENGE_CHECK_OUT
          ? `${notif.reasonType}${notif.challengeId}`
          : notif.reasonType
      )
    );

    // group notificatons by reason id inside each group that is grouped by reasonType
    const groupedByReasonId = groupedByReasonType.flatMap((group: Array<INotification>) =>
      Object.values(groupBy(group, (notif) => notif.reasonId))
    );

    // group by type notifications that are already grouped arrays by reasonType and reasonId
    return groupedByReasonId.flatMap((group: Array<INotification>) =>
      Object.values(groupBy(group, (notif) => notif.type))
    );
  };

  public sortByCreatedDateInsideGroups = (groupedNotification: Array<Array<INotification>>) =>
    groupedNotification.map((group: Array<INotification>) => orderBy(group, ['created'], ['desc']));

  public sortGroupsByDate = (groupedNotification: Array<Array<INotification>>) => {
    // create Map where key is first item of group and value is whole group
    const groupedMap = new Map<INotification, INotification[]>();
    groupedNotification.forEach((group: Array<INotification>) => {
      // if notification type is NEW_IDEA and reason is CHALLENGE then most recent item in group should be taken as key
      // (because in this case items in group are sorted randomly so first item in the group is not the most recent one
      // which will break sorting of all groups)
      this.isNewIdeaInChallenge(group)
        ? groupedMap.set(orderBy(group, ['created'], ['desc'])[0], group)
        : groupedMap.set(group[0], group);
    });

    // create array of Map keys
    const keysArr = [];
    for (const key of groupedMap.keys()) {
      keysArr.push(key);
    }

    // sort array of keys by created date
    const sortedKeysArr = orderBy(keysArr, ['created'], ['desc']);

    // get groups of notifications from Map by sorted keys
    return sortedKeysArr.map((key) => groupedMap.get(key));
  };
}

export const groupNotifications = (notifications: INotification[]) => {
  const notificationHelper = new NotificationHelper(notifications);

  if (notifications.length === 0) {
    return notifications;
  }

  const grouped = notificationHelper.groupByReasonAndType();
  const sortedInsideGroups = notificationHelper.sortByCreatedDateInsideGroups(grouped);
  return notificationHelper.sortGroupsByDate(sortedInsideGroups);
};

import { type IntlShape } from 'react-intl';

import { snap, SortCell, TableCell, TableRow } from '@m/alchemy-ui';
import { type SortDirection } from '@m/alchemy-ui/esm/table/table-cell-sort/sort-direction';
import { type IdeaItemType } from '../types';
import { Table } from '../Analytics.styled';
import { useDateFormat } from '../../../hooks';

export const IdeasTableHeader = ({
  intl,
  getColumnSortDirection,
  sortIdeas
}: {
  readonly intl: IntlShape;
  readonly getColumnSortDirection: (sort: string) => string;
  readonly sortIdeas: (sort: string) => void;
}) => (
  <thead>
    <TableRow>
      <SortCell
        sortDirection={getColumnSortDirection('title') as SortDirection}
        isHeader
        onToggle={() => sortIdeas('title')}
      >
        {intl.formatMessage({ id: 'idea', defaultMessage: 'Idea' })}
      </SortCell>
      <TableCell isHeader>{intl.formatMessage({ id: 'challenge', defaultMessage: 'Challenge' })}</TableCell>
      <SortCell
        sortDirection={getColumnSortDirection('createdAt') as SortDirection}
        isHeader
        onToggle={() => sortIdeas('createdAt')}
      >
        {intl.formatMessage({ id: 'created', defaultMessage: 'Created' })}
      </SortCell>
      <TableCell isHeader>{intl.formatMessage({ id: 'author', defaultMessage: 'Author' })}</TableCell>
      <SortCell
        sortDirection={getColumnSortDirection('commentCount') as SortDirection}
        isHeader
        onToggle={() => sortIdeas('commentCount')}
      >
        {intl.formatMessage({ id: 'comments', defaultMessage: 'Comments' })}
      </SortCell>
      <SortCell
        sortDirection={getColumnSortDirection('voteCount') as SortDirection}
        isHeader
        onToggle={() => sortIdeas('voteCount')}
      >
        {intl.formatMessage({ id: 'votes', defaultMessage: 'Votes' })}
      </SortCell>
      <TableCell isHeader>{intl.formatMessage({ id: 'score', defaultMessage: 'Score' })}</TableCell>
    </TableRow>
  </thead>
);

export const IdeasTableBody = ({ ideasAndCount }: { readonly ideasAndCount: { rows?: IdeaItemType[] } }) => {
  const dateFormat = useDateFormat();
  return (
    <tbody>
      {ideasAndCount?.rows?.map((idea: IdeaItemType) => (
        <TableRow key={idea.id}>
          <TableCell maxWidth={snap(120)}>
            <a href={`/post/${idea.id}`} target="_blank">
              {idea?.title || ''}
            </a>
          </TableCell>
          <TableCell maxWidth={snap(100)}>{idea?.challenge?.title || idea?.theme?.challenge?.title}</TableCell>
          <TableCell>
            {
              dateFormat(
                new Date(idea?.createdAt || Date.now()),
                'PPpp',
                'GMT'
              ) /* forcing GMT timezone to be consistent because all charts showing data in GMT */
            }
          </TableCell>
          <TableCell>{idea?.user?.displayName}</TableCell>
          <TableCell>{idea?.stats?.comments}</TableCell>
          <TableCell>{idea?.voteCount || 0}</TableCell>
          <TableCell>{idea?.score || 0}</TableCell>
        </TableRow>
      ))}
    </tbody>
  );
};

export const IdeasTable = ({
  intl,
  ideasAndCount,
  getColumnSortDirection,
  sortIdeas
}: {
  readonly intl: IntlShape;
  readonly ideasAndCount: { rows?: IdeaItemType[] };
  readonly getColumnSortDirection: (sort: string) => string;
  readonly sortIdeas: (sort: string) => void;
}) => (
  <Table withoutScroller data-testid="ideasTable">
    <IdeasTableHeader intl={intl} sortIdeas={sortIdeas} getColumnSortDirection={getColumnSortDirection} />
    <IdeasTableBody ideasAndCount={ideasAndCount} />
  </Table>
);

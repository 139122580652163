export enum CommentNotificationTypes {
  REASON_BLOG_SINGLE = 'REASON_BLOG_SINGLE',
  REASON_IDEA_SINGLE = 'REASON_IDEA_SINGLE',
  REASON_BLOG_SAME_USER = 'REASON_BLOG_SAME_USER',
  REASON_IDEA_SAME_USER = 'REASON_IDEA_SAME_USER',
  REASON_IDEA_SAME_IDEA = 'REASON_IDEA_SAME_IDEA',
  REASON_USER_SINGLE = 'REASON_USER_SINGLE',
  REASON_USER_SAME_USER = 'REASON_USER_SAME_USER',
  REASON_USER_DIFFERENT_CHALLENGES = 'REASON_USER_DIFFERENT_CHALLENGES',
  REASON_USER_SAME_CHALLENGE = 'REASON_USER_SAME_CHALLENGE',
  REASON_CHALLENGE_SINGLE = 'REASON_CHALLENGE_SINGLE',
  REASON_CHALLENGE_SAME_CHALLENGE = 'REASON_CHALLENGE_SAME_CHALLENGE',
  REASON_BLOG_DIFFERENT_USER = 'REASON_BLOG_DIFFERENT_USER',
  REASON_TAG = 'REASON_TAG'
}

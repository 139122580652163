import { AccountProviderType, type Community, UserGroupType } from '../common/core-api';

export const getAvailableUserGroupTypes = (community: Community): Array<UserGroupType> => {
  const alwaysAvailable = [UserGroupType.STATIC, UserGroupType.DYNAMIC];
  const availableTypes = [...alwaysAvailable];
  if (community.options) {
    const { allowAzureLogin, allowSAML2Login, allowSocialMediaLogin, OpenIdProvider, allowCrowdicityLogin } =
      community.options;
    allowAzureLogin && availableTypes.push(UserGroupType.AZURE_LOGIN);
    allowSAML2Login && availableTypes.push(UserGroupType.SAML_LOGIN);
    allowSocialMediaLogin && availableTypes.push(UserGroupType.SOCIAL_LOGIN);
    OpenIdProvider === AccountProviderType.MEC && availableTypes.push(UserGroupType.MEC_LOGIN);
    availableTypes.length > alwaysAvailable.length &&
      allowCrowdicityLogin &&
      availableTypes.push(UserGroupType.CROWDICITY_LOGIN);
  }

  return availableTypes;
};

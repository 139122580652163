export enum UseroidTypes {
  PROVIDER_FACEBOOK = 'Facebook',
  PROVIDER_GOOGLE = 'Google',
  PROVIDER_GOOGLEP = 'Google+',
  PROVIDER_LINKEDIN = 'LinkedIn',
  PROVIDER_SAML2 = 'SAML2',
  PROVIDER_TWITTER = 'Twitter',
  PROVIDER_YAMMER = 'Yammer',
  PROVIDER_AZURE = 'Azure',
  PROVIDER_MEDALLIA = 'MEC',
  PROVIDER_SSOPROXY = 'SSOPROXY',
  PROVIDER_SLACK = 'Slack',
  PROVIDER_TEAMS = 'Microsoft Teams'
}

import { color, DisplayBox, snap, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const ContentContainer = styled(DisplayBox)`
  padding: ${units(2)};
  &:not(:last-child) {
    margin-bottom: ${units(1.5)};
  }
  [class^='DisplayBoxstyled__Caret'],
  [class^='DisplayBoxstyled__Background'] {
    background-color: ${color('canvas')};
  }
  &:hover {
    [class^='DisplayBoxstyled__Caret'],
    [class^='DisplayBoxstyled__BoxWrapper'] {
      background-color: ${color('container')};
      border: 1px solid ${color('base', { palette: 'interactiveConstructive' })};
      [class^='DisplayBoxstyled__Background'] {
        background-color: ${color('container')};
      }
    }
  }
`;

export const CommentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AlignItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const LinkedTitle = styled.a`
  font-weight: 500;
  max-width: ${snap(150)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:first-child:not(:only-child) {
    margin-right: ${units(0.5)};
  }
  &:last-child:not(:only-child) {
    margin-left: ${units(0.5)};
  }
`;

export const LinkedTitleGreyout = styled.a`
  font-weight: 500;
  max-width: ${snap(150)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:first-child:not(:only-child) {
    margin-right: ${units(0.5)};
  }
  &:last-child:not(:only-child) {
    margin-left: ${units(0.5)};
  }
  color: grey;
  pointer-events: none;
`;

export const CommentBody = styled.p`
  margin-top: ${units(1)};
  line-height: 1.3;
  cursor: pointer;
`;

export const CommentBodyGreyout = styled.p`
  margin-top: ${units(1)};
  line-height: 1.3;
  color: grey;
  pointer-events: none;
`;

export const AuthorName = styled.a`
  font-weight: 500;
  display: inline;
  margin-right: ${units(0.5)};
`;

export const AuthorWithImage = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  margin-right: ${units(1)};
  height: ${units(3)};
  width: ${units(3)};
`;

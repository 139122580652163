import { useCallback } from 'react';

export const useLanguageOverride = () => {
  const languageOverride = useCallback((key: string, fallback: string = ''): string => {
    const overrides = window._APPLICATION_LANGUAGE_OVERRIDES_ || {};
    return key in overrides ? (overrides[key] ? overrides[key] : fallback) : fallback;
  }, []);

  return languageOverride;
};

import styled from 'styled-components';
import { color, units } from '@m/alchemy-ui';

const StyledLine = styled.hr`
  margin: ${units(1)} 0;
  height: 1px;
  border: 0;
  background: ${color('line')};
`;

export const Line = () => <StyledLine role="separator" />;

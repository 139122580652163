import { type Challenge, IdeaSubmissionsType } from '../core-api';
import { type ChallengeTemplate } from '.';

export interface IChallenge extends Challenge {
  loading: boolean;
  isNew: boolean;
  template?: ChallengeTemplate;
  language?: string;
  errors?: ChallengeErrors[];
  focusError?: ChallengeErrorFocus;
}

export interface ChallengeErrors {
  message: string;
  errors: ChallengeError[];
}

export interface ChallengeErrorFocus {
  name: string;
  itemIndex: number;
  field: string;
  itemTabIndex: number;
}

export interface ChallengeError {
  children: ChallengeError[];
  constraints: Record<string, string>;
  property: string;
  value: string;
}

export const defaultChallenge: IChallenge = {
  id: '0',
  title: '',
  themes: [],
  loading: true,
  isNew: true,
  ideas: [],
  archived: 0,
  communityId: '',
  description: '',
  headings: {
    ideaDescriptionHeading: '',
    ideaDescriptionInfoHeading: '',
    ideaFilesHeading: '',
    ideaTitleHeading: '',
    ideaTitleInfoHeading: '',
    themeSelectHeading: ''
  },
  voting: {
    options: {
      maxStars: 5,
      minimumRatings: 1,
      maxScore: 1
    }
  },
  access: {
    users: [],
    groups: [],
    options: {
      comments: {
        enabled: true,
        attachmentsEnabled: true,
        anonymousEnabled: false
      },
      ideaEditLock: {
        enabled: false,
        graceMinutes: 0
      },
      ideaSubmissionEnabled: true,
      ideasVisible: true,
      ideaSubmissionsType: IdeaSubmissionsType.JOINT,
      privateSubmissionEnabled: false,
      showClosedEnabled: false,
      voteOnCommentsEnabled: true,
      voteOnIdeasEnabled: true,
      shareFacebookEnabled: false,
      shareTwitterEnabled: false,
      shareLinkedInEnabled: false,
      copyUrlEnabled: false
    }
  },
  phases: [],
  template: {
    name: '',
    description: '',
    userId: ''
  },
  config: {
    showInCarousels: true,
    sorting: {
      options: [],
      default: 'newest'
    },
    themeRequired: false,
    ideaDescriptionCharacterLimit: 0,
    hideUploadFiles: false,
    textThemeName: 'Challenge Themes'
  },
  categories: [],
  translations: [],
  errors: []
};

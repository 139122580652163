import { useEffect } from 'react';
import {
  Checkbox,
  CheckboxGroup,
  Field,
  FieldStatus,
  ImportantIcon,
  Popper,
  PopperBox,
  Select,
  units
} from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { type FormikProps, useFormikContext } from 'formik';
import { defaultVoting, type IChallenge } from '../../../common/interfaces';
import { MaxScore, MaxStarsOptions, MinimumRatingsOptions, type SelectorType } from '../../../common/enums';
import { ShortInput, SubTitle } from '../../../common/components';
import { useFieldError } from '../../../hooks';
import { type Phase, VotingType } from '../../../common/core-api';
import { Scorecard } from '.';

type BinaryKeyType = Record<string, unknown> | number | string | null;

const CheckboxGroupChild = styled.div`
  flex-basis: 100%;
  margin: ${units(0.5)} 0 0 ${units(3)};
`;

const StyledLabel = styled.label`
  font-weight: normal;
`;

const StyledIcon = styled(ImportantIcon)`
  margin-left: ${units(1)};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledField = styled(Field)`
  margin-top: ${units(3)};
`;

export const VotingOptions = ({ type }: { readonly type: SelectorType }) => {
  const intl = useIntl();
  const { values, setFieldValue, handleBlur }: FormikProps<IChallenge & Phase> = useFormikContext();

  const [fieldError] = useFieldError();

  const { voting } = values;

  useEffect(() => {
    if (voting.type === VotingType.STARS) {
      !voting.options?.maxStars && setFieldValue('voting.options.maxStars', defaultVoting.options?.maxStars);
      !voting.options?.minimumRatings &&
        setFieldValue('voting.options.minimumRatings', defaultVoting.options?.minimumRatings);
    }

    if (voting.type === VotingType.POINTSAWARD) {
      !voting.options?.maxScore && setFieldValue('voting.options.maxScore', defaultVoting.options?.maxScore);
    }
  }, [setFieldValue, voting.options?.maxScore, voting.options?.maxStars, voting.options?.minimumRatings, voting.type]);

  const handleSelectionChange = (selection: BinaryKeyType | BinaryKeyType[]) => {
    if (!Array.isArray(selection)) {
      return;
    }

    const changedItem = selection[selection.length - 1];

    if (changedItem && typeof changedItem === 'object') {
      setFieldValue(String(changedItem.name), !changedItem.checked);
    }
  };

  const handleInputChange = ({ name, value }: { name: string; value: unknown }) => {
    setFieldValue(name, value);
  };

  const selections = [
    {
      name: 'voting.isBlind',
      label: <FormattedMessage id="enableBlindVoting" defaultMessage="Enable blind voting on ideas" />,
      checked: voting?.isBlind,
      hint: intl.formatMessage({
        id: 'enableBlindVotingHint',
        defaultMessage: "Users will not be able to see an idea's total score (moderators and admins will)"
      })
    },
    {
      name: 'voting.showVoters',
      label: <FormattedMessage id="showVoted" defaultMessage="Show who voted" />,
      checked: voting?.showVoters,
      hint: intl.formatMessage({
        id: 'showVotedHint',
        defaultMessage: 'When selected, a list of users who voted will be shown on each idea'
      })
    },
    {
      name: 'voting.wallet.isEnabled',
      label: <FormattedMessage id="enableWallet" defaultMessage="Enable voting Wallet" />,
      checked: voting?.wallet?.isEnabled,
      hint: intl.formatMessage({ id: 'enableWalletHint', defaultMessage: 'Users will have a limited amount of votes' }),
      children: (
        <CheckboxGroupChild>
          <StyledLabel>
            <FormattedMessage
              id="walletAllowance"
              defaultMessage="Users will have allowance of {input} votes"
              values={{
                input: (
                  <ShortInput
                    name="voting.wallet.allowance"
                    value={voting?.wallet?.allowance || ''}
                    onChange={({ target: { name, value } }) => handleInputChange({ name, value })}
                    onBlur={handleBlur}
                    disabled={!voting?.wallet?.isEnabled}
                  />
                )
              }}
            />
            {fieldError('voting.wallet.allowance') && (
              <FieldStatus message="" {...fieldError('voting.wallet.allowance')} />
            )}
          </StyledLabel>
        </CheckboxGroupChild>
      )
    }
  ];

  return (
    <>
      <SubTitle>
        <FormattedMessage id="votingOptions" defaultMessage="Voting options" />
      </SubTitle>
      <CheckboxGroup
        selection={selections.filter((selection) => selection.checked).map((selection) => selection.name)}
        onChange={(selection) => handleSelectionChange(selection)}
      >
        {({ isSelected, handleChange }) =>
          selections.map((selection) => (
            <CheckboxWrapper key={selection.name}>
              <Checkbox
                label={selection.label}
                key={selection.name}
                name={selection.name}
                checked={isSelected(selection.name)}
                onChange={() => handleChange(selection)}
              />
              {selection.hint && (
                <Popper
                  placement="top"
                  mode="tooltip"
                  overlay={
                    <PopperBox hasArrow colorScheme="alchemyDark">
                      {selection.hint}
                    </PopperBox>
                  }
                  trigger={<StyledIcon aria-label="" />}
                />
              )}
              {selection.children}
            </CheckboxWrapper>
          ))
        }
      </CheckboxGroup>

      {voting?.type === VotingType.POINTSAWARD && (
        <StyledField
          label={intl.formatMessage({ id: 'maxScore', defaultMessage: 'Maximum points users can award' })}
          labelVariant="emphasized"
          required
          isFullWidth
          input={
            <Select
              options={Array.from({ length: MaxScore }, (_, index) => index).map((value) => ({
                label: String(value + 1),
                value: String(value + 1)
              }))}
              isFullWidth
              name="voting.options.maxScore"
              value={String(voting.options?.maxScore)}
              onChange={({ value }) => handleInputChange({ name: 'voting.options.maxScore', value })}
            />
          }
        />
      )}

      {voting?.type === VotingType.STARS && (
        <>
          <StyledField
            label={intl.formatMessage({ id: 'howManyStars', defaultMessage: 'How many stars?' })}
            labelVariant="emphasized"
            required
            isFullWidth
            input={
              <Select
                options={MaxStarsOptions.map((value) => String(value)).map((value) => ({ label: value, value }))}
                isFullWidth
                name="voting.options.maxStars"
                value={String(voting.options?.maxStars)}
                onChange={({ value }) =>
                  handleInputChange({ name: 'voting.options.maxStars', value: Number(value) || value })
                }
              />
            }
          />
          <StyledField
            label={intl.formatMessage({ id: 'minimumRatings', defaultMessage: 'Minimum ratings needed' })}
            labelVariant="emphasized"
            required
            isFullWidth
            helpText={intl.formatMessage({
              id: 'minimumRatingsHelp',
              defaultMessage: 'An average rating will not be displayed until the idea receives this number of votes'
            })}
            input={
              <Select
                options={MinimumRatingsOptions.map((value) => String(value)).map((value) => ({ label: value, value }))}
                isFullWidth
                name="voting.options.minimumRatings"
                value={String(voting.options?.minimumRatings)}
                onChange={({ value }) => handleInputChange({ name: 'voting.options.minimumRatings', value })}
              />
            }
          />
        </>
      )}

      {voting?.type === VotingType.SCORECARD && <Scorecard type={type} />}
    </>
  );
};

import { type Voting, VotingType } from '../core-api';

export const defaultVoting: Voting = {
  isBlind: false,
  showVoters: false,
  type: VotingType.UPDOWN,
  wallet: {
    isEnabled: false,
    allowance: 10
  },
  options: {
    maxStars: 3,
    maxScore: 1,
    minimumRatings: 1
  }
};

import styled from 'styled-components';
import { borderRadius, ErrorOutlineIcon, units } from '@m/alchemy-ui';

export interface ErrorFallbackProps {
  readonly error: {
    message: string;
  };
  readonly resetErrorBoundary: () => void;
}

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ErrorBody = styled.section`
  margin-top: 20%;
  text-align: center;
`;

const StyledIcon = styled(ErrorOutlineIcon)`
  height: ${units(12)};
  width: ${units(12)};
  opacity: 0.2;
`;

const Title = styled.h1`
  font:
    bold ${units(2.5)} 'Nunito',
    sans-serif;
`;

const StyledButton = styled.button`
  display: inline-block;
  border: 0;
  padding: ${units(1)} ${units(2)};
  border-radius: ${borderRadius('extraLarge')};
  background: #4050c6;
  color: #fff;
  cursor: pointer;
  font:
    ${units(2)} 'Nunito',
    sans-serif;
`;

const strings = {
  errorMessage: 'Medallia Ideas has encountered an error.',
  tryAgain: 'Try again'
};

export const ErrorFallback = ({ resetErrorBoundary }: ErrorFallbackProps) => (
  <ErrorContainer>
    <ErrorBody role="alert">
      <StyledIcon aria-hidden="true" />
      <Title>{strings.errorMessage}</Title>
      <StyledButton type="button" onClick={resetErrorBoundary}>
        {strings.tryAgain}
      </StyledButton>
    </ErrorBody>
  </ErrorContainer>
);

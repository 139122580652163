import styled from 'styled-components';
import { Toast as AlchemyToast, borderRadius, boxShadow, Button, color, snap, typeStyle, units } from '@m/alchemy-ui';

export const UserCard = styled.div`
  border-radius: ${borderRadius('large')};
  box-shadow: ${boxShadow('base')};
  display: flex;
  height: auto;
  margin: ${units(1)} ${units(2)};
  min-height: ${snap(120)};
  min-width: ${snap(235)};
  padding: 10px;
  width: 100%;
  svg {
    height: ${snap(100)};
    width: ${snap(100)};
    color: ${color('line')};
  }
  @media (min-width: 600px) {
    width: 44%;
  }
  @media (min-width: 1000px) {
    width: ${snap(235)};
    margin: ${units(2)};
  }
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  height: ${snap(100)};
  width: ${snap(100)};
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-left: ${units(1.5)};
  width: ${snap(100)};
`;

export const UserName = styled.a`
  ${typeStyle('bodyM')};
  line-height: 1.3;
  overflow-wrap: break-word;
`;

export const UserTown = styled.p`
  ${typeStyle('captionM')};
  margin-top: ${units(0.5)};
`;

export const FollowButton = styled(Button)`
  align-self: baseline;
  border: 1px solid ${color('highlight', { palette: 'interactiveConstructive' })};
  margin-top: ${units(0.5)};
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: normal;
`;

export const Toast = styled(AlchemyToast)`
  svg {
    color: inherit;
    height: ${units(3)};
    width: ${units(3)};
  }

  button svg {
    color: ${color('line')};
    height: ${units(2)};
    width: ${units(2)};
  }
`;

export const PitchTemplateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="180" height="90" viewBox="0 0 180 90">
    <defs>
      <mask id="a" x="-75.503" y="-50.003" width="301.505" height="149.505" maskUnits="userSpaceOnUse">
        <rect width="180" height="90" fill="#fff" />
      </mask>
    </defs>
    <rect width="180" height="90" fill="#e9e9f8" />
    <g mask="url(#a)">
      <path
        d="M73.881,7.043a16,16,0,0,1-5.632,21.666l-.292.172-119.5,68.5a16,16,0,0,1-21.838-5.924,16,16,0,0,1,5.632-21.666l.292-.172,119.5-68.5A16,16,0,0,1,73.881,7.043Zm150-49a16,16,0,0,1-5.632,21.666l-.292.172-119.5,68.5a16,16,0,0,1-16.206-27.59l.292-.172,119.5-68.5A16,16,0,0,1,223.881-41.957Z"
        fill="#4050c6"
        fillOpacity="0.07"
      />
    </g>
    <rect x="67" y="13" width="74" height="51" rx="6" fill="#e7f4ff" />
    <path
      d="M135,10.5a7.675,7.675,0,0,1,7.5,7.619l0,.248V57.633a7.7,7.7,0,0,1-7.261,7.863l-.239,0H73a1.5,1.5,0,0,1-.144-2.993L73,62.5h62a4.676,4.676,0,0,0,4.5-4.643l0-.224V18.367a4.716,4.716,0,0,0-4.3-4.862L135,13.5H73a4.676,4.676,0,0,0-4.5,4.643l0,.224V39.592a1.5,1.5,0,0,1-2.993.144l-.007-.144V18.367A7.7,7.7,0,0,1,72.761,10.5l.239,0Z"
      fill="#51abff"
    />
    <rect x="114" y="32" width="12" height="18" fill="#fff" />
    <path
      d="M126,51.5H114a1.5,1.5,0,0,1-1.5-1.5V32a1.5,1.5,0,0,1,1.5-1.5h12a1.5,1.5,0,0,1,1.5,1.5V50A1.5,1.5,0,0,1,126,51.5Zm-10.5-3h9v-15h-9Z"
      fill="#404fc7"
    />
    <polygon points="111 50 111 20 99 20 99 50 111 50" fill="#fff" fillRule="evenodd" />
    <path
      d="M111,51.5H99A1.5,1.5,0,0,1,97.5,50V20A1.5,1.5,0,0,1,99,18.5h12a1.5,1.5,0,0,1,1.5,1.5V50A1.5,1.5,0,0,1,111,51.5Zm-10.5-3h9v-27h-9Z"
      fill="#fd5c61"
    />
    <polygon points="96 50 96 32 84 32 84 50 96 50" fill="#fff" fillRule="evenodd" />
    <path
      d="M96,51.5H84A1.5,1.5,0,0,1,82.5,50V32A1.5,1.5,0,0,1,84,30.5H96A1.5,1.5,0,0,1,97.5,32V50A1.5,1.5,0,0,1,96,51.5Zm-10.5-3h9v-15h-9Z"
      fill="#41cbb7"
    />
    <circle cx="49" cy="37" r="8" fill="#404fc7" />
    <circle cx="104.745" cy="66.021" r="11.021" fill="#b4c2ee" />
    <path
      d="M104.745,78.543a12.522,12.522,0,1,1,12.521-12.522A12.536,12.536,0,0,1,104.745,78.543Zm0-22.043a9.522,9.522,0,1,0,9.521,9.521A9.532,9.532,0,0,0,104.745,56.5Z"
      fill="#404fc7"
    />
    <circle cx="141.745" cy="66.021" r="11.021" fill="#b4c2ee" />
    <path
      d="M141.745,78.543a12.522,12.522,0,1,1,12.521-12.522A12.536,12.536,0,0,1,141.745,78.543Zm0-22.043a9.522,9.522,0,1,0,9.521,9.521A9.532,9.532,0,0,0,141.745,56.5Z"
      fill="#404fc7"
    />
    <path d="M120.489,89.638a19.681,19.681,0,0,0-31.489,0" fill="#b4c2ee" fillRule="evenodd" />
    <path
      d="M90.662,89.974a18.136,18.136,0,0,1,28.165,0h3.117a1.491,1.491,0,0,0-.267-1.252,21.18,21.18,0,0,0-33.864,0,1.488,1.488,0,0,0-.268,1.252Z"
      fill="#404fc7"
    />
    <path d="M157.489,89.638a19.681,19.681,0,0,0-31.489,0" fill="#b4c2ee" fillRule="evenodd" />
    <path
      d="M127.663,89.974a18.135,18.135,0,0,1,28.164,0h3.117a1.491,1.491,0,0,0-.267-1.252,21.181,21.181,0,0,0-33.865,0,1.491,1.491,0,0,0-.267,1.252Z"
      fill="#404fc7"
    />
    <path
      d="M79.67,42.313a4.58,4.58,0,0,0-6.42,0l-5.506,5.178a9.141,9.141,0,0,1-6.26,2.47H26.538a4.48,4.48,0,1,0,0,8.96H40.153V89.947H58.308l.02-31.026h7.464a9.135,9.135,0,0,0,6.538-2.748l7.34-7.523A4.44,4.44,0,0,0,79.67,42.313Z"
      fill="#404fc7"
      fillRule="evenodd"
    />
  </svg>
);

import { useIntl } from 'react-intl';
import { ScoreResetView } from '../enums/ScoreResetView';
import { HeaderText } from './ScoreResetListHeader.styled';

export const ScoreResetListHeader = ({
  view,
  resetCount
}: {
  readonly view: ScoreResetView;
  readonly resetCount: number;
}) => {
  const intl = useIntl();
  let text = '';

  if (resetCount) {
    text =
      view === ScoreResetView.USER
        ? intl.formatMessage(
            {
              id: 'scoreResetUserInfo',
              defaultMessage:
                "This user's score has been reset {resetCount} {resetCount, plural, one {time} other {times}} on the following occasions."
            },
            { resetCount }
          )
        : intl.formatMessage(
            {
              id: 'scoreResetGlobalInfo',
              defaultMessage:
                'Scores have been reset {resetCount} {resetCount, plural, one {time} other {times}} on the following occasions.'
            },
            { resetCount }
          );
  } else if (view === ScoreResetView.USER) {
    text = intl.formatMessage({
      id: 'scoreResetUserNeverInfo',
      defaultMessage: "This user's score has never been reset."
    });
  } else {
    text = intl.formatMessage({
      id: 'scoreResetGlobalNeverInfo',
      defaultMessage: 'The scores have never been reset.'
    });
  }

  return <HeaderText data-testid="score-reset-list-header">{text}</HeaderText>;
};

import { borderRadius, ElementObserver } from '@m/alchemy-ui';
import styled from 'styled-components';

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${borderRadius('small')};
`;

interface ResponsiveImageProps {
  readonly callback: (width: number) => string;
  readonly altText: string;
}

export const ResponsiveImage = ({ callback, altText }: ResponsiveImageProps) => (
  <ElementObserver>
    {({ observedElementWidth, observeTarget }) => {
      const ideaImageSrc = callback(Math.ceil(observedElementWidth as number));

      return <Image ref={observeTarget} src={ideaImageSrc} alt={altText} />;
    }}
  </ElementObserver>
);

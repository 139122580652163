import { atMinWidth, border, Button, color, NakedButton, typeStyle, units, UserGradientIcon } from '@m/alchemy-ui';
import styled from 'styled-components';
import { Breakpoints } from '../../../../common/enums';

export const User = styled.li`
  padding: ${units(2)} 0;
  display: flex;
  align-items: flex-start;
  border-bottom: ${border()};

  ${atMinWidth(
    Breakpoints.WIDE_MOBILE_MIN,
    `
      align-items: center;
      padding: ${units(2)};
      padding-right: ${units(3)};
    `
  )};
`;

export const UserImage = styled.img`
  border-radius: 50%;
  height: ${units(5.5)};
  width: ${units(5.5)};
  cursor: pointer;
`;

export const NoUserImage = styled(UserGradientIcon)`
  height: ${units(5.5)};
  width: ${units(5.5)};
  min-width: ${units(5.5)};
  color: ${color('highlight', { palette: 'expressiveNeutral' })};
  cursor: pointer;
`;

export const UserDescr = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${units(2)};
  width: 100%;

  ${atMinWidth(
    Breakpoints.WIDE_MOBILE_MIN,
    `
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `
  )};
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;

  ${atMinWidth(
    Breakpoints.WIDE_MOBILE_MIN,
    `
      margin-right: ${units(1)};
    `
  )};
`;

export const UserName = styled(NakedButton)`
  ${typeStyle('headerS')};
  color: ${color('typeHeader')};
`;

export const Date = styled.p`
  ${typeStyle('captionM')};
  color: ${color('typeSubHeader')};
`;

export const FollowBtn = styled(Button)`
  width: fit-content;
  margin-top: ${units(0.5)};

  ${atMinWidth(
    Breakpoints.WIDE_MOBILE_MIN,
    `
    margin-top: 0;
    `
  )};
`;

import { Button, DatePicker, Input, snap, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const ScoreResetSubmissionFieldWrapper = styled.div`
  padding-bottom: ${units(3)};
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export const ScoreResetDateAndTime = styled.div`
  display: flex;
  align-items: center;
`;

export const ScoreResetStyledDatePicker = styled(DatePicker)`
  min-width: ${snap(130)};
  width: ${snap(130)};
  margin-right: ${units(1)};
  input {
    width: ${snap(130)};
  }
`;

export const ScoreResetTimeInput = styled(Input)`
  max-width: ${snap(130)};
  width: ${snap(130)};
  margin-left: ${units(1)};
`;

export const ScoreResetResetButton = styled(Button)`
  align-self: center;
  margin-left: ${units(1)};
`;

import { createContext, type ReactNode, useEffect, useMemo, useState } from 'react';

export interface PageMetadataProps {
  title: string;
  setTitle: (s: string) => void;
}

const defaultProps: PageMetadataProps = {
  title: window.document.title,
  setTitle() {}
};

const PageMetadataContext = createContext(defaultProps);

const PageMetadataProvider = ({ children }: { readonly children: ReactNode }) => {
  const [title, setTitle] = useState<string>(window.document.title || '');

  useEffect(() => {
    window.document.title = title;
  }, [title]);

  const value = useMemo(() => ({ title, setTitle }), [title, setTitle]);

  return <PageMetadataContext.Provider value={value}>{children}</PageMetadataContext.Provider>;
};

const PageMetadataConsumer = PageMetadataContext.Consumer;

export { PageMetadataContext, PageMetadataConsumer, PageMetadataProvider };

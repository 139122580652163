import { gql } from '@apollo/client';

export const SEARCH_USERS = gql`
  query searchUsers($searchText: String!) {
    searchUsers(searchText: $searchText) {
      id
      displayName
      timezone
      screenName
      createdAt
      commentsCount
      firstName
      lastName
      profileImageId
      votesCount
      profileImageFile {
        id
        name
        url
        hash
        extension
        userId
      }
    }
  }
`;

export const SEARCH_USERGROUPS = gql`
  query searchUsergroups($searchText: String!, $types: [UserGroupType!]!) {
    searchUsergroups(searchText: $searchText, types: $types) {
      id
      name
      type
    }
  }
`;

import { defineMessages } from 'react-intl';
import * as Yup from 'yup';
import { type GraphQLError } from 'graphql/error';
import { ChallengeArchived, ChallengeQuestionType } from '../../common/enums';
import {
  type ChallengeQuestion,
  type GlobalCategoryWithAssignedField,
  type Milestone,
  MilestoneType,
  type Phase,
  type Theme,
  type Translation,
  type UserGroupWithModerationStatus,
  type UserWithModerationStatus,
  VotingType
} from '../../common/core-api';

export interface ValidationProps {
  max: number;
  min: number;
  type: string;
}

const stringToNumber = (value: number | string, originalValue: string) =>
  String(originalValue).trim() === '' ? 0 : value;

const stringToNull = (value: number | string, originalValue: string) =>
  String(originalValue).trim() === '' ? null : value;

export const messages: { [key: string]: { id?: string; defaultMessage: string } } = defineMessages({
  integer: {
    id: 'validation.integer',
    defaultMessage: 'Must be a whole number (integer)'
  },
  maxLength: {
    id: 'validation.maxLength',
    defaultMessage: 'Must be at most {value} {value, plural, one {character} other {characters}}'
  },
  minLength: {
    id: 'validation.minLength',
    defaultMessage: 'Must be at least {value} {value, plural, one {character} other {characters}}'
  },
  minNumber: {
    id: 'validation.number.min',
    defaultMessage: 'Must be {value} or greater'
  },
  required: {
    id: 'validation.required',
    defaultMessage: 'This field is required'
  },
  type: {
    id: 'validation.type',
    defaultMessage: 'A number is required'
  },
  url: {
    id: 'validation.url',
    defaultMessage: 'A web address starting with https:// is required'
  },
  challengeQuestionOptionsNotEmpty: {
    id: 'challengeQuestionOptionsNotEmpty',
    defaultMessage: 'Must add at least one option'
  }
});

Yup.setLocale({
  mixed: {
    required: () => ({ ...messages.required }),
    notType: ({ type }: ValidationProps) => ({ ...messages.type, values: { value: type } })
  },
  string: {
    max: ({ max }: ValidationProps) => ({ ...messages.maxLength, values: { value: max } }),
    min: ({ min }: ValidationProps) => ({ ...messages.minLength, values: { value: min } }),
    url: () => ({ ...messages.url })
  },
  number: {
    min: ({ min }: ValidationProps) => ({ ...messages.minNumber, values: { value: min } }),
    integer: () => ({ ...messages.integer })
  }
});

const numberNullable = Yup.number().min(0).integer().nullable().transform(stringToNull);

const access = Yup.object()
  .nullable()
  .shape({
    users: Yup.array<UserWithModerationStatus[]>().nullable(),
    groups: Yup.array<UserGroupWithModerationStatus[]>().nullable(),
    options: Yup.object()
      .nullable()
      .shape({
        ideaEditLock: Yup.object()
          .shape({
            enabled: Yup.boolean(),
            graceMinutes: Yup.number().when('enabled', {
              is: true,
              // this is a false positive failure
              // eslint-disable-next-line unicorn/no-thenable
              then: Yup.number().min(0).integer().required(),
              otherwise: numberNullable
            })
          })
          .nullable()
      })
  });

const config = Yup.object().shape({
  ideaDescriptionCharacterLimit: numberNullable,
  textThemeName: Yup.string().required().trim()
});

const headings = Yup.object().shape({
  ideaDescriptionHeading: Yup.string().required().trim(),
  themeSelectHeading: Yup.string().required().trim(),
  ideaTitleHeading: Yup.string().required().trim()
});

const questions = Yup.array<ChallengeQuestion[]>().of(
  Yup.object().shape({
    name: Yup.string().required(),
    characterLimit: Yup.number().min(0).integer().transform(stringToNumber),
    type: Yup.string(),
    options: Yup.array()
      .of(
        Yup.object().shape({
          text: Yup.string().required()
        })
      )
      .when('type', {
        is: ChallengeQuestionType.OPTION,
        // eslint-disable-next-line unicorn/no-thenable
        then: Yup.array().required().min(1, messages.challengeQuestionOptionsNotEmpty)
      })
  })
);

const voting = Yup.object()
  .shape({
    scorecards: Yup.object()
      .shape({
        cards: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().min(1).max(255).required().trim()
          })
        )
      })
      .nullable(),
    wallet: Yup.object()
      .shape({
        isEnabled: Yup.boolean(),
        allowance: Yup.number().when('isEnabled', {
          is: true,
          // this is a false positive failure
          // eslint-disable-next-line unicorn/no-thenable
          then: Yup.number().min(1).max(9999).integer().required(),
          otherwise: numberNullable
        })
      })
      .nullable(),
    options: Yup.object()
      .shape({
        maxStars: numberNullable,
        minimumRatings: numberNullable,
        maxScore: numberNullable
      })
      .nullable()
  })
  .nullable();

const milestoneMinimumVotes = Yup.number()
  .nullable()
  .test({
    exclusive: false,
    params: {},
    name: 'minimumVotesRequired',
    message: messages.required,
    test(value) {
      if (this.parent.voting?.type === VotingType.SCORECARD) {
        return Number(value) > 0 && Number(value) <= 9999;
      }

      return true;
    }
  })
  .test({
    exclusive: false,
    params: {},
    name: 'minimumVotesInteger',
    message: messages.integer,
    test(value) {
      if (this.parent.voting?.type === VotingType.SCORECARD) {
        return Number.isInteger(value);
      }

      return true;
    }
  });

const milestoneTarget = Yup.number().when('type', {
  is: MilestoneType.VOTE,
  // this is a false positive failure
  // eslint-disable-next-line unicorn/no-thenable
  then: Yup.number()
    .integer()
    .min(1)
    .max(9999)
    .required()
    .test({
      exclusive: false,
      params: {},
      name: 'targetPositive',
      message: { ...messages.minNumber, values: { min: 1 } },
      test(value) {
        if (this.parent.voting?.type === VotingType.UP) {
          return Number(value) > 0;
        }

        return true;
      }
    })
    .transform(stringToNumber),
  otherwise: numberNullable
});

export const milestoneValidationSchema = Yup.object().shape({
  minimumVotes: milestoneMinimumVotes,
  name: Yup.string().min(3).max(255).required().trim(),
  questions,
  target: milestoneTarget,
  voting
});

export const funnelValidationSchema = Yup.object().shape({
  access,
  name: Yup.string().max(255).trim(),
  questions,
  voting
});

export const challengeManagementValidationSchema = Yup.object().shape({
  access,
  archived: Yup.number().integer().min(ChallengeArchived.PUBLISHED).max(ChallengeArchived.ARCHIVED).required(),
  categories: Yup.array<GlobalCategoryWithAssignedField[]>()
    .of(
      Yup.object().shape({
        order: Yup.number().integer().nullable()
      })
    )
    .nullable(),
  config,
  daysLimit: Yup.number().integer().min(0).max(9999).nullable().transform(stringToNull),
  headings,
  id: Yup.string().required(),
  milestone: Yup.object()
    .nullable()
    .shape({
      timeLimitEnabled: Yup.boolean(),
      daysLimit: Yup.number().when('timeLimitEnabled', {
        is: true,
        // this is a false positive failure
        // eslint-disable-next-line unicorn/no-thenable
        then: Yup.number().min(1).max(9999).integer().required(),
        otherwise: numberNullable
      }),
      milestones: Yup.array<Milestone[]>().of(
        Yup.object().shape({
          access,
          minimumVotes: milestoneMinimumVotes,
          questions,
          target: milestoneTarget,
          voting
        })
      )
    }),
  phases: Yup.array<Phase[]>().of(funnelValidationSchema).nullable(),
  questions,
  subtitle: Yup.string().min(3).max(255).trim(),
  themes: Yup.array<Theme[]>().of(
    Yup.object().shape({
      name: Yup.string().required(),
      order: Yup.number().integer(),
      archived: Yup.number().integer().min(ChallengeArchived.PUBLISHED).max(ChallengeArchived.UNPUBLISHED).required()
    })
  ),
  title: Yup.string().min(3).max(255).required(),
  translations: Yup.array<Translation[]>().nullable(),
  voting
});

interface Exception {
  exception?: {
    stacktrace: string[];
  };
}

export const mapSeverErrorToClient = (errors: GraphQLError[] = []) => {
  const transformedError: Record<string, unknown> = {};

  errors.forEach(({ message, extensions }) => {
    const fieldMatch = message?.match(/"input\.(.*?)"/);
    const field = fieldMatch ? fieldMatch[1].toLowerCase() : '';
    const lengthType = (extensions as Exception)?.exception?.stacktrace[0]?.includes('minimum') ? 'min' : 'max';
    const lengthValue = (extensions as Exception)?.exception?.stacktrace[0]?.match(/\d+/)?.[0] || '';

    transformedError[field] = {
      message: `${lengthType}Length`,
      value: '',
      values: {
        value: lengthValue
      }
    };
  });

  return transformedError as unknown as Error;
};

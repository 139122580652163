import { AppMenuButton, FacebookLogo, TwitterLogo, YouTubeLogo } from '@m/alchemy-ui';
import { type ICommunity } from '../../../context/Community';

interface SocialMediaMenuProps {
  label: string;
  icon: JSX.Element;
  url: string;
  'data-testid': string;
}

export const getSocialMediaOptions = (community: ICommunity) => {
  const options = [];

  if (community?.options?.twitterAllowFollow) {
    options.push({
      label: 'X',
      icon: <TwitterLogo size="20" aria-label="Twitter" aria-hidden="true" />,
      url: `https://twitter.com/${community?.options?.twitterName || ''}`,
      'data-testid': 'twitterLink'
    });
  }

  if (community?.options?.facebookAllowFollow) {
    options.push({
      label: 'Facebook',
      icon: <FacebookLogo size="20" aria-label="Facebook" aria-hidden="true" />,
      url: `https://facebook.com/${community?.options?.facebookName || ''}`,
      'data-testid': 'facebookLink'
    });
  }

  if (community?.options?.youtubeAllowFollow) {
    options.push({
      label: 'YouTube',
      icon: <YouTubeLogo size="20" aria-label="YouTube" aria-hidden="true" />,
      url: `https://youtube.com/${community?.options?.youtubeName || ''}`,
      'data-testid': 'youtubeLink'
    });
  }

  return options;
};

export const SocialMediaMenu = ({ socialMediaOptions }: { socialMediaOptions: SocialMediaMenuProps[] }) => (
  <>
    {socialMediaOptions.map(({ label, icon, url, 'data-testid': dataTestId }: SocialMediaMenuProps) => (
      <AppMenuButton
        key={dataTestId}
        label={label}
        data-testid={dataTestId}
        shouldHideLabel
        icon={icon}
        onClick={(ev) => {
          ev.preventDefault();
          window.open(url, '_blank');
        }}
      />
    ))}
  </>
);

export interface RuntimeIdp {
  region_idp_url: string;
  sp: {
    salesforce: {
      entityid: string;
    };
  };
}

export const defaultRuntimeIdp = {
  region_idp_url: '',
  sp: {
    salesforce: {
      entityid: ''
    }
  }
};

import { alchemyLight } from '@m/alchemy-ui';
import { darken, getContrast, getLuminance, lighten } from 'polished';

/*
    Polished has a helper (meetsContrastGuidelines) to determine whether contrast meets accessibility guidelines
    but it is very aggressive and would result in many customer selected colours being ignored.
    Since we have taken the decision in other accessibility work that customers should be responsible for ensuring contrast in their
    own communities this `hasContrast` check does the bare minimum of making sure primary buttons are legible to people with excellent vision.
  */
export const hasContrast = (color1: string, color2: string) => getContrast(color1, color2) > 1.1;

export const getContrastColors = (color: string) => {
  const luminance = getLuminance(color);
  const white = '#FFF';
  const black = '#000';

  if (luminance <= 0.15) {
    return {
      contrast: alchemyLight.structural.inverse,
      container: color
    };
  }

  if (luminance >= 0.55 && luminance < 0.9) {
    return {
      contrast: darken(0.6, color),
      container: color
    };
  }

  let darkLevel = 0.2;
  const lightLevel = 0.4;

  if (luminance < 0.2 || (luminance > 0.22 && luminance < 0.3)) {
    darkLevel = 0.1;
  }

  if (luminance > 0.5) {
    darkLevel = 0.3;
  }

  const darkened = darken(darkLevel, color);
  const lightened = lighten(lightLevel, color);

  const flip = getContrast(lightened, alchemyLight.structural.inverse) <= 1.05;

  return {
    element: flip ? lightened : darkened,
    container: flip ? darkened : lightened,
    contrast: flip ? black : white
  };
};

export const IncubatorTemplateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="180" height="90" viewBox="0 0 180 90">
    <defs>
      <mask id="a" x="-76.503" y="-40.003" width="301.505" height="149.505" maskUnits="userSpaceOnUse">
        <rect width="180" height="90" fill="#fff" />
      </mask>
      <mask id="b" x="0" y="0" width="180" height="99.145" maskUnits="userSpaceOnUse">
        <rect width="180" height="90" fill="#fff" />
      </mask>
    </defs>
    <rect width="180" height="90" fill="#e7f4ff" />
    <g mask="url(#a)">
      <path
        d="M72.881,17.043a16,16,0,0,1-5.632,21.666l-.292.172-119.5,68.5a16,16,0,0,1-21.838-5.924,16,16,0,0,1,5.632-21.666l.292-.172,119.5-68.5A16,16,0,0,1,72.881,17.043Zm150-49a16,16,0,0,1-5.632,21.666l-.292.172-119.5,68.5a16,16,0,0,1-16.206-27.59l.292-.172,119.5-68.5A16,16,0,0,1,222.881-31.957Z"
        fill="#51abff"
        fillOpacity="0.07"
      />
    </g>
    <g mask="url(#b)">
      <path
        d="M55.372,56.394C44.078,67.688,41.888,83.808,50.479,92.4s24.712,6.4,36.006-4.893L76,84l1-9L66,77l1-11-9.692,1.856Z"
        fill="#cce6ff"
        stroke="#51abff"
        strokeLinejoin="round"
        strokeWidth="3"
        fillRule="evenodd"
      />
    </g>
    <g mask="url(#b)">
      <path
        d="M127.546,56.394c11.294,11.294,13.485,27.414,4.893,36.005s-24.712,6.4-36.006-4.893L106.919,84l-1-9,11,2-1-11,9.692,1.856Z"
        fill="#cce6ff"
        stroke="#51abff"
        strokeLinejoin="round"
        strokeWidth="3"
        fillRule="evenodd"
      />
    </g>
    <path d="M90,10a20,20,0,1,0,14.142,5.858A20,20,0,0,0,90,10Z" fill="#fff" />
    <path
      d="M90.5,57a1.5,1.5,0,0,1,1.493,1.356L92,58.5v9a1.5,1.5,0,0,1-2.993.144L89,67.5v-9A1.5,1.5,0,0,1,90.5,57Zm7-6a1.5,1.5,0,0,1,1.493,1.356L99,52.5v9a1.5,1.5,0,0,1-2.993.144L96,61.5v-9A1.5,1.5,0,0,1,97.5,51Zm-14,0a1.5,1.5,0,0,1,1.493,1.356L85,52.5v9a1.5,1.5,0,0,1-2.993.144L82,61.5v-9A1.5,1.5,0,0,1,83.5,51ZM90,10A20,20,0,1,1,70,30,20,20,0,0,1,90,10Zm1.93,32.942H87.777a.958.958,0,1,0,0,1.916H91.93a.958.958,0,0,0,0-1.916Zm1.012-2.877H86.765a.96.96,0,0,0,0,1.918h6.177a.96.96,0,1,0,0-1.918ZM97.454,25.8A8.413,8.413,0,1,0,84.742,36.1h0v2.053a.958.958,0,0,0,.958.959h7.568a.959.959,0,1,0,0-1.917h-6.6v-.982l-.015-.633a.963.963,0,0,0-.422-.773,6.5,6.5,0,1,1,8.334-.91.959.959,0,0,0,1.39,1.321h0A8.409,8.409,0,0,0,97.454,25.8Zm-7.6-1.683A5.166,5.166,0,0,0,84.7,29.268a.958.958,0,1,0,1.916,0,3.239,3.239,0,0,1,3.235-3.236.959.959,0,1,0,0-1.917Zm-9.034-5.586a.959.959,0,0,0-.677,1.638h0L82.1,22.123a.958.958,0,1,0,1.355-1.355h0L81.5,18.81A.959.959,0,0,0,80.819,18.529Zm19.022.3A.958.958,0,0,0,98.5,18.81h0l-1.955,1.958A.958.958,0,0,0,97.9,22.123h0l1.958-1.956A.963.963,0,0,0,99.841,18.824Zm-9.988-3.682a.958.958,0,0,0-.958.958h0v2.77a.959.959,0,0,0,1.917,0h0V16.1A.958.958,0,0,0,89.853,15.142Z"
      fill="#404fc7"
    />
    <circle cx="52.5" cy="36.5" r="13.5" fill="#41cbb7" />
    <path
      d="M49.4,40.1A4.394,4.394,0,0,0,53,41.711c2.209,0,4-1.279,4-2.856S55.21,36,53,36s-4-1.279-4-2.857,1.792-2.856,4-2.856A4.394,4.394,0,0,1,56.6,31.9"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="53.571"
      y1="41.714"
      x2="53.571"
      y2="44"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="53.571"
      y1="28"
      x2="53.571"
      y2="30.286"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle cx="127.5" cy="36.5" r="13.5" fill="#41cbb7" />
    <path
      d="M124.4,40.1a4.394,4.394,0,0,0,3.6,1.615c2.209,0,4-1.279,4-2.856S130.21,36,128,36s-4-1.279-4-2.857,1.792-2.856,4-2.856a4.394,4.394,0,0,1,3.6,1.615"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="128.571"
      y1="41.714"
      x2="128.571"
      y2="44"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="128.571"
      y1="28"
      x2="128.571"
      y2="30.286"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

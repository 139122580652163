import { ArrowRightIcon, color, Tabs, units } from '@m/alchemy-ui';
import { type ReactNode } from 'react';
import styled, { css } from 'styled-components';

export interface ChallengeManagementTabProps {
  readonly onTabClick: (e: string, { value }: { value: string }) => void;
  readonly tabs: TabType[];
  readonly activeTabValue: string;
  readonly interactive: boolean;
}

interface TabType {
  label: ReactNode;
  value: string;
}

const InteractiveTabs = styled(Tabs)`
  margin-bottom: ${units(1)};
`;

const StyledIconContainer = styled.span`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  svg {
    height: ${units(2.5)};
    width: ${units(2.5)};
    color: ${color('base')};
  }
`;

const StaticTabsContainer = styled.ul`
  display: flex;
  margin-bottom: ${units(1)};
  border-bottom: 1px solid ${color('line')};
`;

const StaticTab = styled.li<{ active: boolean }>`
  position: relative;
  flex: 1;
  padding: ${units(1)};
  color: ${color('typeMeta')};
  text-align: center;
  ${({ active }) =>
    active &&
    css`
      color: ${color('base', { palette: 'interactiveConstructive' })};
      border-bottom: 2px solid ${color('base', { palette: 'interactiveConstructive' })};
    `}
  &:last-of-type {
    ${StyledIconContainer} {
      display: none;
    }
  }
`;

const StaticTabs = ({ tabs, activeTabValue }: { readonly tabs: TabType[]; readonly activeTabValue: string }) => (
  <StaticTabsContainer>
    {tabs.map((tab) => (
      <StaticTab key={tab.value} active={tab.value === activeTabValue}>
        {tab.label}
        <StyledIconContainer>
          <ArrowRightIcon aria-hidden="true" />
        </StyledIconContainer>
      </StaticTab>
    ))}
  </StaticTabsContainer>
);

export const ChallengeManagementTabs = ({
  tabs,
  interactive,
  activeTabValue,
  onTabClick
}: ChallengeManagementTabProps) =>
  interactive ? (
    // @ts-expect-error
    <InteractiveTabs tabs={tabs} activeTabValue={activeTabValue} onTabClick={onTabClick} />
  ) : (
    <StaticTabs tabs={tabs} activeTabValue={activeTabValue} />
  );

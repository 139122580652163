// STAT TYPES
// 1: Vote
// 2: Idea created
// 3: Comment created
// 4: Post view
// 5: Page view
// 6: User join
// 7: User activation  # not using, but keeping here for reference

export const defaultTypes = [1, 2, 3, 4, 5, 6];

export enum StatType {
  VOTE = 1,
  IDEA_CREATED = 2,
  COMMENT_CREATED = 3,
  POST_VIEW = 4,
  PAGE_VIEW = 5,
  USER_JOIN = 6
}

export const StatTypeScoreTitle: { [index: string]: string } = {
  VOTE: 'VOTE',
  IDEA_CREATED: 'IDEA_CREATED',
  COMMENT_CREATED: 'COMMENT_CREATED',
  POST_VIEW: 'POST_VIEW',
  PAGE_VIEW: 'PAGE_VIEW',
  USER_JOIN: 'USER_JOIN'
};

export const StatTypeOptions: { [index: string]: string } = {
  VOTE: 'Votes',
  IDEA_CREATED: 'Ideas',
  COMMENT_CREATED: 'Comments',
  POST_VIEW: 'Post Views',
  PAGE_VIEW: 'Page Views',
  USER_JOIN: 'New Users'
};

export const typeOptions = ['Votes', 'Ideas', 'Comments', 'Post Views', 'Page Views', 'New Users'];

import { Button, color, snap, typeStyle, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const NotificationsWrapper = styled.div`
  padding: ${snap(15)} ${snap(0)};
  div[class^='BinarySelectGroupstyled__BinarySelectGroupItem'] {
    margin-bottom: ${units(1)} !important;
  }
`;

export const Title = styled.h2`
  ${typeStyle('headerM')};
  color: ${color('typeHeader')};
  font-weight: 600;
`;

export const NotificationsAccordion = styled.div`
  padding: ${snap(15)} ${snap(0)};
`;

export const HelpText = styled.div`
  padding: ${snap(15)} ${snap(0)};
  ${typeStyle('bodyS')};
`;

export const Subtitle = styled.h2`
  ${typeStyle('headerS')};
  color: ${color('typeHeader')};
  padding: ${snap(20)} ${snap(0)} ${snap(0)};
`;

export const SaveButton = styled(Button)`
  float: right;
  margin-top: ${snap(20)};
`;

import { createContext, type ReactNode, useMemo, useRef, useState } from 'react';
import { type ScoreHistoryRow } from '../interface/ScoreHistoryRow';
import { type UserScoreContextProps } from '../interface/UserScoreContextProps';
import { type UserScoreHistoryMetadata } from '../interface/UserScoreHistoryMetadata';
import { type LegacyChallenge } from '../interface/LegacyChallenge';

const defaultProps: UserScoreContextProps = {
  scoreHistoryData: undefined,
  setScoreHistoryData() {},
  challengeId: '0',
  setChallengeId() {},
  challenges: [],
  setChallenges() {},
  metadata: { scoreTotal: 0, start: 0, loadMore: false },
  setMetadata() {},
  refetchScores: { current: false }
};

const UserScoreContext = createContext(defaultProps);

const UserScoreProvider = ({ children }: { readonly children: ReactNode }) => {
  const [scoreHistoryData, setScoreHistoryData] = useState<ScoreHistoryRow[] | undefined>();
  const [challengeId, setChallengeId] = useState('0');
  const [challenges, setChallenges] = useState<LegacyChallenge[] | undefined>();
  const [metadata, setMetadata] = useState<UserScoreHistoryMetadata>({
    scoreTotal: 0,
    start: 0,
    loadMore: false
  });
  const refetchScores = useRef<boolean>(false);

  const value = useMemo(
    () => ({
      scoreHistoryData,
      setScoreHistoryData,
      challengeId,
      setChallengeId,
      challenges,
      setChallenges,
      metadata,
      setMetadata,
      refetchScores
    }),
    [
      scoreHistoryData,
      setScoreHistoryData,
      challengeId,
      setChallengeId,
      challenges,
      setChallenges,
      metadata,
      setMetadata,
      refetchScores
    ]
  );

  return <UserScoreContext.Provider value={value}>{children}</UserScoreContext.Provider>;
};

export { UserScoreContext, UserScoreProvider };

import { useMemo } from 'react';
import { useRuntimeConfig } from './Runtime';

export const usePasswordComplexity = () => {
  const runtimeConfig = useRuntimeConfig();
  const passwordComplexity = useMemo(
    () => ({
      passwordComplexityEnabled: Boolean(JSON.parse(runtimeConfig.passwordComplexityEnabled || '0')) || false,
      passwordComplexityLengthMin: Number(runtimeConfig.passwordComplexityLengthMin) || 0,
      passwordComplexitySpecialMin: Number(runtimeConfig.passwordComplexitySpecialMin) || 0,
      passwordComplexitySpecialChars: String(runtimeConfig.passwordComplexitySpecialChars) || '',
      passwordComplexityLengthUpperMin: Number(runtimeConfig.passwordComplexityLengthUpperMin) || 0,
      passwordComplexityLengthLowerMin: Number(runtimeConfig.passwordComplexityLengthLowerMin) || 0,
      passwordComplexityLengthNumberMin: Number(runtimeConfig.passwordComplexityLengthNumberMin) || 0,
      passwordComplexityLifetimeMinutesMin: Number(runtimeConfig.passwordComplexityLifetimeMinutesMin) || 0
    }),
    [runtimeConfig]
  );
  return passwordComplexity;
};

export enum ToastLevel {
  CONFIRM = 'confirm',
  ERROR = 'error',
  INFORMATIVE = 'informative',
  PROGRESS = 'progress',
  WARNING = 'warning'
}

export enum ToastPlacement {
  BOTTOM = 'bottom',
  TOP = 'top'
}

export interface ToastMessageType {
  key: number;
  level: ToastLevel;
  content: string;
  shouldAutoDismiss: boolean;
  autoDismissDelay: number;
  placement?: ToastPlacement;
}

import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, ErrorMessage, LoadingIndicator, Select } from '@m/alchemy-ui';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useIntl } from 'react-intl';
import Exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import {
  type ChallengesQuery,
  type CoauthorIdeasQuery,
  type FilteredUsersQuery,
  GET_CHALLENGES,
  GET_COAUTHOR_IDEAS,
  GET_COUNTRIES,
  GET_TAG_IDEAS_AND_COUNT,
  GET_USERFIELDS_FILTERED_USERIDS,
  type GetCountriesQuery,
  type GetIdeaTagsSummaryQuery,
  type GetTagIdeasAndCountQuery
} from '../../graphql/queries';
import { useUserFields } from '../../hooks';
import { CommunityContext } from '../../context';
import { GET_IDEA_TAGS_SUMMARY } from '../../graphql/queries/stat';
import { Grid, GridCol, GridHeader, GridRow, Pagination } from './Analytics.styled';
import * as Styled from './components/SearchFilters.styled';
import { downloadIcon, ideasByTagChartOptions } from './HighchartsData';
import { CustomFilters } from './CustomFilters';
import { type IdeaTagSummaryItemType, type UserFieldType } from './types';
import { IdeasTable } from './components';
import { IDEAS_DEFAULT_OFFSET, IDEAS_DEFAULT_SORT, IDEAS_PER_PAGE } from './constants';
import { AlchemySortDirectionType, ChallengeSelectionType, CoreApiSortDirectionType } from './enums';

Exporting(Highcharts);
NoDataToDisplay(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.download = downloadIcon;

export const IdeasByTagStats = () => {
  const intl = useIntl();
  const community = useContext(CommunityContext);
  const { data: communityUserFields } = useUserFields();

  const [tag, setTag] = useState<string | undefined>();

  const defaultUserFieldOptions: UserFieldType = {};
  const [userFieldOptions, setUserFieldOptions] = useState(defaultUserFieldOptions);

  const [countries, setCountries] = useState<string[]>([]);
  const [challenge, setChallenge] = useState<string>(ChallengeSelectionType.PUBLISHED);

  const { data: countriesList } = useQuery<GetCountriesQuery>(GET_COUNTRIES, {
    variables: { id: community.id },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    countriesList?.community && setCountries(countriesList.community?.countries as string[]);
  }, [countriesList]);

  const [tagIdeasQueryVariables, setTagIdeasQueryVariables] = useState({
    challengeSelection: challenge,
    tagSelection: tag,
    filters: { userFields: JSON.stringify(userFieldOptions) },
    order: [IDEAS_DEFAULT_SORT],
    offset: IDEAS_DEFAULT_OFFSET
  });

  const isTagSelected = useMemo(() => tagIdeasQueryVariables.tagSelection !== undefined, [tagIdeasQueryVariables]);

  const { data: ideasData, loading: loadingIdeas } = useQuery<GetTagIdeasAndCountQuery>(GET_TAG_IDEAS_AND_COUNT, {
    variables: tagIdeasQueryVariables,
    // If a specific tag is not selected, do not request any ideas data
    skip: !isTagSelected
  });

  const { data: customFiltersUserIds } = useQuery<FilteredUsersQuery>(GET_USERFIELDS_FILTERED_USERIDS, {
    variables: { filters: { userFields: JSON.stringify(userFieldOptions) } },
    fetchPolicy: 'cache-first'
  });

  const { data: coauthorIdeaIds } = useQuery<CoauthorIdeasQuery>(GET_COAUTHOR_IDEAS, {
    variables: {
      customFiltersUserIds: customFiltersUserIds?.filteredUsers || [],
      filters: { userFields: JSON.stringify(userFieldOptions) }
    },
    // There is no need to request coauthors data in case custom fields are not selected
    skip: !customFiltersUserIds
  });

  const [ideaTagsSummaryQueryVariables, setIdeaTagsSummaryQueryVariables] = useState({
    challengeSelection: challenge,
    filters: { userFields: JSON.stringify(userFieldOptions) }
  });

  const { data: chartData, loading: loadingChart } = useQuery<GetIdeaTagsSummaryQuery>(GET_IDEA_TAGS_SUMMARY, {
    variables: ideaTagsSummaryQueryVariables
  });

  // Used for getting the challenges dropdown
  const getChallengesVariables = { order: ['title'] };
  const { loading: loadingChallenges, data: challengeList } = useQuery<ChallengesQuery>(GET_CHALLENGES, {
    variables: getChallengesVariables,
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    setTagIdeasQueryVariables({
      challengeSelection: challenge,
      tagSelection: tag,
      filters: { userFields: JSON.stringify(userFieldOptions) },
      order: [IDEAS_DEFAULT_SORT],
      offset: IDEAS_DEFAULT_OFFSET
    });
  }, [challenge, setTagIdeasQueryVariables, tag, userFieldOptions]);

  const baseChartOptions = useMemo(() => ideasByTagChartOptions(intl), [intl]);

  const sortIdeas = (column: string) => {
    const { order } = tagIdeasQueryVariables;
    const [sortColumn, sortDirection] = order[0];
    const newOrder = [
      [
        column,
        column === sortColumn && sortDirection === CoreApiSortDirectionType.ASC
          ? CoreApiSortDirectionType.DESC
          : CoreApiSortDirectionType.ASC
      ]
    ];

    setTagIdeasQueryVariables({
      ...tagIdeasQueryVariables,
      order: newOrder
    });
  };

  const getColumnSortDirection = (column: string) => {
    const { order } = tagIdeasQueryVariables;
    const [sortColumn, sortDirection] = order[0];
    return sortColumn === column
      ? sortDirection === CoreApiSortDirectionType.DESC
        ? AlchemySortDirectionType.DESC
        : AlchemySortDirectionType.ASC
      : 'none';
  };

  const highchartsOptions = useMemo(() => {
    const data = chartData?.ideaTagsSummary ? chartData.ideaTagsSummary : [];
    const isNotEmpty = data.length > 0;

    const tagNames = isNotEmpty ? data.map(({ tagName }: IdeaTagSummaryItemType) => tagName) : [];
    const tagIds = isNotEmpty ? data.map(({ tagId }: IdeaTagSummaryItemType) => tagId) : [];
    const ideaCounts = isNotEmpty ? data.map(({ ideaCount }: IdeaTagSummaryItemType) => ideaCount) : [];

    return {
      ...baseChartOptions,
      xAxis: [
        {
          ...baseChartOptions.xAxis[0],
          categories: tagNames
        },
        {
          ...baseChartOptions.xAxis[1],
          categories: tagIds
        }
      ],
      series: [
        {
          ...baseChartOptions.series[0],
          data: ideaCounts
        }
      ],
      plotOptions: {
        ...baseChartOptions.plotOptions,
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: (event: Highcharts.PointClickEventObject) => {
                const tagIds = event.point.series.chart.xAxis[1].categories;
                const currentTagIndex = event.point.x;
                const currentTagId = String(tagIds[currentTagIndex]);

                setTag(currentTagId);
              }
            }
          }
        }
      }
    };
  }, [baseChartOptions, chartData, setTag]);

  if ((loadingChallenges && !challengeList) || (loadingChart && !chartData)) {
    return (
      <Styled.LoadingContainer>
        <LoadingIndicator inline />
      </Styled.LoadingContainer>
    );
  }

  return (
    <Grid key="ideasByTagStats">
      <CustomFilters
        userFieldOptions={userFieldOptions}
        communityUserFields={communityUserFields}
        countries={countries}
        userFieldUpdate={setUserFieldOptions}
      />
      <GridHeader key="challengeSelectionHeader">
        {intl.formatMessage({ id: 'show', defaultMessage: 'Show' })}
      </GridHeader>
      <GridRow key="challengeSelection">
        <Select
          defaultValue={challenge}
          options={[
            {
              label: intl.formatMessage({ id: 'allPublishedChallenges', defaultMessage: 'All Published Challenges' }),
              value: ChallengeSelectionType.PUBLISHED
            },
            {
              label: intl.formatMessage({ id: 'allChallenges', defaultMessage: 'All Challenges' }),
              value: ChallengeSelectionType.ALL
            },
            ...((challengeList?.challenges || [])?.map((c) => ({
              label: c.title,
              value: c.id
            })) || {})
          ]}
          onChange={(challengeSelection) => {
            setChallenge(challengeSelection.value);
            setTag(undefined);
          }}
        />
      </GridRow>
      <GridRow key="updateButton">
        <Button
          priority="secondary"
          onClick={() => {
            setIdeaTagsSummaryQueryVariables({
              challengeSelection: challenge,
              filters: { userFields: JSON.stringify(userFieldOptions) }
            });
          }}
        >
          {intl.formatMessage({ id: 'update', defaultMessage: 'Update' })}
        </Button>
      </GridRow>
      <GridRow key="chart">
        <HighchartsReact highcharts={Highcharts} options={highchartsOptions} />
      </GridRow>
      {isTagSelected && (
        <GridRow key="table" justify="center">
          {loadingIdeas && !ideasData ? (
            <Styled.LoadingContainer>
              <LoadingIndicator inline />
            </Styled.LoadingContainer>
          ) : ideasData?.tagIdeasAndCount && ideasData.tagIdeasAndCount.count > 0 ? (
            <>
              <GridCol>
                <IdeasTable
                  getColumnSortDirection={getColumnSortDirection}
                  ideasAndCount={ideasData?.tagIdeasAndCount || { count: 0, rows: [] }}
                  intl={intl}
                  sortIdeas={sortIdeas}
                />
              </GridCol>
              <GridCol>
                <Pagination
                  numPages={Math.ceil(ideasData.tagIdeasAndCount.count / IDEAS_PER_PAGE)}
                  activeIndex={Math.ceil(tagIdeasQueryVariables.offset / IDEAS_PER_PAGE)}
                  onPageClick={(page) => {
                    const pageIndex = page.index as number;
                    setTagIdeasQueryVariables({
                      ...tagIdeasQueryVariables,
                      offset: pageIndex * IDEAS_PER_PAGE
                    });
                  }}
                />
              </GridCol>
            </>
          ) : (
            <ErrorMessage
              message={intl.formatMessage({ id: 'noIdeasWereFound', defaultMessage: 'No ideas were found' })}
              details={intl.formatMessage({
                id: 'pleaseAdjustYourSearch',
                defaultMessage: 'Please adjust your search parameters and try again.'
              })}
            />
          )}
        </GridRow>
      )}
      {isTagSelected && ideasData?.tagIdeasAndCount && ideasData.tagIdeasAndCount.count > 0 && (
        <GridRow key="exportData" justify="start">
          <GridCol>
            <Button
              priority="secondary"
              onClick={() => {
                const challengeId =
                  challenge === ChallengeSelectionType.PUBLISHED || challenge === ChallengeSelectionType.ALL
                    ? '0'
                    : challenge;

                const exportUrl = new URL('/category/admin_challengecsv/' + challengeId, window.location.origin);

                exportUrl.searchParams.append(
                  'includeUnpublished',
                  challenge === ChallengeSelectionType.PUBLISHED ? '0' : '1'
                );

                exportUrl.searchParams.append('includeComments', '0');

                tag && exportUrl.searchParams.append('tagId', tag);

                if (Object.keys(userFieldOptions).length > 0) {
                  exportUrl.searchParams.append(
                    'customFiltersUserIds',
                    (customFiltersUserIds || { filteredUsers: [] }).filteredUsers.toString()
                  );

                  exportUrl.searchParams.append('ideaIds', (coauthorIdeaIds?.coauthorIdeas || []).toString());
                }

                window.location = exportUrl as unknown as Location;
              }}
            >
              {intl.formatMessage({ id: 'exportData', defaultMessage: 'Export Data' })}
            </Button>
          </GridCol>
        </GridRow>
      )}
    </Grid>
  );
};

import { type ReactNode } from 'react';
import { BeautifulList, BeautifulListItem } from '@m/alchemy-ui';
import { DragDropContext, Draggable, Droppable, type DropResult } from 'react-beautiful-dnd';

export interface Item {
  id: string;
  content: ReactNode;
}

interface ReorderableListProps {
  readonly items: Item[];
  readonly onDragEnd: (result: DropResult) => void;
}

export const ReorderableList = ({ items, onDragEnd }: ReorderableListProps) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="droppable">
      {(provided) => (
        <BeautifulList {...provided.droppableProps} ref={provided.innerRef}>
          {items.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <BeautifulListItem draggableProvided={provided} draggableStateSnapshot={snapshot}>
                  {item.content}
                </BeautifulListItem>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </BeautifulList>
      )}
    </Droppable>
  </DragDropContext>
);

import { type IntlShape } from 'react-intl';

import { snap, SortCell, TableCell, TableRow } from '@m/alchemy-ui';
import { type SortDirection } from '@m/alchemy-ui/esm/table/table-cell-sort/sort-direction';
import { Table } from '../Analytics.styled';
import { useDateFormat } from '../../../hooks';
import { type ChallengeVotesAndCommentsItemType } from '../types';

export const ChallengeTableHeader = ({
  intl,
  getColumnSortDirection,
  sortChallenges
}: {
  readonly intl: IntlShape;
  readonly getColumnSortDirection: (sort: string) => string;
  readonly sortChallenges: (sort: string) => void;
}) => (
  <thead>
    <TableRow>
      <SortCell
        sortDirection={getColumnSortDirection('challengeTitle') as SortDirection}
        isHeader
        onToggle={() => sortChallenges('challengeTitle')}
      >
        {intl.formatMessage({ id: 'challenge', defaultMessage: 'Challenge' })}
      </SortCell>
      <SortCell
        sortDirection={getColumnSortDirection('challengeCreatedAt') as SortDirection}
        isHeader
        onToggle={() => sortChallenges('challengeCreatedAt')}
      >
        {intl.formatMessage({ id: 'created', defaultMessage: 'Created' })}
      </SortCell>
      <SortCell
        sortDirection={getColumnSortDirection('commentCount') as SortDirection}
        isHeader
        onToggle={() => sortChallenges('commentCount')}
      >
        {intl.formatMessage({ id: 'comments', defaultMessage: 'Comments' })}
      </SortCell>
      <SortCell
        sortDirection={getColumnSortDirection('voteCount') as SortDirection}
        isHeader
        onToggle={() => sortChallenges('voteCount')}
      >
        {intl.formatMessage({ id: 'votes', defaultMessage: 'Votes' })}
      </SortCell>
    </TableRow>
  </thead>
);

export const ChallengeTableBody = ({ data }: { readonly data: ChallengeVotesAndCommentsItemType[] }) => {
  const dateFormat = useDateFormat();

  return (
    <tbody>
      {data?.map((item: ChallengeVotesAndCommentsItemType) => (
        <TableRow key={item.challengeId}>
          <TableCell maxWidth={snap(120)}>
            <a href={`/category/${item.challengeId}`} target="_blank">
              {item?.challengeTitle || ''}
            </a>
          </TableCell>
          <TableCell>
            {
              dateFormat(
                new Date(item?.challengeCreatedAt || Date.now()),
                'PPpp',
                'GMT'
              ) /* forcing GMT timezone to be consistent because all charts showing data in GMT */
            }
          </TableCell>
          <TableCell>{item?.commentCount || 0}</TableCell>
          <TableCell>{item?.voteCount || 0}</TableCell>
        </TableRow>
      ))}
    </tbody>
  );
};

export const ChallengeTable = ({
  intl,
  data,
  getColumnSortDirection,
  sortChallenges
}: {
  readonly intl: IntlShape;
  readonly data: ChallengeVotesAndCommentsItemType[];
  readonly getColumnSortDirection: (columnName: string) => string;
  readonly sortChallenges: (columnName: string) => void;
}) => (
  <Table withoutScroller data-testid="ChallengeTable">
    <ChallengeTableHeader intl={intl} sortChallenges={sortChallenges} getColumnSortDirection={getColumnSortDirection} />
    <ChallengeTableBody data={data} />
  </Table>
);

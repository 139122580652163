import styled from 'styled-components';
import { Button, color, PopperBox, typeStyle, units } from '@m/alchemy-ui';

export const Holder = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units(2)};
`;

export const ListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.h3`
  ${typeStyle('bodyM')};
  margin-bottom: ${units(1)};
`;

export const ItemWrapper = styled.li`
  margin-bottom: ${units(1)};
`;

export const IconHolder = styled.div`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: ${units(0.5)};

  & > img {
    width: ${units(6)};
    height: ${units(6)};
  }
`;

export const PopperBoxHolder = styled(PopperBox)`
  background: ${color('brandSecondary', { palette: 'structural' })};
  border: 1px solid ${color('a11yBase', { palette: 'expressiveNeutral' })};
  border-radius: ${units(0.375)};
  box-shadow: rgb(0 0 0 / 25%) 0 ${units(0.75)} ${units(1)} 0;
  opacity: 0.95;
  transform: translateY(${units(0.6)});
  width: ${units(35)};

  & > div:first-child {
    border-radius: 0;

    & > div[data-popper-arrow] {
      bottom: -${units(0.625)};

      &:before {
        width: ${units(2)};
        height: ${units(2)};
        left: -${units(0.25)};
      }
    }

    &:after {
      background: transparent;
    }
  }

  & > div:last-child {
    padding: 0;
  }
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: row;
  color: ${color('contrast', { palette: 'structural' })};
`;

export const BadgeIconHolder = styled.div`
  display: flex;
  align-items: center;
  box-sizing: content-box;
  padding: ${units(0.625)};
  background: ${color('contrast', { palette: 'structural' })};
  border-radius: ${units(0.375)};
  margin: ${units(1)} ${units(0.5)};

  & > img {
    width: ${units(6)};
    height: ${units(6)};
  }
`;

export const BadgeDescriptionHolder = styled.div<{ truncated: boolean }>`
  display: flex;
  flex-direction: column;
  margin: ${units(1)} ${units(0.5)};

  ${({ truncated }) =>
    truncated &&
    `
    & > p {
      width: ${units(18)};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`;

export const BadgeName = styled.p`
  ${typeStyle('labelM')};
`;

export const BadgeDescription = styled.p`
  ${typeStyle('captionM')};
`;

export const DateAwarded = styled.div`
  ${typeStyle('captionM')};
  display: flex;
  line-height: 1.3em;
`;

export const Date = styled.p`
  ${typeStyle('actionS')};
  margin-left: ${units(0.5)};
  line-height: 1.3em;
`;

export const TotalPoints = styled.p`
  ${typeStyle('actionS')};
  color: ${color('typeSubHeader', { palette: 'structural' })};
  line-height: 1em;
`;

export const Separator = styled.div`
  height: ${units(9.37)};
  border-left: 1px solid ${color('fill', { palette: 'structural' })};
  margin-right: ${units(1.25)};
`;

export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ScoreValue = styled.p`
  ${typeStyle('headerS')};
  text-align: center;
`;

export const ScoreCaption = styled.p`
  ${typeStyle('captionM')};
  text-align: center;
`;

export const ManageBadgesButton = styled(Button)`
  margin-bottom: ${units(2)};
  margin-top: ${units(1)};
  width: fit-content;
`;

import { type ModeratorActionMessage } from '../../../../common/interfaces/ModeratorActionTypes';
import * as Styled from './ModeratorActionItem.styled';

export const ModeratorActionItem = (message: ModeratorActionMessage) => (
  <Styled.Container>
    <Styled.Title>{message.title}</Styled.Title>
    <Styled.Description>{message.description}</Styled.Description>
    <Styled.FooterContainer>
      <Styled.Footer>{message.moderator}</Styled.Footer>
      <Styled.Footer>{message.date}</Styled.Footer>
    </Styled.FooterContainer>
  </Styled.Container>
);

import { useIntl } from 'react-intl';
import {
  da,
  de,
  el,
  enGB,
  enUS,
  es,
  fi,
  fr,
  frCA,
  hu,
  it,
  ja,
  ka,
  ko,
  ms,
  nl,
  pl,
  pt,
  sk,
  tr,
  zhCN
} from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';
import { useContext } from 'react';
import { UserContext } from '../context';

export const locales: { [key: string]: Locale } = {
  'da-DK': da,
  'de-CH': de,
  'de-DE': de,
  'el-GR': el,
  'en-GB': enGB,
  'en-US': enUS,
  'es-ES': es,
  'fi-FI': fi,
  'fr-CA': frCA,
  'fr-FR': fr,
  'hu-HU': hu,
  'it-IT': it,
  'ja-JP': ja,
  'ka-GE': ka,
  'ko-KR': ko,
  'ms-MY': ms,
  'nl-NL': nl,
  'pl-PL': pl,
  'pt-PT': pt,
  'sk-SK': sk,
  'tr-TR': tr,
  'zh-CN': zhCN
};

export const useDateFormat = () => {
  const intl = useIntl();
  const { timezone: userTimezone } = useContext(UserContext);
  const dateFormat = (date: Date, formatString: string, timezone?: string) => {
    const locale = locales[intl.locale] || enGB;
    return formatInTimeZone(date, timezone || userTimezone, formatString, { locale });
  };

  return dateFormat;
};

import styled from 'styled-components';
import { units } from '@m/alchemy-ui';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${units(1)};
`;

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: ${units(1)};
  text-transform: capitalize;
`;

export const Description = styled.div`
  margin-bottom: ${units(1)};
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: ${units(1)};
`;

export const Footer = styled.div`
  font-size: ${units(1.5)};
  color: grey;
  margin-bottom: ${units(0.5)};
`;

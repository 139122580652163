/*
  Apollo Client uppercases enums.
  Even though the API will return camelCase, by the time these are seen by the front-end, they are UPPERCASE
*/

export enum MaxStars {
  MINIMUM = 3,
  MEDIUM,
  MAXIMUM
}

export enum MinimumRatings {
  MINIMUM = 1,
  MEDIUM = 5,
  MAXIMUM = 20
}

export const MaxStarsOptions = [MaxStars.MINIMUM, MaxStars.MEDIUM, MaxStars.MAXIMUM];
export const MinimumRatingsOptions = [MinimumRatings.MINIMUM, MinimumRatings.MEDIUM, MinimumRatings.MAXIMUM];

export const MaxScore = 10;

export enum ChallengeQuestionType {
  TEXT = 'text',
  OPTION = 'option',
  AUDIO = 'audio',
  VIDEO = 'video'
}

export enum ChallengeIdeaDescriptionType {
  TEXT = 'text',
  AUDIO = 'audio',
  VIDEO = 'video'
}

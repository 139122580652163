import styled from 'styled-components';
import { typeStyle, units } from '@m/alchemy-ui';

export const Holder = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units(2)};
`;

export const Title = styled.p`
  ${typeStyle('bodyM')};
  margin-bottom: ${units(1)};
`;

export const IconsHolder = styled.div`
  display: flex;
  justify-content: start;
`;

export const IconLink = styled.a`
  display: block;
  margin: ${units(0.5)} ${units(0.75)};
  cursor: poiner;

  svg {
    width: 20px;
    height: 20px;
  }
`;

import { type MouseEvent, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Toast } from '@m/alchemy-ui';
import { format } from 'date-fns';
import { type User, type UserRanking } from '../../../common/core-api';
import { useCheckFollowingStatus, useFile, useProfilePreviewDialog, useUserFollow } from '../../../hooks';
import { UserContext } from '../../../context';
import { ToastLevel, type ToastMessageType, ToastPlacement, ToggleFollowType } from '../../../common/enums';
import { getToastMessages } from '../../../helpers';
import * as Styled from './Result.styled';

export const Ranking = ({
  ranking,
  user,
  count
}: {
  readonly ranking: UserRanking;
  readonly user: User;
  readonly count: number;
}) => {
  const intl = useIntl();
  const [getFile] = useFile();
  const { toggleFollow, userFollowLoading, userUnfollowLoading } = useUserFollow();
  const currentUser = useContext(UserContext);
  const { loadProfilePreviewDialog } = useProfilePreviewDialog();
  const [isFollowing, setIsFollowing] = useState(false);
  const [messages, setMessages] = useState<ToastMessageType[]>();
  const { id, displayName, profileImageFile, createdAt } = user;
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  useEffect(() => {
    const getAuthUrl = async () => {
      const url = profileImageFile && (await getFile({ file: profileImageFile, width: 50, height: 50 }));
      setProfilePictureUrl(url || '');
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImageFile, user?.profileImageFile]);

  const isSelf = currentUser.id === id;
  const { data } = useCheckFollowingStatus({
    userId: currentUser.id,
    targetUser: id
  });

  useEffect(() => {
    if (data?.checkFollowing) {
      setIsFollowing(data?.checkFollowing?.success);
    }
  }, [data]);

  const joinedDate = format(new Date(createdAt), 'PPP');
  const handleFollowButtonClick = async () => {
    if (currentUser.id === '0') {
      return setMessages(
        getToastMessages(
          ToastLevel.INFORMATIVE,
          intl.formatMessage({ id: 'loginToFollow', defaultMessage: 'You must be logged on to follow someone' }),
          ToastPlacement.BOTTOM
        )
      );
    }

    const variables = {
      targetUser: id
    };

    await toggleFollow({
      variables,
      setMessages,
      setIsFollowing,
      toggle: isFollowing ? ToggleFollowType.UNFOLLOW : ToggleFollowType.FOLLOW
    });
  };

  const openProfilePreview = (e: MouseEvent) => {
    e.preventDefault();
    loadProfilePreviewDialog(id);
  };

  return (
    <Styled.Ranking data-testid="UserRanking">
      <Styled.Count>{count + 1}</Styled.Count>
      <a href="/" onClick={openProfilePreview} aria-label={displayName}>
        {profilePictureUrl ? (
          <Styled.UserImage data-testid="UserProfileImage" src={profilePictureUrl} aria-hidden="true" />
        ) : (
          <Styled.DefaultUserImage aria-hidden="true" />
        )}
      </a>
      <Styled.FlexGroup>
        <Styled.UserInfo>
          <Styled.UserName href="/" onClick={openProfilePreview}>
            {displayName}
          </Styled.UserName>
          <FormattedMessage id="userSince" defaultMessage="User since" />
          <Styled.JoinDate>{joinedDate}</Styled.JoinDate>
        </Styled.UserInfo>
        <div>
          <Styled.Score data-testid="UserScore">{ranking.score}</Styled.Score>
          <FormattedMessage id="points" defaultMessage="points" />
        </div>
        {!isSelf && (
          <Styled.FollowButton
            priority="secondary"
            size="small"
            onClick={handleFollowButtonClick}
            isLoading={userFollowLoading || userUnfollowLoading}
          >
            {isFollowing ? (
              <FormattedMessage id="unfollow" defaultMessage="Unfollow" />
            ) : (
              <FormattedMessage id="follow" defaultMessage="Follow" />
            )}
          </Styled.FollowButton>
        )}
      </Styled.FlexGroup>
      <Toast messages={messages} />
    </Styled.Ranking>
  );
};

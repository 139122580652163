import { Field as AlchemyField, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const FieldRow = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: ${units(3)};
  & > div {
    flex: 1;
  }
`;

export const Field = styled(AlchemyField)`
  &:not(:last-of-type) {
    margin-bottom: ${units(1)};
  }
`;

import { Field as AlchemyField, snap, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const FieldRow = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: ${units(3)};
  & > div {
    flex: 1;
  }
`;

export const PasswordHintsList = styled.ul`
  margin-left: ${units(3)};
  margin-bottom: ${units(3)};
  list-style: unset;
`;

export const PasswordHintsListItem = styled.li`
  margin-bottom: ${units(0.25)};
`;

export const Field = styled(AlchemyField)`
  width: ${snap(300)};
`;

import styled from 'styled-components';
import { color, ExclamationTriangleIcon, ImportantIcon, typeStyle, units } from '@m/alchemy-ui';

export const Title = styled.div`
  ${typeStyle('headerS')};
  margin: ${units(1)} 0;
`;

export const HelpText = styled.div`
  ${typeStyle('captionM')};
  color: ${color('typeSubHeader')};
`;

export const WarningMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${color('base', { palette: 'interactiveDestructive' })};
  margin-top: ${units(1)};
`;

export const StyledWarningIcon = styled(ExclamationTriangleIcon)`
  margin-right: ${units(1)};
`;

export const ButtonRow = styled.div`
  display: flex;
  margin-top: ${units(4)};
  padding: ${units(2)} ${units(1)} 0;
  border-top: 1px solid ${color('line')};
`;

export const ButtonGroup = styled.div`
  margin-left: auto;
  & > * {
    margin-left: ${units(1)};
  }
`;

export const StyledIcon = styled(ImportantIcon)`
  margin-left: ${units(1)};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import omit from 'lodash.omit';
import { hasValue } from '@m/magic-typescript';
import { FormattedMessage } from 'react-intl';
import { GET_USERRANKING, type GetUserrankingQuery } from '../../../graphql/queries';
import { RuntimeContext } from '../../../context';
import * as Styled from './Result.styled';
import { Ranking } from './Ranking';

const DEFAULT_LEADERBOARD_COUNT = 20;

export const Result = ({ challengeId }: { readonly challengeId?: string }) => {
  const { community } = useContext(RuntimeContext);
  const { data, loading } = useQuery<GetUserrankingQuery>(GET_USERRANKING, {
    variables: { challengeId, limit: Number(community?.leaderboardCount) || DEFAULT_LEADERBOARD_COUNT }
  });

  return (
    <Styled.Results>
      {loading && <Styled.LoadingIndicator />}
      {!loading &&
        data?.userRankings
          ?.map(
            (userRanking, index) =>
              userRanking.user && (
                <Ranking
                  key={userRanking.id}
                  user={userRanking.user}
                  ranking={omit(userRanking, 'user')}
                  count={index}
                />
              )
          )
          .filter(hasValue)}
      {!loading && (!data || !data.userRankings || data.userRankings.length === 0) && (
        <Styled.NoRankingsMesssage>
          <FormattedMessage
            id="noUserRankingsMessage"
            defaultMessage="There are currently no users on the leaderboard for this challenge."
          />
        </Styled.NoRankingsMesssage>
      )}
    </Styled.Results>
  );
};

import { useIntl } from 'react-intl';
import { useState } from 'react';
import axios from 'axios';
import { ExclamationTriangleIcon, MessageBanner, RocketIcon } from '@m/alchemy-ui';
import { useRuntimeCommunity } from '../context';
import * as Styled from './Application.styled';

export const Announcements = () => {
  const { announcements } = useRuntimeCommunity();
  const intl = useIntl();
  const [dismissed, setDismissed] = useState(false);

  if (announcements.length === 0 || dismissed) {
    return null;
  }

  const handleDismiss = () => {
    setDismissed(true);
    // php app expects all request from client scripts to have the ajax header
    axios.get('/user/updatelastannouncement', { headers: { 'X-Requested-With': 'XMLHttpRequest' } });
  };

  const getLevel = (type: string) => {
    switch (type) {
      case 'downtime':
        return 'warning';
      default:
        return 'informative';
    }
  };

  const getIcon = (type: string) => {
    switch (type) {
      case 'downtime':
        return <ExclamationTriangleIcon aria-hidden="true" />;
      case 'features':
        return <RocketIcon aria-hidden="true" />;
      default:
        return undefined;
    }
  };

  return (
    <Styled.AnnouncementWrapper>
      {announcements.map(({ text, type, link }, index) => (
        <MessageBanner
          key={text}
          onDismiss={handleDismiss}
          level={getLevel(type)}
          isDismissable={index === 0}
          icon={getIcon(type)}
        >
          <span>{text}</span>
          {link && <a href={link}>{intl.formatMessage({ id: 'Read more', defaultMessage: 'Read More' })}</a>}
        </MessageBanner>
      ))}
    </Styled.AnnouncementWrapper>
  );
};

import type React from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { units } from '@m/alchemy-ui';
import { type ActivityTimelineProps } from '../../../common/interfaces';
import { ActivityTimelineItem } from './ActivityTimelineItem';
import { ActivityTimelineContent } from '.';

const StyledActivityTimeline = styled.ul`
  /* Prevents line sticking out past last indicator */
  min-height: ${units(15.5)};
  li:last-child {
    border-color: transparent;
    // for horizontal
    max-width: 10px;
  }
  // for horizontal
  display: flex;
  width: 100%;
  padding: ${units(4)};
`;

export const ActivityTimeline = forwardRef((props: ActivityTimelineProps, ref: React.Ref<HTMLUListElement>) => {
  const { items = [], children, ...otherProps } = props;

  return (
    <StyledActivityTimeline ref={ref} data-testid="ActivityTimeline" {...otherProps}>
      {children ||
        items.map((item, index) => (
          <ActivityTimelineItem
            // eslint-disable-next-line react/no-array-index-key
            key={String(index)}
            icon={item.icon}
            customIndicator={item.customIndicator}
            itemIconWrapperPalette={item.itemIconWrapperPalette}
            itemType={item.type}
          >
            {item.content !== undefined &&
              item.content.map((content, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ActivityTimelineContent key={String(index)} {...content}>
                  {content.body}
                </ActivityTimelineContent>
              ))}
          </ActivityTimelineItem>
        ))}
    </StyledActivityTimeline>
  );
});

ActivityTimeline.displayName = 'ActivityTimeline';

ActivityTimeline.defaultProps = {
  items: []
};

import { gql } from '@apollo/client';
import { apiRequestParts } from '../queries/api';

export const SAVE_API_REQUEST = gql`
  mutation saveApiRequest($input: ApiRequestInput!) {
    saveApiRequest(input: $input) {
      ...apiRequest
    }
  }
  ${apiRequestParts}
`;

export const SAVE_API_CLIENT = gql`
  mutation saveApiClient($input: ApiClientInput!) {
    saveApiClient(input: $input) {
      id
      authEndPoint
      password
    }
  }
`;

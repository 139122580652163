import { type ChallengeMilestone, type Milestone, MilestoneType } from '../core-api';

export const defaultChallengeMilestone: ChallengeMilestone = {
  completeMessageToAuthors: '',
  completeMessageToUsers: '',
  daysLimit: '0',
  milestones: [],
  timeLimitEnabled: false,
  milestoneRejectedIdeaIds: []
};

export const defaultMilestone: Milestone = {
  id: Date.now().toString(),
  name: '',
  label: '',
  description: '',
  condition: {
    id: ''
  },
  type: MilestoneType.SUBMITTED,
  resetVotes: true,
  minimumVotes: 0,
  approachMessageToAuthors: '',
  approachMessageToUsers: '',
  approachEmailMessageToVoters: '',
  reachedEmailMessageToAuthors: '',
  approachEmailMessageToModerators: '',
  access: {
    allowComments: true,
    privateSubmissionEnabled: false
  },
  moderators: { users: [], groups: [] },
  voters: { users: [], groups: [] }
};

import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type BlogPost, type Challenge, type Idea, type IdeaUser, type Maybe } from '../../../common/core-api';
import { useFile, useMainModal } from '../../../hooks';
import { removeTags } from '../../../helpers';
import { CommentNotificationTypes } from '../../../common/enums';
import { RelativeDateWithTooltip, Tooltip } from '../../../common/components';
import { AnonymousIcon, AnonymousName } from '../NotificationStream.styled';
import { ANONYMOUS_USER_ID } from '../../shared-constants';
import * as Styled from './CommentContent.styled';

export const CommentContent = ({
  item,
  author,
  challenge,
  type,
  commentText,
  time,
  commentId,
  isValid
}: {
  readonly item?: Maybe<BlogPost | Idea>;
  readonly author?: Maybe<IdeaUser>;
  readonly challenge?: Maybe<Challenge>;
  readonly type?: CommentNotificationTypes;
  readonly commentText: string;
  readonly time: string;
  readonly commentId?: string | null;
  readonly isValid: boolean;
}) => {
  const [getFile] = useFile();
  const [openModal] = useMainModal();
  const intl = useIntl();

  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  useEffect(() => {
    const getAuthUrl = async () => {
      const url =
        author &&
        'profileImageFile' in author &&
        author?.profileImageFile &&
        (await getFile({ file: author.profileImageFile, width: 30, height: 30 }));
      setProfilePictureUrl(url || '');
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [author]);

  if (!item || !type) {
    return null;
  }

  const message = intl.formatMessage({
    id: 'youNoLongerHaveAccessToThisItem',
    defaultMessage: 'You no longer have access to this item'
  });

  const getItemUrl = () => {
    const url =
      type &&
      [
        CommentNotificationTypes.REASON_BLOG_SINGLE,
        CommentNotificationTypes.REASON_BLOG_SAME_USER,
        CommentNotificationTypes.REASON_BLOG_DIFFERENT_USER
      ].includes(type)
        ? `/blogpost/${item.id}`
        : `/post/${item.id}`;

    return url;
  };

  const commentBodyClicked = () => {
    const url = `${getItemUrl()}?highlightPost=${commentId}`;

    window.open(url, '_blank');
  };

  const isAuthorAnonymous = author?.id === ANONYMOUS_USER_ID;
  const itemUrl = getItemUrl();
  const clearTitle = removeTags(item.title || '');
  const clearChallengeTitle = removeTags(challenge?.title || '');

  return (
    <Styled.ContentContainer caretLocation="leftTop" border="none" depth={2} data-testid="CommentContent">
      <Styled.CommentTitle>
        <Styled.AlignItems>
          {(type === CommentNotificationTypes.REASON_IDEA_SINGLE ||
            type === CommentNotificationTypes.REASON_IDEA_SAME_USER ||
            type === CommentNotificationTypes.REASON_USER_SINGLE ||
            type === CommentNotificationTypes.REASON_USER_SAME_USER ||
            type === CommentNotificationTypes.REASON_USER_DIFFERENT_CHALLENGES ||
            type === CommentNotificationTypes.REASON_TAG) &&
            (isValid ? (
              <Styled.LinkedTitle href={itemUrl} target="_blank" rel="noreferrer">
                {clearTitle}
              </Styled.LinkedTitle>
            ) : (
              <Tooltip content={message}>
                <Styled.LinkedTitleGreyout target="_blank" rel="noreferrer">
                  {clearTitle}
                </Styled.LinkedTitleGreyout>
              </Tooltip>
            ))}
          {type &&
            [CommentNotificationTypes.REASON_BLOG_SINGLE, CommentNotificationTypes.REASON_BLOG_SAME_USER].includes(
              type
            ) &&
            (isValid ? (
              <Styled.LinkedTitle href={itemUrl} target="_blank" rel="noreferrer">
                {clearTitle}
              </Styled.LinkedTitle>
            ) : (
              <Tooltip content={message}>
                <Styled.LinkedTitleGreyout target="_blank" rel="noreferrer">
                  {clearTitle}
                </Styled.LinkedTitleGreyout>
              </Tooltip>
            ))}
          {type === CommentNotificationTypes.REASON_IDEA_SAME_USER && (
            <>
              <FormattedMessage id="fromTheChallenge" defaultMessage="from the challenge" />
              {isValid ? (
                <Styled.LinkedTitle href={`/category/${challenge?.id}`} target="_blank" rel="noreferrer">
                  {clearChallengeTitle}
                </Styled.LinkedTitle>
              ) : (
                <Tooltip content={message}>
                  <Styled.LinkedTitleGreyout target="_blank" rel="noreferrer">
                    {clearChallengeTitle}
                  </Styled.LinkedTitleGreyout>
                </Tooltip>
              )}
            </>
          )}
          {(type === CommentNotificationTypes.REASON_IDEA_SAME_IDEA ||
            type === CommentNotificationTypes.REASON_USER_SAME_CHALLENGE ||
            type === CommentNotificationTypes.REASON_CHALLENGE_SINGLE ||
            type === CommentNotificationTypes.REASON_CHALLENGE_SAME_CHALLENGE ||
            type === CommentNotificationTypes.REASON_BLOG_DIFFERENT_USER) && (
            <Styled.AuthorWithImage>
              {isAuthorAnonymous ? (
                <>
                  <AnonymousIcon aria-hidden="true" />
                  <AnonymousName>
                    <FormattedMessage id="anonymous" defaultMessage="Anonymous" />
                  </AnonymousName>
                </>
              ) : (
                <>
                  {profilePictureUrl && <Styled.ProfileImage data-testid="UserProfileImage" src={profilePictureUrl} />}
                  <Styled.AuthorName
                    href={`/user/activity/${author?.id}`}
                    onClick={(e) =>
                      openModal(e, {
                        title: intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' }),
                        url: `/user/view/${author?.id}`
                      })
                    }
                  >
                    {author?.displayName}
                  </Styled.AuthorName>
                </>
              )}
              <FormattedMessage id="hasCommented" defaultMessage="has commented:" />
            </Styled.AuthorWithImage>
          )}
        </Styled.AlignItems>
        {time && <RelativeDateWithTooltip time={time} />}
      </Styled.CommentTitle>
      {isValid ? (
        <Styled.CommentBody onClick={commentBodyClicked}>{removeTags(commentText)}</Styled.CommentBody>
      ) : (
        <Tooltip content={message}>
          <Styled.CommentBodyGreyout>{removeTags(commentText)}</Styled.CommentBodyGreyout>
        </Tooltip>
      )}
    </Styled.ContentContainer>
  );
};

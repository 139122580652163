import styled, { css } from 'styled-components';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  borderRadius,
  boxShadow,
  Button,
  Checkbox,
  color,
  Field,
  GridCol,
  Input,
  PopperBox,
  SearchField,
  snap,
  typeStyle,
  units
} from '@m/alchemy-ui';

export const StyledForm = styled.form`
  align-items: center;
  background-color: ${color('canvas')};
  flex-wrap: wrap;
  justify-content: center;
  padding: ${units(2)} ${units(2.5)};
`;

export const StyledColumn = styled(GridCol)`
  display: flex;
`;

export const StyledField = styled(Field)`
  align-self: center;
  align-items: center;
  label {
    color: inherit;
  }
  input {
    background: ${color('container')};
    border: 2px solid ${color('line')};
  }
`;

export const AdvancedFilters = styled.div<{ displayAdvancedFilters: boolean }>`
  align-items: center;
  align-self: center;
  display: ${({ displayAdvancedFilters }) => (displayAdvancedFilters ? 'flex' : 'none')};
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const FilterName = styled.div`
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FilterButton = styled(Button)`
  ${typeStyle('bodyS')};
  background-color: ${color('container')};
  border-color: ${color('typeMeta')};
  border-radius: ${borderRadius('extraLarge')};
  border: none;
  box-shadow: ${boxShadow(2)};
  color: inherit;
  font-weight: 400;
  margin: ${units(0.5)};
  min-width: ${snap(150)};
  padding: ${units(0.5)} ${units(3)};
  width: 100%;

  > * {
    justify-content: flex-start;
  }

  :hover,
  :focus {
    background-color: ${color('container')} !important;
  }

  @media (min-width: 550px) {
    width: 48%;
  }
  @media (min-width: 750px) {
    width: 32%;
  }
`;

export const ArrowDown = styled(ArrowDownIcon)`
  color: ${color('base')};
  height: ${units(2.5)};
  margin-left: ${units(1)};
  min-width: ${units(2.5)};
  width: ${units(2.5)};
`;

export const ArrowUp = styled(ArrowUpIcon)`
  color: ${color('base')};
  height: ${units(2.5)};
  margin-left: ${units(1)};
  min-width: ${units(2.5)};
  width: ${units(2.5)};
`;

export const FiltersContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${units(1)};
`;

export const AddedFilter = styled.div`
  align-items: center;
  background-color: ${color('fill')};
  border-radius: ${borderRadius('extraLarge')};
  display: flex;
  margin-bottom: ${units(0.5)};
  margin-right: ${units(0.5)};
  padding: ${units(0.5)} ${units(1.5)};
  width: fit-content;
`;

export const FilterInputSubmitButton = styled(Button)`
  margin-top: ${units(1)};
`;

export const SearchSubmitButton = styled(Button)<{ floatRight?: boolean }>`
  min-height: unset;
  padding: 7px 16px;
  ${({ floatRight }) =>
    floatRight &&
    css`
      margin-left: auto;
    `}
`;

export const FiltersCount = styled.span`
  color: ${color('typeLink')};
  margin-left: auto;
`;

export const LinkButton = styled(Button)`
  ${typeStyle('bodyS')};
  background: none;
  border-radius: ${borderRadius('large')};
  border: none;
  margin-right: ${units(1)};
  padding: ${units(0)};
  text-decoration: underline;
  width: fit-content;
  :hover,
  :focus {
    background-color: transparent !important;
  }
`;

export const OptionToggleButton = styled(Button)`
  ${typeStyle('bodyM')};
  background: none;
  border: none;
  color: inherit;
  padding: ${units(0)};
  width: fit-content;
  :hover,
  :focus {
    background-color: transparent !important;
  }
`;

export const StyledSearchField = styled(SearchField)`
  margin-bottom: ${units(1.5)};
`;

export const StyledPopperBox = styled(PopperBox)`
  background: ${color('container')};
  padding-bottom: ${units(1)};
`;

export const CheckboxFilters = styled.div`
  max-height: 50vh;
`;

// adding outline styling for checkbox and input for Alchemy PopperBox,
// focus always automatically moves into the box,
// but indicator appearance is not consistent
export const StyledCheckbox = styled(Checkbox)`
  :focus-within {
    outline: 1px auto ${color('base', { palette: 'interactiveConstructive' })};
  }
`;

export const StyledInput = styled(Input)`
  :focus-within {
    outline: 1px auto ${color('base', { palette: 'interactiveConstructive' })};
  }
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: ${units(1)};
`;

export const AdvancedOptionsButtons = styled.div`
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: ${units(1.5)};
  text-align: right;
`;

export const decodeHtml = (input = '') => {
  const tag = document.createElement('textarea');
  tag.innerHTML = input;
  return tag.value;
};

export const removeTags = (input = '') => {
  const div = document.createElement('div');
  div.innerHTML = input;
  return div.textContent;
};

import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { type ModeratorActionMessage, type ModeratorActionResult } from '../../common/interfaces/ModeratorActionTypes';
import { type ModeratorActionProps } from '../../features';
import { GET_MODERATOR_ACTIONS, type GetModeratorActionLogsQuery } from '../../graphql/queries';
import { ModeratorActionItem } from './components/ModeratorActionItem/ModeratorActionItem';
import { createMessage } from './helpers/createMessage';
import ToggleShowAllLess from './components/ToggleShowMoreLess/ToggleShowAllLess';

export const ModeratorActionPanel = (props: ModeratorActionProps) => {
  const { postId, user } = props;
  const intl = useIntl();
  const defaultOffset = 0;
  const defaultLimit = 3;

  const [moderatorActionResult, setModeratorActionResult] = useState<ModeratorActionResult>(
    {} as ModeratorActionResult
  );
  const [messages, setMessages] = useState<ModeratorActionMessage[]>([] as ModeratorActionMessage[]);
  const [isShowAll, setIsShowAll] = useState<boolean>(true);
  const [isAllLoaded, setIsAllLoaded] = useState<boolean>(false);

  const { data, loading, refetch } = useQuery<GetModeratorActionLogsQuery>(GET_MODERATOR_ACTIONS, {
    variables: { ideaId: Number(postId), language: user?.language, offset: defaultOffset, limit: defaultLimit }
  });

  useEffect(() => {
    setModeratorActionResult(data?.moderatorActionLogs as ModeratorActionResult);
  }, [data]);

  useEffect(() => {
    const messages = moderatorActionResult?.items?.map((item) => createMessage(item, intl));
    setMessages(messages);
  }, [intl, moderatorActionResult]);

  const isShowAllDisabled = moderatorActionResult?.total <= defaultLimit;
  const Header = () => (
    <>
      {intl.formatMessage({ id: 'moderatorAction.moderatorActivity', defaultMessage: 'Moderator Activity' })}
      {` (${moderatorActionResult?.total || 0})`}
    </>
  );

  const toggleShowAllLess = () => {
    if (!isAllLoaded) {
      refetch({
        ideaId: Number(postId),
        language: user?.language,
        offset: defaultOffset,
        limit: isShowAll ? moderatorActionResult?.total : defaultLimit
      }).then(() => setIsAllLoaded(true));
    }

    setIsShowAll(!isShowAll);
  };

  const renderMessagesToElements = (messages: ModeratorActionMessage[]): JSX.Element[] =>
    messages?.map((message) => <ModeratorActionItem key={message.id} {...message} />);

  /* eslint-disable react/button-has-type */
  return (
    <>
      <div className="mdl-idea-details__sidebar-title">
        <Header />
        <button
          className="i-sidebar__section-expand-button mdl-button -tertiary -icon-only"
          title="Moderator Actions"
          aria-expanded="true"
          aria-label="click to collapse moderator actions"
        >
          <span className="mdl-icon -s">
            <svg>
              <use href="#arrow-down" />
            </svg>
          </span>
        </button>
      </div>
      <div className="mdl-idea-details__sidebar-content">
        {renderMessagesToElements(messages?.slice(0, isShowAll ? defaultLimit : moderatorActionResult?.total))}
        <ToggleShowAllLess
          isShowAll={isShowAll}
          isLoading={loading}
          isDisabled={isShowAllDisabled}
          toggle={toggleShowAllLess}
        />
      </div>
    </>
  );
};

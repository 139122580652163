import type React from 'react';
import { forwardRef, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon, Button } from '@m/alchemy-ui';
import { type ActivityTimelineContentProps } from '../../../common/interfaces';
import * as Styled from './ActivityTimelineContent.styled';

export const ActivityTimelineContent = forwardRef(
  (props: ActivityTimelineContentProps, ref: React.Ref<HTMLDivElement>) => {
    const { title, subtitle, icon, timestamp, body, children, collapseConfig = {}, ...otherProps } = props;

    const [isCollapsed, setIsCollapsed] = useState(collapseConfig.defaultCollapsed);

    const isCollapsable = Object.keys(collapseConfig).length > 0;
    const isControlled = collapseConfig.isCollapsed !== undefined;
    const isBodyCollapsed: boolean | undefined = isControlled ? collapseConfig.isCollapsed : isCollapsed;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      collapseConfig.onClick && collapseConfig.onClick(event, isBodyCollapsed);
      !isControlled && setIsCollapsed(!isCollapsed);
    };

    return (
      <Styled.ActivityTimelineContent ref={ref} data-testid="ActivityTimelineContent" {...otherProps}>
        <Styled.ActivityTimelineContentHeader>
          <Styled.ActivityTimelineContentHeaderStart>
            {icon}
            <div>
              {title && <Styled.ActivityTimelineContentTitle>{title}</Styled.ActivityTimelineContentTitle>}
              {subtitle && <Styled.ActivityTimelineContentSubtitle>{subtitle}</Styled.ActivityTimelineContentSubtitle>}
            </div>
          </Styled.ActivityTimelineContentHeaderStart>
          {timestamp && <Styled.ActivityTimelineContentTimestamp>{timestamp}</Styled.ActivityTimelineContentTimestamp>}
        </Styled.ActivityTimelineContentHeader>
        <Styled.ActivityTimelineContentBody isBodyCollapsed={isBodyCollapsed}>
          {body || children}
        </Styled.ActivityTimelineContentBody>
        {isCollapsable && (
          <Styled.ActivityTimelineContentFooter>
            <Button priority="tertiary" onClick={handleClick}>
              {isBodyCollapsed ? collapseConfig.expandLabel : collapseConfig.collapseLabel}
              {collapseConfig.hideLabelArrows ? null : isCollapsed ? (
                <ArrowDownIcon size={24} aria-hidden="true" />
              ) : (
                <ArrowUpIcon size={24} aria-hidden="true" />
              )}
            </Button>
          </Styled.ActivityTimelineContentFooter>
        )}
      </Styled.ActivityTimelineContent>
    );
  }
);

ActivityTimelineContent.displayName = 'ActivityTimelineContent';

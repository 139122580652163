import React from 'react';
import { FormattedMessage } from 'react-intl';
import { type User } from '../../../../common/core-api';
import UserItem from './UserItem';
import * as Styled from './UserList.styled';
import { NoUserMessage } from './FollowingContent.styled';

export const UserList = React.forwardRef(function UserList(
  {
    userList,
    onProfilePopupLoaded,
    type,
    toggleFollowState,
    refetchUsers,
    isProfileOwner,
    userFollowing,
    setUserFollowing,
    hideShowMoreButton,
    updateIndex
  }: {
    readonly userList: User[];
    readonly onProfilePopupLoaded: (id: string) => void;
    readonly type: 'followersTab' | 'followingTab';
    readonly toggleFollowState: (id: string, type: string) => void;
    readonly refetchUsers: () => void;
    readonly isProfileOwner: boolean;
    readonly userFollowing: number;
    readonly setUserFollowing: (count: number) => void;
    readonly userFollowers: number;
    readonly setUserFollowers: (count: number) => void;
    readonly hideShowMoreButton: boolean;
    readonly updateIndex: () => void;
  },
  ref: React.Ref<HTMLDivElement>
) {
  const userPagination = 100;

  const getMoreUsers = () => {
    const count = userFollowing + userPagination;
    setUserFollowing(count);
  };

  if (!userList) {
    return null;
  }

  return (
    <div ref={ref}>
      <Styled.UserListContainer>
        {userList.map((user) => (
          <UserItem
            key={user.id}
            user={user}
            onProfilePopupLoaded={onProfilePopupLoaded}
            type={type}
            toggleFollowState={toggleFollowState}
            refetchUsers={refetchUsers}
            isProfileOwner={isProfileOwner}
            updateIndex={updateIndex}
          />
        ))}
      </Styled.UserListContainer>
      {hideShowMoreButton ? (
        <NoUserMessage>
          <FormattedMessage id="noUsersFound" defaultMessage="No users found." />
        </NoUserMessage>
      ) : (
        <Styled.MoreButton priority="secondary" onClick={getMoreUsers}>
          <FormattedMessage id="showMore" defaultMessage="Show more" />
        </Styled.MoreButton>
      )}
    </div>
  );
});

export default UserList;

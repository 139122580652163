import styled from 'styled-components';
import { color, typeStyle, units } from '@m/alchemy-ui';

export const Wrapper = styled.div`
  background-color: ${color('container')};
  border-radius: 0 0 ${units(2.25)} ${units(2.25)};
`;

export const ListHolder = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const FieldItem = styled.li`
  margin-top: ${units(2.5)};

  &:first-child {
    margin-top: 0;
  }

  & > p:first-child {
    margin-bottom: ${units(1)};
  }
`;

export const FieldTitle = styled.p`
  ${typeStyle('bodyS')};
  color: ${color('typeMeta', { palette: 'structural' })};
`;

export const FieldDescription = styled.p`
  ${typeStyle('bodyS')};
  color: ${color('typeBody', { palette: 'structural' })};
  padding-left: ${units(1.5)};
`;

import { useState } from 'react';
import { Select } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { GET_USER_ACCESSIBLE_CHALLENGES, type UserAccessibleChallengesQuery } from '../../graphql/queries';
import { ChallengePublicationStatus } from '../../common/core-api';
import * as Styled from './Leaderboard.styled';
import { Result } from './components/Result';

export const Leaderboard = () => {
  const intl = useIntl();

  const { data, loading } = useQuery<UserAccessibleChallengesQuery>(GET_USER_ACCESSIBLE_CHALLENGES, {
    variables: { publicationStatus: ChallengePublicationStatus.PUBLISHED }
  });
  const [challengeId, setChallengeId] = useState<string>();

  const getSelectOptions = () =>
    (!loading &&
      data?.accessibleChallenges?.map(({ id, title }) => ({
        label: title,
        value: id
      }))) ||
    [];

  return (
    <Styled.Wrapper>
      <Styled.Title>
        <FormattedMessage id="leaderboard" defaultMessage="Leaderboard" />
      </Styled.Title>
      <Styled.ChallengeSelectField
        isFullWidth
        label={intl.formatMessage({ id: 'show', defaultMessage: 'Show' })}
        layoutVariant="inline"
        input={
          <Select
            isFullWidth
            isLoading={loading}
            defaultValue="0"
            options={[
              { label: intl.formatMessage({ id: 'overall', defaultMessage: 'Overall' }), value: '0' },
              ...getSelectOptions()
            ]}
            onChange={({ value }) => setChallengeId(value)}
          />
        }
      />
      <Result challengeId={challengeId} />
    </Styled.Wrapper>
  );
};

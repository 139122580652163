import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, DateRangePicker, ErrorMessage, LoadingIndicator, Select, Toast } from '@m/alchemy-ui';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useIntl } from 'react-intl';
import { getToastMessages } from '../../helpers';
import {
  type ChallengesQuery,
  type CoauthorIdeasQuery,
  type FilteredUsersQuery,
  GET_CHALLENGES,
  GET_COAUTHOR_IDEAS,
  GET_COUNTRIES,
  GET_IDEAS_AND_COUNT,
  GET_USERFIELDS_FILTERED_USERIDS,
  type GetCountriesQuery,
  type GetDailySummaryQuery,
  type GetIdeasAndCountQuery
} from '../../graphql/queries';
import { useUserFields } from '../../hooks';
import { CommunityContext } from '../../context';
import { GET_DAILY_SUMMARY } from '../../graphql/queries/stat';
import { ToastLevel, type ToastMessageType, ToastPlacement } from '../../common/enums';
import { Grid, GridCol, GridHeader, GridRow, Pagination } from './Analytics.styled';
import * as Styled from './components/SearchFilters.styled';
import { ideaStatsChartOptions } from './HighchartsData';
import { CustomFilters } from './CustomFilters';
import { type UserFieldType } from './types';
import { IdeasTable } from './components/IdeasTable';
import { EXPORT_TO_CSV_ENDPOINT } from './constants';

export const MemoizedHighcharts = ({
  highcharts,
  options,
  constructorType,
  updateArgs,
  callback
}: {
  readonly highcharts: typeof Highcharts;
  // highcharts and alchemy don't play nice, need to skip this lint rule
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly options: any;
  readonly constructorType: string;
  readonly updateArgs: boolean[];
  readonly callback: (c: HighchartsReact.Props) => void;
}) => (
  <HighchartsReact
    highcharts={highcharts}
    options={options}
    constructorType={constructorType}
    updateArgs={updateArgs}
    callback={callback}
  />
);

export const MemoizedHighchartsReact = React.memo(
  MemoizedHighcharts,
  (a, b) => JSON.stringify(a.options.series) === JSON.stringify(b.options.series)
);

export const IdeaStats = () => {
  const intl = useIntl();
  const community = useContext(CommunityContext);
  const { data: communityUserFields } = useUserFields();

  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [clickDate, setClickDate] = useState<Date | undefined>();

  const defaultUserFieldOptions: UserFieldType = {};
  const [userFieldOptions, setUserFieldOptions] = useState(defaultUserFieldOptions);

  const [countries, setCountries] = useState<string[]>([]);
  const [challenge, setChallenge] = useState<string>('allPublishedChallenges');
  const [chartData, setChartData] = useState<GetDailySummaryQuery>({ dailySummary: [] });
  const [sort, setSort] = useState<string[][]>([['createdAt', 'DESC']]);
  const [chart, setChart] = useState<Highcharts.Chart>();
  const [offset, setOffset] = useState<number>(0);

  const [messages, setMessages] = useState<ToastMessageType[]>();

  const { data: countriesList } = useQuery<GetCountriesQuery>(GET_COUNTRIES, {
    variables: { id: community.id },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (countriesList?.community) {
      countriesList.community && setCountries(countriesList.community.countries as string[]);
    }
  }, [countriesList]);

  const userFields = JSON.stringify(userFieldOptions);

  const [ideasQueryVariables, setIdeasQueryVariables] = useState({
    challengeSelection: challenge,
    filters: { userFields },
    startDate,
    endDate,
    order: sort,
    offset: offset * 10
  });

  useEffect(() => {
    setIdeasQueryVariables({
      challengeSelection: challenge,
      filters: { userFields },
      startDate,
      endDate,
      order: sort,
      offset: offset * 10
    });
  }, [challenge, setIdeasQueryVariables, userFields, startDate, endDate, sort, offset]);

  const [ideasAndCount, setIdeasAndCount] = useState<GetIdeasAndCountQuery['ideasAndCount']>();

  const [getIdeasData, { loading: loadingIdeas }] = useLazyQuery<GetIdeasAndCountQuery>(GET_IDEAS_AND_COUNT, {
    variables: ideasQueryVariables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      setIdeasAndCount(response?.ideasAndCount);
      if (chart?.xAxis) {
        chart.xAxis[0].setExtremes(startDate?.valueOf(), (endDate?.valueOf() || 0) + 24 * 60 * 60 * 1000); // add 24 hours to get the end of the day
      }
    }
  });

  const getChallengesVariables = { order: ['title'] };
  const { loading: loadingChallenges, data: challengeList } = useQuery<ChallengesQuery>(GET_CHALLENGES, {
    variables: getChallengesVariables,
    fetchPolicy: 'cache-first'
  });

  const dailySummaryQueryVariables = {
    challengeSelection: challenge,
    filters: { userFields },
    // Use toDateString because we don't care about time,
    // and the absence of a timezone is fine because we treat everything as GMT in charts currently
    startDate: startDate ? startDate.toDateString() : undefined
  };

  const [getChartData, { loading: loadingChart, data: fetchedChartData }] = useLazyQuery<GetDailySummaryQuery>(
    GET_DAILY_SUMMARY,
    {
      variables: dailySummaryQueryVariables,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setChartData(response);
      }
    }
  );

  const [getFilteredUserIds, { data: customFiltersUserIds }] = useLazyQuery<FilteredUsersQuery>(
    GET_USERFIELDS_FILTERED_USERIDS,
    {
      variables: { filters: { userFields } },
      onCompleted: (response) => {
        getCoauthorIdeaIds({
          variables: {
            filters: { userFields },
            customFiltersUserIds: response?.filteredUsers || []
          }
        });
      }
    }
  );

  const [getCoauthorIdeaIds, { data: coauthorIdeaIds }] = useLazyQuery<CoauthorIdeasQuery>(GET_COAUTHOR_IDEAS, {
    variables: { customUserFieldsIds: customFiltersUserIds, filters: { userFields } }
  });

  useEffect(() => {
    if (fetchedChartData?.dailySummary && !startDate && !endDate) {
      setStartDate(new Date(fetchedChartData?.dailySummary[0]?.created || Date.now()));
      setEndDate(
        new Date(fetchedChartData?.dailySummary[fetchedChartData.dailySummary.length - 1]?.created || Date.now())
      );
    }
  }, [chartData, fetchedChartData, startDate, endDate]);

  const lastUserFieldOptions = useRef<UserFieldType>(userFieldOptions);
  const lastChallenge = useRef<string>(challenge);

  useEffect(() => {
    lastUserFieldOptions.current = userFieldOptions;
    lastChallenge.current = challenge;
  }, [userFieldOptions, challenge]);

  const highchartsOptions = {
    ...ideaStatsChartOptions(intl),

    series: [
      {
        name: intl.formatMessage({ id: 'ideas', defaultMessage: 'Ideas' }),
        data: chartData ? chartData?.dailySummary?.map(({ created, total }) => [created, total]) : []
      }
    ],
    plotOptions: {
      ...ideaStatsChartOptions(intl).plotOptions,
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click(event: Highcharts.PointClickEventObject) {
              const clickedDate = new Date(event.point.x);
              const newIdeasQueryVariables = {
                startDate: clickedDate,
                endDate: clickedDate,
                challengeSelection: lastChallenge.current,
                filters: { userFields: JSON.stringify(lastUserFieldOptions.current) },
                order: sort,
                offset: 0
              };
              getIdeasData({ variables: newIdeasQueryVariables });
              setOffset(0);
              setClickDate(clickedDate);
              setIdeasQueryVariables(newIdeasQueryVariables);
            }
          }
        }
      }
    }
  };

  const ideasSort = (column: string) => {
    const [sortColumn, sortDirection] = sort[0];
    const newOrder = [[column, column === sortColumn && sortDirection === 'ASC' ? 'DESC' : 'ASC']];
    getIdeasData({
      variables: {
        ...ideasQueryVariables,
        ...(clickDate && { startDate: clickDate, endDate: clickDate }),
        order: newOrder
      }
    });
    setSort(newOrder);
  };

  const getColumnSortDirection = (column: string) => {
    const [sortColumn, sortDirection] = sort[0];
    return sortColumn === column ? (sortDirection === 'DESC' ? 'descending' : 'ascending') : 'none';
  };

  if (loadingChallenges && !challengeList) {
    return (
      <Styled.LoadingContainer>
        <LoadingIndicator inline />
      </Styled.LoadingContainer>
    );
  }

  const handleUpdateClick = () => {
    setOffset(0);
    if (clickDate) {
      setClickDate(undefined);
      setIdeasQueryVariables({ ...ideasQueryVariables, offset: 0, startDate, endDate });
    }

    getChartData({ variables: dailySummaryQueryVariables });
    getIdeasData({ variables: { ...ideasQueryVariables, offset: 0, startDate, endDate } });
    getFilteredUserIds({ variables: { filters: { userFields } } });
  };

  return (
    <Grid key="ideaStats">
      <CustomFilters
        userFieldOptions={userFieldOptions}
        communityUserFields={communityUserFields}
        countries={countries}
        userFieldUpdate={setUserFieldOptions}
      />
      <GridHeader key="challengeSelectionHeader">
        {intl.formatMessage({ id: 'show', defaultMessage: 'Show' })}
      </GridHeader>
      <GridRow key="challengeSelection">
        <Select
          defaultValue={challenge}
          options={[
            {
              label: intl.formatMessage({ id: 'allPublishedChallenges', defaultMessage: 'All Published Challenges' }),
              value: 'allPublishedChallenges'
            },
            {
              label: intl.formatMessage({ id: 'allChallenges', defaultMessage: 'All Challenges' }),
              value: 'allChallenges'
            },
            ...((challengeList as ChallengesQuery) &&
              (challengeList?.challenges as { title: string; id: string }[]) &&
              (challengeList?.challenges as { title: string; id: string }[]).map(
                (challenge: { title: string; id: string }) => ({
                  label: challenge.title,
                  value: challenge.id
                })
              ))
          ]}
          onChange={(e) => {
            setChallenge(e.value);
          }}
        />
      </GridRow>
      <GridHeader key="rangeSelectionHeader">
        {intl.formatMessage({ id: 'dateRange', defaultMessage: 'Date range' })}
      </GridHeader>
      {loadingChart && !fetchedChartData ? (
        <Styled.LoadingContainer>
          <LoadingIndicator inline />
        </Styled.LoadingContainer>
      ) : (
        <GridRow key="rangeSelection">
          <DateRangePicker
            start={{
              date: startDate,
              onDateChange: (date: Date | null) => {
                setStartDate(date ? date : startDate);
              }
            }}
            end={{
              date: endDate,
              onDateChange: (date: Date | null) => {
                setEndDate(date ? date : endDate);
              }
            }}
          />
        </GridRow>
      )}
      <GridRow key="updateButton">
        <Button priority="secondary" onClick={handleUpdateClick}>
          {intl.formatMessage({ id: 'update', defaultMessage: 'Update' })}
        </Button>
      </GridRow>
      <GridRow key="chart">
        <MemoizedHighchartsReact
          highcharts={Highcharts}
          options={highchartsOptions}
          constructorType="stockChart"
          updateArgs={[false, false, false]}
          callback={(c: HighchartsReact.Props) => {
            setChart(c as Highcharts.Chart);
            getChartData({ variables: dailySummaryQueryVariables });
            getIdeasData({ variables: ideasQueryVariables });
          }}
        />
      </GridRow>
      <GridRow key="table" justify="center">
        {loadingIdeas && !ideasAndCount ? (
          <Styled.LoadingContainer>
            <LoadingIndicator inline />
          </Styled.LoadingContainer>
        ) : ideasAndCount?.count ? (
          <>
            <GridCol>
              <IdeasTable
                getColumnSortDirection={getColumnSortDirection}
                ideasAndCount={ideasAndCount || { count: 0, rows: [] }}
                intl={intl}
                sortIdeas={ideasSort}
              />
            </GridCol>
            <Pagination
              numPages={Math.ceil(ideasAndCount.count / 10)}
              activeIndex={offset}
              onPageClick={(page) => {
                setOffset(page.index as number);
                getIdeasData({
                  variables: {
                    ...ideasQueryVariables,
                    ...(clickDate && { startDate: clickDate, endDate: clickDate }),
                    offset: (page.index as number) * 10
                  }
                });
              }}
            />
          </>
        ) : (
          <ErrorMessage
            message={intl.formatMessage({ id: 'noIdeasWereFound', defaultMessage: 'No ideas were found' })}
            details={intl.formatMessage({
              id: 'pleaseAdjustYourSearch',
              defaultMessage: 'Please adjust your search parameters and try again.'
            })}
          />
        )}
      </GridRow>
      <GridRow key="exportData">
        <Button
          priority="secondary"
          onClick={() => {
            const includeUnpublished = challenge === 'allPublishedChallenges' ? 0 : 1;
            const challengeId =
              challenge === 'allPublishedChallenges' || challenge === 'allChallenges' ? '0' : challenge;

            const clickDateEnd = clickDate ? new Date(clickDate.valueOf() + 24 * 60 * 60 * 1000) : undefined;
            const exportUrl = new URL(EXPORT_TO_CSV_ENDPOINT + challengeId.toString(), `https://${community.url}`);
            exportUrl.searchParams.append('includeUnpublished', includeUnpublished.toString());
            exportUrl.searchParams.append('includeComments', '0');
            exportUrl.searchParams.append(
              'fromDate',
              ((clickDate || startDate || new Date()).valueOf() / 1000).toString()
            );
            exportUrl.searchParams.append(
              'toDate',
              ((clickDateEnd || endDate || new Date()).valueOf() / 1000).toString()
            );

            if (Object.keys(userFieldOptions).length > 0) {
              exportUrl.searchParams.append(
                'customFiltersUserIds',
                (customFiltersUserIds?.filteredUsers || []).toString()
              );

              exportUrl.searchParams.append('ideaIds', (coauthorIdeaIds?.coauthorIdeas || []).toString());
            }

            if (ideasAndCount?.count === 0) {
              setMessages(
                getToastMessages(
                  ToastLevel.INFORMATIVE,
                  intl.formatMessage({ id: 'noIdeasToExport', defaultMessage: 'There are no ideas to export' }),
                  ToastPlacement.BOTTOM
                )
              );
              return;
            }

            window.location = exportUrl as unknown as Location;
          }}
        >
          {intl.formatMessage({ id: 'exportData', defaultMessage: 'Export Data' })}
        </Button>
      </GridRow>
      <Toast messages={messages} />
    </Grid>
  );
};

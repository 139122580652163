import { type AnalyticsProps } from '../../features';
import {
  ActivityOverview,
  IdeasByMilestoneStats,
  IdeasByPhaseStats,
  IdeasByStatusStats,
  IdeasByTagStats,
  IdeasByThemeStats,
  IdeaStats,
  UserLoginsStats,
  VotesAndCommentsStats
} from './';

export const Analytics = (props: AnalyticsProps) => {
  const { page } = props;
  switch (page) {
    case 'activityoverview':
      return <ActivityOverview />;
    case 'ideastats':
      return <IdeaStats />;
    case 'ideasbyphase':
      return <IdeasByPhaseStats />;
    case 'ideasbymilestone':
      return <IdeasByMilestoneStats />;
    case 'themestats':
      return <IdeasByThemeStats />;
    case 'ideas_by_status':
      return <IdeasByStatusStats />;
    case 'ideas_by_tag':
      return <IdeasByTagStats />;
    case 'totvotecommentstats':
      return <VotesAndCommentsStats />;
    case 'loginstats':
      return <UserLoginsStats />;
    default:
      return <></>;
  }
};

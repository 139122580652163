import { type BinaryKeyType } from '@m/alchemy-ui/esm/binary-select/types';
import { type UserFieldsQuery } from '../../../graphql/queries';
import { typeOptions } from '../../../common/enums';
import { type UserFieldType } from '../types';
import { difference } from '../../../helpers';

export function handleCheckboxFilterChange(
  selections: BinaryKeyType[],
  id: string,
  parentProps: {
    countries: string[];
    userFieldOptions: UserFieldType;
    communityUserFields: UserFieldsQuery;
    userFieldUpdate?: (userFieldValue: UserFieldType) => void;
    setTypes?: (number: number[]) => void;
    setSkip?: (skip: boolean) => void;
  }
) {
  const { countries, userFieldOptions, communityUserFields, userFieldUpdate, setTypes, setSkip } = parentProps;
  setSkip && setSkip(true);
  let options;
  if (id === 'types') {
    options = typeOptions;
  } else if (id === 'countries') {
    options = countries;
  } else {
    options =
      communityUserFields?.userFields?.find((x: { id: string }) => x.id === id.replace(/_option$/, ''))?.options || [];
  }

  const userFieldsClone: UserFieldType = { ...userFieldOptions };
  if (
    difference(selections as string[], options).length > 0 ||
    selections.length === options.length ||
    selections.length === 0
  ) {
    if (id === 'types') {
      setTypes && setTypes([]);
    } else {
      delete userFieldsClone[id];
    }
  } else if (id === 'types') {
    setTypes && setTypes(selections.map((type) => typeOptions.indexOf(type as string) + 1));
  } else {
    userFieldsClone[id] = selections as string[];
  }

  userFieldUpdate && userFieldUpdate(userFieldsClone);
}

export const handleTextInputFilterChange = (
  selection: { id: string; option: string },
  userFieldUpdate: (userFieldValue: UserFieldType) => void,
  userFieldOptions: UserFieldType,
  setSkip?: (skip: boolean) => void
) => {
  setSkip && setSkip(true);
  const { id, option } = selection;
  if (option.length === 0) {
    return;
  }

  const userFieldsClone: UserFieldType = { ...userFieldOptions };
  if (id in userFieldsClone) {
    const idx = userFieldsClone[id].indexOf(option);
    if (idx === -1) {
      userFieldsClone[id].push(option);
    } else {
      userFieldsClone[id].splice(idx, 1);
      if (userFieldsClone[id].length === 0) {
        delete userFieldsClone[id];
      }
    }
  } else {
    userFieldsClone[id] = [option];
  }

  userFieldUpdate(userFieldsClone);
};

import styled from 'styled-components';
import { Grid, GridCol, GridRow, units } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { SelectorType } from '../../../common/enums';
import { Title } from '../../../common/components';
import { VotingOptions, VotingSelector } from '.';

const PhaseVotingWrapper = styled.div`
  padding: ${units(2)};
`;

const HelpText = styled.div`
  margin-top: ${units(1)};
  margin-bottom: ${units(2)};
`;

export const PhaseVoting = () => {
  const intl = useIntl();

  return (
    <PhaseVotingWrapper>
      <Title>{intl.formatMessage({ id: 'voting', defaultMessage: 'Voting' })}</Title>
      <HelpText>
        {intl.formatMessage({
          id: 'phaseVotingHelpText',
          defaultMessage:
            'Choose the voting type for this phase. When you have selected the type of voting, you will be able to edit the voting options. If you change the vote type between phases, or after votes have been cast in this phase, the idea score will reset to 0. You can restore the score by switching back to the original vote type.'
        })}
      </HelpText>
      <Grid>
        <GridRow>
          <GridCol span={{ small: 12, medium: 5 }}>
            <VotingSelector type={SelectorType.PHASE} />
          </GridCol>
          <GridCol span={{ small: 12, medium: 6 }}>
            <VotingOptions type={SelectorType.PHASE} />
          </GridCol>
        </GridRow>
      </Grid>
    </PhaseVotingWrapper>
  );
};

import {
  atMinWidth,
  border,
  borderRadius,
  color,
  Dialog,
  LoadingIndicator,
  snap,
  typeStyle,
  units
} from '@m/alchemy-ui';
import styled from 'styled-components';
import { Breakpoints } from '../../../../common/enums';

export const Header = styled.h1`
  ${typeStyle('headerL')};
  margin-bottom: ${units(2.5)};
`;

export const ContentWrapper = styled.div`
  padding: ${units(1)};
  ${atMinWidth(
    Breakpoints.WIDE_MOBILE_MIN,
    `
      padding: ${units(2)};
    `
  )};
  ${atMinWidth(
    Breakpoints.TABLET_MIN,
    `
      padding: ${units(2)} ${units(3)};
    `
  )};
`;

export const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: ${border()};
  line-height: 1;
`;

export const TabContent = styled.div`
  width: 100%;
`;

export const Message = styled.p`
  padding-top: ${units(2)};
`;

/* z-index: 999 to have a higher number than the nav bar */
export const ProfileDialog = styled(Dialog)`
  z-index: 999;
  #followModals {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  #popUpBodyId {
    padding-bottom: 0;
    margin: 0;
  }
`;

export const NoUserMessage = styled.div`
  margin-top: ${units(2)};
  background-color: ${color('hint', { palette: 'expressiveInformative' })};
  padding: ${units(0.5)} ${units(1.5)};
  border-radius: ${units(1)};
  border: 1px solid ${color('highlight', { palette: 'expressiveInformative' })};
`;

export const StyledLoadingIndicator = styled(LoadingIndicator)`
  height: ${snap(100)};
  background: ${color('container')};
  border-radius: ${borderRadius('extraLarge')};
`;

import { useEffect } from 'react';

export const useBeforeUnload = (callback: (event: BeforeUnloadEvent) => string | undefined) => {
  useEffect(() => {
    window.addEventListener('beforeunload', callback);

    return () => {
      window.removeEventListener('beforeunload', callback);
    };
  }, [callback]);
};

import { defaultMilestone, defaultVoting } from '../../../common/interfaces';
import { type Milestone, type MilestoneType, VotingType } from '../../../common/core-api';

export const getNewMilestone = (type: MilestoneType, name: string): Milestone => ({
  ...defaultMilestone,
  id: Date.now().toString(),
  name,
  type,
  target: 0,
  questions: [],
  voting: {
    ...defaultVoting,
    type: VotingType.UP
  }
});

import { type UserFieldType } from '../../../../common/core-api';
import * as Styled from './Userfields.styled';

export const UserFields = ({ fields = [] }: { readonly fields?: UserFieldType[] }) => {
  if (fields?.length === 0) {
    return null;
  }

  return (
    <Styled.Wrapper>
      <Styled.ListHolder>
        {fields?.map((field: UserFieldType) => (
          <Styled.FieldItem key={field.id}>
            <Styled.FieldTitle>{field.name}</Styled.FieldTitle>
            <Styled.FieldDescription>{field.value}</Styled.FieldDescription>
          </Styled.FieldItem>
        ))}
      </Styled.ListHolder>
    </Styled.Wrapper>
  );
};

import styled from 'styled-components';

const Wrapper = styled.div`
  svg {
    padding: 0;
    margin: 0;
    .cls-1 {
      fill: #7f8ad8;
    }
    .cls-2,
    .cls-3 {
      fill: #fff;
    }
    .cls-3,
    .cls-4 {
      fill-rule: evenodd;
    }
    .cls-4 {
      fill: #d5d9f2;
    }
  }
`;

export const CloneTemplateIcon = () => (
  <Wrapper>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="90"
      viewBox="0 0 180 90"
    >
      <defs />
      <g id="Rectangle-Copy">
        <rect id="path-1-2" className="cls-1" width="180" height="90" />
      </g>
      <g id="Group-3">
        <path
          id="Combined-Shape"
          className="cls-2"
          d="M94,61.84l.11.1,3.75,3.75h0l.06.07-.08-.09.12.13v.07h0V66a1.55,1.55,0,0,1,0,1.36v.13h0v.07h0l-.06.07h0l-3.75,3.75a1.5,1.5,0,0,1-2.22-2l.1-.11,1.19-1.19H81a1.5,1.5,0,1,1-.14-3H93.13l-1.19-1.19a1.5,1.5,0,0,1-.1-2l.1-.11A1.5,1.5,0,0,1,94,61.84Z"
        />
        <g id="rating-star-bubble-copy">
          <g id="Group-7">
            <path
              id="Combined-Shape-2"
              className="cls-3"
              d="M119,28a7.64,7.64,0,0,1,7.31,4.54,8.69,8.69,0,0,1,8.95,1.17,5.78,5.78,0,0,1,1.39,7.59c3.18.86,5.35,3.37,5.35,6.2s-2.17,5.34-5.35,6.2a5.78,5.78,0,0,1-1.39,7.59,8.34,8.34,0,0,1-5.26,1.9V72a2,2,0,0,1-2,2h-1a2,2,0,0,1-2-2V64.55a8.57,8.57,0,0,1-12,0V72a2,2,0,0,1-2,2h-1a2,2,0,0,1-2-2V63.19a8.34,8.34,0,0,1-5.26-1.9,5.78,5.78,0,0,1-1.39-7.59C98.17,52.84,96,50.33,96,47.5s2.17-5.34,5.35-6.2a5.78,5.78,0,0,1,1.39-7.59,8.69,8.69,0,0,1,9-1.17A7.66,7.66,0,0,1,119,28Zm0,7a8,8,0,0,0-5.9,2.81c-4.21,1.25-7.1,3.77-7.1,6.69,0,2.13,1.54,1.94,4,1.24v.76c0,6.35,4,11.5,9,11.5s9-5.15,9-11.5v-.76c2.48.7,4,.89,4-1.24,0-2.92-2.89-5.44-7.1-6.69A8,8,0,0,0,119,35Zm-3.5,8a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,115.5,43Zm6,0a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,121.5,43Z"
            />
          </g>
        </g>
        <g id="rating-star-bubble-copy-2">
          <g id="Group-5">
            <path
              id="Combined-Shape-3"
              className="cls-4"
              d="M61,28a7.64,7.64,0,0,1,7.31,4.54,8.69,8.69,0,0,1,9,1.17A5.78,5.78,0,0,1,78.7,41.3c3.18.86,5.35,3.37,5.35,6.2s-2.17,5.34-5.35,6.2a5.78,5.78,0,0,1-1.39,7.59A8.36,8.36,0,0,1,72,63.19V72a2,2,0,0,1-2,2H69a2,2,0,0,1-2-2V64.55a8.57,8.57,0,0,1-12,0V72a2,2,0,0,1-2,2H52a2,2,0,0,1-2-2V63.19a8.34,8.34,0,0,1-5.26-1.9,5.78,5.78,0,0,1-1.39-7.59C40.17,52.84,38,50.33,38,47.5s2.17-5.34,5.35-6.2a5.78,5.78,0,0,1,1.39-7.59,8.69,8.69,0,0,1,8.95-1.17A7.64,7.64,0,0,1,61,28Zm0,7a8,8,0,0,0-5.9,2.81C50.89,39.06,48,41.58,48,44.5c0,2.13,1.54,1.94,4,1.24v.76C52,52.85,56,58,61,58s9-5.15,9-11.5v-.76c2.48.7,4,.89,4-1.24,0-2.92-2.89-5.44-7.1-6.69A8,8,0,0,0,61,35Zm-3.5,8A1.5,1.5,0,1,1,56,44.5,1.5,1.5,0,0,1,57.5,43Zm6,0A1.5,1.5,0,1,1,62,44.5,1.5,1.5,0,0,1,63.5,43Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </Wrapper>
);

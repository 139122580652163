import { AppMenuButtonMobile, AppMenuList, AppMenuListItem, ListIcon, units } from '@m/alchemy-ui';
import { Link } from 'react-location';
import { FormattedMessage } from 'react-intl';
import { usePageMetadata } from '../../../hooks';
import { type RouteLink } from '../../../common/interfaces';
import * as Styled from '../Navigation.styled';
import { useRuntimeUser } from '../../../context';
import { PageType } from '../../../common/enums/PageType';
import { CommunityLogo } from './CommunityLogo';

export const MobileMenu = ({
  isLoggedIn,
  links,
  showMyProfileLink = true,
  useHomePage = true
}: {
  isLoggedIn: boolean;
  links?: RouteLink[];
  showMyProfileLink?: boolean;
  useHomePage?: boolean;
}) => {
  const { title } = usePageMetadata();
  const { displayName, avatar } = useRuntimeUser();

  return (
    <AppMenuButtonMobile
      attachedMobileMenuTo="left"
      dialogHeaderContent={
        <Link to="/">
          <CommunityLogo />
        </Link>
      }
      label={
        <Styled.AppMobileButtonLabel>
          <ListIcon style={{ marginRight: units(1.5) }} aria-hidden="true" />
          <span>{title}</span>
        </Styled.AppMobileButtonLabel>
      }
      overlay={({ setIsOpen }) => (
        <AppMenuList>
          {isLoggedIn && showMyProfileLink && (
            <AppMenuListItem key="login">
              <Link
                to="user/ideastream"
                onClick={() => {
                  setIsOpen && setIsOpen(false);
                }}
              >
                {avatar ? (
                  <Styled.NavProfileImage src={avatar} alt={displayName} data-testid="UserAvatar" />
                ) : (
                  <Styled.UserIcon aria-hidden="true" />
                )}
                <FormattedMessage id="My profile" defaultMessage="My Profile" />
              </Link>
            </AppMenuListItem>
          )}
          {links?.map(({ linkTitle, url, type, newWindow }, index) => (
            <AppMenuListItem key={url}>
              {type === PageType.URL ? (
                <a
                  href={url}
                  target={newWindow ? '_blank' : ''}
                  onClick={() => {
                    setIsOpen && setIsOpen(false);
                  }}
                >
                  {linkTitle}
                </a>
              ) : (
                <Link
                  to={index === 0 && useHomePage ? '/' : url}
                  onClick={() => {
                    setIsOpen && setIsOpen(false);
                  }}
                  getActiveProps={() => ({
                    className: Styled.ACTIVE_OVERFLOW_LINK_CLASSNAME
                  })}
                >
                  {linkTitle}
                </Link>
              )}
            </AppMenuListItem>
          ))}
        </AppMenuList>
      )}
    />
  );
};

import { type ChangeEvent, useEffect, useState } from 'react';
import { CheckboxGroup, Popper } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { type BinaryKeyType } from '@m/alchemy-ui/esm/binary-select/types';
import { handleCheckboxFilterChange } from '../helpers';
import { type UserFieldType } from '../types';
import { type UserFieldsQuery } from '../../../graphql/queries';
import * as Styled from './SearchFilters.styled';
import { StyledCheckbox, StyledPopperBox, StyledSearchField } from './SearchFilters.styled';

export const CheckboxFilter = ({
  id,
  name,
  options,
  selectedFilters,
  countries,
  userFieldOptions,
  communityUserFields,
  userFieldUpdate,
  setTypes,
  setSkip
}: {
  readonly id: string;
  readonly name: string;
  readonly options?: string[] | null;
  readonly selectedFilters?: string[];
  readonly countries?: string[];
  readonly userFieldOptions: UserFieldType;
  readonly communityUserFields: UserFieldsQuery | undefined;
  readonly userFieldUpdate?: (userFieldValue: UserFieldType) => void;
  readonly setTypes?: (types: number[]) => void;
  readonly setSkip?: (skip: boolean) => void;
}) => {
  const CHARACTER_LIMIT = 2;
  const intl = useIntl();
  const selectedFiltersCount = selectedFilters?.length;
  const [selections, setSelections] = useState<string[]>(selectedFilters || []);
  const [searchText, setSearchText] = useState<string>('');

  const selectAll = intl.formatMessage({ id: 'selectAll', defaultMessage: '{name} - All' }, { name });

  useEffect(() => {
    !searchText && options && setSelections([selectAll, ...options]);
  }, [options, searchText, selectAll]);

  if (!options) {
    return null;
  }

  const handleSearchTextChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSkip?.(true);
    setSearchText(value);

    const searchTextTrimmed = value.trim();
    if (searchTextTrimmed.length === 0) {
      setSelections([selectAll, ...options]);
    }

    if (searchTextTrimmed.length >= CHARACTER_LIMIT) {
      const matchingSelections = options.filter((option) =>
        option.toLowerCase().includes(searchTextTrimmed.toLowerCase())
      );
      setSelections([selectAll, ...matchingSelections]);
    }
  };

  return (
    <Popper
      trigger={
        <Styled.FilterButton priority="secondary">
          {name}
          {selectedFiltersCount ? (
            <>
              <Styled.FiltersCount>{selectedFiltersCount}</Styled.FiltersCount>
            </>
          ) : (
            <>
              <Styled.FiltersCount>{intl.formatMessage({ id: 'all', defaultMessage: 'All' })}</Styled.FiltersCount>
            </>
          )}
          <Styled.ArrowDown aria-hidden="true" />
        </Styled.FilterButton>
      }
      placement="bottom-start"
      overlay={
        <StyledPopperBox hasArrow maxWidth={250}>
          {options.length > 10 && (
            <StyledSearchField
              isFullWidth
              onChange={handleSearchTextChange}
              value={searchText}
              title={intl.formatMessage({ id: 'searchSelectOptions', defaultMessage: 'Search select options' })}
            />
          )}
          <CheckboxGroup
            selection={selectedFilters}
            onChange={(selections: BinaryKeyType[]) => {
              handleCheckboxFilterChange(selections, id, {
                countries: countries || [],
                userFieldOptions,
                communityUserFields: communityUserFields || { userFields: [] },
                userFieldUpdate,
                setTypes,
                setSkip
              });
            }}
          >
            {({ isSelected, handleChange }) =>
              selections.map((option, index) => {
                const selected =
                  index === 0
                    ? !selectedFilters || selectedFilters.length === 0 || selectedFilters.length === options.length
                    : isSelected(option);

                const modifiedOptionLabel =
                  id === 'countries' && option.trim() === ''
                    ? intl.formatMessage({ id: 'none', defaultMessage: 'None' })
                    : option;

                return (
                  <StyledCheckbox
                    key={option}
                    name={option}
                    label={modifiedOptionLabel}
                    value={index.toString()}
                    checked={selected}
                    onChange={() => {
                      setSkip?.(true);
                      handleChange(option);
                    }}
                  />
                );
              })
            }
          </CheckboxGroup>
        </StyledPopperBox>
      }
    />
  );
};

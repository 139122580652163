import styled from 'styled-components';
import { Input, units } from '@m/alchemy-ui';

export const ShortInput = styled(Input)`
  width: ${units(8)};
  input {
    min-width: ${units(8)};
    width: ${units(8)};
  }
`;

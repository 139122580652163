import { type Theme } from '../core-api';

export const defaultTheme: Theme = {
  archived: 0,
  id: '0',
  name: '',
  description: '',
  order: 0,
  communityId: '',
  createdAt: new Date(Date.now()).toISOString()
};

import { snap, units } from '@m/alchemy-ui';
import styled, { css } from 'styled-components';
import { type ReactNode } from 'react';
import { useMainModal } from '../../../hooks';

const LinkedName = styled.a<{ bold?: boolean }>`
  ${({ bold }) =>
    bold &&
    css`
      font-weight: 500;
      margin: 0 ${units(0.5)};
    `};
  max-width: ${snap(200)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ChallengeLink = (msg: ReactNode, challengeId?: string) =>
  challengeId ? (
    <LinkedName bold href={`/category/${challengeId}`} target="_blank" rel="noreferrer">
      {msg}
    </LinkedName>
  ) : null;

export const UserLink = (msg: ReactNode, userId?: string, title = '', bold = true) => {
  const [openModal] = useMainModal();

  return userId ? (
    <LinkedName
      bold={bold}
      href={`/user/activity/${userId}`}
      onClick={(e) =>
        openModal(e, {
          title,
          url: `/user/view/${userId}`
        })
      }
    >
      {msg}
    </LinkedName>
  ) : null;
};

export const IdeaLink = (msg: ReactNode, ideaId?: string) =>
  ideaId ? (
    <LinkedName bold href={`/post/${ideaId}`} target="_blank" rel="noreferrer">
      {msg}
    </LinkedName>
  ) : null;

export const BlogPostLink = (msg: ReactNode, postId?: string) =>
  postId ? (
    <LinkedName bold href={`/blogpost/${postId}`} target="_blank" rel="noreferrer">
      {msg}
    </LinkedName>
  ) : null;

import { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DialogBody, DialogHeader, Tabs, Toast } from '@m/alchemy-ui';
import { flushSync } from 'react-dom';
import { UserContext } from '../../../../context';
import { type User } from '../../../../common/core-api';
import { evalScripts, getPopupData, getToastMessages } from '../../../../../src/helpers';
import { ToastLevel, type ToastMessageType, ToastPlacement } from '../../../../common/enums';
import * as FocusHelper from '../../../../helpers/focus';
import * as Styled from './FollowingContent.styled';
import UserList from './UserList';

const tabs = [
  {
    value: 'following',
    label: <FormattedMessage id="following" defaultMessage="Following" />
  },
  {
    value: 'followers',
    label: <FormattedMessage id="followers" defaultMessage="Followers" />
  }
];

const FollowingContent = ({
  user,
  refetchUsers,
  userFollowing,
  setUserFollowing,
  userFollowers,
  setUserFollowers
}: {
  readonly user: User;
  readonly refetchUsers: () => void;
  readonly userFollowing: number;
  readonly setUserFollowing: (count: number) => void;
  readonly userFollowers: number;
  readonly setUserFollowers: (count: number) => void;
}) => {
  const currentUser = useContext(UserContext);
  const isProfileOwner = currentUser.id === user.id;
  const [followingUsers, setFollowingUsers] = useState(
    user.following?.map((u) => ({
      ...u,
      isFollowing: true
    })) || []
  );
  const intl = useIntl();

  const [followersUsers, setFollowersUsers] = useState(
    user.followers?.map((u) => ({
      ...u,
      isFollowing: user.following?.some((item) => item.id === u.id) || false
    }))
  );

  const urlParams = new URLSearchParams(window.location.search);
  // when we navigating to Following tab through profile modal it redirects with 'sortBy' query param
  // why param name is 'sortBy' ¯\_(ツ)_/¯
  const selectedTabFromUrl = urlParams.get('sortBy');
  // and we need to render correct tab, based on url params if they exists
  // otherwise display first tab
  const initialSelectedTabState = selectedTabFromUrl ? selectedTabFromUrl : tabs[0].value;

  const [selectedTab, setSelectedTab] = useState(initialSelectedTabState);
  const [dialogData, setDialogData] = useState('');
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [dialogDataLoading, setDialogDataLoading] = useState(false);
  const [messages, setMessages] = useState<ToastMessageType[]>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(
    () =>
      function clear() {
        refetchUsers();
      },
    [refetchUsers]
  );

  useEffect(() => {
    setFollowingUsers(
      user.following?.map((u) => ({
        ...u,
        isFollowing: true
      })) || []
    );
    setFollowersUsers(
      user.followers?.map((u) => ({
        ...u,
        isFollowing: user.following?.some((item) => item.id === u.id) || false
      }))
    );
  }, [user]);

  const updateCurrentIndex = () => {
    const currentIndex = FocusHelper.getActiveElement(ref);

    setCurrentIndex(currentIndex);
  };

  const closeDialog = () => {
    refetchUsers();
    setIsOpenDialog(false);
  };

  const toggleFollowState = (id: string, type: string) => {
    if (type === 'followingTab') {
      setFollowingUsers((prev) => {
        const updatedState = prev?.map((user) => {
          if (user.id === id) {
            const isFollowing = !user.isFollowing;
            if (!isFollowing) {
              setMessages(
                getToastMessages(
                  ToastLevel.CONFIRM,
                  intl.formatMessage({ id: 'userUnfollowed', defaultMessage: 'User unfollowed' }),
                  ToastPlacement.BOTTOM
                )
              );
            }

            return {
              ...user,
              isFollowing
            };
          }

          return user;
        });

        return updatedState;
      });
    }

    if (type === 'followersTab') {
      setFollowersUsers((prev) =>
        prev?.map((user) => {
          if (user.id === id) {
            return {
              ...user,
              isFollowing: !user.isFollowing
            };
          }

          return user;
        })
      );
    }
  };

  const onProfilePopupLoaded = async (id: string) => {
    const followModalElement = document.querySelector('#followModals')?.parentElement?.parentElement;
    if (followModalElement) {
      const ifDialogPresent = followModalElement?.style.display === 'none';
      if (ifDialogPresent) {
        followModalElement.style.display = 'flex';
        closeDialog();
      }
    }

    setIsOpenDialog(true);
    setDialogDataLoading(true);

    const result = (await getPopupData(`user/view/${id}?popUpTitle=Profile`)) as Document;

    const form = result.querySelector('.viewProfileBox');
    const bodyScripts = result.querySelectorAll<HTMLScriptElement>('body script');

    if (form && bodyScripts.length > 0) {
      flushSync(() => {
        setDialogDataLoading(false);

        setDialogData(form.outerHTML);
      });

      evalScripts(bodyScripts);
    }
  };

  const selectedTabHandle = (value: string) => {
    setSelectedTab(value);
  };

  const followingMessage = isProfileOwner ? 'You are' : `${user.displayName} is`;
  const followersMessage = isProfileOwner ? 'you' : user.displayName;

  let followingContent = (
    <Styled.NoUserMessage>
      <FormattedMessage
        id="noFollowing"
        defaultMessage="{user} not following any users in this community"
        values={{ user: followingMessage }}
      />
    </Styled.NoUserMessage>
  );

  if (followingUsers?.length) {
    const listFollowingUsers = followingUsers.slice(0, userFollowing);
    const hideShowMoreButton = listFollowingUsers.length === followingUsers.length;
    followingContent = (
      <UserList
        onProfilePopupLoaded={onProfilePopupLoaded}
        toggleFollowState={toggleFollowState}
        updateIndex={updateCurrentIndex}
        type="followingTab"
        userList={listFollowingUsers}
        refetchUsers={refetchUsers}
        isProfileOwner={isProfileOwner}
        userFollowing={userFollowing}
        setUserFollowing={setUserFollowing}
        hideShowMoreButton={hideShowMoreButton}
        userFollowers={userFollowers}
        setUserFollowers={setUserFollowers}
        ref={ref}
      />
    );
  }

  let followersContent = (
    <Styled.NoUserMessage>
      <FormattedMessage
        id="noFollowers"
        defaultMessage="No one in this group is following {user} yet"
        values={{ user: followersMessage }}
      />
    </Styled.NoUserMessage>
  );
  if (followersUsers?.length) {
    const listFollowingUsers = followersUsers.slice(0, userFollowing) || [];
    const hideShowMoreButton = listFollowingUsers.length === followersUsers.length;
    followersContent = (
      <UserList
        onProfilePopupLoaded={onProfilePopupLoaded}
        toggleFollowState={toggleFollowState}
        type="followersTab"
        userList={listFollowingUsers}
        refetchUsers={refetchUsers}
        userFollowing={userFollowing}
        setUserFollowing={setUserFollowing}
        isProfileOwner={isProfileOwner}
        hideShowMoreButton={hideShowMoreButton}
        userFollowers={userFollowers}
        setUserFollowers={setUserFollowers}
        updateIndex={updateCurrentIndex}
      />
    );
  }

  const setFocus = () => {
    const items = FocusHelper.getFocusableElements(ref);
    items && items[currentIndex]?.focus();
  };

  return (
    <>
      <Styled.ContentWrapper>
        <Styled.Header>
          <FormattedMessage id="following" defaultMessage="Following" />
        </Styled.Header>
        <Styled.TabsWrapper>
          <Tabs
            tabs={tabs}
            layout="left"
            onTabClick={(_, tabData) => selectedTabHandle(tabData.value)}
            activeTabValue={selectedTab}
          />
        </Styled.TabsWrapper>
        <Styled.TabContent>
          {isProfileOwner && (
            <Styled.Message>
              <FormattedMessage
                id="buildRelationships"
                defaultMessage="Build relationships, start collaborations. Connect with other users, get real-time updates direct to your idea stream and share your latest updates with the users who follow you."
              />
            </Styled.Message>
          )}
          {selectedTab === tabs[0].value && user.following && followingContent}
          {selectedTab === tabs[1].value && user.followers && followersContent}
        </Styled.TabContent>
      </Styled.ContentWrapper>
      <Styled.ProfileDialog isCentered hasCloseIcon isOpen={isOpenDialog} onClose={closeDialog} size="large">
        {dialogDataLoading ? (
          <Styled.StyledLoadingIndicator />
        ) : (
          <div id="followModals">
            <DialogHeader>
              <FormattedMessage id="profile" defaultMessage="Profile" />
            </DialogHeader>
            <DialogBody id="popUpBodyId" dangerouslySetInnerHTML={{ __html: dialogData }} />
          </div>
        )}
      </Styled.ProfileDialog>
      <Toast messages={messages} onDismiss={setFocus} onBlur={setFocus} />
    </>
  );
};

export default FollowingContent;

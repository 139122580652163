import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { Button, DateRangePicker, ErrorMessage, LoadingIndicator } from '@m/alchemy-ui';
import { subWeeks, toDate } from 'date-fns';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import { useUserFields } from '../../hooks';
import { CommunityContext } from '../../context';
import { GET_COUNTRIES, GET_USER_LOGIN, type GetCountriesQuery, type GetUserLoginQuery } from '../../graphql/queries';
import { CustomFilters } from './CustomFilters';
import { Grid, GridHeader, GridRow } from './Analytics.styled';
import * as Styled from './components/SearchFilters.styled';
import { downloadIcon, userLoginsStatsChartOptions } from './HighchartsData';
import { type UserFieldType } from './types';

Exporting(Highcharts);
ExportData(Highcharts);

Highcharts.SVGRenderer.prototype.symbols.download = downloadIcon;

export const Chart = ({
  highcharts,
  highchartsOptions
}: {
  readonly highcharts: typeof Highcharts;
  readonly highchartsOptions: Record<string, unknown>;
}) => <HighchartsReact highcharts={highcharts} options={highchartsOptions} constructorType="stockChart" />;
export const MemoizedChart = React.memo(
  Chart,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps?.highchartsOptions?.series) === JSON.stringify(nextProps?.highchartsOptions?.series)
);

export const UserLoginsStats = () => {
  const intl = useIntl();
  const community = useContext(CommunityContext);
  const defaultUserFieldOptions: UserFieldType = {};
  const [userFieldOptions, setUserFieldOptions] = useState(defaultUserFieldOptions);
  const { data: communityUserFields } = useUserFields();
  const [countries, setCountries] = useState<string[]>([]);
  const [endDate, setEndDate] = useState(() => toDate(new Date()));
  const [startDate, setStartDate] = useState(() => toDate(subWeeks(new Date(), 4)));
  const [skipQuery, setSkipQuery] = useState(false);
  const [userLoginData, setUserLoginData] = useState<GetUserLoginQuery>({ userLoginStats: [] });

  const { data: countriesList } = useQuery<GetCountriesQuery>(GET_COUNTRIES, {
    variables: { id: community.id },
    fetchPolicy: 'cache-first'
  });

  const variables = {
    startDate,
    endDate,
    filters: { userFields: JSON.stringify(userFieldOptions) }
  };

  const { data: userLoginDataList, loading } = useQuery<GetUserLoginQuery>(GET_USER_LOGIN, {
    variables,
    skip: skipQuery,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });

  const highchartsOptions = {
    ...userLoginsStatsChartOptions(intl),
    series: [
      {
        name: intl.formatMessage({ id: 'users', defaultMessage: 'Users' }),
        data: (userLoginData?.userLoginStats || []).map(({ created, total }) => [created, total])
      }
    ]
  };

  useEffect(() => {
    userLoginDataList && setUserLoginData(userLoginDataList);
  }, [userLoginDataList]);

  useEffect(() => {
    if (countriesList?.community) {
      countriesList.community && setCountries(countriesList.community.countries || []);
    }
  }, [countriesList]);

  if (loading) {
    return (
      <Styled.LoadingContainer>
        <LoadingIndicator inline />
      </Styled.LoadingContainer>
    );
  }

  return (
    <Grid key="userLogins">
      <CustomFilters
        userFieldOptions={userFieldOptions}
        communityUserFields={communityUserFields}
        countries={countries}
        userFieldUpdate={setUserFieldOptions}
        setSkip={setSkipQuery}
      />
      <GridHeader key="dateRangeHeader">
        {intl.formatMessage({ id: 'dateRange', defaultMessage: 'Date range' })}
      </GridHeader>
      <GridRow key="dateRange">
        <DateRangePicker
          start={{
            label: intl.formatMessage({ id: 'start', defaultMessage: 'Start' }),
            date: startDate,
            onDateChange(date: Date | null) {
              setSkipQuery(true);
              setStartDate(date || toDate(subWeeks(new Date(), 4)));
            }
          }}
          end={{
            label: intl.formatMessage({ id: 'end', defaultMessage: 'End' }),
            date: endDate,
            onDateChange(date: Date | null) {
              setSkipQuery(true);
              setEndDate(date || toDate(new Date()));
            }
          }}
        />
      </GridRow>

      <GridRow key="updateButton">
        <Button
          priority="secondary"
          onClick={() => {
            setSkipQuery(false);
          }}
        >
          {intl.formatMessage({ id: 'update', defaultMessage: 'Update' })}
        </Button>
      </GridRow>
      <GridRow key="chart" data-testid="highchartUserLogins">
        <MemoizedChart highcharts={Highcharts} highchartsOptions={highchartsOptions} />
      </GridRow>
      {(userLoginData?.userLoginStats || []).length === 0 && (
        <GridRow key="message">
          <ErrorMessage
            message={intl.formatMessage({ id: 'noUsersFound', defaultMessage: 'No users found' })}
            details={intl.formatMessage({
              id: 'pleaseAdjustYourSearch',
              defaultMessage: 'Please adjust your search parameters and try again.'
            })}
          />
        </GridRow>
      )}
    </Grid>
  );
};

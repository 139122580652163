import styled from 'styled-components';
import { atMinWidth, Button, DisplayBox, typeStyle, units } from '@m/alchemy-ui';
import { Breakpoints } from '../../../common/enums';

export const Card = styled(DisplayBox)`
  margin-top: ${units(2)};
  border: none;
  font: ${typeStyle('labelM')};
  width: 100%;
`;

export const ContentContainer = styled.div`
  padding: ${units(2)};
  ${atMinWidth(
    Breakpoints.WIDE_MOBILE_MIN,
    `
    padding: ${units(2)} ${units(3)};
    `
  )};
`;

export const ExpandButtonContainer = styled.div`
  text-align: center;
`;

export const ExpandButton = styled(Button)`
  svg {
    margin-left: ${units(0.5)};
  }
`;

import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  type AppProps,
  defaultRuntimeCommunity,
  defaultRuntimeIdp,
  defaultRuntimeUser,
  type RuntimeUser
} from '../common/interfaces';

export const defaultRuntime: AppProps = {
  pkey: '',
  apiV2Url: '',
  sessionId: '',
  sessionTimeout: '',
  api: '',
  cloudinaryUrl: '',
  mirp: { enabled: false, region: '', url: '' },
  passwordComplexityEnabled: 'false',
  passwordComplexityLengthMin: '1',
  passwordComplexitySpecialMin: '1',
  passwordComplexitySpecialChars: '',
  passwordComplexityLengthUpperMin: '1',
  passwordComplexityLengthLowerMin: '1',
  passwordComplexityLengthNumberMin: '1',
  passwordComplexityLifetimeMinutesMin: '0',
  community: defaultRuntimeCommunity,
  user: defaultRuntimeUser,
  regionDbCode: 'dev',
  popularTags: [],
  idp: defaultRuntimeIdp,
  device: 'desktop'
};

const RuntimeContext = createContext<AppProps>(defaultRuntime);

const RuntimeProvider = ({ children, value }: { readonly children: ReactNode; readonly value: AppProps }) => {
  const {
    api,
    apiV2Url,
    cloudinaryUrl,
    pkey,
    sessionId,
    sessionTimeout,
    mirp: { enabled, url, region },
    passwordComplexityEnabled,
    passwordComplexityLengthMin,
    passwordComplexitySpecialMin,
    passwordComplexitySpecialChars,
    passwordComplexityLengthUpperMin,
    passwordComplexityLengthLowerMin,
    passwordComplexityLengthNumberMin,
    passwordComplexityLifetimeMinutesMin,
    user: initialUser,
    community,
    regionDbCode,
    popularTags,
    idp,
    device
  } = value;
  const [user, setUser] = useState<RuntimeUser | undefined>(initialUser);
  const memoizedValue = useMemo(
    () => ({
      api,
      apiV2Url,
      cloudinaryUrl,
      pkey,
      mirp: { enabled, url, region },
      sessionId,
      sessionTimeout,
      passwordComplexityEnabled,
      passwordComplexityLengthMin,
      passwordComplexitySpecialMin,
      passwordComplexitySpecialChars,
      passwordComplexityLengthUpperMin,
      passwordComplexityLengthLowerMin,
      passwordComplexityLengthNumberMin,
      passwordComplexityLifetimeMinutesMin,
      user,
      community,
      regionDbCode,
      popularTags,
      idp,
      device
    }),
    [
      api,
      apiV2Url,
      cloudinaryUrl,
      pkey,
      enabled,
      url,
      region,
      sessionId,
      sessionTimeout,
      passwordComplexityEnabled,
      passwordComplexityLengthMin,
      passwordComplexitySpecialMin,
      passwordComplexitySpecialChars,
      passwordComplexityLengthUpperMin,
      passwordComplexityLengthLowerMin,
      passwordComplexityLengthNumberMin,
      passwordComplexityLifetimeMinutesMin,
      user,
      community,
      regionDbCode,
      popularTags,
      idp,
      device
    ]
  );

  const updateUserContextHandler = useCallback(
    (event: CustomEvent | Event): void => {
      if ('detail' in event) {
        const { avatar }: { avatar: string } = event.detail;
        const newUser = { ...user, avatar };
        setUser(newUser as RuntimeUser);
      }
    },
    [user]
  );

  useEffect(() => {
    window.addEventListener('updateUserContext', updateUserContextHandler);

    return () => {
      window.removeEventListener('updateUserContext', updateUserContextHandler);
    };
  }, [updateUserContextHandler]);

  return <RuntimeContext.Provider value={memoizedValue}>{children}</RuntimeContext.Provider>;
};

const RuntimeConsumer = RuntimeContext.Consumer;

const useRuntimeConfig = () => useContext(RuntimeContext);

export { RuntimeContext, RuntimeConsumer, RuntimeProvider, useRuntimeConfig };

import { gql } from '@apollo/client';

export const GET_USERRANKING = gql`
  query getUserranking($challengeId: String, $limit: Int) {
    userRankings(challengeId: $challengeId, limit: $limit) {
      id
      userId
      score
      user {
        id
        displayName
        createdAt
        timezone
        profileImageFile {
          id
          name
          url
          hash
          extension
        }
      }
    }
  }
`;

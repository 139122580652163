import styled from 'styled-components';
import {
  UserIcon as AlchemyUserIcon,
  atMinWidth,
  Button,
  color,
  MultiSelect,
  snap,
  typeStyle,
  units
} from '@m/alchemy-ui';
import { Breakpoints } from '../../common/enums';

export const NotificationStreamWrapper = styled.div`
  padding: ${units(1)};
  ${atMinWidth(
    Breakpoints.TABLET_MIN,
    `
    padding: ${units(1)} ${units(2)};
    `
  )};
  ${atMinWidth(
    Breakpoints.DESKTOP_MIN,
    `
    padding: ${units(2)} ${units(4)};
    `
  )};
`;

export const AnonymousIcon = styled(AlchemyUserIcon)`
  /* Alchemy is overzealous with targeting SVG styles when nested in a AppNavListItem */
  height: ${snap(20)} !important;
  margin-right: ${units(0.8)} !important;
  width: ${snap(20)} !important;
`;

export const AnonymousName = styled.div`
  font-weight: 500;
  display: inline;
  margin-right: ${units(0.5)};
`;

export const Title = styled.h1`
  ${typeStyle('headerL')};
  color: ${color('typeHeader')};
  margin-bottom: ${units(2.5)};
`;

export const CardsContainer = styled.div``;

export const LoadMoreButton = styled(Button)`
  border-color: ${color('base', { palette: 'interactiveConstructive' })};
`;

export const ButtonContainer = styled.div`
  margin-top: ${units(2)};
  text-align: center;
`;

export const NoNotificationMessage = styled.div`
  margin-top: ${units(2)};
  padding: ${units(1)} ${units(1.5)};
  border-radius: 8px;
  background-color: ${color('hint', { palette: 'interactiveConstructive' })};
  border: 1px solid ${color('highlight', { palette: 'interactiveConstructive' })};
  max-width: 95%;
`;

export const LoadingWrapper = styled.div`
  position: relative;
  height: ${snap(200)};
`;

export const ZeroState = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${units(5)} 0;
  text-align: center;

  svg {
    align-self: center;
    color: ${color('lineContrast')};
    margin-bottom: ${units(2)};
  }
`;

export const CustomMultiSelect = styled(MultiSelect)`
  [class*='MultiSelectstyled__SelectedMultiOption'],
  [class*='MultiSelectstyled__Toggle'] {
    margin-top: 5px;
  }
`;

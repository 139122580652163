import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog } from '@m/alchemy-ui';
import { flushSync } from 'react-dom';
import { type BadgeWithCountType, type User } from '../../../../common/core-api';
import { UserContext } from '../../../../context';
import { evalScripts, getPopupData } from '../../../../helpers';
import { UserType } from '../../../../common/enums';
import { BadgeItem } from './BadgeItem';
import * as Styled from './Badges.styled';

interface DialogState {
  isOpened: boolean;
  bodyContent: string;
}

export const Badges = ({
  user,
  handleSaveBadges,
  handleBadgeDialogState
}: {
  readonly user: User;
  readonly handleSaveBadges: () => void;
  readonly handleBadgeDialogState: (isOpened: boolean) => void;
}) => {
  const intl = useIntl();
  const currentUser = useContext(UserContext);
  const { badges } = user;

  const [isBadgesPopupLoading, setIsBadgesPopupLoading] = useState(false);
  const [dialogData, setDialogData] = useState({
    isOpened: false,
    bodyContent: ''
  });

  useEffect(() => {
    handleBadgeDialogState(dialogData.isOpened);
  }, [dialogData, handleBadgeDialogState]);

  const userAccess = currentUser?.userAccess;

  const closeDialog = () => {
    handleSaveBadges();
    setDialogData((prevState: DialogState) => ({ ...prevState, isOpened: false }));
  };

  const openBadgesDialog = async () => {
    setIsBadgesPopupLoading(true);

    const result = (await getPopupData(`user/admin_badges/${user.id}?fullProfile=1`)) as Document;

    const content = result.querySelector('body');
    const scripts = result.querySelectorAll<HTMLScriptElement>('body script');

    if (content) {
      flushSync(() => {
        setDialogData({
          bodyContent: content.outerHTML,
          isOpened: true
        });
      });

      evalScripts(scripts);
    }

    setIsBadgesPopupLoading(false);
  };

  if (!userAccess) {
    return null;
  }

  const isAdmin = [UserType.SUPER_ADMIN, UserType.ADMIN, UserType.SUPPORT, UserType.ENGINEER].includes(
    userAccess.userType
  );

  return (
    <>
      <Styled.Holder>
        {(badges || isAdmin) && (
          <Styled.Title>
            <FormattedMessage id="badges" defaultMessage="Badges" />
          </Styled.Title>
        )}
        {badges && badges.length > 0 && (
          <Styled.ListWrapper>
            {badges.map((item: BadgeWithCountType) => (
              <BadgeItem key={item.id} item={item} />
            ))}
          </Styled.ListWrapper>
        )}
        {isAdmin && (
          <Styled.ManageBadgesButton priority="secondary" isLoading={isBadgesPopupLoading} onClick={openBadgesDialog}>
            <FormattedMessage id="manageBadges" defaultMessage="Manage Badges" />
          </Styled.ManageBadgesButton>
        )}
        <Dialog
          isOpen={dialogData.isOpened}
          onClose={closeDialog}
          hasCloseIcon
          size="medium"
          headerContent={intl.formatMessage({ id: 'editUserBadges', defaultMessage: 'Edit user badges' })}
        >
          <Dialog.Body dangerouslySetInnerHTML={{ __html: dialogData.bodyContent || '' }} />
        </Dialog>
      </Styled.Holder>
    </>
  );
};

import { useIntl } from 'react-intl';
import { useRuntimeCommunity } from '../context';
import * as Styled from './Application.styled';

export const PageHeader = (props: { readonly pageBanner?: string }) => {
  const { pageBanner = '' } = props;
  const intl = useIntl();
  const { banner: communityBanner = '', name, description = '', isNameInBanner } = useRuntimeCommunity();

  const bannerSrc = pageBanner || communityBanner;

  if (!bannerSrc) {
    return null;
  }

  const altText = intl.formatMessage({ id: 'Page Banner alt', defaultMessage: 'Page banner' });
  return (
    <Styled.BannerWrapper>
      <img src={bannerSrc} alt={altText} />
      <Styled.BannerTextWrapper>
        {name && isNameInBanner && <p>{name}</p>}
        {description && <p>{description}</p>}
      </Styled.BannerTextWrapper>
    </Styled.BannerWrapper>
  );
};

import { PhasesEditType } from '../../../common/enums';
import { defaultChallengeVisibility, defaultVoting } from '../../../common/interfaces';
import { type DefaultAccess, type Maybe, type Phase } from '../../../common/core-api';

export interface Duration {
  day: number;
  hour: number;
  minute: number;
}
export const defaultDuration: Duration = { day: 7, hour: 0, minute: 0 };
export const DEFAULT_DATE = '0101-01-01T00:00:00.000Z';

export const getPhaseDuration = (startDate: string, endDate: string): Duration => {
  const startTimeInSeconds = Date.parse(startDate) / 1000;
  const endTimeInSeconds = Date.parse(endDate) / 1000;
  const durationInSeconds = endTimeInSeconds - startTimeInSeconds;

  const durationDays = Math.floor(durationInSeconds / 86_400);
  const remainderSecondsFromDays = durationInSeconds % 86_400;

  const durationHours = Math.floor(remainderSecondsFromDays / 3600);
  const remainderSecondsFromHours = (durationInSeconds % 86_400) % 3600;

  const durationMinutes = Math.floor(remainderSecondsFromHours / 60);

  return { day: durationDays, hour: durationHours, minute: durationMinutes };
};

export const getEndDate = (startDate: string, duration: Duration) => {
  const { day, hour, minute } = duration;
  const startTimeInSeconds = Date.parse(startDate) / 1000;
  const durationDaysInSeconds = day * 86_400;
  const durationHourInSeconds = hour * 3600;
  const durationMinuteInSeconds = minute * 60;
  const endTimeInSeconds = startTimeInSeconds + durationDaysInSeconds + durationHourInSeconds + durationMinuteInSeconds;
  const newEndDate = new Date(endTimeInSeconds * 1000).toISOString();

  return newEndDate;
};

export const getNewPhase = (
  previousPhase: Phase | null = null,
  afterPhase: Phase | null = null,
  defaultAccess: Maybe<DefaultAccess> | undefined
): Phase => {
  let startDate = DEFAULT_DATE;
  let endDate = DEFAULT_DATE;
  if (previousPhase) {
    if (afterPhase && previousPhase.endDate) {
      // when phase is added in the middle of the timeline
      startDate = previousPhase.endDate;
      endDate = getEndDate(startDate, defaultDuration);
    } else {
      // when phase is added at the end of the timeline
      const newStartDate = getEndDate(previousPhase.startDate as string, defaultDuration);
      const newStartDateEnded = hasPhaseEnded(newStartDate);
      // if newStartDate is already in the past, set a start date which starts in 5 hours from now
      const newStartDateInFuture = new Date(Date.now() + 18_000_000).toISOString();
      startDate = newStartDateEnded ? newStartDateInFuture : newStartDate;
    }
  }

  return {
    id: Date.now().toString(),
    access: {
      users: defaultAccess?.users,
      groups: defaultAccess?.groups,
      options: defaultChallengeVisibility
    },
    description: '',
    startDate,
    endDate,
    name: '',
    questions: [],
    voting: defaultVoting
  };
};

export const updatePhasesStartAndEndDate = (phases: Phase[], index: number, type: PhasesEditType): Phase[] => {
  let updatedPhases: Phase[] = [];
  const addPhase = type === PhasesEditType.ADD;
  const deletePhase = type === PhasesEditType.DELETE;
  const editPhase = type === PhasesEditType.EDIT;

  const lastPhaseAdded = addPhase && index === phases.length - 1;
  const lastPhaseRemoved = deletePhase && index === phases.length;

  if (lastPhaseAdded || lastPhaseRemoved) {
    updatedPhases = phases.map((p, i) => {
      if (addPhase && i === phases.length - 2) {
        return { ...p, endDate: phases[i + 1].startDate };
      }

      if (deletePhase && i === phases.length - 1) {
        return { ...p, endDate: DEFAULT_DATE };
      }

      return p;
    });
  } else {
    phases.forEach((p, i) => {
      if (((addPhase || editPhase) && i > index) || (deletePhase && i >= index)) {
        const duration = getPhaseDuration(p.startDate as string, p.endDate as string);
        const newStartDate = updatedPhases[i - 1].endDate;
        const newEndDate = i === phases.length - 1 ? p.endDate : getEndDate(newStartDate as string, duration);
        const updatedPhase = { ...p, startDate: newStartDate, endDate: newEndDate };
        updatedPhases.push(updatedPhase);
      } else {
        updatedPhases.push(p);
      }
    });
  }

  return updatedPhases;
};

export const hasPhaseStarted = (startDate: string) => {
  const startTimeInSeconds = Date.parse(startDate) / 1000;
  const currentTimeInSeconds = Date.now() / 1000;
  const secondsUntilStart = startTimeInSeconds - currentTimeInSeconds;

  return secondsUntilStart <= 0;
};

export const hasPhaseEnded = (endDate: string) => {
  if (endDate === DEFAULT_DATE) {
    return false;
  }

  const endTimeInSeconds = Date.parse(endDate) / 1000;
  const currentTimeInSeconds = Date.now() / 1000;

  return endTimeInSeconds < currentTimeInSeconds;
};

export enum ChallengeSelectionType {
  ALL = 'allChallenges',
  PUBLISHED = 'allPublishedChallenges',
  SELECT = 'selectAChallenge'
}

export enum ThemeSelectionType {
  ALL = 'allThemes',
  PUBLISHED = 'allPublishedThemes'
}

export enum AlchemySortDirectionType {
  ASC = 'ascending',
  DESC = 'descending'
}

export enum CoreApiSortDirectionType {
  ASC = 'ASC',
  DESC = 'DESC'
}

import { CoreApiSortDirectionType } from '../enums';

export const IDEAS_DEFAULT_OFFSET = 0;
export const IDEAS_DEFAULT_SORT = ['createdAt', CoreApiSortDirectionType.DESC];
export const IDEAS_PER_PAGE = 10;

export const CHART_MAX_COLUMN_COUNT = 20;

export const EXPORT_TO_CSV_ENDPOINT = '/category/admin_challengecsv/';

export const CHALLENGES_DEFAULT_OFFSET = 0;
export const CHALLENGES_DEFAULT_SORT = { column: 'challengeCreatedAt', direction: CoreApiSortDirectionType.DESC };
export const CHALLENGES_PER_PAGE = 10;

import { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { Button, Field, LoadingIndicator, type Option, Select, units } from '@m/alchemy-ui';
import styled from 'styled-components';
import { type UserScoreProps } from '../../../features';
import { UserScoreContext } from '../context/UserScoreContext';
import { type ScoreHistoryRow } from '../interface/ScoreHistoryRow';
import { type LegacyScoreHistoryApi } from '../interface/LegacyScoreHistoryApi';
import { ScoreHistoryList } from './ScoreHistoryList';

const StoryHistoryContainer = styled.div`
  margin-top: 20px;
`;

const LoadMoreButton = styled(Button)`
  margin-top: 8px;
`;

export const LoadingWrapper = styled.div`
  height: ${units(40)};
`;

export const ScoreHistory = (props: UserScoreProps) => {
  const intl = useIntl();
  const totalScore = intl.formatMessage({ id: 'totalScore', defaultMessage: 'Total score' });
  const showScoreHistoryFor = intl.formatMessage({
    id: 'showScoreHistoryFor',
    defaultMessage: 'Show score history for'
  });
  const allChallenges = intl.formatMessage({ id: 'allChallenges', defaultMessage: 'All Challenges' });
  const loadMore = intl.formatMessage({ id: 'loadMore', defaultMessage: 'Load more' });

  const {
    scoreHistoryData,
    setScoreHistoryData,
    challengeId,
    setChallengeId,
    challenges,
    setChallenges,
    metadata,
    setMetadata,
    refetchScores
  } = useContext(UserScoreContext);
  const [isLoadMoreButtonLoading, setIsLoadMoreButtonLoading] = useState(false);

  const updateScoreState = (row: ScoreHistoryRow) => {
    const score = row.removed ? row.score : -row.score;

    setMetadata((prevMetadata) => ({ ...prevMetadata, scoreTotal: prevMetadata.scoreTotal + score }));
    setScoreHistoryData((prevData) => {
      if (prevData) {
        const index = prevData.findIndex((data) => data.id === row.id);

        if (index !== -1) {
          const updatedData = [...prevData];
          updatedData[index] = { ...updatedData[index], removed: !updatedData[index].removed };
          return updatedData;
        }
      }

      return prevData;
    });
    axios({
      url: `/user/admin_scorerecalculate/${props.userScoreId}?ak=${props.user?.actionKey}`,
      method: 'POST'
    });
  };

  const fetchScoreHistoryData = useCallback(
    async (userId: string, categoryId: string = '0', start: string = '0', recalculate: boolean = false) => {
      const transformLegacyData = (data: LegacyScoreHistoryApi): ScoreHistoryRow[] =>
        data.logs.map((d) => ({
          id: String(d.scorelog_id),
          url: d.scorelog_link,
          urlTitle: d.scorelog_link_title,
          removed: d.scorelog_removed === '1',
          name: d.scorelog_type_name_translated,
          date: d.scorelog_date_iso,
          score: d.score_value
        }));

      try {
        const request = new FormData();
        request.append('isAjax', 'true');
        request.append('start', start);
        request.append('cat_id', categoryId);
        recalculate && request.append('recalculate', 'true');

        const response = await axios.post<LegacyScoreHistoryApi>(`/user/admin_scorelog_table/${userId}`, request, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest'
          }
        });

        if (response.status === 200) {
          if (start === '0') {
            setScoreHistoryData(transformLegacyData(response.data));
          } else {
            setIsLoadMoreButtonLoading(false);
            setScoreHistoryData((prevData) => {
              if (prevData) {
                return [...prevData, ...transformLegacyData(response.data)];
              }

              return prevData;
            });
          }

          if (challenges === undefined) {
            setChallenges(response.data?.challenges);
          }

          setMetadata({
            scoreTotal: response.data?.totalScore,
            start: Number(start) + 100,
            loadMore: response.data?.loadMore && response.data.logs.length === 100
          });
        } else {
          throw new Error('Error fetching score history, status not equal to 200');
        }
      } catch (error) {
        console.log(error);
        throw new Error('Error fetching score history, please check logs');
      }
    },
    [setMetadata, setScoreHistoryData, challenges, setChallenges]
  );

  useEffect(() => {
    if (scoreHistoryData === undefined) {
      fetchScoreHistoryData(props.userScoreId, '0', '0', true);
    }

    if (refetchScores.current === true) {
      refetchScores.current = false;
      fetchScoreHistoryData(props.userScoreId, challengeId);
    }
  }, [refetchScores, challengeId, props.userScoreId, scoreHistoryData, props.user?.actionKey, fetchScoreHistoryData]);

  const handleChallengeChange = async ({ value }: Option) => {
    await fetchScoreHistoryData(props.userScoreId, value);

    setChallengeId(value);
  };

  const handleLoadMore = async () => {
    setIsLoadMoreButtonLoading(true);

    fetchScoreHistoryData(props.userScoreId, challengeId, String(metadata.start));
  };

  const challangesSelectData = [
    {
      label: allChallenges,
      value: '0'
    },
    ...(challenges ? challenges.map((challenge) => ({ label: challenge.name, value: String(challenge.id) })) : [])
  ];

  const displayLoading = scoreHistoryData === undefined || refetchScores.current;

  return (
    <StoryHistoryContainer data-testid="score-history-container">
      {displayLoading ? (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      ) : (
        <>
          <h3 className="mdl-form__header -s"> {`${totalScore}: ${metadata.scoreTotal}`} </h3>

          <Field
            label={`${showScoreHistoryFor}:`}
            labelVariant="normal"
            input={
              <Select
                name="access.options.ideaSubmissionsType"
                defaultValue="0"
                value={challengeId}
                options={challangesSelectData}
                onChange={handleChallengeChange}
                popperProps={{ zIndex: 1050 }}
              />
            }
          />
          {scoreHistoryData && (
            <ScoreHistoryList scoreHistoryData={scoreHistoryData} handleScoreTotalUpdate={updateScoreState} />
          )}
          {metadata.loadMore && (
            <LoadMoreButton
              onClick={handleLoadMore}
              isLoading={isLoadMoreButtonLoading}
              priority="secondary"
              data-testid="score-history-load-more-button"
            >
              {loadMore}
            </LoadMoreButton>
          )}
        </>
      )}
    </StoryHistoryContainer>
  );
};

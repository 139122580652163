import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popper } from '@m/alchemy-ui';
import { format } from 'date-fns';
import { decode } from 'html-entities';
import { useFile } from '../../../../hooks';
import { type BadgeWithCountType } from '../../../../common/core-api';
import * as Styled from './Badges.styled';

export const BadgeItem = ({
  item: { count, dateAwarded, description, name, imageFile, imageUrl, score, totalScore }
}: {
  readonly item: BadgeWithCountType;
}) => {
  const intl = useIntl();
  const [getFile] = useFile();
  const point = intl.formatMessage({ id: 'point', defaultMessage: 'Point' });
  const points = intl.formatMessage({ id: 'pointsVoting', defaultMessage: 'Points' });
  const renderBadgeImage = () => <img src={badgeImageUrl || ''} alt={name} />;

  const [badgeImageUrl, setBadgeImageUrl] = useState('');
  useEffect(() => {
    const getAuthUrl = async () => {
      const url = imageFile ? imageFile && (await getFile({ file: imageFile, width: 100, height: 100 })) : imageUrl;
      setBadgeImageUrl(url || '');
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageFile, imageUrl]);

  return (
    <Styled.ItemWrapper>
      <Popper
        placement="top"
        mode="tooltip"
        delay={100}
        overlay={
          <Styled.PopperBoxHolder hasArrow colorScheme="alchemyDark">
            <Styled.TooltipContent>
              <Styled.BadgeIconHolder>{renderBadgeImage()}</Styled.BadgeIconHolder>
              <Styled.BadgeDescriptionHolder truncated={totalScore > 1}>
                <Styled.BadgeName role="contentinfo">{decode(name)}</Styled.BadgeName>
                {description && (
                  <Styled.BadgeDescription role="contentinfo">{decode(description)}</Styled.BadgeDescription>
                )}

                <Styled.DateAwarded>
                  {count > 1 ? (
                    <FormattedMessage id="firstAwarded" defaultMessage="First awarded" />
                  ) : (
                    <FormattedMessage id="awarded" defaultMessage="awarded" />
                  )}
                  <Styled.Date role="contentinfo">{format(new Date(dateAwarded), 'PP')}</Styled.Date>
                </Styled.DateAwarded>

                {count > 1 && (
                  <Styled.TotalPoints role="contentinfo">
                    <FormattedMessage
                      id="countAndScoreAwarded"
                      defaultMessage="{count} awarded ({score} points each)"
                      values={{ count, score: score > 1 ? score : 1 }}
                    />
                  </Styled.TotalPoints>
                )}
              </Styled.BadgeDescriptionHolder>

              {totalScore > 1 && (
                <>
                  <Styled.Separator />
                  <Styled.ScoreContainer>
                    <Styled.ScoreValue role="contentinfo">{totalScore}</Styled.ScoreValue>
                    <Styled.ScoreCaption role="contentinfo">{totalScore > 1 ? points : point}</Styled.ScoreCaption>
                  </Styled.ScoreContainer>
                </>
              )}
            </Styled.TooltipContent>
          </Styled.PopperBoxHolder>
        }
        trigger={<Styled.IconHolder>{renderBadgeImage()}</Styled.IconHolder>}
      />
    </Styled.ItemWrapper>
  );
};

import type React from 'react';
import { type IntlShape } from 'react-intl';
import ModeratorAction from '../../../common/enums/ModeratorAction';
import {
  type ModeratorActionMessage,
  type ModeratorActionResultItem,
  type ModeratorActionSimplePayload,
  type ModeratorActionTranslatedContent,
  type ModeratorActionUserEntity
} from '../../../common/interfaces/ModeratorActionTypes';

interface TranlateMessageDescriptor {
  id: string;
  defaultMessage: string;
  values?: Record<string, React.ReactNode | string>;
}

export const createMessage = (dataItem: ModeratorActionResultItem, intl: IntlShape): ModeratorActionMessage => {
  const translation = getMessageTranslationWithLink(dataItem, intl);
  const moderator = addUserLink(dataItem.user, intl);
  return buildMessage(dataItem, translation, moderator);
};

const buildMessage = (
  dataItem: ModeratorActionResultItem,
  translation: ModeratorActionTranslatedContent,
  moderator: React.ReactNode
): ModeratorActionMessage => ({
  id: dataItem.id,
  title: translation.title,
  description: translation.description,
  moderator: <>{moderator}</>,
  date: new Date(dataItem.createdAt).toLocaleDateString()
});

// Type guard
const checkIfTranlateMessageDescriptor = (
  translateMessageDescriptor: React.ReactElement | TranlateMessageDescriptor
): translateMessageDescriptor is TranlateMessageDescriptor =>
  'id' in translateMessageDescriptor && 'defaultMessage' in translateMessageDescriptor;

// New message type should be add only here.
const getMessageTranslationWithLink = (
  dataItem: ModeratorActionResultItem,
  intl: IntlShape
): ModeratorActionTranslatedContent => {
  let title: React.ReactNode | string = '';
  let description: React.ReactNode | string = '';
  let titleDefinition: React.ReactElement | TranlateMessageDescriptor;
  let descriptionDefinition: React.ReactElement | TranlateMessageDescriptor;

  const getTranslation = (data: TranlateMessageDescriptor | undefined): React.ReactNode =>
    data ? intl.formatMessage(data, data.values) : <></>;

  const objectName = addPayloadObjectLink(dataItem.payload as ModeratorActionSimplePayload);

  switch (dataItem.actionId) {
    case ModeratorAction.IDEA_MOVED: {
      const from = addPayloadObjectLink(
        'from' in dataItem.payload ? dataItem.payload.from : ({} as ModeratorActionSimplePayload)
      );
      const to = addPayloadObjectLink(
        'to' in dataItem.payload ? dataItem.payload.to : ({} as ModeratorActionSimplePayload)
      );

      titleDefinition = { id: 'moderatorAction.moved', defaultMessage: 'Moved' };
      descriptionDefinition = {
        id: 'moderatorAction.fromChallengeThemeToChallengeTheme',
        defaultMessage: 'From {challengeThemeNameFrom} to {challengeThemeNameTo}.',
        values: { challengeThemeNameFrom: from, challengeThemeNameTo: to }
      };

      break;
    }

    case ModeratorAction.STATUS_LABEL_CHANGED: {
      let added;
      let removed;

      if ('added' in dataItem.payload && dataItem.payload.added.length > 0) {
        added = {
          id: 'moderatorAction.statusLabelsAdded',
          defaultMessage: 'Status labels added: {statusLabelsAdded}',
          values: {
            statusLabelsAdded: 'added' in dataItem.payload ? dataItem.payload.added.map((p) => p.name).join(', ') : []
          }
        };
      }

      if ('removed' in dataItem.payload && dataItem.payload.removed.length > 0) {
        removed = {
          id: 'moderatorAction.statusLabelsRemoved',
          defaultMessage: 'Status labels removed: {statusLabelsRemoved}',
          values: {
            statusLabelsRemoved:
              'removed' in dataItem.payload ? dataItem.payload.removed.map((p) => p.name).join(', ') : []
          }
        };
      }

      titleDefinition = {
        id: 'moderatorAction.statusLabelChanges',
        defaultMessage: 'Status Label Changes'
      };

      // Exceptional case because of merge
      descriptionDefinition = (
        <>
          <>{getTranslation(added)}</>
          <br />
          <>{getTranslation(removed as TranlateMessageDescriptor)}</>
        </>
      );

      break;
    }

    case ModeratorAction.MODERATION_STATUS_CHANGED: {
      titleDefinition = <>{objectName}</>;
      descriptionDefinition = {
        id: 'moderatorAction.moderationStatusSetTo',
        defaultMessage: 'Moderation status set to: {moderationStatus}',
        values: { moderationStatus: objectName }
      };

      break;
    }

    case ModeratorAction.MILESTONE_REACHED_VOTE: {
      titleDefinition = {
        id: 'moderatorAction.milestoneReached',
        defaultMessage: 'Milestone reached: {milestoneName}',
        values: { milestoneName: objectName }
      };

      descriptionDefinition = {
        id: 'moderatorAction.enoughVotesWereReceivedToReachTheMilestone',
        defaultMessage: 'Enough votes were received to reach the {milestoneName} milestone.',
        values: { milestoneName: objectName }
      };

      break;
    }

    case ModeratorAction.MILESTONE_REACHED_REFINEMENT: {
      titleDefinition = {
        id: 'moderatorAction.milestoneReached',
        defaultMessage: 'Milestone reached: {milestoneName}',
        values: { milestoneName: objectName }
      };

      descriptionDefinition = {
        id: 'moderatorAction.refinementDetailsWereEnteredForTheMilestone',
        defaultMessage: 'Refinement details were entered for the {milestoneName} milestone.',
        values: { milestoneName: objectName }
      };

      break;
    }

    case ModeratorAction.MILESTONE_REACHED_APPROVAL: {
      titleDefinition = {
        id: 'moderatorAction.milestoneReached',
        defaultMessage: 'Milestone reached: {milestoneName}',
        values: { milestoneName: objectName }
      };

      descriptionDefinition = {
        id: 'moderatorAction.approvedAtTheMilestone',
        defaultMessage: 'Approved at the {milestoneName} milestone.',
        values: { milestoneName: objectName }
      };

      break;
    }

    case ModeratorAction.MILESTONE_COMPLETED_ALL: {
      titleDefinition = {
        id: 'moderatorAction.allMilestonesCompleted',
        defaultMessage: 'All milestones completed'
      };

      descriptionDefinition = {
        id: 'moderatorAction.allMilestonesInWereCompleted',
        defaultMessage: 'All milestones in {challengeName} were completed.',
        values: { challengeName: objectName }
      };

      break;
    }

    case ModeratorAction.MILESTONE_SENT_BACK_TO_PREVIOUS: {
      titleDefinition = {
        id: 'moderatorAction.revertedToPreviousMilestone',
        defaultMessage: 'Reverted to previous milestone'
      };

      descriptionDefinition = {
        id: 'moderatorAction.revertedBackToTheMilestone',
        defaultMessage: 'Reverted back to the {milestoneName} milestone.',
        values: { milestoneName: objectName }
      };

      break;
    }

    case ModeratorAction.MILESTONE_IDEA_REJECTED: {
      titleDefinition = {
        id: 'moderatorAction.milestoneIdeaRejected',
        defaultMessage: 'Milestone idea rejected'
      };

      descriptionDefinition = {
        id: 'moderatorAction.ideaProgressEndedAndIdeaIsClosed',
        defaultMessage: 'The idea progress ended at the {milestoneName} milestone and the idea is closed.',
        values: { milestoneName: objectName }
      };

      break;
    }

    case ModeratorAction.PHASE_REVERTED_TO_PREVIOUS: {
      titleDefinition = {
        id: 'moderatorAction.revertedToPreviousPhase',
        defaultMessage: 'Reverted to previous phase'
      };

      descriptionDefinition = {
        id: 'moderatorAction.revertedBackToThePreviousPhase',
        defaultMessage: 'Reverted back to the previous phase: {phaseName}.',
        values: { phaseName: objectName }
      };

      break;
    }

    case ModeratorAction.PHASE_REVERTED_TO_CURRENT_FROM_NEXT: {
      titleDefinition = {
        id: 'moderatorAction.revertedToPhase',
        defaultMessage: 'Reverted to phase'
      };

      descriptionDefinition = {
        id: 'moderatorAction.revertedBackToThePhase',
        defaultMessage: 'Reverted back to the phase: {phaseName}.',
        values: { phaseName: objectName }
      };

      break;
    }

    case ModeratorAction.PHASE_ADVANCED_TO_CURRENT_FROM_PREVIOUS: {
      titleDefinition = {
        id: 'moderatorAction.advancedToPhase',
        defaultMessage: 'Advanced to phase'
      };

      descriptionDefinition = {
        id: 'moderatorAction.advancedToThePhase',
        defaultMessage: 'Advanced to the phase: {phaseName}.',
        values: { phaseName: objectName }
      };

      break;
    }

    case ModeratorAction.PHASE_ADVANCED_TO_NEXT: {
      titleDefinition = {
        id: 'moderatorAction.advancedToNextPhase',
        defaultMessage: 'Advanced to next phase'
      };

      descriptionDefinition = {
        id: 'moderatorAction.advancedToTheNextPhase',
        defaultMessage: 'Advanced to the next phase: {phaseName}.',
        values: { phaseName: objectName }
      };

      break;
    }

    default:
      titleDefinition = <></>;
      descriptionDefinition = <></>;
  }

  title = checkIfTranlateMessageDescriptor(titleDefinition) ? getTranslation(titleDefinition) : titleDefinition;
  description = checkIfTranlateMessageDescriptor(descriptionDefinition)
    ? getTranslation(descriptionDefinition)
    : descriptionDefinition;

  return { title, description };
};

const addUserLink = (user: ModeratorActionUserEntity, intl: IntlShape): React.ReactNode => {
  const by = intl.formatMessage({
    id: 'moderatorAction.by',
    defaultMessage: 'By'
  });
  /* eslint-disable */
    return user.url ? (
      <span dangerouslySetInnerHTML={{__html: `
      ${by} <a onClick="parent.popUp('${user.url}', { popUpTitle: 'Profile', width:823, height: profilePopUpHeight, focusOnClose: this }); return false;">
           ${user.name}
      </a>
    `}} />
    ) : (
      `${by} ${user.name}`
    )
  /* eslint-enable */
};

const addPayloadObjectLink = (payload: ModeratorActionSimplePayload): React.ReactNode =>
  payload.url ? (
    <a id="current-category-link" href={payload.url} data-id={payload.id}>
      {payload.name}
    </a>
  ) : (
    <>{payload.name}</>
  );

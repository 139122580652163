import type React from 'react';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserContext } from '../context';
import * as Styled from './Application.styled';

export const EmailValidation = () => {
  const currentUser = useContext(UserContext);

  if (!currentUser?.regCode) {
    return null;
  }

  const handlerAjaxRoute = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const href = e?.currentTarget?.getAttribute('href');

    href && (window.location.href = href);
  };

  return (
    <Styled.WarningAlert level="warning" attached="top" icon={<></>}>
      <FormattedMessage
        defaultMessage="<p>Please validate your email address to SUBMIT IDEAS and COMMENT & VOTE</p><p>It's quick and easy. Check your inbox for the validation email and click the link, <a>or click here to be sent a new email</a></p>"
        values={{
          a: (msg) => (
            <a href="/user/validateemailscreen" rel="noopener noreferrer" onClick={(e) => handlerAjaxRoute(e)}>
              {msg}
            </a>
          ),
          p: (msg) => <p>{msg}</p>
        }}
      />
    </Styled.WarningAlert>
  );
};

import { type SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { Field, Grid, GridCol, GridRow, Input, type SlateToolbarControl } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { CollapsedField, Container, Line, MultipleUpload, SectionTitle, SingleUpload } from '../../common/components';
import { FileUploadType } from '../../common/enums';
import { useFieldError, useFile } from '../../hooks';
import { type IChallenge } from '../../common/interfaces';
import { IS_CHALLENGE_EXIST } from '../../graphql/queries';
import * as Styled from '../../application/Application.styled';
import { ThemesEditor, TranslatableInput, TranslatableRTE } from './components';

/* // It is base64 it should not be option to do that but in case of demand we can quicky restore it.
const imageUploadToBase64Control = () => ({
  name: 'image',
  label: 'Upload image',
  options: {
    upload: {
      onUpload: (file: Blob) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.addEventListener('load', (event) => {
            const base64String = event?.target?.result;
            resolve({ url: base64String, alt: 'image' });
          });
          reader.readAsDataURL(file);
        })
    },
    url: true
  }
}); */

const richTextEditorConfigs = {
  description: {
    buttons: [
      'bold',
      'italic',
      'unorderedList',
      'orderedList',
      'link',
      'alignLeft',
      'alignCenter',
      'alignRight'
    ] as SlateToolbarControl[]
  },
  longDescription: {
    buttons: [
      'bold',
      'italic',
      'typeStyle',
      'unorderedList',
      'orderedList',
      'undo',
      'redo',
      'link',
      // It is base64 it should not be option to do that but in case of demend we can quicky restore it.
      // imageUploadToBase64Control(),
      'alignLeft',
      'alignCenter',
      'alignRight'
    ] as SlateToolbarControl[]
  }
};

export const Details = () => {
  const intl = useIntl();
  const [isChallengeExist, setIsChallengeExist] = useState<boolean>(false);
  const [newChallengeTitle, setNewChallengeTitle] = useState<string>('');
  const { values: challenge, handleChange, handleBlur } = useFormikContext<IChallenge>();

  const [fieldError] = useFieldError();

  const [, UploadExtensions] = useFile();

  const { refetch } = useQuery<{ challengeExist: boolean }>(IS_CHALLENGE_EXIST, {
    variables: { id: challenge.id, title: challenge.title },
    skip: true
  });

  useEffect(() => {
    refetch({ id: challenge.id, title: newChallengeTitle }).then((response) => {
      const { challengeExist } = response.data;

      if (challengeExist) {
        setIsChallengeExist(challengeExist);
      }
    });
  }, [challenge.id, newChallengeTitle, refetch]);

  const handleBlurExtend = (e: SyntheticEvent) => {
    handleBlur(e);
    setIsChallengeExist(false);

    const { value } = e.currentTarget as HTMLInputElement;
    if (value) {
      setNewChallengeTitle(value);
    }
  };

  return (
    <Grid>
      <GridRow>
        <GridCol span={{ small: 12, medium: 6 }}>
          <Container>
            <Field
              label={intl.formatMessage({ id: 'title', defaultMessage: 'Title' })}
              required
              isFullWidth
              status={fieldError('title')}
              input={
                <TranslatableInput type="challenge" field="title" translateId={challenge.id}>
                  <Input name="title" value={challenge.title} onChange={handleChange} onBlur={handleBlurExtend} />
                </TranslatableInput>
              }
            />
            {isChallengeExist ? (
              <Styled.WarningAlert level="warning" attached="top" icon={<></>}>
                <FormattedMessage
                  id="thisTitleIsAlreadyInUseConsiderToUseAnotherOne"
                  defaultMessage="This title is already in use, consider to use another one."
                />
              </Styled.WarningAlert>
            ) : null}
            <CollapsedField
              trigger={intl.formatMessage({ id: 'addSubtitle', defaultMessage: 'Add Subtitle' })}
              label={intl.formatMessage({ id: 'subtitle', defaultMessage: 'Subtitle' })}
              isFullWidth
              status={fieldError('subtitle')}
              input={
                <TranslatableInput type="challenge" field="subtitle" translateId={challenge.id}>
                  <Input name="subtitle" value={challenge.subtitle || ''} onChange={handleChange} onBlur={handleBlur} />
                </TranslatableInput>
              }
            />

            <CollapsedField
              trigger={intl.formatMessage({ id: 'addFullDescription', defaultMessage: 'Add Full Description' })}
              label={intl.formatMessage({ id: 'fullDescription', defaultMessage: 'Full Description' })}
              isFullWidth
              input={
                <TranslatableRTE
                  type="challenge"
                  field="longDescription"
                  translateId={challenge.id}
                  config={richTextEditorConfigs.longDescription}
                />
              }
            />
          </Container>
        </GridCol>
        <GridCol span={{ small: 12, medium: 6 }}>
          <Container>
            <Field
              label={intl.formatMessage({ id: 'shortDescription', defaultMessage: 'Short Description' })}
              isFullWidth
              helpText={intl.formatMessage({
                id: 'shortDescriptionHelpText',
                defaultMessage: 'This will appear on the challenge page and the idea submission form'
              })}
              input={
                <TranslatableRTE
                  type="challenge"
                  field="description"
                  translateId={challenge.id}
                  config={richTextEditorConfigs.description}
                />
              }
            />
          </Container>
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <Line />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol span={{ small: 12, medium: 6 }}>
          <Container>
            <Field
              isFullWidth
              label={intl.formatMessage({ id: 'challengeListingImage', defaultMessage: 'Challenge listing image' })}
              input={
                <SingleUpload
                  buttonLabel={intl.formatMessage({ id: 'attachImage', defaultMessage: 'Attach image' })}
                  fileTypes={UploadExtensions.image}
                  type={FileUploadType.CHALLENGE_LISTING}
                >
                  <p>
                    {intl.formatMessage({
                      id: 'otherFilesWeAcceptDragDrop',
                      defaultMessage: 'Drag & Drop or manually attach an image'
                    })}
                  </p>
                  <p>
                    {intl.formatMessage({
                      id: 'otherFilesWeAcceptDimensions',
                      defaultMessage: '(318px x 195px)'
                    })}
                  </p>
                </SingleUpload>
              }
            />

            <CollapsedField
              isFullWidth
              trigger={intl.formatMessage({ id: 'addMainChallengeImage', defaultMessage: 'Add Main challenge image' })}
              label={intl.formatMessage({ id: 'mainChallengeImage', defaultMessage: 'Main challenge image' })}
              initialExpanded={challenge.files?.header !== null}
              input={
                <SingleUpload
                  buttonLabel={intl.formatMessage({ id: 'attachImage', defaultMessage: 'Attach image' })}
                  fileTypes={UploadExtensions.image}
                  type={FileUploadType.CHALLENGE_HEADER}
                >
                  <p>
                    {intl.formatMessage({
                      id: 'filetypesImages',
                      defaultMessage: 'We accept jpg, gif and png files'
                    })}
                  </p>
                  <p>
                    {intl.formatMessage({
                      id: 'filetypesImagesDimensions',
                      defaultMessage: 'For best results upload a 980px by 240px image '
                    })}
                  </p>
                </SingleUpload>
              }
            />
          </Container>
        </GridCol>
        <GridCol span={{ small: 12, medium: 6 }}>
          <Container>
            <Field
              isFullWidth
              label={intl.formatMessage({ id: 'otherFilesOrMedia', defaultMessage: 'Other files or media' })}
              input={
                <MultipleUpload
                  buttonLabel={intl.formatMessage({ id: 'attachFiles', defaultMessage: 'Attach files' })}
                  fileTypes={[...UploadExtensions.image, ...UploadExtensions.video, ...UploadExtensions.raw]}
                  type={FileUploadType.CHALLENGE_ATTACHMENT}
                >
                  <p>
                    {intl.formatMessage({
                      id: 'otherFilesWeAccept',
                      defaultMessage: 'We accept jpg, gif, png, office docs, txt, pdf, zip, mp4, mov files'
                    })}
                  </p>
                </MultipleUpload>
              }
            />
          </Container>
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <Line />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol span={{ small: 12, medium: 6 }}>
          <SectionTitle>
            <FormattedMessage id="themes" defaultMessage="Themes" />
          </SectionTitle>
          <Container>
            <Field
              label={intl.formatMessage({ id: 'textThemeName', defaultMessage: 'Title for your challenge themes' })}
              required
              isFullWidth
              status={fieldError('config.textThemeName')}
              input={
                <TranslatableInput type="Challenge" field="options.textThemeName" translateId={challenge.id}>
                  <Input
                    name="config.textThemeName"
                    value={challenge.config.textThemeName ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </TranslatableInput>
              }
            />
          </Container>
          <ThemesEditor />
        </GridCol>
      </GridRow>
    </Grid>
  );
};

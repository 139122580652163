import styled, { css } from 'styled-components';
import { borderRadius, color, type ColorProps, type Palette, snap, typeStyle, units } from '@m/alchemy-ui';
import { TimelineItemType } from '../../../common/enums';

export const ActivityTimelineItemIndicator = styled.div<{ shouldUseDefaultStyles?: boolean }>`
  ////// for horizontal
  top: -3px;
  ////// for horizontal

  position: absolute;
  //   top: 13px;
  //   left: -17px;
  //   padding: 16px;
  border-radius: 50%;
  ${({ shouldUseDefaultStyles }) =>
    shouldUseDefaultStyles &&
    css`
      border: 2px solid ${color('container')};
      background-color: ${color('container')};
      //   left: -19px;
    `};
  > * {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  > div:nth-child(2) {
    font-weight: ${typeStyle('bodyM')};
    width: ${units(12.5)};
    height: 0;
    line-height: normal;
  }
`;

export const ActivityTimelineItemIndicatorName = styled.div`
  margin-top: ${units(4)};
  text-align: center;
`;

export const ActivityTimelineItemCustomIndicator = styled.div`
  position: absolute;
  //   top: 13px;
  //   left: -17px;
  //   padding: 16px;
  > * {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
`;

export const ActivityTimelineItem = styled.li<{ itemIconWrapperPalette?: Palette }>`
  ////// for horizontal
  ${({ itemType }) => {
    if (itemType === TimelineItemType.PHASE_BULLET || itemType === TimelineItemType.PHASE_BUTTON) {
      return css`
        max-width: ${snap(50)};
        min-width: ${snap(50)};
      `;
    }

    if (itemType === TimelineItemType.MILESTONE || itemType === TimelineItemType.MILESTONE_BUTTON) {
      return css`
        min-width: ${snap(90)};
      `;
    }

    return css`
      padding-right: ${units(1)};
    `;
  }}
  display: flex;
  flex: 1;
  flex-direction: column;
  border-top: 2px solid ${color('fill')};
  max-height: ${snap(350)};
  ////// for horizontal

  position: relative;
  //   margin-left: ${units(2)};
  //   padding: 0 0 ${units(3)} ${units(5)};
  //   border-left: 2px solid ${color('fill')};
  z-index: 1;
  /* first indicator needs to cover extra line visible above it, done with this small rectangle */
  //   &:first-child ${ActivityTimelineItemIndicator}::after {
  //     position: absolute;
  //     top: -${units(2)};
  //     left: ${units(1)};
  //     height: ${units(2)};
  //     width: ${units(2)};
  //     content: '';
  //     background-color: ${color('container')};
  //     z-index: 2;
  //   }
  /* last ActivityTimelineEventContainer doesn't have a border, need a small rectangle to link up line with last indicator */
  //   &:last-child ${ActivityTimelineItemIndicator}::after {
  //     position: absolute;
  //     top: -${units(2)};
  //     left: 15px;
  //     content: '';
  //     background-color: ${color('fill')};
  //     height: ${units(1.5)};
  //     width: ${units(0.25)};
  //     z-index: 2;
  //   }
`;

export const ActivityTimelineItemIconWrapper = styled.div<ColorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${units(2)};
  height: ${units(2)};
  background-color: ${color('base', { palette: 'interactiveConstructive' })};
  svg {
    color: ${color('hint')};
  }
`;

export const ActivityTimelineItemCard = styled.div`
  border-radius: ${borderRadius('large')};
  background-color: ${color('canvas')};
  width: 100%;

  ////// for horizontal
  margin-top: 20px;
  height: 100%;
`;

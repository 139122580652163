import { useMutation, useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { type ToastMessageType, ToggleFollowType } from '../common/enums';
import { type FollowMutation, type UnfollowMutation, USER_FOLLOW, USER_UNFOLLOW } from '../graphql/mutations';
import { type CheckFollowingQuery, GET_FOLLOWING } from '../graphql/queries';
import { getConfirmToastMessage, getErrorToastMessage } from '../helpers';

export const useCheckFollowingStatus = ({ userId, targetUser }: { userId: string; targetUser: string }) =>
  useQuery<CheckFollowingQuery>(GET_FOLLOWING, {
    variables: {
      userId,
      targetUser
    },
    skip: userId === targetUser || userId === '0'
  });

export const useUserFollow = () => {
  const intl = useIntl();

  const [followUser, { loading: userFollowLoading }] = useMutation<FollowMutation>(USER_FOLLOW);
  const [unfollowUser, { loading: userUnfollowLoading }] = useMutation<UnfollowMutation>(USER_UNFOLLOW);

  const toggleFollow = async ({
    variables,
    setMessages,
    setIsFollowing,
    toggle
  }: {
    variables: { targetUser: string };
    setMessages: (messages: ToastMessageType[]) => void;
    setIsFollowing: (value: boolean) => void;
    toggle: ToggleFollowType;
  }) => {
    let result;
    let success;
    if (toggle === ToggleFollowType.FOLLOW) {
      result = await followUser({ variables });
      success = result.data?.followUser.success;
    } else {
      result = await unfollowUser({ variables });
      success = result.data?.unfollowUser.success;
    }

    if (success) {
      if (toggle === ToggleFollowType.FOLLOW) {
        setMessages(
          getConfirmToastMessage(intl.formatMessage({ id: 'userFollowed', defaultMessage: 'User followed' }))
        );
        setIsFollowing(true);
      } else {
        setMessages(
          getConfirmToastMessage(intl.formatMessage({ id: 'userUnfollowed', defaultMessage: 'User unfollowed' }))
        );
        setIsFollowing(false);
      }
    } else if (toggle === ToggleFollowType.FOLLOW) {
      setMessages(
        getErrorToastMessage(intl.formatMessage({ id: 'userFollowFailed', defaultMessage: 'User follow failed' }))
      );
    } else {
      setMessages(
        getErrorToastMessage(intl.formatMessage({ id: 'userUnfollowFailed', defaultMessage: 'User unfollow failed' }))
      );
    }
  };

  return { toggleFollow, userFollowLoading, userUnfollowLoading };
};

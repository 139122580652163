import { useContext, useState } from 'react';
import {
  CommentBubbleIcon,
  CustomerIdeaSignalOutlineIcon,
  ShowEyeIcon,
  TeamIcon,
  ThumbsUpIcon,
  Toast,
  Tooltip
} from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { type BlogPost, type Challenge, FollowType, type Idea } from '../../../common/core-api';
import { UserContext } from '../../../context';
import { NOTIFICATION_FOLLOW, type NotificationFollowMutation } from '../../../graphql/mutations';
import { NotificationCardTypes, type ToastMessageType } from '../../../common/enums';
import { getConfirmToastMessage, getErrorToastMessage } from '../../../helpers';
import * as Styled from './Footer.styled';

export const Footer = ({
  type,
  item,
  challenge
}: {
  readonly type: NotificationCardTypes;
  readonly item?: Partial<BlogPost | Idea>;
  readonly challenge?: Partial<Challenge>;
}) => {
  const intl = useIntl();

  const user = useContext(UserContext);
  const { subscribedIdeaIds, subscribedChallengeIds } = user;

  const forBlogPost = type === NotificationCardTypes.BLOG_POST;
  const forIdea = type === NotificationCardTypes.IDEA;
  const forChallenge = type === NotificationCardTypes.CHALLENGE;

  const itemStats = item?.stats;
  const challengeStats = challenge?.stats;

  const [messages, setMessages] = useState<ToastMessageType[]>();

  let subscribed: boolean = false;
  if (forIdea) {
    subscribed = Boolean(item?.id && subscribedIdeaIds?.includes(item.id));
  }

  if (forChallenge) {
    subscribed = Boolean(challenge?.id && subscribedChallengeIds?.includes(challenge.id));
  }

  const [isSubscribed, setIsSubscribed] = useState(subscribed);
  const [subscribe, { loading: followLoading }] = useMutation<NotificationFollowMutation>(NOTIFICATION_FOLLOW);

  const subscribeClicked = async () => {
    let input;
    if (forIdea && item?.id) {
      input = {
        targetId: item.id,
        type: FollowType.IDEA
      };
    }

    if (forChallenge && challenge?.id) {
      input = {
        targetId: challenge.id,
        type: FollowType.CHALLENGE
      };
    }

    const result = await subscribe({
      variables: {
        input
      }
    });

    if (result.data?.follow.success) {
      setMessages(getConfirmToastMessage(intl.formatMessage({ id: 'subscribeSuccess', defaultMessage: 'Subscribed' })));
      setIsSubscribed(!isSubscribed);
    } else {
      setMessages(
        getErrorToastMessage(intl.formatMessage({ id: 'subscribeFailed', defaultMessage: 'Subscribe Failed' }))
      );
    }
  };

  return (
    <Styled.FooterContainer>
      {forBlogPost && (
        <Styled.Stats>
          <Styled.Stat>
            <Tooltip
              trigger={<ShowEyeIcon aria-hidden="true" />}
              placement="top"
              contents={<FormattedMessage id="views" defaultMessage="Views" />}
            />
            {itemStats?.views}
          </Styled.Stat>
          <Styled.Stat>
            <Tooltip
              trigger={<CommentBubbleIcon aria-hidden="true" />}
              placement="top"
              contents={<FormattedMessage id="comments" defaultMessage="Comments" />}
            />
            {itemStats?.comments}
          </Styled.Stat>
          <Styled.Stat>
            <Tooltip
              trigger={<TeamIcon aria-hidden="true" />}
              placement="top"
              contents={<FormattedMessage id="subscribers" defaultMessage="Subscribers" />}
            />
            {itemStats?.followers}
          </Styled.Stat>
        </Styled.Stats>
      )}

      {forIdea && (
        <Styled.Stats>
          <Styled.Stat>
            <Tooltip
              trigger={<ShowEyeIcon aria-hidden="true" />}
              placement="top"
              contents={<FormattedMessage id="views" defaultMessage="Views" />}
            />
            {itemStats?.views}
          </Styled.Stat>
          {itemStats?.votes !== null && (
            <Styled.Stat>
              <Tooltip
                trigger={<ThumbsUpIcon aria-hidden="true" />}
                placement="top"
                contents={<FormattedMessage id="votes" defaultMessage="Votes" />}
              />
              {itemStats?.votes}
            </Styled.Stat>
          )}
          <Styled.Stat>
            <Tooltip
              trigger={<CommentBubbleIcon aria-hidden="true" />}
              placement="top"
              contents={<FormattedMessage id="comments" defaultMessage="Comments" />}
            />
            {itemStats?.comments}
          </Styled.Stat>
          <Styled.Stat>
            <Tooltip
              trigger={<TeamIcon aria-hidden="true" />}
              placement="top"
              contents={<FormattedMessage id="subscribers" defaultMessage="Subscribers" />}
            />
            {itemStats?.followers}
          </Styled.Stat>
        </Styled.Stats>
      )}

      {forChallenge && (
        <Styled.Stats>
          <Styled.Stat>
            <Tooltip
              trigger={<CustomerIdeaSignalOutlineIcon aria-hidden="true" />}
              placement="top"
              contents={<FormattedMessage id="ideas" defaultMessage="Ideas" />}
            />

            {challengeStats?.ideas}
          </Styled.Stat>
          <Styled.Stat>
            <Tooltip
              trigger={<ThumbsUpIcon aria-hidden="true" />}
              placement="top"
              contents={<FormattedMessage id="votes" defaultMessage="Votes" />}
            />
            {challengeStats?.votes}
          </Styled.Stat>
          <Styled.Stat>
            <Tooltip
              trigger={<CommentBubbleIcon aria-hidden="true" />}
              placement="top"
              contents={<FormattedMessage id="comments" defaultMessage="Comments" />}
            />
            {challengeStats?.comments}
          </Styled.Stat>
          <Styled.Stat>
            <Tooltip
              trigger={<TeamIcon aria-hidden="true" />}
              placement="top"
              contents={<FormattedMessage id="subscribers" defaultMessage="Subscribers" />}
            />
            {challengeStats?.subscribers}
          </Styled.Stat>
        </Styled.Stats>
      )}
      {!isSubscribed && (
        <Styled.SubscribeButton priority="secondary" size="small" onClick={subscribeClicked} isLoading={followLoading}>
          <FormattedMessage id="subscribe" defaultMessage="Subscribe" />
        </Styled.SubscribeButton>
      )}
      <Toast messages={messages} />
    </Styled.FooterContainer>
  );
};

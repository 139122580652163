import axios from 'axios';
import { hasValue } from '@m/magic-typescript';
import { useContext } from 'react';
import { RuntimeContext } from '../context';
import { type File as FileType } from '../common/core-api';

interface UseFileProps {
  file: FileType;
  width?: number;
  height?: number;
  crop?: string;
}

const UploadExtensions = {
  image: ['gif', 'jpeg', 'jpg', 'png'],
  video: ['mp4', '3gp', 'mov', 'avi', 'wmv', 'flv', 'mpeg', 'mpg', 'mp3', 'wav', 'mkv', 'webm', 'ogv'],
  raw: ['doc', 'docx', 'odt', 'ppt', 'pptx', 'xls', 'xlsx', 'csv', 'zip', 'rar', 'txt', 'rtf', 'pdf']
};

export const useFile = () => {
  const {
    community: { id: communityId },
    mirp
  } = useContext(RuntimeContext);

  const getUrl = async ({ file, width, height, crop = 'fill' }: UseFileProps) => {
    const useMirp = mirp?.enabled === true && mirp?.url !== '';

    if (useMirp) {
      return `${mirp?.url}/display-media/${params({ width, height, crop })}${mirp?.region}/${communityId}/${fileName({
        file,
        useMirp
      })}`;
    }

    return getCloudinaryAuthUrl(file.hash, width, height);
  };

  return [getUrl, UploadExtensions] as const;
};

const getCloudinaryAuthUrl = async (fileName: string, width: number | undefined, height: number | undefined) => {
  const formData = new URLSearchParams();
  formData.append('fileName', fileName);

  if (width) {
    formData.append('width', width.toString());
  }

  if (height) {
    formData.append('height', height.toString());
  }

  const response = await axios.post('file/add_auth_token', formData);
  return response?.data as string;
};

const fileName = ({
  file,
  raw = false,
  urlencode = true,
  useMirp
}: {
  file: FileType;
  raw?: boolean;
  urlencode?: boolean;
  useMirp: boolean;
}) => {
  const name = urlencode ? encodeURIComponent(file.hash) : file.hash;
  return useMirp ? `${name}.${file.extension}` : `${name}${raw ? `.${file.extension}` : ''}`;
};

const params = ({ width, height, crop }: Pick<UseFileProps, 'crop' | 'height' | 'width'>) =>
  width ? [`w_${width}`, height && `h_${height}`, `c_${crop}`].filter(hasValue).join(',') + '/' : '';

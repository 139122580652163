import { FormattedMessage } from 'react-intl';
import * as Styled from '../People.styled';
import { PAGE_LIMIT } from '../People';

export const PaginationNav = ({
  paginationIndex,
  pagesCount,
  handlePageChange,
  showRange,
  totalUserCount
}: {
  readonly paginationIndex: number;
  readonly pagesCount: number;
  readonly handlePageChange: ({ index }: { index: number }) => void;
  readonly showRange?: boolean;
  readonly totalUserCount: number;
}) => {
  if (!pagesCount || pagesCount <= 1) {
    return null;
  }

  const start = 1 + paginationIndex * PAGE_LIMIT;
  const endLimit = (1 + paginationIndex) * PAGE_LIMIT;
  const end = start < totalUserCount && totalUserCount < endLimit ? totalUserCount : endLimit;

  return (
    <>
      <Styled.PaginationContainer>
        <Styled.PaginationButton
          priority="tertiary"
          onClick={() => {
            handlePageChange({ index: 0 });
          }}
        >
          <FormattedMessage id="first" defaultMessage="First" />
        </Styled.PaginationButton>
        <Styled.Pagination
          activeIndex={paginationIndex}
          limit={10}
          numPages={pagesCount}
          // @ts-expect-error
          onPageClick={handlePageChange}
          alwaysShowArrows
        />
        <Styled.PaginationButton
          priority="tertiary"
          onClick={() => {
            handlePageChange({ index: pagesCount - 1 });
          }}
        >
          <FormattedMessage id="last" defaultMessage="Last" />
        </Styled.PaginationButton>
      </Styled.PaginationContainer>
      {showRange && (
        <Styled.Range>
          <FormattedMessage
            id="profilesOf"
            defaultMessage="{range} Profiles of {total}"
            values={{
              range: `${start} - ${end}`,
              total: totalUserCount
            }}
          />
        </Styled.Range>
      )}
    </>
  );
};

import { type ReactNode } from 'react';
import styled from 'styled-components';
import { units } from '@m/alchemy-ui';

const StyledContainer = styled.div`
  padding: ${units(1)} ${units(2)};
`;

export const Container = ({ children }: { readonly children: ReactNode }) => (
  <StyledContainer>{children}</StyledContainer>
);

import { type ReactElement, type ReactNode } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, CancelXIcon, LoadingIndicator } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import omit from 'lodash.omit';
import {
  GET_COUNTRIES,
  GET_USERS,
  type GetCountriesQuery,
  type GetCountriesQueryVariables,
  type GetUsersQuery,
  type GetUsersQueryVariables
} from '../../graphql/queries';

import { CommunityContext } from '../../context';
import { useUserFields } from '../../hooks';
import { PEOPLE_SEARCH_CHARACTER_MIN } from '../shared-constants';
import { type User } from '../../common/core-api';
import * as Styled from './People.styled';
import { PaginationNav, SearchFilters, Usercard } from './components';

export interface Filters {
  searchText: string;
  userFields: { [key: string]: string[] };
  userFieldType: { [key: string]: string }; // for right searching in the backend
  countries: string[];
  initial?: string;
}

export const PAGE_LIMIT = 15;

const boldText = (msg: ReactNode[]) => <Styled.Bold>{msg}</Styled.Bold>;

const EMPTY_FILTERS = { searchText: '', userFields: {}, countries: [], userFieldType: {} };

export const People = () => {
  const intl = useIntl();
  const community = useContext(CommunityContext);

  const INITIALS = [
    intl.formatMessage({
      id: 'all',
      defaultMessage: 'All'
    }),
    ...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  ];

  // TODO: we should listen to this query change and keep it updated
  const urlParams = new URLSearchParams(window.location.search);
  const searchParam = urlParams.get('q');

  const initialFilters: Filters = { ...EMPTY_FILTERS, searchText: searchParam || '' };
  const [searchFilters, setSearchFilters] = useState<Filters>(initialFilters);
  const [paginationIndex, setPaginationIndex] = useState<number>(0);
  const [initialIndex, setInitialIndex] = useState<number>(0);
  const [searchTextToDisplay, setSearchTextToDisplay] = useState<string>('');
  const searchRef = useRef<HTMLInputElement>(null);
  const cardsRef = useRef<HTMLAnchorElement>(null);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const transformFiltersToVariables = (
    { searchText, userFields, countries, userFieldType, initial }: Filters,
    index: number = 0
  ) => ({
    filters: {
      searchText,
      userFields: JSON.stringify(userFields),
      country: countries.length > 0 ? countries : undefined,
      userFieldType: JSON.stringify(userFieldType),
      ...(initial && { initial })
    },
    offset: index * PAGE_LIMIT
  });

  const { loading: loadingUsers, data } = useQuery<GetUsersQuery, GetUsersQueryVariables>(GET_USERS, {
    variables: transformFiltersToVariables(searchFilters, paginationIndex)
  });

  const { data: userFieldsData } = useUserFields();
  const { data: countriesList } = useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GET_COUNTRIES, {
    variables: { id: String(community.id) }
  });

  const allCountries = countriesList?.community?.countries || [];

  const formik = useFormik<Filters>({
    initialValues: searchFilters,
    onSubmit: (values) => handleSubmit(values)
  });

  const { userFields, countries } = searchFilters;
  const filtersSelected: boolean = Object.keys(userFields).length > 0 || countries.length > 0;

  const handleUserSearch = ({ searchText, userFields, countries, userFieldType }: Filters) => {
    setPaginationIndex(0);
    setInitialIndex(0);
    setSearchTextToDisplay(searchText);
    setSearchFilters({
      searchText,
      userFields,
      countries,
      userFieldType
    });
  };

  useEffect(() => {
    if (initialFilters.searchText === '' || initialFilters.searchText.length < PEOPLE_SEARCH_CHARACTER_MIN) {
      return;
    }

    handleUserSearch(initialFilters);
    // we only want to do this on mount since to load the search query
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = ({ index }: { index: number }) => {
    setPaginationIndex(index);
  };

  const handleInitialSelected = (index: number) => {
    formik.resetForm();
    setSearchTextToDisplay('');
    setPaginationIndex(0);
    setInitialIndex(index);
    setIsFiltered(true);
    setSearchFilters({ ...EMPTY_FILTERS, initial: index === 0 ? '' : INITIALS[index] });
  };

  const handleSubmit = (values: Filters) => {
    const { searchText } = values;
    const text = (searchText && searchText.trim()) || '';

    if (text.length > 0 && text.length < PEOPLE_SEARCH_CHARACTER_MIN) {
      formik.setFieldError(
        'searchText',
        intl.formatMessage({
          id: 'atLeastTwoChar',
          defaultMessage: 'Please enter a search of at least two characters'
        })
      );
    } else {
      const newValues = { ...values, searchText: text };
      setIsFiltered(true);
      setSearchFilters(newValues);
      handleUserSearch(newValues);
    }
  };

  const handleClearSearchText = () => {
    formik.setFieldValue('searchText', '');
    setSearchTextToDisplay('');
    formik.handleSubmit();
  };

  const handleRemoveUserFieldFilter = ({ id, option, submit }: { id: string; option: string; submit?: boolean }) => {
    const { userFields, userFieldType } = formik.values;
    const field = userFields[id];
    const newValue = field.filter((value) => value !== option);
    if (newValue.length === 0) {
      formik.setFieldValue('userFields', omit(userFields, id));
      formik.setFieldValue('userFieldType', omit(userFieldType, id));
    } else {
      formik.setFieldValue(`userFields[${id}]`, newValue);
    }

    submit && formik.handleSubmit();
  };

  const handleRemoveCountryFilter = ({ option, submit }: { option: string; submit?: boolean }) => {
    const { countries } = formik.values;
    countries.splice(countries.indexOf(option), 1);
    formik.setFieldValue('countries', countries);
    submit && formik.handleSubmit();
  };

  const resetAll = () => {
    setIsFiltered(false);
    handleUserSearch(EMPTY_FILTERS);
    searchRef.current?.focus();
    formik.resetForm();
  };

  const totalUserCount = data?.users?.userCount || 0;
  const pagesCount = Math.ceil(totalUserCount / PAGE_LIMIT);

  const getSelectedFilters = () => {
    const selectedFilters: { id?: string; filterName: string; option: string }[] = [];
    Object.keys(userFields).forEach((id) => {
      const filterName = userFieldsData?.userFields.find((field) => field.id === id)?.name || '';
      userFields[id].forEach((option) => {
        selectedFilters.push({ id, filterName, option });
      });
    });
    countries.forEach((country) => {
      selectedFilters.push({
        filterName: intl.formatMessage({
          id: 'country',
          defaultMessage: 'Country'
        }),
        option: country
      });
    });

    return selectedFilters;
  };

  const users = data?.users?.userBatch || [];

  const setCardFocus = (user: User, index: number): ReactElement => {
    if (index === 0) {
      return <Usercard key={user.id} user={user} ref={cardsRef} />;
    }

    return <Usercard key={user.id} user={user} />;
  };

  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  useEffect(() => {
    if (!loadingUsers && isFiltered) {
      cardsRef.current?.focus();
    }
  }, [loadingUsers, isFiltered, searchFilters]);

  return (
    <Styled.Wrapper>
      <SearchFilters
        formik={formik}
        userFieldsList={userFieldsData?.userFields}
        allCountries={allCountries}
        handleRemoveUserFieldFilter={handleRemoveUserFieldFilter}
        handleRemoveCountryFilter={handleRemoveCountryFilter}
        resetAll={resetAll}
        ref={searchRef}
      />

      <Styled.InitialFilter data-testid="InitialFilter">
        {INITIALS.map((initial, index) => {
          const selected = initialIndex === index;
          return (
            <li key={initial}>
              <Button priority={selected ? 'primary' : 'tertiary'} onClick={() => handleInitialSelected(index)}>
                {initial}
              </Button>
            </li>
          );
        })}
      </Styled.InitialFilter>

      {!loadingUsers && (
        <PaginationNav
          paginationIndex={paginationIndex}
          pagesCount={pagesCount}
          handlePageChange={handlePageChange}
          totalUserCount={totalUserCount}
        />
      )}
      <Styled.Result>
        <Styled.FiltersDisplay data-testid="filtersDisplay">
          {searchTextToDisplay && (
            <>
              <FormattedMessage id="searchingFor" defaultMessage="Searching for" />
              <Styled.SelectedFilter autoWidth>
                {searchTextToDisplay}
                <Styled.DeleteButton
                  onClick={handleClearSearchText}
                  aria-label={intl.formatMessage({
                    id: 'clearSearchText',
                    defaultMessage: 'Clear search text'
                  })}
                >
                  <CancelXIcon aria-hidden="true" />
                </Styled.DeleteButton>
              </Styled.SelectedFilter>
            </>
          )}
          {!searchTextToDisplay && !filtersSelected && (
            <>
              <FormattedMessage id="showing" defaultMessage="Showing" />
              <Styled.Initial>{INITIALS[initialIndex]}</Styled.Initial>
            </>
          )}
          {filtersSelected && (
            <>
              <FormattedMessage id="filterBy" defaultMessage="Filter by:" />
              {getSelectedFilters().map(({ id, filterName, option }) => (
                <Styled.SelectedFilter key={option}>
                  {`${filterName}: ${option}`}
                  <Styled.DeleteButton
                    aria-label={intl.formatMessage(
                      {
                        id: 'clearFilter',
                        defaultMessage: 'Clear filter {filterName}'
                      },
                      { filterName: `${filterName}: ${option}` }
                    )}
                    onClick={() =>
                      id
                        ? handleRemoveUserFieldFilter({ id, option, submit: true })
                        : handleRemoveCountryFilter({ option, submit: true })
                    }
                  >
                    <CancelXIcon aria-hidden="true" />
                  </Styled.DeleteButton>
                </Styled.SelectedFilter>
              ))}
            </>
          )}
          {!loadingUsers && (
            <Styled.TotalStat>
              <FormattedMessage
                id="profilesInTotal"
                defaultMessage="<b>{profileCount} Profiles</b> {found, plural, one {found} other {in total}}"
                values={{
                  profileCount: totalUserCount,
                  found: searchTextToDisplay ? 1 : 2,
                  b: (msg) => boldText(msg)
                }}
              />
            </Styled.TotalStat>
          )}
        </Styled.FiltersDisplay>
        <Styled.UserCardsContainer>
          {loadingUsers ? (
            <Styled.LoadingContainer>
              <LoadingIndicator inline />
            </Styled.LoadingContainer>
          ) : (
            <>
              {users && users.length > 0 && users.map(setCardFocus)}
              {(!users || users.length === 0) && (
                <Styled.NoUsersMessage>
                  <FormattedMessage id="noUsers" defaultMessage="No users found." />
                </Styled.NoUsersMessage>
              )}
            </>
          )}
        </Styled.UserCardsContainer>
      </Styled.Result>
      {!loadingUsers && (
        <PaginationNav
          paginationIndex={paginationIndex}
          pagesCount={pagesCount}
          handlePageChange={handlePageChange}
          showRange
          totalUserCount={totalUserCount}
        />
      )}
    </Styled.Wrapper>
  );
};

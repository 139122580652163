import { ToastLevel, ToastPlacement } from '../common/enums/ToastMessageType';

export const getToastMessages = (level: ToastLevel, content: string, placement?: ToastPlacement) => [
  {
    key: Date.now(),
    level,
    content,
    shouldAutoDismiss: true,
    autoDismissDelay: 3000,
    placement
  }
];

export const getConfirmToastMessage = (content: string) => [
  {
    key: Date.now(),
    level: ToastLevel.CONFIRM,
    content,
    shouldAutoDismiss: true,
    autoDismissDelay: 3000,
    placement: ToastPlacement.BOTTOM
  }
];

export const getErrorToastMessage = (content: string) => [
  {
    key: Date.now(),
    level: ToastLevel.ERROR,
    content,
    shouldAutoDismiss: true,
    autoDismissDelay: 3000,
    placement: ToastPlacement.BOTTOM
  }
];

import axios from 'axios';
import { defineMessages, type MessageDescriptor } from 'react-intl';

interface ValidationMessage {
  message: MessageDescriptor;
  type: string;
}

interface Errors {
  newPassword: Array<ValidationMessage>;
  newPasswordConfirm: Array<ValidationMessage>;
}

export const DEFAULT_PASSWORD_RULES = {
  minLength: 8,
  maxLength: 30,
  specialChars: '!@#$%^&*'
};

interface PasswordComplexity {
  passwordComplexityEnabled?: boolean;
  passwordComplexityLengthMin?: number;
  passwordComplexitySpecialMin?: number;
  passwordComplexitySpecialChars?: string;
  passwordComplexityLengthUpperMin?: number;
  passwordComplexityLengthLowerMin?: number;
  passwordComplexityLengthNumberMin?: number;
}

const ERROR = 'error';
const SUCCESS = 'success';

export const getValidationMessages = ({
  passwordComplexityLengthMin,
  passwordComplexitySpecialMin,
  passwordComplexitySpecialChars,
  passwordComplexityLengthUpperMin,
  passwordComplexityLengthLowerMin,
  passwordComplexityLengthNumberMin
}: PasswordComplexity) =>
  defineMessages({
    passwordLengthCorrect: {
      id: 'passwordLengthCorrect',
      defaultMessage: 'Password length correct.'
    },
    passwordMinLengthError: {
      id: 'passwordMinLengthError',
      defaultMessage: 'Password must contain a minimum of {minLength} characters.',
      values: {
        minLength: DEFAULT_PASSWORD_RULES.minLength
      }
    },
    passwordConfigurableMinLengthError: {
      id: 'passwordConfigurableMinLengthError',
      defaultMessage: 'Password must contain a minimum of {minLength} non-blank characters.',
      values: {
        minLength: passwordComplexityLengthMin
      }
    },
    passwordMaxLengthError: {
      id: 'passwordMaxLengthError',
      defaultMessage: 'Password must not be longer than 30 characters.'
    },
    passwordCharactersComplexityCorrect: {
      id: 'passwordCharactersComplexityCorrect',
      defaultMessage: 'Password contains at least 3 character types.'
    },
    passwordConfigurableCharactersComplexityCorrect: {
      id: 'passwordConfigurableCharactersComplexityCorrect',
      defaultMessage: 'Password contains required character types.'
    },
    passwordCharactersComplexityError: {
      id: 'passwordCharactersComplexityError',
      defaultMessage:
        'Password must contain 3 of the following characters types: uppercase, lowercase, number or symbol.'
    },
    passwordConfigurableCharactersComplexityError: {
      id: 'passwordConfigurableCharactersComplexityError',
      defaultMessage:
        'Password must contain a minimum of {lowerCaseMin} lowercase, {upperCaseMin} uppercase, {numbersMin} numeric and {specialCharsMin} special({specialChars}) characters.',
      values: {
        lowerCaseMin: passwordComplexityLengthLowerMin,
        upperCaseMin: passwordComplexityLengthUpperMin,
        numbersMin: passwordComplexityLengthNumberMin,
        specialCharsMin: passwordComplexitySpecialMin,
        specialChars: passwordComplexitySpecialChars
      }
    },
    passwordComplexityCorrect: {
      id: 'passwordComplexityCorrect',
      defaultMessage: 'Password is strong.'
    },
    passwordComplexityError: {
      id: 'passwordComplexityError',
      defaultMessage:
        'Password must be strong. Avoid using common words, simple character sequences or your personal information.'
    },
    confirmPasswordError: {
      id: 'confirmPasswordError',
      defaultMessage: 'Those passwords did not match. Try again.'
    },
    passwordNoTrailingOrLeadingWhitespacesError: {
      id: 'passwordNoTrailingOrLeadingWhitespacesError',
      defaultMessage: 'Password cannot start or end with a blank space'
    }
  });

// check if password has required min length 0
export const isMinLengthCorrect = (term: string, minValue: number = DEFAULT_PASSWORD_RULES.minLength): boolean =>
  term.length >= minValue;

// check if password has required max length 30
export const isMaxLengthCorrect = (term: string): boolean => term.length <= DEFAULT_PASSWORD_RULES.maxLength;

export const isLengthCorrect = (term: string, minValue: number = DEFAULT_PASSWORD_RULES.minLength): boolean =>
  isMinLengthCorrect(term, minValue) && isMaxLengthCorrect(term);

// check if password has lowercase, uppercase, numbers or special character
export const isComplexityCorrect = (term: string): boolean =>
  /^(?=.*[a-z])(?=.*[A-Z])((?=.*\d)|(?=.*\W))[A-Za-z\d\W]/.test(term);

export const hasLeadingOrTrailingWhitespaces = (term: string): boolean => term.trim() !== term;

const removeSpecialChars = (password: string, chars: string) => {
  // eslint-disable-next-line no-return-assign
  [...chars].forEach((char) => (password = password.replace(char, '')));
  return password;
};

export const isConfigurableComplexityCorrect = (password: string, passwordComplexity: PasswordComplexity) => {
  const {
    passwordComplexityLengthUpperMin = 0,
    passwordComplexityLengthLowerMin = 0,
    passwordComplexityLengthNumberMin = 0,
    passwordComplexitySpecialMin = 0,
    passwordComplexitySpecialChars = ''
  } = passwordComplexity;

  const uppercaseCount = (password.match(/[A-Z]/g) || []).length;
  const lowercaseCount = (password.match(/[a-z]/g) || []).length;
  const numberCount = (password.match(/[0-9]/g) || []).length;
  const passwordWithoutSpecialCharacters = removeSpecialChars(password, passwordComplexitySpecialChars);

  const specialCount = password.length - passwordWithoutSpecialCharacters.length;

  return !(
    uppercaseCount < passwordComplexityLengthUpperMin ||
    lowercaseCount < passwordComplexityLengthLowerMin ||
    numberCount < passwordComplexityLengthNumberMin ||
    specialCount < passwordComplexitySpecialMin
  );
};

const getIsPasswordStrong = async (newPassword: string): Promise<boolean> => {
  const { data }: { data: string } = await axios.post(
    '/user/validatepassword',
    `newPassword=${newPassword}&controller=user&action=ideastream`,
    {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
  );

  // data is a string which is either "true" or "false"
  return JSON.parse(data) as boolean;
};

export const validateResetPasswordForm = async (
  {
    newPassword = '',
    newPasswordConfirm = ''
  }: {
    newPassword: string;
    newPasswordConfirm: string;
  },
  passwordComplexity: PasswordComplexity
): Promise<Errors | void> => {
  const errors = {} as Errors;
  const newPasswordErrors = await validateNewPassword(newPassword, passwordComplexity);

  const newPasswordConfirmErrors = validatePasswordConfirm(newPassword, newPasswordConfirm, passwordComplexity);

  if (newPasswordErrors) {
    errors.newPassword = newPasswordErrors;
  }

  if (newPasswordConfirmErrors) {
    errors.newPasswordConfirm = newPasswordConfirmErrors;
  }

  return errors;
};

export const validateNewPassword = async (newPassword: string, passwordComplexity: PasswordComplexity) => {
  const messages: ValidationMessage[] = [];
  const validationMessages: { [key: string]: MessageDescriptor } = getValidationMessages(passwordComplexity);

  const minValue = passwordComplexity.passwordComplexityEnabled
    ? passwordComplexity.passwordComplexityLengthMin
    : DEFAULT_PASSWORD_RULES.minLength;

  if (!newPassword) {
    return;
  }

  if (hasLeadingOrTrailingWhitespaces(newPassword)) {
    messages.push({
      message: validationMessages.passwordNoTrailingOrLeadingWhitespacesError,
      type: ERROR
    });
  }

  if (!isMinLengthCorrect(newPassword, minValue)) {
    messages.push({
      message: passwordComplexity.passwordComplexityEnabled
        ? validationMessages.passwordConfigurableMinLengthError
        : validationMessages.passwordMinLengthError,
      type: ERROR
    });
  }

  if (!isMaxLengthCorrect(newPassword)) {
    messages.push({
      message: validationMessages.passwordMaxLengthError,
      type: ERROR
    });
  }

  if (isLengthCorrect(newPassword, minValue)) {
    messages.push({
      message: validationMessages.passwordLengthCorrect,
      type: SUCCESS
    });
  }

  const isComplexityValid = passwordComplexity.passwordComplexityEnabled
    ? isConfigurableComplexityCorrect(newPassword, passwordComplexity)
    : isComplexityCorrect(newPassword);

  if (isComplexityValid) {
    messages.push({
      message: passwordComplexity.passwordComplexityEnabled
        ? validationMessages.passwordConfigurableCharactersComplexityCorrect
        : validationMessages.passwordCharactersComplexityCorrect,
      type: SUCCESS
    });
  } else {
    messages.push({
      message: passwordComplexity.passwordComplexityEnabled
        ? validationMessages.passwordConfigurableCharactersComplexityError
        : validationMessages.passwordCharactersComplexityError,
      type: ERROR
    });
  }

  // only verify if password is strong when length and complexity meets requirements
  // we will avoid unnecessary api calls
  if (isLengthCorrect(newPassword) && isComplexityValid) {
    const isPasswordStrong: boolean = await getIsPasswordStrong(newPassword);

    if (isPasswordStrong) {
      messages.push({
        message: validationMessages.passwordComplexityCorrect,
        type: SUCCESS
      });
    } else {
      messages.push({
        message: validationMessages.passwordComplexityError,
        type: ERROR
      });
    }
  }

  return messages;
};

export const validatePasswordConfirm = (
  newPassword: string,
  newPasswordConfirm: string,
  passwordComplexity: PasswordComplexity
): Array<ValidationMessage> | null => {
  const validationMessages: { [key: string]: MessageDescriptor } = getValidationMessages(passwordComplexity);

  if (newPassword && newPasswordConfirm && newPassword !== newPasswordConfirm) {
    return [
      {
        message: validationMessages.confirmPasswordError,
        type: ERROR
      }
    ];
  }

  return null;
};

export const isFormValid = (messages: {
  oldPassword: ValidationMessage[];
  newPassword: ValidationMessage[];
  newPasswordConfirm: ValidationMessage[];
}): boolean => {
  const errors = Object.values(messages)
    .flat()
    .filter(({ type }) => type === ERROR);

  return errors.length === 0;
};

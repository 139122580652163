import { useFormik } from 'formik';
import { Field, FieldContent, FieldLabel, Select } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { format, utcToZonedTime } from 'date-fns-tz';
import { isAfter, isBefore } from 'date-fns';
import { ScoreResetView } from '../enums/ScoreResetView';
import { type IScoreReset } from '../../../common/interfaces/ScoreReset';
import {
  ScoreResetDateAndTime,
  ScoreResetResetButton,
  ScoreResetStyledDatePicker,
  ScoreResetSubmissionFieldWrapper,
  ScoreResetTimeInput
} from './ScoreResetSubmission.styled';

export const ScoreResetSubmission = ({
  view,
  userTimezone,
  loading,
  lastResetDate,
  onSubmit
}: {
  readonly view: ScoreResetView;
  readonly userTimezone: string;
  readonly loading: boolean;
  readonly lastResetDate: string | undefined;
  readonly onSubmit: (values: IScoreReset) => void;
}) => {
  const intl = useIntl();
  const full = intl.formatMessage({
    id: 'full',
    defaultMessage: 'Full'
  });
  const partial = intl.formatMessage({
    id: 'partial',
    defaultMessage: 'Partial'
  });
  const resetScoreButtonText =
    view === ScoreResetView.GLOBAL
      ? intl.formatMessage({
          id: 'resetScoreGlobalButton',
          defaultMessage: "Reset everyone's scores"
        })
      : intl.formatMessage({
          id: 'resetScoreUserButton',
          defaultMessage: 'Reset user score'
        });
  const fullDescription =
    view === ScoreResetView.GLOBAL
      ? intl.formatMessage({
          id: 'resetScoreGlobalFullDesc',
          defaultMessage: "Everyone's scores will be reset to zero"
        })
      : intl.formatMessage({
          id: 'resetScoreUserFullDesc',
          defaultMessage: 'User score will be reset to zero'
        });
  const partialDescription =
    view === ScoreResetView.GLOBAL
      ? intl.formatMessage({
          id: 'resetScoreGlobalPartialDesc',
          defaultMessage: "Everyone's scores will be reset, but the scores awarded for badges will be retained"
        })
      : intl.formatMessage({
          id: 'resetScoreUserPartialDesc',
          defaultMessage: 'User score will be reset, but the score awarded for badges will be retained'
        });
  const scoreResetDateTooEarly = intl.formatMessage({
    id: 'scoreResetDateTooEarly',
    defaultMessage: 'Date must be after the last reset'
  });
  const scoreResetDateTooLate = intl.formatMessage({
    id: 'scoreResetDateTooLate',
    defaultMessage: 'Date must not be a future date'
  });

  const scoreResetFormValidate = (values: IScoreReset) => {
    const errors: Partial<IScoreReset> = {};

    if (lastResetDate && isBefore(new Date(values.date).setSeconds(0), new Date(lastResetDate))) {
      errors.date = scoreResetDateTooEarly;
    }

    if (isAfter(new Date(values.date), new Date())) {
      errors.date = scoreResetDateTooLate;
    }

    return errors;
  };

  const now = new Date();
  now.setSeconds(0);
  now.setMilliseconds(0);

  const formik = useFormik({
    initialValues: { excludeBadges: false, date: now.toISOString() },
    onSubmit: () => {},
    validate: scoreResetFormValidate
  });

  const resetDateInUserTimezone = utcToZonedTime(formik.values.date, userTimezone);
  const resetTimeInUserTimezone = format(resetDateInUserTimezone, 'HH:mm');

  const handleScoreResetTimeChange = (value: string) => {
    const [hour, minute] = value.split(':');
    resetDateInUserTimezone.setHours(Number(hour));
    resetDateInUserTimezone.setMinutes(Number(minute));
    formik.setFieldValue('date', resetDateInUserTimezone.toISOString());
  };

  const handleScoreResetDateChange = (value: string) => {
    const [month, day, year] = value.split('/');
    resetDateInUserTimezone.setFullYear(Number(year), Number(month) - 1, Number(day));
    formik.setFieldValue('date', resetDateInUserTimezone.toISOString());
  };

  const handleScoreResetTypeChange = (value: string) => {
    formik.setFieldValue('excludeBadges', value === 'partial');
  };

  const isFormValid = !formik.errors?.date;

  return (
    <form data-testid="score-reset-submission-form">
      <ScoreResetSubmissionFieldWrapper>
        <Field
          status={formik.errors?.date ? { level: 'error', message: formik.errors.date } : undefined}
          label={<FormattedMessage id="scoreResetDateAndTimeLabel" defaultMessage="Reset date and time" />}
          description={
            <FormattedMessage
              id="scoreResetDateAndTimeDesc"
              defaultMessage="Select a date between the last reset and now."
            />
          }
          input={
            <ScoreResetDateAndTime id="score-reset-date-and-time">
              <FormattedMessage
                id="phaseStartDate"
                defaultMessage="{dateInput} at {timeInput}"
                values={{
                  dateInput: (
                    <ScoreResetStyledDatePicker
                      date={resetDateInUserTimezone}
                      onDateChange={(date) => {
                        date && handleScoreResetDateChange(format(date, 'MM/dd/yyyy'));
                      }}
                      data-testid="score-reset-date-picker"
                      aria-label="Date input"
                      popperProps={{ zIndex: 1052 }}
                    />
                  ),
                  timeInput: (
                    <ScoreResetTimeInput
                      value={resetTimeInUserTimezone}
                      type="time"
                      onChange={(e) => handleScoreResetTimeChange(e.target.value)}
                      data-testid="score-reset-time-picker"
                      aria-label="Time input"
                    />
                  )
                }}
              />
            </ScoreResetDateAndTime>
          }
        />
        <Field>
          <FieldLabel htmlFor="score-reset-type">
            <FormattedMessage id="scoreResetTypeLabel" defaultMessage="Reset type" />
          </FieldLabel>
          <FieldContent>
            <Select
              data-testid="score-reset-submission-select-type"
              id="score-reset-type"
              required
              defaultValue="full"
              onChange={({ value }) => handleScoreResetTypeChange(value)}
              popperProps={{ zIndex: 1052 }}
              options={[
                { label: full, value: 'full', description: fullDescription },
                { label: partial, value: 'partial', description: partialDescription }
              ]}
            />
          </FieldContent>
        </Field>
        <ScoreResetResetButton
          onClick={() => {
            isFormValid && onSubmit(formik.values);
          }}
          isLoading={loading}
          size="large"
          destructive
          priority="secondary"
          data-testid="score-reset-submission-submit-button"
          disabled={!isFormValid}
        >
          {resetScoreButtonText}
        </ScoreResetResetButton>
      </ScoreResetSubmissionFieldWrapper>
    </form>
  );
};

import styled, { css } from 'styled-components';
import { atMinWidth, borderRadius, color, MoreMenuButton, snap, typeStyle, units, UserIcon } from '@m/alchemy-ui';
import { Breakpoints } from '../../../common/enums';

export const HeaderContainer = styled.div<{ addBackgroundColor: boolean }>`
  align-items: flex-start;
  display: flex;
  font: ${typeStyle('bodyS')};
  padding-left: ${units(2)};

  ${({ addBackgroundColor }) =>
    addBackgroundColor &&
    css`
      background-color: ${color('base', { palette: 'illustrativeBrand' })};
      border-top-left-radius: ${borderRadius('extraLarge')};
      border-top-right-radius: ${borderRadius('extraLarge')};
    `}

  ${atMinWidth(
    Breakpoints.WIDE_MOBILE_MIN,
    `
      padding-left: ${units(3)};
    `
  )};
`;

export const HeaderBody = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: ${units(2)};
  width: calc(100% - ${units(5)});
`;

export const HeaderBodyWithIcon = styled.div`
  align-items: center;
  color: ${color('contrast', { palette: 'illustrativeBrand' })};
  display: flex;
  flex-wrap: wrap;
  font: ${typeStyle('subHeaderM')};
  line-height: 1.3;
  padding: ${units(0.7)} 0;
  width: calc(100% - ${units(5)});
`;

export const AlignItemsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.3;
`;

export const AlignItems = styled.div`
  display: flex;
  align-items: center;
`;

export const AlignItemsTop = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color('contrast', { palette: 'illustrativeBrand' })};
  border-radius: 50%;
  width: ${units(4)};
  height: ${units(4)};
  margin-right: ${units(1)};
  svg {
    color: ${color('base', { palette: 'illustrativeBrand' })};
    width: ${units(2.1)};
    height: ${units(2.1)};
  }
`;

export const LinkedName = styled.a`
  font-weight: 500;
  margin-left: ${units(0.5)};
  margin-right: ${units(0.5)};
  max-width: ${snap(200)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  margin-right: ${units(0.5)};
  height: ${units(3)};
  width: ${units(3)};
`;

export const MoreButton = styled(MoreMenuButton)<{ hasPadding: boolean }>`
  display: none;
  padding-right: ${units(2)};
  svg {
    height: ${units(2.7)};
  }
  width: ${units(5)};

  ${({ hasPadding }) =>
    hasPadding
      ? css`
          padding-top: ${units(2)};
        `
      : css`
          align-self: center;
          svg {
            color: ${color('contrast', { palette: 'illustrativeBrand' })};
          }
        `}
`;

export const UserTitle = styled.a`
  font: ${typeStyle('bodyM')};
`;

export const NoProfileImage = styled(UserIcon)`
  height: ${units(3)};
  width: ${units(3)};
  margin-right: ${units(0.5)};
  color: ${color('base')};
`;

export const SubHeader = styled.div`
  padding-left: ${units(3)};
`;

import { Button, Dialog, Field, Input, TextArea, units } from '@m/alchemy-ui';
import { Form, Formik, useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Body, ButtonRow, Title } from '../../../common/components';
import { type IChallenge } from '../../../common/interfaces';

export interface TemplateOptionsProps {
  readonly isOpen?: boolean;
  readonly isLoading: boolean;
  readonly handleClose: () => void;
  readonly handleSave: () => Promise<boolean>;
}

const StyledField = styled(Field)`
  margin: 0 0 ${units(2)} ${units(1)};
`;

export const TemplateOptions = ({ isOpen = false, isLoading, handleClose, handleSave }: TemplateOptionsProps) => {
  const { handleChange } = useFormikContext<IChallenge>();

  const intl = useIntl();

  const handleSubmit = async () => {
    const saveSuccess = await handleSave();
    if (saveSuccess) {
      handleClose();
    }
  };

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} size="medium">
      <Dialog.Header>
        <Title>
          <FormattedMessage id="saveAsTemplateTitle" defaultMessage="Save as a Template for future use" />
        </Title>
      </Dialog.Header>
      <Dialog.Body>
        <Body>
          <FormattedMessage
            id="templateOptionsInfo"
            defaultMessage="Saving a template enables you to create new challenges with these settings. When you click 'Save', both the challenge and template will be saved."
          />
        </Body>
        <Formik initialValues={{}} onSubmit={handleSubmit}>
          {() => (
            <Form>
              <StyledField
                label={intl.formatMessage({ id: 'name', defaultMessage: 'Name' })}
                required
                isFullWidth
                input={
                  <Input
                    name="template.name"
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'nameYourTemplate', defaultMessage: 'Name your template' })}
                  />
                }
              />
              <StyledField
                label={intl.formatMessage({ id: 'description', defaultMessage: 'Description' })}
                isFullWidth
                input={
                  <TextArea
                    name="template.description"
                    onChange={handleChange}
                    placeholder={intl.formatMessage({
                      id: 'templateDescription',
                      defaultMessage: 'Describe what this template is used for (max. 100 chars)'
                    })}
                  />
                }
              />
              <ButtonRow>
                <Button onClick={handleClose} priority="secondary">
                  <FormattedMessage id="cancel" defaultMessage="Cancel" />
                </Button>
                <Button isLoading={isLoading} type="submit" priority="primary">
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
              </ButtonRow>
            </Form>
          )}
        </Formik>
      </Dialog.Body>
    </Dialog>
  );
};

import { hasValue } from '@m/magic-typescript';
import { type ReactNode, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CommunityContext, UserContext } from '../../../../context';
import { UserType } from '../../../../common/enums';
import * as Styled from './Settings.styled';
import { Accounts } from './Accounts';
import { Notifications } from './Notifications';
import { Api } from './Api';
import { SocialMedia } from './SocialMedia';
import { PasswordReset, type PasswordResetState } from './PasswordReset';
import { Visibility } from './Visibility';

export enum SettingsTabs {
  NOTIFICATIONS = 'NOTIFICATIONS',
  LINKED_ACCOUNTS = 'LINKED_ACCOUNTS',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  PASSWORD_RESET = 'PASSWORD_RESET',
  API = 'API',
  VISIBILITY = 'VISIBILITY'
}

export const Settings = () => {
  const community = useContext(CommunityContext);
  const user = useContext(UserContext);
  const [passwordResetState, setPasswordResetState] = useState<PasswordResetState>({ resetAt: null, blocked: false });

  const tabs: { label?: ReactNode; value: string }[] = [
    {
      label: <FormattedMessage id="notifications" defaultMessage="Notifications" />,
      value: SettingsTabs.NOTIFICATIONS
    },
    community.options?.allowSocialMediaLogin && {
      label: <FormattedMessage id="linkedAccounts" defaultMessage="Linked accounts" />,
      value: SettingsTabs.LINKED_ACCOUNTS
    },
    community.options?.allowSocialMediaLinksInProfile && {
      label: <FormattedMessage id="socialMedia" defaultMessage="Social media" />,
      value: SettingsTabs.SOCIAL_MEDIA
    },
    user.hasPassword && {
      label: <FormattedMessage id="passwordReset" defaultMessage="Password reset" />,
      value: SettingsTabs.PASSWORD_RESET
    },
    community.options?.apiEnabled && {
      label: <FormattedMessage id="API" defaultMessage="API" />,
      value: SettingsTabs.API
    },
    user.userAccess &&
      [UserType.SUPER_ADMIN, UserType.ADMIN, UserType.SUPPORT, UserType.ENGINEER].includes(
        user.userAccess.userType
      ) && {
        label: <FormattedMessage id="visibility" defaultMessage="Visibility" />,
        value: SettingsTabs.VISIBILITY
      }
  ].filter(hasValue);

  const [activeTab, setActiveTab] = useState(tabs[0].value);

  return (
    <Styled.Wrapper>
      <Styled.Title>
        <FormattedMessage id="Settings" defaultMessage="Settings" />
      </Styled.Title>
      <Styled.SettingsSelect
        defaultValue={SettingsTabs.NOTIFICATIONS}
        options={tabs as { label: ReactNode; value: string }[]}
        onChange={({ value }) => setActiveTab(value)}
        menuMaxHeight={140}
      />
      <Styled.SettingsTabs
        tabs={tabs}
        activeTabValue={activeTab}
        onTabClick={(_, tabData) => setActiveTab(tabData.value)}
        layout="left"
      />
      {activeTab === SettingsTabs.API && <Api />}
      {activeTab === SettingsTabs.NOTIFICATIONS && <Notifications />}
      {activeTab === SettingsTabs.LINKED_ACCOUNTS && <Accounts />}
      {activeTab === SettingsTabs.SOCIAL_MEDIA && <SocialMedia />}
      {activeTab === SettingsTabs.PASSWORD_RESET && (
        <PasswordReset passwordResetState={passwordResetState} updateHandler={setPasswordResetState} />
      )}
      {activeTab === SettingsTabs.VISIBILITY && <Visibility />}
    </Styled.Wrapper>
  );
};

import styled from 'styled-components';
import { useEffect } from 'react';
import { Field, OverflowScroller, units } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import {
  type ActivityTimelineItem,
  defaultChallengeMilestone,
  defaultMilestone,
  type IChallenge
} from '../../../common/interfaces';
import { TimelineItemType } from '../../../common/enums';
import { ActivityTimeline } from './ActivityTimeline';
import { MilestoneAddButton, MilestoneIndicator, MilestoneOptions } from '.';

const StyledDiv = styled.div`
  margin-top: ${units(1)};
`;

export const MilestoneEditor = () => {
  const { values, setFieldValue } = useFormikContext<IChallenge>();
  const intl = useIntl();

  const { milestone } = values;
  const milestones = milestone?.milestones || [];
  const milestoneCount = milestones.length;

  const hasIdeas = Number(values.ideas?.length) > 0;

  useEffect(() => {
    const submittedMilestoneName = intl.formatMessage({ id: 'submitted', defaultMessage: 'Submitted' });
    const submittedMilestone = { ...defaultMilestone, name: submittedMilestoneName };

    if (!milestone) {
      setFieldValue('milestone', { ...defaultChallengeMilestone, milestones: [submittedMilestone] });
    }
  }, [milestone, setFieldValue, intl]);

  const getMilestoneTimelineItems = () => {
    const items: ActivityTimelineItem[] = [];

    items.push({ type: TimelineItemType.MILESTONE_BULLET });

    milestones.forEach((milestone, i) => {
      items.push({
        customIndicator: {
          indicator: <MilestoneIndicator milestone={milestone} milestoneIndex={i} />,
          name: milestone.name || ''
        },
        type: TimelineItemType.MILESTONE
      });

      if (!(i === milestoneCount - 1) && !hasIdeas) {
        items.push({
          customIndicator: {
            indicator: (
              <MilestoneAddButton
                message={intl.formatMessage({ id: 'insert', defaultMessage: 'insert' })}
                newMilestoneIndex={i + 1}
              />
            )
          },
          type: TimelineItemType.MILESTONE_BUTTON
        });
      }
    });

    items.push({
      customIndicator: {
        indicator: (
          <MilestoneAddButton
            message={intl.formatMessage({ id: 'add', defaultMessage: 'Add' })}
            newMilestoneIndex={milestoneCount}
          />
        )
      },
      type: TimelineItemType.MILESTONE_BUTTON
    });

    return items;
  };

  return (
    <StyledDiv>
      <MilestoneOptions />
      <Field
        required
        isFullWidth
        labelVariant="emphasized"
        label={intl.formatMessage({ id: 'milestone', defaultMessage: 'Milestone' })}
        input={
          <OverflowScroller>
            <ActivityTimeline items={getMilestoneTimelineItems()} />
          </OverflowScroller>
        }
      />
    </StyledDiv>
  );
};

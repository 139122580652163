import { useIntl } from 'react-intl';
import { atMinWidth, Button, Field, Input, Radio, RadioGroup, Select, typeStyle, units } from '@m/alchemy-ui';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { useState } from 'react';
import { type GetChallengesForSearchQuery, type GetChallengesForSearchQueryVariables } from '../../../graphql/queries';
import { CHALLENGES_FOR_SEARCH_QUERY } from '../../../graphql/queries/challenge';
import { useRuntimeConfig } from '../../../context';
import { Breakpoints } from '../../../common/enums';
import { PEOPLE_SEARCH_CHARACTER_MIN } from '../../shared-constants';

enum SearchTarget {
  IDEAS = 'ideas',
  PEOPLE = 'people'
}

const PeopleForm = () => {
  const intl = useIntl();
  return (
    <form action="/user/search" method="get" data-testid="people-form">
      <Field
        isFullWidth
        input={
          <Input
            defaultValue=""
            placeholder={intl.formatMessage({ id: 'Search for a user', defaultMessage: 'Search for a user' })}
            type="search"
            name="q"
            minLength={PEOPLE_SEARCH_CHARACTER_MIN}
            required
          />
        }
      />
      <div>
        <Button type="submit">{intl.formatMessage({ id: 'Search', defaultMessage: 'Search' })}</Button>
      </div>
    </form>
  );
};

const ALL_CHALLENGES = '0';

const ChallengeSelector = ({
  value,
  onChange
}: {
  readonly value: string;
  readonly onChange: (challengeId: string) => void;
}) => {
  const intl = useIntl();
  const ALL_CHALLENGES_OPTION = {
    value: ALL_CHALLENGES,
    label: intl.formatMessage({ id: 'All Challenges', defaultMessage: 'All Challenges' })
  };
  const { data, loading } = useQuery<GetChallengesForSearchQuery, GetChallengesForSearchQueryVariables>(
    CHALLENGES_FOR_SEARCH_QUERY
  );
  const options = [
    ALL_CHALLENGES_OPTION,
    ...(data?.challenges || []).map(({ id, title }) => ({ value: id, label: title }))
  ];
  return (
    <Field
      isFullWidth
      label={intl.formatMessage({ id: 'Search by challenge', defaultMessage: 'Search by challenge' })}
      input={
        <Select
          value={value}
          onChange={(option) => onChange(option.value)}
          options={options}
          isLoading={loading}
          isSearchable={options.length > 10}
        />
      }
    />
  );
};

const StyledField = styled(Field)`
  & button {
    margin-bottom: ${units(0.5)};

    &:not(:last-child) {
      margin-right: ${units(1)};
    }
  }
`;

const TagList = ({ onTagClick }: { readonly onTagClick: (tag: string) => void }) => {
  const intl = useIntl();
  const { popularTags: tags } = useRuntimeConfig();
  if (tags.length === 0) {
    return null;
  }

  return (
    <StyledField
      label={intl.formatMessage({ id: 'Popular Tags', defaultMessage: 'Popular Tags' })}
      input={
        <>
          {tags.map((tag) => (
            <Button
              key={tag}
              priority="secondary"
              type="button"
              size="small"
              aria-label={intl.formatMessage(
                { id: 'SearchTagLabel', defaultMessage: 'Add tag {tag} to the search text' },
                { tag }
              )}
              onClick={() => {
                onTagClick(tag);
              }}
            >
              {tag}
            </Button>
          ))}
        </>
      }
    />
  );
};

const IdeasForm = () => {
  const intl = useIntl();
  const [challenge, setChallenge] = useState<string>(ALL_CHALLENGES);
  const [search, setSearch] = useState<string>('');

  const handleTagClick = (tag: string) => {
    setSearch(`${search}[${tag}]`);
  };

  return (
    <form action={`category/${challenge}`} method="get" data-testid="ideas-form">
      <Field
        isFullWidth
        input={
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={intl.formatMessage({
              id: 'Search Crowdicity: use [tag name] to search tags',
              defaultMessage: 'Search Crowdicity: use [tag name] to search tags'
            })}
            type="search"
            name="q"
          />
        }
      />
      <ChallengeSelector value={challenge} onChange={(challengeId) => setChallenge(challengeId)} />
      <TagList onTagClick={handleTagClick} />
      <div>
        <Button type="submit">{intl.formatMessage({ id: 'Search', defaultMessage: 'Search' })}</Button>
      </div>
    </form>
  );
};

const FormWrapper = styled.div`
  margin: ${units(1)};
  max-height: 50vh;
  overflow: auto;
  width: 80vw;
  padding: ${units(1)};

  ${atMinWidth(
    Breakpoints.TABLET_MIN,
    `
      max-height: unset;
      overflow: unset;
      width: 600px;
    `
  )};

  & form > :last-child {
    text-align: right;
  }

  & form > div:not(:last-child) {
    margin-bottom: ${units(3)};
  }
`;

export const Header = styled.h1`
  ${typeStyle('headerL')};
  margin-bottom: ${units(2)};
`;

export const SearchForm = () => {
  const intl = useIntl();
  const [searchTarget, setSearchTarget] = useState<SearchTarget>(SearchTarget.IDEAS);
  return (
    <FormWrapper>
      <Header data-testid="form-header">{intl.formatMessage({ id: 'Search', defaultMessage: 'Search' })}</Header>
      <Field
        label={intl.formatMessage({ id: 'Search in:', defaultMessage: 'Search in:' })}
        input={
          <RadioGroup
            layout="row"
            selection={searchTarget}
            onChange={(selection: SearchTarget) => setSearchTarget(selection)}
          >
            {({ isSelected, handleChange }) => [
              <Radio
                key={SearchTarget.IDEAS}
                name="search-target"
                checked={isSelected(SearchTarget.IDEAS)}
                value={SearchTarget.IDEAS}
                label={intl.formatMessage({ id: 'Ideas', defaultMessage: 'Ideas' })}
                onChange={() => handleChange(SearchTarget.IDEAS)}
              />,
              <Radio
                key={SearchTarget.PEOPLE}
                checked={isSelected(SearchTarget.PEOPLE)}
                name="search-target"
                value={SearchTarget.PEOPLE}
                label={intl.formatMessage({ id: 'People', defaultMessage: 'People' })}
                onChange={() => handleChange(SearchTarget.PEOPLE)}
              />
            ]}
          </RadioGroup>
        }
      />
      <div style={{ display: searchTarget === SearchTarget.PEOPLE ? 'block' : 'none' }}>
        <PeopleForm />
      </div>
      <div style={{ display: searchTarget === SearchTarget.IDEAS ? 'block' : 'none' }}>
        <IdeasForm />
      </div>
    </FormWrapper>
  );
};

import styled from 'styled-components';
import { Field, snap, typeStyle, units } from '@m/alchemy-ui';

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: ${units(2)};
  width: 100%;

  @media (min-width: 500px) {
    padding: ${units(3)};
  }
`;

export const Title = styled.h1`
  ${typeStyle('headerL')};
  align-self: center;
  margin-bottom: ${units(3)};

  @media (min-width: 500px) {
    ${typeStyle('headerXL')};
  }
`;

export const ChallengeSelectField = styled(Field)`
  width: 100%;

  @media (min-width: 500px) {
    align-self: flex-end;
    width: ${snap(400)};
  }
`;

import { useEffect, useRef, useState } from 'react';
import uniq from 'lodash.uniq';
import { ArrowDownIcon, ArrowUpIcon } from '@m/alchemy-ui';
import { FormattedMessage } from 'react-intl';
import { type NotificationEvent, NotificationReasonType, NotificationType } from '../../../common/core-api';
import { getCardType } from '../helpers/notificationCard';
import { NotificationCardTypes } from '../../../common/enums';
import { getCommentCardType } from '../helpers/commentCard';
import * as FocusHelper from '../../../helpers/focus';
import * as Styled from './NotificationCard.styled';
import { ChallengeContent, CommentContent, Header, IdeaContent } from '.';

export const NOTIFICATION_LIMIT = 3;

export const NotificationCard = ({ notifications }: { readonly notifications: NotificationEvent[] }) => {
  const [expanded, setExpanded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const notificationType: NotificationType = notifications[0].type;
  const notificationReason: NotificationReasonType = notifications[0].reasonType;
  let notificationsToRender = notifications;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expanded && ref.current) {
      const items = FocusHelper.getFocusableElements(ref);
      items && items[currentIndex]?.focus();
    }
  }, [expanded, currentIndex]);

  const updateCurrentIndex = () => {
    const currentIndex = FocusHelper.getActiveElement(ref);

    setCurrentIndex(currentIndex);
  };

  if (notificationType === NotificationType.IDEA_VOTE) {
    const ideaIds: string[] = [];
    notificationsToRender = notifications.filter(({ ideaId }) => {
      if (ideaId && ideaIds.includes(ideaId)) {
        return false;
      }

      ideaId && ideaIds.push(ideaId);
      return true;
    });
  }

  const notificationCount = notificationsToRender.length;
  const isSingle = notificationCount === 1;

  const sameChallenge =
    !isSingle &&
    uniq(notificationsToRender.map((notification: NotificationEvent) => notification.challengeId)).length === 1;
  const sameIdea =
    !isSingle && uniq(notificationsToRender.map((notification: NotificationEvent) => notification.ideaId)).length === 1;
  const sameUser =
    !isSingle && uniq(notificationsToRender.map((notification: NotificationEvent) => notification.userId)).length === 1;

  const cardChallenge = notificationsToRender[0].challenge;
  const cardAuthor = notificationsToRender[0].user;
  const cardIdea = notificationsToRender[0].idea;
  const createdDate = notificationsToRender[0].created;
  const cardType = getCardType(notificationType, notificationReason);
  const commentCardType =
    cardType && [NotificationCardTypes.COMMENT, NotificationCardTypes.BLOG_COMMENT].includes(cardType)
      ? getCommentCardType({ isSingle, notificationReason, sameChallenge, sameIdea, sameUser, notificationType })
      : undefined;

  const isModeratorNotification = notificationReason === NotificationReasonType.CHALLENGE_MODERATOR;
  const isUserNotification =
    notificationReason === NotificationReasonType.FOLLOWED_USER ||
    notificationReason === NotificationReasonType.USER_TAGGED_IN_IDEA;

  const notificationsForDisplay = expanded ? notificationsToRender : notificationsToRender.slice(0, NOTIFICATION_LIMIT);
  const isValidAccess = notificationsForDisplay
    .filter((c) => c.challengeId === cardChallenge?.id)
    .every((i) => i.isValid);

  return (
    <Styled.Card depth="moduleChrome">
      <Header
        type={notificationType}
        notificationCount={notificationCount}
        challenge={cardChallenge}
        author={cardAuthor}
        idea={cardIdea}
        commentCardType={commentCardType}
        time={createdDate}
        isUserNotification={isUserNotification}
        isModeratorNotification={isModeratorNotification}
        sameChallenge={sameChallenge}
        cardType={cardType}
        isValid={isValidAccess}
      />
      <Styled.ContentContainer ref={ref}>
        {cardType === NotificationCardTypes.IDEA &&
          notificationsForDisplay.map(({ idea, created, isValid }) => {
            const createdDate = isSingle ? undefined : created;
            const ideaAuthor = idea?.user;
            const showImage = isSingle
              ? Boolean(idea?.files?.images?.length)
              : notificationsToRender.some((notification) => Boolean(notification?.idea?.files?.images?.length));
            const hideAuthorName = isUserNotification && cardAuthor?.id === ideaAuthor?.id;
            return (
              <IdeaContent
                key={created}
                item={idea}
                author={ideaAuthor}
                showImage={showImage}
                type={notificationType}
                hideAuthorName={hideAuthorName}
                time={createdDate}
                isModeratorNotification={isModeratorNotification}
                isValid={isValid}
              />
            );
          })}
        {cardType === NotificationCardTypes.CHALLENGE &&
          notificationsForDisplay.map(({ challenge, created, isValid }) => (
            <ChallengeContent key={created} time={created} challenge={challenge} isValid={isValid} />
          ))}
        {cardType === NotificationCardTypes.COMMENT &&
          notificationsForDisplay.map(({ idea, user, commentId, challenge, body, created, isValid }) => (
            <CommentContent
              key={created}
              item={idea}
              author={user}
              challenge={challenge}
              type={commentCardType}
              commentText={body}
              time={created}
              commentId={commentId}
              isValid={isValid}
            />
          ))}
        {cardType === NotificationCardTypes.BLOG_POST &&
          notificationsForDisplay.map(({ user, blogPost, created, isValid }) => {
            const createdDate = isSingle ? undefined : created;
            const item = blogPost;
            const author = user;
            const showImage = isSingle
              ? Boolean(blogPost?.files?.images?.length)
              : notificationsToRender.some((notification) => Boolean(notification?.blogPost?.files?.images?.length));
            return (
              <IdeaContent
                key={created}
                item={item}
                author={author}
                showImage={showImage}
                type={notificationType}
                time={createdDate}
                cardType={NotificationCardTypes.BLOG_POST}
                isValid={isValid}
              />
            );
          })}
        {cardType === NotificationCardTypes.BLOG_COMMENT &&
          notificationsForDisplay.map(({ user, blogPost, body, commentId, created, isValid }) => {
            const item = blogPost;
            const author = user;
            return (
              <CommentContent
                key={created}
                item={item}
                author={author}
                type={commentCardType}
                commentText={body}
                time={created}
                commentId={commentId}
                isValid={isValid}
              />
            );
          })}
        {notificationCount > NOTIFICATION_LIMIT && (
          <Styled.ExpandButtonContainer>
            <Styled.ExpandButton
              onClick={() => {
                updateCurrentIndex();
                setExpanded(!expanded);
              }}
              size="extraSmall"
              priority="tertiary"
            >
              {expanded ? (
                <>
                  <FormattedMessage id="collapse" defaultMessage="Collapse" />
                  <ArrowUpIcon aria-hidden="true" />
                </>
              ) : (
                <>
                  <FormattedMessage id="expand" defaultMessage="Expand" />
                  <ArrowDownIcon aria-hidden="true" />
                </>
              )}
            </Styled.ExpandButton>
          </Styled.ExpandButtonContainer>
        )}
      </Styled.ContentContainer>
    </Styled.Card>
  );
};

import { ArrowLeftIcon, Button } from '@m/alchemy-ui';
import { FormattedMessage } from 'react-intl';
import { type RouteLink } from '../../../common/interfaces';
import * as Styled from '../Navigation.styled';

export const AdminNavigation = ({ links }: { links?: RouteLink[] }) => (
  <>
    <Styled.AppNav
      isAdmin
      items={links?.map(({ isActive, linkTitle, url }) => ({
        label: linkTitle || ' ',
        isActive,
        id: linkTitle,
        href: url
      }))}
      stringMap={{
        navList: 'Main Menu',
        mobileMenuButtonTooltip: 'This opens the mobile menu',
        mobileMenuIcon: 'open menu',
        overflowIconButton: 'toggle menu'
      }}
    />
    <Styled.ButtonWrapper>
      <Button
        icon={<ArrowLeftIcon aria-hidden="true" />}
        onClick={() => {
          window.location.href = '/';
        }}
      >
        <FormattedMessage id="back2Community" defaultMessage="Back to Community" />
      </Button>
    </Styled.ButtonWrapper>
  </>
);

import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type BlogPost, type Idea, type IdeaUser, type Maybe, NotificationType } from '../../../common/core-api';
import { useFile, useIsNarrowMobile } from '../../../hooks';
import { removeTags } from '../../../helpers';
import { DefaultIdeaImage, FunnelChallengeIcon, MilestoneChallengeIcon } from '../../../common/icons';
import { NotificationCardTypes, UserType } from '../../../common/enums';
import { RelativeDateWithTooltip, ResponsiveImage, Tooltip } from '../../../common/components';
import { ANONYMOUS_USER_ID, NOTIF_STREAM_DEFAULT_IMAGE_HEIGHT } from '../../shared-constants';
import * as Styled from './IdeaContent.styled';
import { Footer, UserLink } from '.';

export const IdeaContent = ({
  item,
  author,
  showImage,
  type,
  cardType = NotificationCardTypes.IDEA,
  hideAuthorName,
  time,
  isModeratorNotification,
  isValid
}: {
  readonly item?: Maybe<BlogPost | Idea>;
  readonly author?: Maybe<IdeaUser>;
  readonly showImage: boolean;
  readonly cardType?: NotificationCardTypes;
  readonly type: NotificationType;
  readonly hideAuthorName?: boolean;
  readonly time?: string;
  readonly isModeratorNotification?: boolean;
  readonly isValid: boolean;
}) => {
  const intl = useIntl();
  const [getFile] = useFile();
  const isNarrowMobile = useIsNarrowMobile();

  const { id, text, title, files } = item || {};

  const message = intl.formatMessage({
    id: 'youNoLongerHaveAccessToThisItem',
    defaultMessage: 'You no longer have access to this item'
  });
  const ideaImage = files?.images && files?.images[0];
  const defaultIdeaImageSize = 120;

  const [imageSrc, setImageSrc] = useState<string>('');
  const [width, setWidth] = useState(0);
  useEffect(() => {
    const fetchImageSrc = async () => {
      if (!ideaImage) {
        return;
      }

      const imageOptions = {
        file: ideaImage,
        width: isNarrowMobile ? width : defaultIdeaImageSize,
        height: isNarrowMobile ? NOTIF_STREAM_DEFAULT_IMAGE_HEIGHT : defaultIdeaImageSize
      };

      const src = await getFile(imageOptions);
      setImageSrc(src);
    };

    fetchImageSrc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideaImage, isNarrowMobile, width]);

  const linkUrl = cardType === NotificationCardTypes.BLOG_POST ? `/blogpost/${id}` : `/post/${id}`;
  const { displayName, id: authorId } = author || {};
  const userAccess = author && 'userAccess' in author ? author?.userAccess : { userType: '' };
  const isAuthorAnonymous = authorId === ANONYMOUS_USER_ID;
  const isAuthorAdmin = [UserType.SUPER_ADMIN, UserType.ADMIN].includes(Number(userAccess?.userType));
  const clearTitle = removeTags(title || '');

  if (!item || !author) {
    return null;
  }

  const imageResizeCallback = (width: number) => {
    if (!ideaImage) {
      return '';
    }

    setWidth(width);
    return imageSrc;
  };

  return (
    <Styled.ContentContainer data-testid="IdeaContent">
      {showImage &&
        (imageSrc ? (
          <Styled.IdeaImageWrapper>
            <ResponsiveImage callback={imageResizeCallback} altText={title || ''} />
          </Styled.IdeaImageWrapper>
        ) : (
          !isNarrowMobile && (
            <Styled.DefaultImageContainer>
              <DefaultIdeaImage />
            </Styled.DefaultImageContainer>
          )
        ))}
      <Styled.Content>
        <Styled.TitleContainer>
          {type === NotificationType.IDEA_PHASE_REACHED && (
            <Styled.IconContainer>
              <FunnelChallengeIcon />
            </Styled.IconContainer>
          )}
          {type === NotificationType.IDEA_MILESTONE_REACHED && (
            <Styled.IconContainer>
              <MilestoneChallengeIcon />
            </Styled.IconContainer>
          )}
          {isValid ? (
            <Styled.IdeaTitle href={linkUrl} target="_blank" rel="noreferrer">
              {clearTitle}
            </Styled.IdeaTitle>
          ) : (
            <Tooltip content={message}>
              <Styled.IdeaTitleGreyout target="_blank" rel="noreferrer">
                {clearTitle}
              </Styled.IdeaTitleGreyout>
            </Tooltip>
          )}
          {time && <RelativeDateWithTooltip time={time} />}
        </Styled.TitleContainer>
        {!hideAuthorName && (
          <Styled.ByAuthor>
            {isAuthorAnonymous ? (
              <FormattedMessage id="byAnonymous" defaultMessage="by Anonymous " />
            ) : (
              <FormattedMessage
                id="byAuthor"
                defaultMessage="by <a>{authorName} {admin, plural, one {(Admin)} other {}}</a>"
                values={{
                  authorName: displayName,
                  admin: isAuthorAdmin ? 1 : 2,
                  a: (msg) =>
                    UserLink(msg, author.id, intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' }), false)
                }}
              />
            )}
          </Styled.ByAuthor>
        )}
        <Styled.IdeaDescription lines={isModeratorNotification ? 3 : 2} maxWidth="100%">
          {removeTags(text || '')}
        </Styled.IdeaDescription>
        {!isModeratorNotification && isValid && <Footer item={item} type={cardType} />}
      </Styled.Content>
    </Styled.ContentContainer>
  );
};

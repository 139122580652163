export const MilestoneChallengeIcon = () => (
  <svg
    data-testid="MilestoneChallengeIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M3.25,17.25A3.25,3.25,0,1,0,6.5,20.5,3.25,3.25,0,0,0,3.25,17.25Zm0,1.5A1.75,1.75,0,1,1,1.5,20.5,1.751,1.751,0,0,1,3.25,18.75Z"
    />
    <polygon fill="currentColor" points="10.75 19.75 10.75 21.25 5.25 21.25 5.25 19.75 10.75 19.75" />
    <polygon fill="currentColor" points="18.75 19.75 18.75 21.25 13.25 21.25 13.25 19.75 18.75 19.75" />
    <path
      fill="currentColor"
      d="M20.75,17.25A3.25,3.25,0,1,0,24,20.5,3.25,3.25,0,0,0,20.75,17.25Zm0,1.5A1.75,1.75,0,1,1,19,20.5,1.751,1.751,0,0,1,20.75,18.75Z"
    />
    <path fill="currentColor" d="M12,17.25a3.25,3.25,0,1,0,3.25,3.25A3.25,3.25,0,0,0,12,17.25Z" />
    <path
      fill="currentColor"
      d="M21,.25a.75.75,0,0,1,.75.75h0V8a.75.75,0,0,1-.75.75H12.75V15a.75.75,0,0,1-1.493.1L11.25,15V1A.75.75,0,0,1,11.9.257L12,.25Zm-.751,1.5h-7.5v5.5h7.5Z"
    />
  </svg>
);

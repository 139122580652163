import { useIntl } from 'react-intl';
import { type SyntheticEvent } from 'react';

export const useLanguageSwitcher = () => {
  const intl = useIntl();
  const switchLanguage = (lang: string = '', event?: SyntheticEvent): void => {
    event?.preventDefault();
    const confirmationMessage = intl.formatMessage({
      id: 'confirmChangeMessage',
      defaultMessage: 'Changing your language will refresh the page, and any unsaved information will be lost.'
    });

    // eslint-disable-next-line
    if (confirm(confirmationMessage)) {
      const cleared = window.location.href.replace(window.location.search, '');
      window.location.href = cleared + '?set_user_language=' + lang;
    }
  };

  return switchLanguage;
};

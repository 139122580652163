import {
  type User,
  type UserGroup,
  type UserGroupWithModerationStatus,
  type UserWithModerationStatus
} from '../../../common/core-api';

export const hasModerationStatus = (
  item: User | UserGroup | UserGroupWithModerationStatus | UserWithModerationStatus
): item is UserGroupWithModerationStatus | UserWithModerationStatus =>
  (item as UserWithModerationStatus).canModerate !== undefined ||
  (item as UserGroupWithModerationStatus).canModerate !== undefined;

export const isUser = (
  item: User | UserGroup | UserGroupWithModerationStatus | UserWithModerationStatus
): item is User | UserWithModerationStatus =>
  (item as UserWithModerationStatus).user !== undefined || (item as User).displayName !== undefined;

import { useState } from 'react';
import { useIntl } from 'react-intl';
import { borderRadius, color, ImportantIcon, Popper, PopperBox, Radio, snap, units } from '@m/alchemy-ui';
import styled, { css } from 'styled-components';
import { type FormikProps, useFormikContext } from 'formik';
import uniq from 'lodash.uniq';
import { hasValue } from '@m/magic-typescript';
import { SelectorType } from '../../../common/enums';
import { type Maybe, type ScorecardLabel, type Voting, VotingType } from '../../../common/core-api';
import { useChallenge } from '../context/Challenge';

interface VotingOptionProps {
  selected: boolean;
}
const Container = styled.div`
  position: relative;
  width: ${snap(270)};
  max-width: 100%;
  border-radius: ${borderRadius('medium')};
`;
const VotingOption = styled.div<VotingOptionProps>`
  display: flex;
  align-items: center;
  padding: ${units(2)};
  border: 1px solid ${color('line')};
  :not(:first-of-type) {
    border-top: 0;
  }
  :first-of-type {
    border-radius: ${borderRadius('medium')} ${borderRadius('medium')} 0 0;
  }
  :last-of-type {
    border-radius: 0 0 ${borderRadius('medium')} ${borderRadius('medium')};
  }
  ${(props) =>
    props.selected
      ? css`
          background-color: ${color('canvas')};
          ::before {
            border-top: 13px solid transparent;
            border-bottom: 13px solid transparent;
            border-left: 13px solid ${color('line')};
            position: absolute;
            right: -12px;
            content: '';
            width: 0;
            height: 0;
          }
          ::after {
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 12px solid ${color('canvas')};
            position: absolute;
            right: -11px;
            content: '';
            width: 0;
            height: 0;
          }
        `
      : ''}
`;

const RadioLabel = styled.span`
  margin-left: ${units(1)};
`;

const StyledIcon = styled(ImportantIcon)`
  margin-left: auto;
`;

export const VotingSelector = ({ type }: { readonly type: SelectorType }) => {
  const { values, setFieldValue }: FormikProps<{ id: string; voting: Voting }> = useFormikContext();
  const intl = useIntl();
  const challenge = useChallenge();

  const isExistingMilestone = challenge.milestone?.milestones?.find((milestone) => milestone.id === values.id);
  const disableChange = type === SelectorType.MILESTONE_VOTE && isExistingMilestone && Boolean(challenge.ideas?.length);

  let votingtypes = [VotingType.UPDOWN, VotingType.UP, VotingType.STARS, VotingType.POINTSAWARD, VotingType.SCORECARD];

  const { voting } = values;

  if (type === SelectorType.MILESTONE_VOTE) {
    votingtypes = [VotingType.UP, VotingType.SCORECARD];
  }

  const votingType = voting?.type;
  const [votingSelection, setVotingSelection] = useState(votingType ? votingType : VotingType.UPDOWN);

  const messages = {
    [VotingType.UPDOWN]: {
      label: intl.formatMessage({ id: 'thumbsUpDownVoting', defaultMessage: 'Thumbs up/down' }),
      message: intl.formatMessage({
        id: 'thumbsUpDownVotingHelpText',
        defaultMessage:
          'Users give a thumbs up or thumbs down to ideas. A thumbs up vote gives an idea a point, a thumbs down vote takes a point away.'
      })
    },
    [VotingType.UP]: {
      label: intl.formatMessage({ id: 'thumbsUpVoting', defaultMessage: 'Thumbs up' }),
      message: intl.formatMessage({
        id: 'thumbsUpVotingHelpText',
        defaultMessage: "Users can give a 'Thumbs up' vote to an idea. Each vote gives the idea 1 point."
      })
    },
    [VotingType.STARS]: {
      label: intl.formatMessage({ id: 'starsVoting', defaultMessage: 'Stars' }),
      message: intl.formatMessage({
        id: 'starsVotingHelpText',
        defaultMessage: "Users give ideas a star rating (eg 3 out of 5 stars). An idea's score is the average rating."
      })
    },
    [VotingType.POINTSAWARD]: {
      label: intl.formatMessage({ id: 'pointsVoting', defaultMessage: 'Points' }),
      message: intl.formatMessage({
        id: 'pointsVotingHelpText',
        defaultMessage:
          "Users give points to ideas. The maximum number of points that users can give to ideas is set in the voting options. An idea's score is the total of all points awarded to it."
      })
    },
    [VotingType.SCORECARD]: {
      label: intl.formatMessage({ id: 'scorecardVoting', defaultMessage: 'Scorecard' }),
      message: intl.formatMessage({
        id: 'scorecardVotingHelpText',
        defaultMessage:
          'Users rate ideas against different criteria. The idea will have an average score for each criterion, and an overall score, averaged across all criteria.'
      })
    }
  };

  const handleVotingTypeChange = (selection: VotingType) => {
    const validScorecards = voting.scorecards?.cards?.filter((item) => item.name?.length !== 0);

    let scorecardLabels: Maybe<ScorecardLabel[]> | undefined = voting.scorecards?.labels;
    if (type === SelectorType.PHASE && selection === VotingType.SCORECARD) {
      const labels = uniq(challenge.phases?.flatMap((phase) => phase.voting?.scorecards?.labels).filter(hasValue));

      scorecardLabels = uniq([...(scorecardLabels || []), ...labels]);
    }

    setVotingSelection(selection);
    setFieldValue('voting', {
      ...voting,
      type: selection,
      scorecards: { cards: validScorecards, labels: scorecardLabels }
    });
  };

  return (
    <Container>
      {votingtypes.map((type) => {
        const selected = type === votingSelection;

        return (
          <VotingOption key={type} selected={selected}>
            <Radio
              label={<RadioLabel>{messages[type].label}</RadioLabel>}
              name="column-group"
              checked={selected}
              onChange={() => handleVotingTypeChange(type)}
              disabled={disableChange}
            />
            <Popper
              placement="top"
              mode="tooltip"
              overlay={
                <PopperBox hasArrow colorScheme="alchemyDark">
                  {messages[type].message}
                </PopperBox>
              }
              trigger={
                <StyledIcon aria-label={intl.formatMessage({ id: 'importantTrigger', defaultMessage: 'Important' })} />
              }
            />
          </VotingOption>
        );
      })}
    </Container>
  );
};

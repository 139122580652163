import { type ChangeEvent, useEffect, useState } from 'react';
import { CheckboxGroup, Popper } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { PEOPLE_SEARCH_CHARACTER_MIN } from '../../shared-constants';
import * as Styled from './SearchFilters.styled';

export const FilterWithCheckbox = ({
  id,
  name,
  options,
  selectedFilters,
  handleAddFilter,
  handleRemoveFilter,
  handleSelectAll,
  clearSearch,
  handleClearSearch
}: {
  readonly id: string;
  readonly name: string;
  readonly options?: string[] | null;
  readonly selectedFilters?: string[];
  readonly handleAddFilter: ({ id, option }: { id: string; option: string }) => void;
  readonly handleRemoveFilter: ({ id, option }: { id: string; option: string }) => void;
  readonly handleSelectAll: (id: string) => void;
  readonly clearSearch: boolean;
  readonly handleClearSearch: (value: boolean) => void;
}) => {
  const intl = useIntl();
  const selectedFiltersCount = selectedFilters?.length;
  const [selections, setSelections] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const selectAll = intl.formatMessage({ id: 'selectAll', defaultMessage: '{name} - All' }, { name });

  useEffect(() => {
    if (clearSearch) {
      setSearchText('');
      handleClearSearch(false);
    }
  }, [handleClearSearch, clearSearch]);

  useEffect(() => {
    !searchText && options && setSelections([selectAll, ...options]);
  }, [options, searchText, selectAll]);

  if (!options) {
    return null;
  }

  const handleSearchTextChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchText(value);

    const searchTextTrimmed = value.trim();
    if (searchTextTrimmed.length === 0) {
      setSelections([selectAll, ...options]);
    }

    if (searchTextTrimmed.length >= PEOPLE_SEARCH_CHARACTER_MIN) {
      const matchingSelections = options.filter((option) =>
        option.toLowerCase().includes(searchTextTrimmed.toLowerCase())
      );
      setSelections([selectAll, ...matchingSelections]);
    }
  };

  return (
    <Popper
      trigger={
        <Styled.FilterButton priority="secondary">
          <Styled.FilterName>{name}</Styled.FilterName>
          {selectedFiltersCount ? (
            <Styled.FiltersCount>{selectedFiltersCount}</Styled.FiltersCount>
          ) : (
            <Styled.FiltersCount>{intl.formatMessage({ id: 'all', defaultMessage: 'All' })}</Styled.FiltersCount>
          )}
          <Styled.ArrowDown aria-hidden="true" />
        </Styled.FilterButton>
      }
      zIndex={990} // should be below nav bar's z-index which is 998
      placement="bottom-start"
      overlay={() => (
        <Styled.StyledPopperBox hasArrow maxWidth={250} overflow="auto">
          <Styled.CheckboxFilters>
            {options.length > 10 && (
              <Styled.StyledSearchField
                isFullWidth
                onChange={handleSearchTextChange}
                value={searchText}
                title={intl.formatMessage({ id: 'searchSelectOptions', defaultMessage: 'Search select options' })}
              />
            )}
            <CheckboxGroup>
              {() =>
                selections.map((option, index) => {
                  const selected =
                    (index === 0
                      ? !selectedFilters || selectedFilters.length === 0
                      : selectedFilters?.includes(option)) || false;

                  return (
                    <Styled.StyledCheckbox
                      key={option}
                      name={option}
                      label={option}
                      checked={selected}
                      onChange={() => {
                        index === 0
                          ? handleSelectAll(id)
                          : selected
                            ? handleRemoveFilter({ id, option })
                            : handleAddFilter({ id, option });
                      }}
                    />
                  );
                })
              }
            </CheckboxGroup>
          </Styled.CheckboxFilters>
        </Styled.StyledPopperBox>
      )}
    />
  );
};

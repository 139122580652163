import { useIntl } from 'react-intl';
import { IconButton, Popper, PopperBox, PortalRoot, units } from '@m/alchemy-ui';
import * as Styled from '../Navigation.styled';
import { SearchForm } from './SearchForm';

export const SearchButton = () => {
  const intl = useIntl();

  return (
    <Popper
      placement="bottom"
      trigger={
        <IconButton
          icon={
            <Styled.SearchButton
              aria-label={intl.formatMessage({ id: 'Icons.SearchIcon', defaultMessage: 'Search Icon' })}
              size="18"
            />
          }
          tooltipContents={intl.formatMessage({
            id: 'Search for users and ideas',
            defaultMessage: 'Search for users and ideas'
          })}
        />
      }
      // PopperBoxes are a little finnicky with click events inside them so we must wrap it in a portal root and stop the click propagation
      overlay={
        <PortalRoot>
          <PopperBox
            /* @ts-expect-error */
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
            }}
            hasArrow={false}
            maxWidth="100%"
            hasPadding={false}
            style={{ margin: units(2) }}
          >
            <SearchForm />
          </PopperBox>
        </PortalRoot>
      }
    />
  );
};

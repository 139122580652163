import { createRoot } from 'react-dom/client';
import { Application } from './application';
import { type AppProps } from './common/interfaces';

declare global {
  interface Window {
    _APPLICATION_INITIAL_STATE_: AppProps;
    _APPLICATION_LANGUAGE_OVERRIDES_: {
      [key: string]: string;
    };
    _SPA_APPLICATION_ENABLED_: boolean;
    AJAX_ROUTER_URL?: string;
    startCrowdicityApplication: () => void;
    applicationEventAction: (s: string) => void;
  }
}

export const startCrowdicityApplication = () => {
  const domNode = document.createElement('div');
  const alchemy = document.querySelector('#crowdicity-application');
  if (alchemy?.getAttribute('data-initialized') !== 'true') {
    alchemy?.appendChild(domNode);
    const root = createRoot(domNode);
    root.render(<Application {...window._APPLICATION_INITIAL_STATE_} />);
    alchemy?.setAttribute('data-initialized', 'true');
  }
};

window.startCrowdicityApplication = startCrowdicityApplication;

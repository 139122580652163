import { type CommunityDesign } from '.';

export interface RuntimeCommunity {
  id: string;
  name: string;
  logo: string;
  colors: CommunityDesign;
  leaderboardCount: string;
  announcements: { link?: string; text: string; type: 'downtime' | 'features' | 'news' }[];
  banner: string;
  description?: string;
  isNameInBanner?: boolean;
}

export const defaultRuntimeCommunity = {
  id: '0',
  announcements: [],
  banner: '',
  name: '',
  logo: '',
  leaderboardCount: '20',
  colors: {
    link: '4D615C',
    voteBar: '99A1DB',
    banner: 'C6BC40',
    button: '81325D',
    navBg: '',
    ideaTitle: '',
    bg: 'EAEAEA',
    keyLineDisplay: '0',
    bannerDisplay: '',
    newBannerHeight: 'auto',
    backgroundFile: 0,
    backgroundPosition: 'center top',
    backgroundRepeat: 'repeat',
    backgroundAttachment: '1',
    newBannerHeightAutoCalculated: 'NAN',
    userNavBg: 'Dark'
  }
};

import styled, { css } from 'styled-components';
import { color, IconButton, transition, units, variant } from '@m/alchemy-ui';

export const DeleteIconButton = styled(IconButton)`
  display: flex;
  align-items: center;
  margin-left: ${units(2)};
  padding: ${units(1)};
  border: 1px solid ${color('lineContrast')};
  border-radius: 50%;
  background: transparent;
  transition: ${transition('base', 'all', '.1s')};

  ${variant('size', {
    small: css`
      padding: ${units(0.5)};
      border: 0;
    `
  })}

  &:hover {
    border-color: ${color('highlight', { palette: 'interactiveDestructive' })};
    background: ${color('hint', { palette: 'interactiveDestructive' })};
  }
  svg,
  &:hover > svg,
  svg:hover {
    color: ${color('base', { palette: 'interactiveDestructive' })} !important;
  }
`;

import { type ChangeEvent, useEffect, useState } from 'react';
import { Button, DeleteIcon, Input, type Option, Select } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { type FormikProps, useFormikContext } from 'formik';
import { hasValue } from '@m/magic-typescript';
import { GET_SCORECARDS, type GetScorecardsQuery } from '../../../graphql/queries';
import { type MixedTypes } from '../../../common/interfaces';
import { Body, DeleteIconButton, SubTitle } from '../../../common/components';
import { SelectorType } from '../../../common/enums';
import { useFieldError } from '../../../hooks';
import { type Maybe, type Milestone, type Phase, type ScorecardLabel } from '../../../common/core-api';
import { useChallenge } from '../context/Challenge';
import { hasPhaseStarted } from '../helpers/phase';
import * as Styled from './Scorecard.styled';
import { TranslatableInput } from '.';

export const Scorecard = ({ type }: { readonly type: SelectorType }) => {
  const intl = useIntl();
  const challenge = useChallenge();
  const { phases } = challenge;

  const { values, setFieldValue, handleBlur }: FormikProps<MixedTypes> = useFormikContext();
  const { voting, ideasHaveVotes } = values;

  const [fieldError] = useFieldError();

  const { scorecards } = values.voting;

  const { data, error, loading } = useQuery<GetScorecardsQuery>(GET_SCORECARDS, {
    variables: { id: challenge.id },
    skip: type === SelectorType.MILESTONE_VOTE
  });
  const [selection, setSelection] = useState<Option>();
  const [scorecardEditDisabled, setScorecardEditDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (scorecards?.cards?.length === 0) {
      setScorecardEditDisabled(false);
    } else if (type === SelectorType.PHASE) {
      const phase = phases?.find((phase) => phase.id === values.id);
      setScorecardEditDisabled(Boolean(phase?.startDate && hasPhaseStarted(phase.startDate)));
    } else {
      setScorecardEditDisabled(Boolean(ideasHaveVotes));
    }
    // disable the lint rule to exclude 'scorecards' from the dept array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideasHaveVotes, phases, type, values.id]);

  const handleChange = ({ name, value }: { name: string; value: unknown }) => {
    setFieldValue(name, value);
  };

  const handleClone = (option: Option) => {
    if (loading || !data) {
      return;
    }

    setSelection(option);

    // value ex) challenge-624-phase-145, challenge-624-milestone-212
    const splitValue = option.value.split('-');
    const challengeId = splitValue[1];
    const phaseId = splitValue[2] === 'phase' ? splitValue[3] : null;
    const milestoneId = splitValue[2] === 'milestone' ? splitValue[3] : null;

    const clonedChallenge = data.getScorecards.find((challenge) => challenge.id === challengeId);

    let clonedScorecards;
    if (phaseId) {
      clonedScorecards = clonedChallenge?.phases?.find((phase) => phase.id === phaseId)?.voting.scorecards;
    } else if (milestoneId) {
      clonedScorecards = clonedChallenge?.milestone?.milestones?.find((milestone) => milestone.id === milestoneId)
        ?.voting?.scorecards;
    } else {
      clonedScorecards = clonedChallenge?.voting?.scorecards;
    }

    return (
      clonedScorecards &&
      setFieldValue('voting', {
        ...voting,
        scorecards: clonedScorecards
      })
    );
  };

  const handleAdd = () => {
    const value = [
      ...(scorecards?.cards || []),
      {
        id: Date.now().toString(),
        name: '',
        milestoneId: type === SelectorType.MILESTONE_VOTE ? values.id : null,
        phaseId: type === SelectorType.PHASE ? values.id : null
      }
    ];
    setFieldValue('voting', { ...voting, scorecards: { ...voting?.scorecards, cards: value } });
  };

  const handleClear = () => {
    setFieldValue('voting', { ...voting, scorecards: { ...voting?.scorecards, cards: [] } });
    setScorecardEditDisabled(false);
  };

  const handleDelete = (index: number) => {
    const value = scorecards?.cards?.filter((card, cardIndex) => cardIndex !== index);
    setFieldValue('voting', { ...voting, scorecards: { ...voting?.scorecards, cards: value } });
  };

  const handleLabelChange = (e: ChangeEvent<HTMLInputElement>, rating: number) => {
    const { value } = e.target;

    if (value === '') {
      const newLabelsValue = scorecards?.labels?.filter((label) => String(label.rating) !== String(rating));
      return setFieldValue('voting.scorecards.labels', newLabelsValue);
    }

    const existingLabelIndex = scorecards?.labels?.findIndex((label) => String(label.rating) === String(rating));

    if (existingLabelIndex === undefined || existingLabelIndex === -1) {
      const newLabel = { id: Date.now().toString(), name: value, rating: String(rating) };
      const newLabelsValue = [...(scorecards?.labels || []), newLabel];
      return setFieldValue('voting.scorecards.labels', newLabelsValue);
    }

    const name = `voting.scorecards.labels[${existingLabelIndex}].name`;
    return setFieldValue(name, value);
  };

  const getNestedSelectOptions = ({
    objects,
    type,
    closeAndReturnFocus,
    challengeId,
    challengeTitle
  }: {
    objects: Array<Milestone> | Array<Phase> | null | undefined;
    type: string;
    closeAndReturnFocus: (() => void) | undefined;
    challengeId: string;
    challengeTitle: string;
  }) => {
    const nestedScorecards = (
      objects?.map((object) => {
        const { name, id, voting } = object;
        const cards = voting?.scorecards?.cards;
        const value = `challenge-${challengeId}-${type}-${id}`;

        if (cards && cards.length > 0) {
          return (
            <Select.Option
              key={value}
              label={name}
              value={value}
              isNested
              onClick={(option) => {
                closeAndReturnFocus?.();
                handleClone(option);
              }}
              isSelected={selection?.value === value}
            />
          );
        }

        return false;
      }) as (Element | false)[]
    ).filter(hasValue);

    if (nestedScorecards && nestedScorecards.length > 0) {
      return [
        <Select.Option key={`challenge-${challengeId}`} label={challengeTitle} value={challengeTitle} disabled />,
        ...nestedScorecards
      ];
    }

    return [];
  };

  const getSelectOptions = (closeAndReturnFocus: (() => void) | undefined) =>
    data?.getScorecards.flatMap((challenge) => {
      const { id: challengeId, title: challengeTitle, isFunnel, isMilestone, phases, milestone } = challenge;

      if (isFunnel) {
        return getNestedSelectOptions({
          objects: phases as Phase[],
          type: 'phase',
          closeAndReturnFocus,
          challengeId,
          challengeTitle
        });
      }

      if (isMilestone) {
        return getNestedSelectOptions({
          objects: milestone?.milestones as Milestone[],
          type: 'milestone',
          closeAndReturnFocus,
          challengeId,
          challengeTitle
        });
      }

      const value = `challenge-${challengeId}`;
      return (
        <Select.Option
          key={value}
          label={challengeTitle}
          value={value}
          onClick={(option) => {
            closeAndReturnFocus?.();
            handleClone(option);
          }}
          isSelected={selection?.value === value}
        />
      );
    });

  const getSelectChallengeLabel = () => (
    <Styled.StyledLabel>
      {intl.formatMessage({ id: 'selectChallenge', defaultMessage: 'Select challenge' })}
    </Styled.StyledLabel>
  );

  return (
    <>
      <Styled.StyledButton onClick={handleClear} destructive priority="secondary">
        <FormattedMessage id="clearScorecard" defaultMessage="Clear scorecard" />
      </Styled.StyledButton>

      {loading && <Styled.StyledLoadingIndicator />}

      {!loading && !error && data && type !== SelectorType.MILESTONE_VOTE && !scorecardEditDisabled && (
        <Styled.StyledField
          label={intl.formatMessage({
            id: 'scorecardPreviousChallenge',
            defaultMessage: 'Use a scorecard from a previous challenge'
          })}
          input={
            <Select
              name="name"
              value={selection?.value}
              noOptionsMessage={intl.formatMessage({
                id: 'noScorecards',
                defaultMessage: 'There are no challenges with scorecards'
              })}
              placeholder={getSelectChallengeLabel()}
              renderSelectedOption={() => (selection?.label as string) || getSelectChallengeLabel()}
            >
              {({ closeAndReturnFocus }) => (
                <>
                  <Select.Option label={getSelectChallengeLabel()} value="" disabled />
                  {getSelectOptions(closeAndReturnFocus)}
                </>
              )}
            </Select>
          }
          labelVariant="emphasized"
          isFullWidth
        />
      )}

      <SubTitle>
        <FormattedMessage id="criteria" defaultMessage="Criteria" />
      </SubTitle>
      {scorecards?.cards?.map((card, index) => {
        const showCard = type === SelectorType.CHALLENGE ? !card.phaseId && !card.milestoneId : true;
        return (
          <Styled.FlexRow key={card.id} visible={showCard}>
            <div>
              <Styled.StyledField
                label={intl.formatMessage({ id: 'title', defaultMessage: 'Title' })}
                status={fieldError(`voting.scorecards.cards[${index}].name`)}
                input={
                  <TranslatableInput type="Scorecard" field="name" translateId={card.id}>
                    <Input
                      name={`voting.scorecards.cards[${index}].name`}
                      value={card.name || undefined}
                      placeholder={intl.formatMessage({
                        id: 'scorecardCriteriaNamePlaceholder',
                        defaultMessage: 'Criterion name'
                      })}
                      onChange={({ target: { name, value } }) => handleChange({ name, value })}
                      onBlur={handleBlur}
                    />
                  </TranslatableInput>
                }
                labelVariant="emphasized"
                isFullWidth
                required
              />
              <Styled.StyledField
                label={intl.formatMessage({ id: 'description', defaultMessage: 'Description' })}
                input={
                  <TranslatableInput type="Scorecard" field="description" translateId={card.id}>
                    <Input
                      name={`voting.scorecards.cards[${index}].description`}
                      value={card.description || ''}
                      placeholder={intl.formatMessage({
                        id: 'scorecardCriteriaDescriptionPlaceholder',
                        defaultMessage: 'Criterion description'
                      })}
                      onChange={({ target: { name, value } }) => handleChange({ name, value })}
                    />
                  </TranslatableInput>
                }
                labelVariant="emphasized"
                isFullWidth
              />
            </div>
            {!scorecardEditDisabled && (
              <DeleteIconButton
                icon={
                  <DeleteIcon aria-label={intl.formatMessage({ id: 'deleteIcon', defaultMessage: 'Delete icon' })} />
                }
                tooltipContents={intl.formatMessage({ id: 'Delete', defaultMessage: 'Delete' })}
                onClick={() => handleDelete(index)}
              />
            )}
          </Styled.FlexRow>
        );
      })}

      {scorecardEditDisabled ? (
        type === SelectorType.PHASE ? (
          <FormattedMessage
            id="scorecardEditDisabledMessageForPhase"
            defaultMessage="You cannot add or remove criteria in this scorecard because this phase has already started. If you need to make changes, use ‘Clear scorecard’ button to start over."
          />
        ) : (
          <FormattedMessage
            id="scorecardEditDisabledMessage"
            defaultMessage="You cannot add or remove criteria in this scorecard because some of the ideas have already been voted on. If you need to make changes, use ‘Clear scorecard’ button to start over."
          />
        )
      ) : (
        <Button onClick={handleAdd} priority="secondary">
          <FormattedMessage id="addCriteria" defaultMessage="Add a criterion" />
        </Button>
      )}

      <Styled.StyledExpandableContent
        hasDivider={false}
        label={intl.formatMessage({ id: 'scoringLabels', defaultMessage: 'Scoring labels' })}
      >
        <Body>
          <FormattedMessage
            id="scoringLabelsHelp"
            defaultMessage='Add labels to scores to provide users with some context of their rating. For example, you could label 1 as "Poor", 5 as "OK" and 10 as "Excellent". These labels apply to all the criteria that you add to the score card.'
          />
        </Body>
        {Array.from({ length: 10 }, (_, index) => index).map((value) => {
          const label: Maybe<ScorecardLabel> | undefined = scorecards?.labels?.find(
            (label) => label.rating === String(value + 1)
          );
          return (
            <Styled.StyledField
              key={String(value)}
              label={value + 1}
              input={
                <TranslatableInput type="ScorecardLabel" field="name" translateId={label?.id || ''}>
                  <Input
                    name="name"
                    value={label?.name || ''}
                    onChange={(e) => handleLabelChange(e, value + 1)}
                    placeholder={intl.formatMessage({ id: 'Optional', defaultMessage: 'Optional' })}
                  />
                </TranslatableInput>
              }
              isFullWidth
              labelVariant="emphasized"
              layoutVariant="inline"
            />
          );
        })}
      </Styled.StyledExpandableContent>
    </>
  );
};

import { atMinWidth, color, Select, Tabs, typeStyle, units } from '@m/alchemy-ui';
import styled from 'styled-components';
import { Breakpoints } from '../../../../common/enums/Breakpoints';

export const Title = styled.h1`
  ${typeStyle('headerL')};
  ${color('typeHeader')};
  margin-bottom: ${units(2.5)};
`;

export const SectionTitle = styled.h3`
  ${typeStyle('headerL', { marginStrategy: 'bottom' })};
  ${color('typeSubHeader')};
`;

export const Wrapper = styled.div`
  min-height: 100%;
  position: relative;
  padding: ${units(1)};
  ${atMinWidth(
    Breakpoints.WIDE_MOBILE_MIN,
    `
      padding: ${units(2)};
    `
  )};
  ${atMinWidth(
    Breakpoints.TABLET_MIN,
    `
      padding: ${units(2)} ${units(3)};
    `
  )};
`;

export const SettingsTabs = styled(Tabs)`
  ${atMinWidth(
    Breakpoints.DESKTOP_MIN,
    `
      display: flex;
    `
  )};

  display: none;
`;

export const SettingsSelect = styled(Select)`
  ${atMinWidth(
    Breakpoints.DESKTOP_MIN,
    `
      display: none;
    `
  )};

  display: block;
`;

export const useUrlParams = () => {
  const insertUrlParam = (key: string, value: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newurl =
      window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  return [insertUrlParam] as const;
};

export const useUrlPath = () => {
  const addUrlPath = (value: string) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      value +
      window.location.search;
    window.history.pushState({ path: newurl }, '', newurl);
  };

  return [addUrlPath] as const;
};

export const useRoutePath = () => {
  const addRoutePath = (path: string) => {
    const newurl = window.location.protocol + '//' + window.location.host + '/' + path;
    window.history.pushState({ path: newurl }, '', newurl);
  };

  return [addRoutePath] as const;
};

import { useIntl } from 'react-intl';
import { type UserFieldsQuery } from '../../graphql/queries';
import { GridHeader, GridRow } from './Analytics.styled';
import * as Styled from './components/SearchFilters.styled';
import { CheckboxFilter, TextInputFilter } from './components';
import { type UserFieldType } from './types';
import { handleTextInputFilterChange } from './helpers';

export const CustomFilters = ({
  userFieldOptions,
  communityUserFields,
  countries,
  userFieldUpdate,
  setSkip
}: {
  readonly userFieldOptions: UserFieldType;
  readonly communityUserFields: UserFieldsQuery | undefined;
  readonly countries: string[];
  readonly userFieldUpdate: (userField: UserFieldType) => void;
  readonly setSkip?: (skip: boolean) => void;
}) => {
  const intl = useIntl();
  return (
    <>
      <GridHeader key="advancedFiltersHeader">
        {intl.formatMessage({ id: 'filterByUser', defaultMessage: 'Filter by user' })}
      </GridHeader>
      <GridRow key="advancedFilters">
        <Styled.AdvancedFilters data-testid="advancedFilters" displayAdvancedFilters>
          {communityUserFields?.userFields?.map(({ id, name, type, options }) => {
            const selectedFilters = userFieldOptions[id + (type === 'option' ? '_option' : '')];

            if (type === 'option') {
              const filteredOptions = options?.filter(Boolean);
              if (!filteredOptions || filteredOptions.length === 0) {
                return null;
              }

              return (
                <CheckboxFilter
                  key={id}
                  id={id + '_option'}
                  name={name}
                  options={filteredOptions}
                  selectedFilters={selectedFilters}
                  userFieldUpdate={userFieldUpdate}
                  userFieldOptions={userFieldOptions}
                  communityUserFields={communityUserFields}
                  setSkip={setSkip}
                />
              );
            }

            return (
              <TextInputFilter
                key={id}
                id={id}
                name={name}
                setSkip={setSkip}
                selectedFilters={selectedFilters}
                userFieldUpdate={userFieldUpdate}
                userFieldOptions={userFieldOptions}
                handleChange={handleTextInputFilterChange}
              />
            );
          })}

          <CheckboxFilter
            key="countries"
            id="countries"
            name="Countries"
            selectedFilters={userFieldOptions.countries}
            userFieldUpdate={userFieldUpdate}
            options={countries}
            countries={countries}
            userFieldOptions={userFieldOptions}
            communityUserFields={communityUserFields}
            setSkip={setSkip}
          />
        </Styled.AdvancedFilters>
      </GridRow>
    </>
  );
};
